import React from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import ReactHtmlParser from "html-react-parser";
import {
  HELPER,
  IMAGE_SRC,
  CART_HELPER,
  ANALYTICS_HELPER,
} from "../../../utils";

let productImage;

function FORMAT_PRICE(currency_code, product) {
  const { product_total, product_sub_total, campaign_id, campaign_discount_price, campaign_discount_sale_price } = product;

  const price = HELPER.isNotEmpty(campaign_id) ? campaign_discount_price : product_sub_total;
  const discountedPrice = HELPER.isNotEmpty(campaign_id) ? campaign_discount_sale_price : product_total

  const formattedPrice = HELPER.formatPrice(currency_code, price)
  const discountPrice = HELPER.formatPrice(currency_code, discountedPrice)

  if (!HELPER.isEmpty(formattedPrice?.charges) && formattedPrice?.charges !== discountPrice?.charges) {
    return <span><span>{formattedPrice?.currency}</span> {formattedPrice?.charges}</span>;
  }
  return "";
}

function FORMAT_DISCOUNTED_PRICE(currency_code, product) {
  const { product_total, product_sub_total, campaign_id, campaign_discount_price, campaign_discount_sale_price } = product;

  const price = HELPER.isNotEmpty(campaign_id) ? campaign_discount_price : product_sub_total;
  const discountedPrice = HELPER.isNotEmpty(campaign_id) ? campaign_discount_sale_price : product_total

  const formattedPrice = HELPER.formatPrice(currency_code, price)
  const discountPrice = HELPER.formatPrice(currency_code, discountedPrice)

  if (!HELPER.isEmpty(discountPrice?.charges) || formattedPrice?.charges === discountPrice?.charges) {
    return <span><span>{discountPrice?.currency}</span> {discountPrice?.charges}</span>;
  }
  return "";
}


const showProductImage = (items) => {
  const image = items.product_image;
  if (!HELPER.isEmpty(image)) {
    productImage = image;
  } else {
    productImage = IMAGE_SRC.PRODUCT_PLACEHOLDER;
  }
  return productImage;
};

function CartItem() {
  const translate = useTranslate();

  const { items } = useSelector(state => state.cart.cart_details);
  const { currency_code } = useSelector(state => state.cart.cart_details.summary);
  const { is_customer_app } = useSelector(state => state.configuration.config);

  return (
    <div className="cartItemSection">
      {items.map((items, i) => {
        return (
          <div className="cartItemBox" key={i}>
            <div className="sale-header-off">
              {HELPER.CAMPAIGN_SALE_NAME(items)}
              <div className="cartImage" onClick={() => { ANALYTICS_HELPER._productClick(items, i) }}>
                {HELPER.PRODUCT_OFF(items)}
                <img src={showProductImage(items)} alt={items.product_name} />
              </div>
            </div>
            <div className="cartInfo">
              <div className={`${HELPER.wordCount(items.product_name) > 1 ? "" : "cartProductNameClass"} cartProductStore`} key={items.product_name}>
                {is_customer_app === 1 &&
                  <div className="storeInfo d-flex">
                    <div className="store-icon">
                      <img src={items?.store?.branding?.favicon} alt="bSecure" />
                    </div>
                    <div className="store-name">
                      {items?.store?.name}
                    </div>
                  </div>
                }
              </div>
              <div className={`${HELPER.wordCount(items.product_name) > 1 ? "" : "cartProductNameClass"} cartProductName d-flex`}>
                <div onClick={() => { ANALYTICS_HELPER._productDetail(items) }}>
                  <span>{items.product_name}</span>
                  {ReactHtmlParser(
                     HELPER.parseMessage(
                    CART_HELPER.PRODUCT_ATTRIBUTES(items?.product_attributes_json)
                  ))}
                </div>
              </div>
              {
                !HELPER.isEmpty(items.product_additional_notes) ?
                  <div className="cartInfoAddNotes">
                    <span> {items.product_additional_notes} </span>
                  </div> : ""
              }
              <div className="cartInfoBottom">
                <div className="qty">
                  {ReactHtmlParser(
                    HELPER.parseMessage(
                    HELPER.parseQuantity(
                      translate("quantity_keyword"),
                      items.product_qty
                    )
                  ))}
                </div>
                <div className="price">
                  <div className="slideProdPrice suggestPrice strikeText"> {FORMAT_PRICE(currency_code, items)} </div>
                  <div className="slideProdPrice newPrice"> {FORMAT_DISCOUNTED_PRICE(currency_code, items)} </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}


export default CartItem;
