import React from "react";
import { useTranslate } from "react-redux-multilingual/lib/context";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import  HtmlTooltip from "../../../components/HtmlTooltip";
import {
  CONSTANTS,
  IMAGE_SRC,
} from "../../../utils";


function PAYMENT_PROTECTION() {
    const translate = useTranslate();
  
    return (<div className="card-header card-payment-protection">
      <div className="payment-protection">
        <div className="iconImage">
          <img src={IMAGE_SRC.PAYMENT_METHODS.PAYMENT_PROTECTION} alt="credit card" />
        </div>
        <div className="protection">
          {translate("CART.PAYMENT_PROTECTION")}
          <span>
            <IconButton
              aria-label="delete"
              color="primary"
              disableFocusRipple={true}
              className="toolTipButton"
            >
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 1000 }}
                overlay={HtmlTooltip}
                popperConfig={{
                  props: {
                    state: CONSTANTS.CHECKOUT_INFO.PAYMENT_METHOD.PAYMENT_GUARENTEED,
                  },
                }}
              >
                <InfoIcon />
              </OverlayTrigger>
            </IconButton>
          </span>
        </div>
      </div>
    </div>)
  };

  export default PAYMENT_PROTECTION