// @flow
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useTranslate } from "react-redux-multilingual/lib/context";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  CONSTANTS,
  ANALYTICS_HELPER,
  GOOGLE_ANALYTICS_HELPER,
  TEXT_FORMAT,
  HELPER
} from "../../../../utils";
import {
  paymentAction
} from "../../../../store/actions";

const maxCharLimit = 24;

function APGBankAccount(props) {
  const {
    data,
    payment,
  } = props;
  let dispatch = useDispatch();
  const translate = useTranslate();
  const { selected_payment_method, payment_methods } = useSelector(state => state.payment);

  let _bankOptions = selected_payment_method?.bank_options
  if (_bankOptions?.length === 0 && payment_methods?.length > 0) {
    payment_methods?.find(e => CONSTANTS.PAYMENT_METHOD.FORM_TYPE.PAYFAST.BANK_ACCOUNT_NUMBER === e?.form_type)?.bank_options
  }

  const [value, setValue] = useState("");
  const [bankCode, setBankCode] = useState("");

  useEffect(() => {
    if (!HELPER.isEmpty(selected_payment_method?.account_no)
      && CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.BANK_ACCOUNT_NUMBER.includes(selected_payment_method?.form_type)) {
      setValue(selected_payment_method?.account_no);
    }
    if (selected_payment_method?.bank_code === payment?.bank_code && HELPER.isEmpty(bankCode) && HELPER.isNotEmpty(_bankOptions)) {
      const selectedBank = HELPER.isEmpty(payment?.bank_code) ? _bankOptions[0] : _bankOptions?.find(e => payment?.bank_code === e.code)
      setBankCode(selectedBank)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selected_payment_method?.bank_code === payment?.bank_code && HELPER.isEmpty(bankCode) && HELPER.isNotEmpty(_bankOptions)) {
      const selectedBank = HELPER.isEmpty(payment?.bank_code) ? _bankOptions[0] : _bankOptions?.find(e => payment?.bank_code === e.code)
      setBankCode(selectedBank)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_payment_method?.bank_code]);

  //componentWillRecieveProp
  useEffect(() => {
    if (selected_payment_method?.index === payment?.index) {
      setValue(selected_payment_method?.account_no);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_payment_method?.account_no]);

  //componentWillRecieveProp
  useEffect(() => {
    if (selected_payment_method?.index === payment?.index && selected_payment_method?.account_no !== value && HELPER.isNotEmpty(value)) {
      let _selected={...selected_payment_method, account_no: value, bank_code: bankCode?.code,  selected_bank_option: bankCode}
      dispatch(paymentAction.update_selected_payment_index(_selected))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);


  //componentWillRecieveProp
  useEffect(() => {
    if (selected_payment_method?.index === payment?.index && selected_payment_method?.bank_code !== bankCode) {
      let _selected = { ...selected_payment_method, bank_code: bankCode?.code, selected_bank_option: bankCode, account_no: !selected_payment_method?.token_id ? "" : selected_payment_method?.account_no }
      dispatch(paymentAction.update_selected_payment_index(_selected))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankCode]);


  const handleChange = (e) => {
    let value = e.target.value;
    if (value.length === 1) {
      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.PAYMENT,
        {
          action: CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.BANK_ACCOUNT_NUMBER.includes(payment?.form_type) ? GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.INPUT.BANK_ACCOUNT_NUMBER.ACTION : GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.INPUT.WALLET_NUMBER.ACTION,
          label: CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.BANK_ACCOUNT_NUMBER.includes(payment?.form_type) ? GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.INPUT.BANK_ACCOUNT_NUMBER.LABEL : GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.INPUT.WALLET_NUMBER.LABEL,
          properties: {}
        }
      );
    }

    let updatedValue = TEXT_FORMAT.format_BA_Number(
      TEXT_FORMAT.format_BA_Number(value)
    );
    setValue(updatedValue);
  };

  const handleAppsBankChange = (e, newValue) => {
    setBankCode(newValue)
  }

  return (
    <>

      {HELPER.isNotEmpty(_bankOptions) &&
        <>
          <Autocomplete
            options={Array.isArray(selected_payment_method?.bank_options) ? selected_payment_method?.bank_options : []}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            getOptionSelected={(option, value) => {
              return value?.value;
            }}
            disabled={!HELPER.isEmpty(payment?.token_id)}
            disableClearable
            value={bankCode}
            onChange={handleAppsBankChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="normal"
                name="country_id"
                label={translate("PAYMENT.SELECT_BANK")}
                placeholder={HELPER.isEmpty(bankCode?.placeholder) ? translate("PAYMENT.SELECT_BANK") : bankCode?.placeholder}
                // label={translate("PAYMENT.SELECT_BANK")}
                // placeholder={translate("PAYMENT.SELECT_BANK")}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                required={true}
                autoFocus={false}
              />
            )}
          />
        </>
      }
      <TextField
        type="text"
        margin="normal"
        variant="outlined"
        className={"col-md-12 bankAccTextField  mt-2 mb-2 " + data?.className}
        // label={data?.label}
        placeholder={HELPER.isEmpty(bankCode?.placeholder) ? data?.placeholder : bankCode?.placeholder}
        label={HELPER.isEmpty(bankCode?.label) ? data?.label : bankCode?.label}
        name={data?.name}
        value={value}
        onChange={handleChange}
        disabled={!HELPER.isEmpty(payment?.token_id)}
        autoComplete="off"
        required={true}
        inputProps={{
          autoComplete: "off",
          form: {
            autoComplete: "off",
            maxLength: maxCharLimit,
          },
          maxLength: maxCharLimit,
          id: `bank-account-text-field-${payment?.index}`
        }}
        onInput={(e) => {
          let val = e.target.value;
          return val !== null
            ? Math.max(0, parseInt(val)).toString().slice(0, maxCharLimit)
            : null;
        }}
      />

    </>
  );
}

export default APGBankAccount;
