// @flow
import React, { useState, useEffect } from "react";
import { useTranslate } from "react-redux-multilingual/lib/context";
import { useDispatch, useSelector } from "react-redux";

import { loadVGSCollect } from "@vgs/collect-js";
import ReactHtmlParser from "html-react-parser";

import { FormControlLabel, Checkbox, Divider } from "@material-ui/core";
import BackButton from "../../../../components/BackButton";
import LoadingButton from "../../../../components/LoadingButton";

import {
  generalAction,
  paymentAction,
  formAction,
  alertActions
} from "../../../../store/actions";
import {
  HELPER,
  IMAGE_SRC,
  LOCAL_STORAGE_SERVICE,
  ROUTE_CONSTANTS,
  CONSTANTS,
  history
} from "../../../../utils";
import { useCancellablePromise } from '../../../../hooks'

let timeoutTime;
let defaultErrorState = {
  card_holder_name: "",
  cc_number: "",
  cc_cvv: "",
  expiry_date: "",
};


const FORM_TYPE = CONSTANTS.PAYMENT_METHOD.FORM_TYPE;
let cardExpiryCss, cardNameCss, cardNumberCss, cardCvvCss;

// cardCvvCss, cardExpiryCss;

function GeneralCCForm() {
  const { cancellablePromise } = useCancellablePromise();
  let dispatch = useDispatch();
  const translate = useTranslate();

  const {
    cc_payment_3ds_required,
    cc_payment_verified,
    cc_payment_redirect_url,
    cc_payment_otp_required,
    cc_payment_email_otp_required,
    selected_payment_method
  } = useSelector(state => state.payment);
  const { pay_limit_reached } = useSelector(state => state.cart);
  const { showBtnLoader } = useSelector(state => state.loading);
  const { locale } = useSelector(state => state.Intl);
  const { cc_gateway } = selected_payment_method
  
  const [form, setForm] = useState({});

  const [saveCard, setSaveCard] = useState(1);
  const [defaultCard, setDefaultCard] = useState(false);
  const [defaultCardDisable, setDefaultCardDisable] = useState(false);
  const [errors, setErrors] = useState(defaultErrorState);

  const vgsEnabled = selected_payment_method?.vgs_enabled;
  const vgsCredentials = selected_payment_method?.vgs_config;
  const errorCardHolderName = HELPER.isEmpty(errors.card_holder_name)
    ? false
    : true;
  const errorCCNumber = HELPER.isEmpty(errors.cc_number) ? false : true;
  const errorCvv = HELPER.isEmpty(errors.cc_cvv) ? false : true;
  const errorExpiryDate = HELPER.isEmpty(errors.expiry_date) ? false : true;

  const backBtn = LOCAL_STORAGE_SERVICE._isFirstScreen();
  //componentDidMount
  useEffect(() => {
    cardExpiryCss = HELPER.getStyledCCAttributes().cardExpiry;
    cardCvvCss = HELPER.getStyledCCAttributes().cardCvv;
    cardNameCss = HELPER.getStyledCCAttributes().cardName;
    cardNumberCss = HELPER.getStyledCCAttributes().cardNumberCss;
    /* LOAD VGS FORM STARTS HERE */
    const loadForm = async () => {
      const vgs_collect = await cancellablePromise(loadVGSCollect({
        vaultId: vgsCredentials?.vault,
        environment: vgsCredentials?.environment,
        version: vgsCredentials?.version,
      }).catch((err) => { window.onerror(err)}))
      if (!HELPER.isEmpty(vgs_collect)) {
        initForm(vgs_collect);
      } else {
        dispatch(alertActions.error("vgs collect form unable to load"))
      }
    };
    vgsCredentials?.vault && loadForm();
    /* LOAD VGS FORM ENDS HERE */

    // returned function will be called on component unmount
    return () => {
      clearTimeout(timeoutTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //componentWillRecieveProps
  useEffect(() => {
    cardExpiryCss = HELPER.getStyledCCAttributes().cardExpiry;
    cardCvvCss = HELPER.getStyledCCAttributes().cardCvv;
    cardNameCss = HELPER.getStyledCCAttributes().cardName;
    cardNumberCss = HELPER.getStyledCCAttributes().cardNumberCss;
    if (cc_payment_otp_required === 1 || cc_payment_email_otp_required === 1) {
      LOCAL_STORAGE_SERVICE._nextScreen();
      history.push({
        pathname: ROUTE_CONSTANTS.BANK_OTP,
      });
    } else {
      if (cc_payment_verified && cc_payment_3ds_required === 0) {
        history.push({
          pathname: ROUTE_CONSTANTS.CART,
        });
      } else if (
        !HELPER.isEmpty(cc_payment_redirect_url) &&
        cc_payment_verified
      ) {
        // dispatch(formAction.SHOW_PLEASE_WAIT_LOADER());
        HELPER.redirectToPath(cc_payment_redirect_url, false);
      }
    }

    //CHECK IF PAYMENT LIMIT IS EXCEEDED THEN SHOW OUT OF RETRIES AND REDIRECT TO STORE
    if (pay_limit_reached) {
      history.push({
        pathname: ROUTE_CONSTANTS.OUT_OF_RETRIES,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cc_payment_verified, pay_limit_reached]);

  const initForm = (vgs_collect) => {
    const form = vgs_collect.init(() => {
      // console.log(state);
    });
    form.field("#card-name", {
      type: "text",
      name: "card_holder",
      isValid: true,
      successColor: "#1b1d1f",
      errorColor: "#e86060",
      autoComplete: "cc-name",
      placeholder: translate("PAYMENT.LABEL.CARD.NAME"),
      validations: ["required", '/^[a-zA-Z ]{1,25}$/'],
      cardNameCss,
    });
    const cardNumberFieldName = vgsEnabled ? "card_number" : "decoded_card_number";
    const cardNumberFieldId = vgsEnabled ? "#card-number" : "#card-number";
    const cardNumber = form.field(cardNumberFieldId, {
      type: "card-number",
      name: cardNumberFieldName,
      autoComplete: "cc-number",
      successColor: "#1b1d1f",
      errorColor: "#e86060",
      isValid: true,
      placeholder: translate("PAYMENT.LABEL.CARD.NUMBER"),
      showCardIcon: locale === CONSTANTS.IS_URDU ? {
        left: "7px",
        width: "37px",
        height: "25px",
      } : {
        right: "7px",
        width: "37px",
        height: "25px",
      },
      validations: ["required", "validCardNumber"],
      css: cardNumberCss,
      addCardBrands: [{
				type: 'paypak',
				pattern: /^(2205|2206)/,
				format: /(\d{1,4})/g,
				length: [16],
				cvcLength: [3],
				luhn: true,
			  },],
    });
    const cvvFieldName = vgsEnabled ? "card_cvc" : "card_cvv";
    const cvvFieldId = vgsEnabled ? "#card-cvc" : "#card-cvc";

    const cvc = form.field(cvvFieldId, {
      type: "card-security-code",
      hideValue: true,
      name: cvvFieldName,
      css: cardCvvCss,
      successColor: "#1b1d1f",
      autoComplete: "cc-csc",
      isValid: true,
      showCardIcon: locale === CONSTANTS.IS_URDU ? {
        left: "7px",
        width: "37px",
        height: "25px",
      } : {
        right: "7px",
        width: "37px",
        height: "25px",
      },
      errorColor: "#e86060",
      placeholder: translate("PAYMENT.LABEL.CARD.CVV"),
      maxLength: 3,
      validations: ["required", "validCardSecurityCode"],
    });

    cardNumber.setCVCDependency(cvc);
    form.field("#card-expiry", {
      type: "card-expiration-date",
      name: "card_exp",
      // options: { monthName: "month", yearName: "year" },
      serializers: [form.SERIALIZERS.separate(
        { monthName: 'month', yearName: 'year' }
      )],
      autoComplete: "cc-exp",
      isValid: true,
      successColor: "#1b1d1f",
      errorColor: "#e86060",
      placeholder: "MM / YY",
      validations: ["required", "validCardExpirationDate"],
      yearLength: 2, //year only 2 digits
      css: cardExpiryCss,
    });

    setForm(form);
  };

  const checkForErrors = (fieldName, placeholder) => {
    const errorMessages = fieldName?.errorMessages;
    if (!HELPER.isEmpty(errorMessages)) {
      let errorMsg = "";
      errorMessages.map((msg) => {
        errorMsg += placeholder + " " + msg + "<br/>";
        return null;
      });
      return errorMsg === "" ? null : errorMsg;
    }
  };

  const handleValidation = () => {
    const state = form?.state;
    const card_cvc = checkForErrors(state?.card_cvc, "CVV");
    const card_cvv = checkForErrors(state?.card_cvv, "CVV");
    const card_exp = checkForErrors(state?.card_exp, "Expiry date");
    const card_holder = checkForErrors(state?.card_holder, "Card holder name");
    const card_number = checkForErrors(state?.card_number, "Card number");
    const decoded_card_number = checkForErrors(state?.decoded_card_number, "Card number");

    // same as above, but feel free to move this into a class method now.
    let validation_errors = {
      card_holder_name: "",
      cc_number: "",
      cc_cvv: "",
      expiry_date: "",
    };
    let errorFound = false;

    if (!HELPER.isEmpty(card_holder)) {
      validation_errors.card_holder_name = card_holder;
      errorFound = true;
    }
    if (!HELPER.isEmpty(decoded_card_number)) {
      validation_errors.cc_number = decoded_card_number;
      errorFound = true;
    }
    if (!HELPER.isEmpty(card_number)) {
      validation_errors.cc_number = card_number;
      errorFound = true;
    }
    if (!HELPER.isEmpty(card_exp)) {
      validation_errors.expiry_date = card_exp;
      errorFound = true;
    }
    if (!HELPER.isEmpty(card_cvc)) {
      validation_errors.cc_cvv = card_cvc;
      errorFound = true;
    }
    if (!HELPER.isEmpty(card_cvv)) {
      validation_errors.cc_cvv = card_cvv;
      errorFound = true;
    }

    setErrors(validation_errors);
    return errorFound;
  };

  const handleFormSubmit = () => {
    let validationCheck = handleValidation();
    if (!validationCheck) {
      dispatch(formAction.SHOW_LOADER());
      form.submit("/post", {}, (status, data) => {
        if (status === 200) {
          dispatch(formAction.HIDE_LOADER());
          const cardHash = vgsEnabled ? data?.json?.card_number : data?.json?.decoded_card_number;
          const cvvHash = vgsEnabled ? data?.json?.card_cvc : data?.json?.card_cvv;
          var dateExpiry = data?.json?.card_exp?.year;
          const ccData = {
            input_type: "card",
            cc_number: cardHash,
            cc_expiry_month: data?.json?.card_exp?.month,
            cc_expiry_year: dateExpiry.length === 2 ? dateExpiry : dateExpiry.substr(2),
            cc_name: data?.json?.card_holder,
            cc_cvv: cvvHash,
            save_card: saveCard ? 1 : 0,
            is_default: defaultCard ? 1 : 0,
          };
          const validateTokenData = {
            input_type: "card",
            requestData: ccData,
            history,
            extraParams: {
              payment_method: selected_payment_method,
              history
            }
          };
          if (FORM_TYPE.CREDIT_CARD.API_BASED === selected_payment_method?.form_type) {
            dispatch(generalAction.SAVE_PAYMENT_INSTRUMENT(validateTokenData))
          } else {
            dispatch(paymentAction.validate_payment_method(validateTokenData))
          }
        } else {
          dispatch(formAction.HIDE_LOADER());
        }
      });
    } else {
      timeoutTime = setTimeout(() => {
        setErrors(defaultErrorState);
      }, 10000);
    }
  };

  //enable form submit button after 3 sec of form json load
  const [disableBtn, setdisableBtn]= useState(true)
  useEffect(()=>{
    if(Object.keys(form).length !== 0){
      setTimeout(() => {
        setdisableBtn(false)
      }, 3000);
    }
  }, [form])

  return (
    <>
      {" "}
      {/* PAGE CONTENT STARTS HERE */}
      <form id="collect-form" className="creditCardCollectForm">
        <div className="group formFields">
          <div className="mb-4">
            <label className={`nameFieldLabel ${errorCardHolderName ? "error-label" : ""}`}>
              <div id="card-name" className="nameField" />
            </label>
            {errorCardHolderName ? (
              <div  className="error">{ReactHtmlParser(HELPER.parseMessage(errors.card_holder_name))}</div >
            ) : (
              ""
            )}
          </div>
          <div>
            <label className={errorCCNumber ? "error-label" : ""}>
              <div id="card-number" className={"cardNumberField"} />
            </label>
            {errorCCNumber ? (
              <div  className="error">{ReactHtmlParser(HELPER.parseMessage(errors.cc_number))}</div >
            ) : (
              ""
            )}
          </div>
          <div className="row twoField creditCardDiv ccGeneral">
            <div className="col-6 expiry">
              <label className={errorExpiryDate ? "error-label" : ""}>
                <div id="card-expiry" className="field" />
              </label>
              {errorExpiryDate ? (
                <div  className="error">{ReactHtmlParser(HELPER.parseMessage(errors.expiry_date))}</div >
              ) : (
                ""
              )}
            </div>
            <div className="col-6 cvv">
              <label className={errorCvv ? "error-label" : " "}>
                <div id="card-cvc" className="field" />
              </label>
              {errorCvv ? (
                <div  className="error">{ReactHtmlParser(HELPER.parseMessage(errors.cc_cvv))}</div >
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="checkBoxCard ccGatewayCheckboxCard">
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  '&.MuiSvgIcon-root': {
                    fontSize: 70,
                    borderRadius: 50
                  }
                }}
                color="primary"
                name="saveCard"
                value={Number(saveCard)}
                onChange={() => {
                  setSaveCard(!saveCard);
                  setDefaultCardDisable(!saveCard);
                  // setDefaultCardDisable(saveCard);
                  // if (defaultCard) {
                  //   setDefaultCard(false);
                  // }
                  // if (!saveCard) {
                  //   setDefaultCard(true);
                  // }
                }}
                checked={Number(saveCard) === 1 ? true : false}
              />
            }
            label={translate("save_card_details")}
          />
          <div className="saveDetailsFooter">
            <div className="d-flex">
              <span className={`icon icomoon-icon-fast-forward mr-2 ${Number(saveCard) === 1 ? "active" : ""}`}></span>
              <span className={`footContent`}>{ translate("PAYMENT.LABEL.CARD.FOOTER.FASTER_CHECKOUT") }</span>
            </div>
            <div className="d-flex">
              <span className={`icon icomoon-icon-protection mr-2 ${Number(saveCard) === 1 ? "active" : ""}`}></span>
              <span className={`footContent`}>{ translate("PAYMENT.LABEL.CARD.FOOTER.PROTECTION") }</span>
            </div>

            {[CONSTANTS.CC_GATEWAY.HBL, CONSTANTS.CC_GATEWAY.PAYFAST, CONSTANTS.CC_GATEWAY.ALFALAH_MPGS].includes(cc_gateway) &&
            <div className="ccVerificationFooter">
              <fieldset id="el##">
                <legend> { translate("PAYMENT.LABEL.CARD.FOOTER.POWERED_BY") }</legend>
                <div>
                  <img src={cc_gateway === CONSTANTS.CC_GATEWAY.PAYFAST ? IMAGE_SRC.CC_PAYFAST :
                    cc_gateway === CONSTANTS.CC_GATEWAY.ALFALAH_MPGS ? IMAGE_SRC.CC_ALFALAH_MPGS
                    : IMAGE_SRC.CC_HBL} alt="bank icon" className="niftImg" />
                </div>
              </fieldset>
              <Divider orientation="vertical" className="divider" />
              <div className="niftText">
                { translate(cc_gateway === CONSTANTS.CC_GATEWAY.PAYFAST ? "PAYMENT.LABEL.CARD.FOOTER.PAY_FAST": 
                 cc_gateway === CONSTANTS.CC_GATEWAY.ALFALAH_MPGS ? "PAYMENT.LABEL.CARD.FOOTER.ALFALAH_MPGS": 
                "PAYMENT.LABEL.CARD.FOOTER.HBL") }
              </div>
            </div>
            }


            <div className="ccVerificationFooter">
              <fieldset id="el##">
                <legend>Trusted Payments</legend>
                <div>
                  <img src={IMAGE_SRC.PCI_DSS} alt="nift icon" className="niftImg" />
                </div>
              </fieldset>
              <Divider orientation="vertical" className="divider" />
              <div className="niftText">
                { translate("PAYMENT.LABEL.CARD.FOOTER.PCI_PROTECTION") }
              </div>
            </div>
          </div>
        </div>
        <div className="checkBoxCard d-none">
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  '&.MuiSvgIcon-root': {
                    fontSize: 70,
                    borderRadius: 50
                  }
                }}
                disabled={defaultCardDisable}
                color="primary"
                name="defaultCard"
                value={Number(defaultCard)}
                onChange={() => {
                  if (saveCard) {
                    setDefaultCard(!defaultCard);
                  }
                }}
                checked={Number(defaultCard) === 1 ? true : false}
              />
            }
            label={translate("default_placeholder")}
          />
        </div>
        <div className="optionBtn">
          {!backBtn && <BackButton btnTitle={translate("back_btn")} />}
          <button
            className="btn btn-primary width150"
            type="button"
            id="card-submit"
            onClick={handleFormSubmit}
            disabled={disableBtn}
          >
            <LoadingButton
              loadingState={showBtnLoader}
              preloadingText={translate("next_btn")}
              loadingText={translate("next_btn")}
            />
          </button>
        </div>
      </form>
      {/* PAGE CONTENT ENDS HERE */}
    </>
  );
}

export default GeneralCCForm;
