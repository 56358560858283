import { HELPER } from '../../utils'

const locationPermissionDenied = (bool=true) =>{
    if (HELPER.sendEventToAndroid("locationPermissionDenied")) {
      window.Android.locationPermissionDenied(bool);
    }
  }

export {
    locationPermissionDenied,
}