import { CART_CONSTANTS, PAYMENT_CONSTANT, FORM_CONSTANTS, CONFIG_CONSTANTS } from "../actionTypes";
import { ANALYTICS_HELPER, HELPER } from "../../utils";

const defaultVoucherState = {
  code: "",
  error: false,
  errorMessage: "",
};

const defaultPaymentMethodState = {
  form_type: "none",
  id: null,
  name: null,
};
const defaultPaymentFailureState = {
  payment_failure: null,
  pay_limit_reached: false,
  failure_redirect_url: null,
  store_redirect_url: null,
};
const defaultSummartState = {
  currency_code: "PKR",
  analytic_currency_code: "PKR",
  total_amount: "",
  sub_total_amount: "",
  shipment_charges: "",
  merchant_service_charges: "",
  discount_amount: "",
  customer_notes: null,
  voucher_applicable: false,
  discount_breakdown: [],
  merchant_breakdown: [],
  placement_type: "App"
}
const initState = {
  cart_details_loading: false,
  //CART
  cart_details: {
    items: [],
    summary: defaultSummartState,
    count: 0
  },

  device_fingerprint: {
    required: 0,
    device_fingerprint_response: []
  },

  cart_success: {},
  order_processed: 0,

  delivery_address: [],
  shipment_method: [],
  payment_method: defaultPaymentMethodState,

  cnic_requirement: [],
  voucherRequest: false,
  voucher: defaultVoucherState,

  order_id: null,
  placement_status: null,

  order_placed: false,
  order_failure: false,

  ask_for_verification: false,
  verification_type: "",
  //Payment fail
  payment_failure_response: defaultPaymentFailureState,
  vouchers: [],

  post_order_msg: "",

  banner_title: "",
	banner_alert: "",
	banner_icon: "",
	banner_theme: ""
};

let response, order_id;

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case CONFIG_CONSTANTS.INITIATE_ORDER_REQUEST:
    case CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_REQUEST:
      return initState;
    case CONFIG_CONSTANTS.INITIATE_ORDER_SUCCESS:
    case CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_SUCCESS:
      return {
        ...state,
        cart_details: {
          items: action?.response?.cart?.items,
          summary: action?.response?.cart?.summary,
          count: (action?.response?.cart?.items).length
        },
      };
    case CART_CONSTANTS.UPDATE_CART_DETAILS_REQUEST:
      return state;
    case CART_CONSTANTS.UPDATE_CART_DETAILS_SUCCESS:
      return {
        ...state,
        cart_details: {
          items: action?.response?.cart?.items,
          summary: action?.response?.cart?.summary,
          count: (action?.response?.cart?.items).length
        },
      };
    case CART_CONSTANTS.UPDATE_CART_DETAILS_FAILURE:
      return state;
    // CART
    case CART_CONSTANTS.DEVICE_FINGERPRINT_FOUND:
      return {
        ...state,
        device_fingerprint: {
          ...state.device_fingerprint,
          device_fingerprint_response: action.response
        },
      };
    case CART_CONSTANTS.GET_CART_DETAILS_REQUEST:
      return {
        ...state,
        payment_failure_response: defaultPaymentFailureState,
        payment_failure: false,
        cart_details_loading: true,
      };
    case CART_CONSTANTS.GET_CART_DETAILS_SUCCESS:
      return {
        ...state,
        cart_details_loading: false,
        cnic_requirement: action.cart_data?.cnic_required,
        delivery_address: action.cart_data?.delivery_address,
        shipment_method: action.cart_data?.shipment_method,
        vouchers: action.cart_data?.vouchers,
        device_fingerprint: action.cart_data?.dfp,
        payment_method: !HELPER.isEmpty(action.cart_data?.payment_method) ? action.cart_data?.payment_method : defaultPaymentMethodState,
        cart_details: {
          items: action.cart_data?.items,
          summary: action.cart_data?.summary,
          count: (action.cart_data?.items).length
        },
        merchant_bank_details: action.cart_data?.merchant_bank_details,
        voucherRequest: false,
        voucher: state?.voucher,
      };
    case CART_CONSTANTS.GET_CART_DETAILS_FAILURE:
      return {
        ...state,
        cart_details_loading: false,
      };
    case CART_CONSTANTS.PLACE_ORDER_REQUEST:
      return {
        ...state,
        order_placed: true,
        order_failure: false,
      };
    case CART_CONSTANTS.PLACE_ORDER_SUCCESS:
      response = action.response;
      order_id = response.order_id;
      ANALYTICS_HELPER._checkoutCompleted(response, state.cart_details, state?.voucher)
      return {
        ...state,
        order_id,
        order_placed: false,
        placement_status: response?.placement_status,
        cart_success: response,
        order_processed: response?.is_order_completed,
        ask_for_verification: !!response?.ask_for_verification,
        verification_type: response?.verification_type,
        post_order_msg: response?.post_order_msg,

        banner_title: response?.banner_title,
        banner_alert: response?.banner_alert,
        banner_icon: response?.banner_icon,
        banner_theme: response?.banner_theme
      };
    case CART_CONSTANTS.PLACE_ORDER_FAILURE:
      return {
        ...state,
        order_placed: false,
        order_failure: true,
        payment_failure_response: {
          payment_failure: action?.error?.payment_failure,
          pay_limit_reached: HELPER.stringToBoolean(
            action?.error?.limit_reached
          ),
          failure_redirect_url: action?.error?.redirect_url,
          store_redirect_url: action?.error?.store_url,
        },
      };
    case PAYMENT_CONSTANT.NIFT.UPDATE_NIFT_RESP_FAILURE:
      return {
        ...state,
        payment_failure_response: {
          payment_failure: action?.error?.payment_failure,
          pay_limit_reached: HELPER.stringToBoolean(
            action?.error?.limit_reached
          ),
          failure_redirect_url: action?.error?.redirect_url,
          store_redirect_url: action?.error?.store_url,
        },
      };
    case CART_CONSTANTS.VALIDATE_PAYMENT_INFO_REQUEST:
      return {
        ...state,
        payment_failure_response: defaultPaymentFailureState,
        payment_failure: false,
      };
    case CART_CONSTANTS.VALIDATE_PAYMENT_INFO_SUCCESS:
      response = action.response;
      order_id = response.order_id;
      return {
        ...state,
        order_id,
        cart_success: response,
        order_processed: response?.is_order_completed,
        ...(response?.verification_type &&
          {ask_for_verification: !!response.ask_for_verification}
        ),
        ...(response?.verification_type &&
          {verification_type: response.verification_type}
        ),
        post_order_msg: response?.post_order_msg
      };
    case CART_CONSTANTS.VALIDATE_PAYMENT_INFO_FAILURE:
      return {
        ...state,
        payment_failure_response: {
          payment_failure: action?.error?.payment_failure,
          pay_limit_reached: HELPER.stringToBoolean(
            action?.error?.limit_reached
          ),
          failure_redirect_url: action?.error?.redirect_url, // Reditect url is not recieved.
          store_redirect_url: action?.error?.store_url,
        },
      };
    case CART_CONSTANTS.POST_CHECKOUT_VERIFICATION_COMPLETED:
        return {
          ...state,
          ask_for_verification: false,
          cart_success: {
            ...state?.cart_success,
            ask_for_verification: 0,
          }
        };
    case CART_CONSTANTS.POST_CHECKOUT_VERIFICATION_CHECK:
      return {
        ...state,
        ask_for_verification: true,
      };
    case PAYMENT_CONSTANT.UPDATE_ORDER_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        payment_failure: false,
        payment_failure_response: {
          payment_failure: null,
          pay_limit_reached: false,
          failure_redirect_url: null,
          store_redirect_url: null,
        },
      };
    case PAYMENT_CONSTANT.CLEAR_ERROR_UPDATE_ORDER_PAYMENT_METHOD:
      return {
        ...state,
        payment_failure: false,
        payment_failure_response: {
          payment_failure: null,
          pay_limit_reached: false,
          failure_redirect_url: null,
          store_redirect_url: null,
        },
      };
    // CREDIT CARD PAYMENT VERIFICATION UPDATE
    // State update for API: Validate PrePayment
    // POST v1/order/validate-payment
    case PAYMENT_CONSTANT.VALIDATE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
      };
    case PAYMENT_CONSTANT.VALIDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
      };
    case PAYMENT_CONSTANT.VALIDATE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        payment_failure_response: {
          payment_failure: action?.error?.payment_failure,
          pay_limit_reached: HELPER.stringToBoolean(
            action?.error?.limit_reached
          ),
          failure_redirect_url: action?.error?.redirect_url,
          store_redirect_url: action?.error?.store_url,
        },
      };
    case PAYMENT_CONSTANT.UPDATE_ORDER_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        delivery_address: [],
        shipment_method: [],
        payment_method: [],
        cnic_requirement: [],
      };
    // VOUCHER
    case FORM_CONSTANTS.CLEAR_VOUCHER_MESSAGE:
      return {
        ...state,
        voucher: {
          ...state.voucher,
          error: false,
          errorMessage: "",
        },
      };
    case FORM_CONSTANTS.UPDATE_VOUCHER_CODE:
      return {
        ...state,
        voucherRequest: false,
        voucher: {
          code: action?.response,
          voucherify_voucher_id: action?.voucherify_voucher_id,
          error: false,
          errorMessage: "",
        },
      };
    case CART_CONSTANTS.VOUCHER_REQUEST:
      return {
        ...state,
        voucherRequest: false,
        voucher: {
          code: state?.voucher?.code,
          error: false,
          errorMessage: "",
        },
      };
    case CART_CONSTANTS.VOUCHER_SUCCESS:
      return {
        ...state,
        cart_details: {
          items: state.cart_details.items,
          summary: action.response?.summary,
          count: state.cart_details.count,
        },
        voucherRequest: action.is_customer_app ? false : true,
        voucher: {
          code: action.is_customer_app ? "" : state?.voucher?.code ,
          error: false,
          errorMessage: action.is_customer_app ? "" : action?.success_message,
        },
      };
    case CART_CONSTANTS.VOUCHER_FAILURE:
      return {
        ...state,
        voucherRequest: false,
        voucher: {
          code: state?.voucher?.code,
          error: true,
          errorMessage: action?.error_message,
        },
      };
    case PAYMENT_CONSTANT.UPDATE_SELECTED_PAYMENT_INDEX:
      return {
        ...state,
        cnic_requirement: HELPER.isNotEmpty(action?.selected_payment?.cnic_settings) ? action?.selected_payment?.cnic_settings : "",
      };
    default:
      return {
        ...state,
      };
  }
};
export default cartReducer;
