// @flow
import React from "react";
import { useTranslate } from "react-redux-multilingual/lib/context";

import ReactHtmlParser from "html-react-parser";

import { HELPER } from "../../utils";

function DBTToolTipText({
  merchant_bank_details,
  showBankDetails,
  showCustomMsg,
}) {
  const translate = useTranslate();

  const merchant_support_email = merchant_bank_details?.email;
  const merchant_whatsapp_no = merchant_bank_details?.phone;
  const custom_message = merchant_bank_details?.custom_message;
  return (
    <>
      {showCustomMsg ? (
        <div className="dbt-explanation">{custom_message}</div>
      ) : (
        ""
      )}

      {showBankDetails ? (
        <div className="dbt-details">
          <div className="dbt-title">
            {translate("dbt_bank_name")}
            <span className="dbt-label">
              {merchant_bank_details?.bank_name}
            </span>
          </div>
          <div className="dbt-title">
            {translate("dbt_account_title")}
            <span className="dbt-label">
              {merchant_bank_details?.bank_account_title}
            </span>
          </div>
          <div className="dbt-title">{translate("dbt_iban_number")}</div>
          <div className="dbt-title">
            <div className="dbt-divider"></div>
            <span className="dbt-label">
              {merchant_bank_details?.iban_number}
            </span>
          </div>
          <div className="dbt-title">
            {translate("dbt_account_number")}
            <span className="dbt-label">
              {merchant_bank_details?.bank_account_number}
            </span>
          </div>
          <div className="dbt-title">
            {translate("dbt_branch_code")}
            <span className="dbt-label">
              {merchant_bank_details?.bank_branch_code}
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="dbt-message">
        {ReactHtmlParser(
          HELPER.parseMessage(
          HELPER.dbtMessageFormatting(
            translate("dbt_message", {
              merchant_support_email,
              merchant_whatsapp_no,
            }),
            merchant_support_email,
            merchant_whatsapp_no
          )
        ))}
      </div>
    </>
  );
}

export default DBTToolTipText;
