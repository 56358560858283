import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  Typography
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { HELPER } from "../../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const DialogTitle = ((props) => {
  const { children, onCloseHandler, ...other } = props;

  return (
    <MuiDialogTitle disableTypography {...other} id="terms-dialog-title">
      <Typography variant="h6">{children}</Typography>
      {onCloseHandler ?
        <IconButton
          aria-label="close"
          className="btn-primary closeright"
          onClick={onCloseHandler}
        >
        <span className="icomoon-close"></span>
        </IconButton>
        : null
      }
    </MuiDialogTitle>
  );
});
function Modal() {
  const {
    setModel,
    onCloseHandler,
    onCancelHandlder,
    onSuccessHandler,
    modal,
  } = useSelector(state => state.action);

  const { disableBackdropClick } = modal;
  const [open, setOpen] = useState(setModel);

  useEffect(() => {
    setOpen(setModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setModel]);

  const onClose = (event, reason) => {
    if (disableBackdropClick) {
      if (reason !== "backdropClick") {
        onCloseHandler();
      }
    } 
    else if (reason === "disableBackdropClick") {
      return disableBackdropClick ?? true;
    }
    else {
      onCloseHandler();
    }
  }
  return (
    <div>
      <Dialog
        // disableBackdropClick={disableBackdropClick ?? true}
        // onBackdropClick={disableBackdropClick ? onCloseHandler() : ()=> {return}}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        // onClose={onCloseHandler}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {HELPER.isEmpty(modal.title) ? (
          ""
        ) : (
          <>
            <DialogTitle id="alert-dialog-slide-title" onClose={() => onCloseHandler()}>
              {modal.title}
            </DialogTitle>
          </>
        )}

        {HELPER.isEmpty(modal.content) ? (
          ""
        ) : (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {modal.content}
              </DialogContentText>
            </DialogContent>
          </>
        )}

        <DialogActions className="ModalButtons">
          {modal.cancelBtn ? (
            <Button
              onClick={onCancelHandlder}
              color="primary"
              id={modal.cancelBtnId}
              className="btn btn-outline-white"
            >
              {modal.cancelBtn}
            </Button>
          ) : (
            ""
          )}
          {modal.successBtn ? (
            <Button
              onClick={onSuccessHandler}
              id={modal.successBtnId}
              color="primary"
              className="btn btn-white btn-white-B"
            >
              {modal.successBtn}
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default Modal;
