const ANALYTICS_CONSTANTS = {
  CHECKOUT_STEP: "TRIGGER_CHECKOUT_STEP",
};

const ALERT_CONSTANTS = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  SECONDARY: "ALERT_SECONDARY",
  CLEAR: "ALERT_CLEAR",
  WARNING: "ALERT_WARNING",
  INFO: "ALERT_INFO"
};

const ACTION_CONSTANTS = {
  TERMS_OPEN: "DIALOG_OPEN_REQUEST",
  TERMS_CLOSE: "DIALOG_CLOSE_REQUEST",

  MODAL_OPEN: "MODAL_OPEN_REQUEST",
  MODAL_REOPEN: "MODAL_REOPEN_REQUEST",
  MODAL_CLOSE: "MODAL_CLOSE_REQUEST",

  ORDER_EXPIRED: "ORDER_STATUS_EXPIRED",
  SHOW_PAGE_LOADER: "SHOW_PAGE_LOADER"
};

const ERROR_CONSTANTS = {
  ERROR: "ERROR_SCREEN",
  MESSAGE: "ERROR_MESSAGE",
};

const FORM_CONSTANTS = {
  UPDATE_PHONE_NUMBER: "UPDATE_PHONE_NUMBER",
  UPDATE_COUNTRY: "UPDATE_COUNTRY",
  UPDATE_COUNTRY_LIST: "UPDATE_COUNTRY_LIST",

  UPDATE_CUSTOMER_NAME: "UPDATE_CUSTOMER_NAME",
  UPDATE_CUSTOMER_EMAIL: "UPDATE_CUSTOMER_EMAIL",

  LOGOUT: "LOGOUT",

  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  SHOW_PLEASE_WAIT_LOADER: "",

  NO_INTERNET_CONNECTION: "NO_INTERNET_CONNECTION",

  UPDATE_VOUCHER_CODE: "UPDATE_VOUCHER_CODE",
  CLEAR_VOUCHER_MESSAGE: "CLEAR_VOUCHER_MESSAGE",
  MAINTAIN_ROUTER_HISTORY: "MAINTAIN_ROUTER_HISTORY"
};

const LANG_CONSTANTS = {
  LANGUAGE_POPUP_OPEN: "LANGUAGE_POPUP_OPEN_REQUEST",
  LANGUAGE_POPUP_CLOSE: "LANGUAGE_POPUP_CLOSE_REQUEST",

  UPDATE_LANGUAGE_LOCALE: "LANGUAGE_LOCALE_UPDATE_REQUEST",
};

const META_CONSTANTS = {
  META_REQUEST: "META_DATA_REQUEST",
  META_SUCCESS: "META_DATA_SUCCESS",
  META_FAILURE: "META_DATA_FAILURE",

  DEFAULT_META_REQUEST: "DEFAULT_META_DATA_REQUEST",
  DEFAULT_META_SUCCESS: "DEFAULT_META_DATA_SUCCESS",
  DEFAULT_META_FAILURE: "DEFAULT_META_DATA_FAILURE",

  COUNTRY_LIST_REQUEST: "COUNTRY_LIST_DATA_REQUEST",
  COUNTRY_LIST_SUCCESS: "COUNTRY_LIST_DATA_SUCCESS",
  COUNTRY_LIST_FAILURE: "COUNTRY_LIST_DATA_FAILURE",
};

const NETWORK_CONSTANTS = {
  NETWORK_LIST_REQUEST: "NETWORK_LIST_DATA_REQUEST",
  NETWORK_LIST_SUCCESS: "NETWORK_LIST_DATA_SUCCESS",
  NETWORK_LIST_FAILURE: "NETWORK_LIST_DATA_FAILURE",

  NETWORK_CHANGE_REQUEST: "NETWORK_CHANGE_DATA_REQUEST",

  NETWORK_UPDATE_REQUEST: "NETWORK_UPDATE_DATA_REQUEST",

  NETWORK_PHONE_REQUEST: "NETWORK_PHONE_DATA_REQUEST",
};

const CUSTOMER_CONSTANTS = {
  PROFILE_REQUEST: "PROFILE_DATA_REQUEST",
  PROFILE_SUCCESS: "PROFILE_DATA_SUCCESS",
  PROFILE_FAILURE: "PROFILE_DATA_FAILURE",

  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_SUCCESS",

  PHONE_NUMBER_CHANGED: "PHONE_NUMBER_CHANGE_REQUEST",
};

const MAP_CONSTANTS = {
  SEARCHED_ADDRESS: "SEARCHED_ADDRESS",
  ADD_ADDRESS_LAT_LNG_REQUEST: "ADD_ADDRESS_LOCATION_REQUEST",

  UPDATE_ADDRESS_LAT_LNG_REQUEST: "UPDATE_ADDRESS_LOCATION_REQUEST",

  UPDATE_PLACE_SEARCH_REQUEST: "UPDATE_PLACE_SEARCH_REQUEST",

  FULL_SCREEN_MODE: "SET_FULL_SCREEN_MODE",
  GET_MY_LOCATION: "GET_MY_LOCATION_PARAMS",
  INITIATE_MAP: "INITIATE_MAP",
  UPDATE_PERMISSION_LOCATION: "UPDATE_PERMISSION_LOCATION",
  MAP_PERMISSION_PROMPT: "MAP_PERMISSION_PROMPT"
};

const CONFIG_CONSTANTS = {
  SHOW_ACCESS_FORBIDDEN: "SHOW_ACCESS_FORBIDDEN",

  IDENTIFY_CUSTOMER_VALIDATION_REQUEST: "CUSTOMER_IDENTIFY_REQUEST",
  IDENTIFY_CUSTOMER_VALIDATION_UNSUCCESSFULL: "CUSTOMER_IDENTIFY_UNSUCCESSFULL",
  IDENTIFY_CUSTOMER_VALIDATION_SUCCESSFULL: "CUSTOMER_IDENTIFY_SUCCESSFULL",
  IDENTIFY_CUSTOMER_VALIDATION_FAILURE: "CUSTOMER_IDENTIFY_FAILURE",

  CUSTOMER_IDENTIFIER_DELETE: "CUSTOMER_IDENTIFIER_DELETE_REQUEST",

  INITIATE_ORDER_REQUEST: "INITIATE_CUSTOMER_ORDER_REQUEST",
  INITIATE_ORDER_SUCCESS: "INITIATE_CUSTOMER_ORDER_SUCCESS",
  INITIATE_ORDER_FAILURE: "INITIATE_CUSTOMER_ORDER_FAILURE",


  INITIATE_ORDER_ABANDONED_REQUEST: "INITIATE_ORDER_ABANDONED_REQUEST",
  INITIATE_ORDER_ABANDONED_SUCCESS: "INITIATE_ORDER_ABANDONED_SUCCESS",
  INITIATE_ORDER_ABANDONED_FAILURE: "INITIATE_ORDER_ABANDONED_FAILURE",

  PHONE_VERIFICATION_REQUEST: "CUSTOMER_PHONE_VERIFICATION_REQUEST",
  PHONE_VERIFICATION_SUCCESS: "CUSTOMER_PHONE_VERIFICATION_SUCCESS",
  PHONE_VERIFICATION_FAILURE: "CUSTOMER_PHONE_VERIFICATION_FAILURE",

  OTP_VERIFY_REQUEST: "CUSTOMER_OTP_VERIFY_REQUEST",
  OTP_VERIFY_SUCCESS: "CUSTOMER_OTP_VERIFY_SUCCESS",
  OTP_VERIFY_FAILURE: "CUSTOMER_OTP_VERIFY_FAILURE",

  OTP_SEND_REQUEST: "CUSTOMER_OTP_SEND_REQUEST",
  OTP_SEND_SUCCESS: "CUSTOMER_OTP_SEND_SUCCESS",
  OTP_SEND_FAILURE: "CUSTOMER_OTP_SEND_FAILURE",

  OTP_RETRY_REQUEST: "CUSTOMER_OTP_RETRY_REQUEST",
  OTP_RETRY_SUCCESS: "CUSTOMER_OTP_RETRY_SUCCESS",
  OTP_RETRY_FAILURE: "CUSTOMER_OTP_RETRY_FAILURE",

  DISABLE_OTP_TIMER: "SET_OTP_TIMER_STATE",
  INITIALIZE_OTP_TIMER: "INITIALIZE_OTP_TIMER",
  MULTI_PHONE_DIALOG: "MULTI_PHONE_DIALOG",

  CANCEL_ORDER:{
		REQUEST: 'CANCEL_ORDER_REQUEST',
		SUCCESS: 'CANCEL_ORDER_SUCCESS',
		FAILURE: 'CANCEL_ORDER_FAILURE',
	}
};

const ADDRESS_CONSTANTS = {
  ADD_NEW_ADDRESS_REQUEST: "ADD_NEW_ADDRESS_REQUEST",
  ADD_NEW_ADDRESS_SUCCESS: "ADD_NEW_ADDRESS_SUCCESS",
  ADD_NEW_ADDRESS_FAILURE: "ADD_NEW_ADDRESS_FAILURE",

  ADD_CUSTOMER_ADDRESS_REQUEST: "ADD_ADDRESS_REQUEST",
  ADD_CUSTOMER_ADDRESS_SUCCESS: "ADD_ADDRESS_SUCCESS",
  ADD_CUSTOMER_ADDRESS_FAILURE: "ADD_ADDRESS_FAILURE",

  UPDATE_CUSTOMER_ADDRESS_REQUEST: "UPDATE_CUSTOMER_ADDRESS_REQUEST",
  UPDATE_CUSTOMER_ADDRESS_SUCCESS: "UPDATE_CUSTOMER_ADDRESS_SUCCESS",
  UPDATE_CUSTOMER_ADDRESS_FAILURE: "UPDATE_CUSTOMER_ADDRESS_FAILURE",

  DELETE_CUSTOMER_ADDRESS_REQUEST: "DELETE_CUSTOMER_ADDRESS_REQUEST",
  DELETE_CUSTOMER_ADDRESS_SUCCESS: "DELETE_CUSTOMER_ADDRESS_SUCCESS",
  DELETE_CUSTOMER_ADDRESS_FAILURE: "DELETE_CUSTOMER_ADDRESS_FAILURE",

  GET_CUSTOMER_ADDRESS_LIST_REQUEST: "CUSTOMER_ADDRESS_LIST_REQUEST",
  GET_CUSTOMER_ADDRESS_LIST_SUCCESS: "CUSTOMER_ADDRESS_LIST_SUCCESS",
  GET_CUSTOMER_ADDRESS_LIST_FAILURE: "CUSTOMER_ADDRESS_LIST_FAILURE",

  GET_LOCATION_DETAILS_REQUEST: "GET_LOCATION_DETAILS_REQUEST",
  GET_LOCATION_DETAILS_SUCCESS: "GET_LOCATION_DETAILS_SUCCESS",
  GET_LOCATION_DETAILS_FAILURE: "GET_LOCATION_DETAILS_FAILURE",

  SET_LOCATION_DETAILS: "SET_LOCATION_DETAILS",
  EMPTY_LOCATION_DETAILS: "EMPTY_LOCATION_DETAILS",
  SELECT_CUSTOMER_ADDRESS: "SELECT_CUSTOMER_ADDRESS",

  UPDATE_ORDER_ADDRESS_REQUEST: "UPDATE_ORDER_ADDRESS_REQUEST",
  UPDATE_ORDER_ADDRESS_SUCCESS: "UPDATE_ORDER_ADDRESS_SUCCESS",
  UPDATE_ORDER_ADDRESS_FAILURE: "UPDATE_ORDER_ADDRESS_FAILURE",

  SET_SELECTED_ADDRESS_INDEX: "SET_SELECTED_ADDRESS_INDEX",
  CONFIRM_ADDRESS_FULLSCREEN_MAP: "CONFIRM_ADDRESS_FULLSCREEN_MAP",
};

const SHIPMENT_CONSTANTS = {
  GET_MERCHANT_SHIPMENT_METHODS_REQUEST: "GET_SHIPMENT_METHODS_REQUEST",
  GET_MERCHANT_SHIPMENT_METHODS_SUCCESS: "GET_SHIPMENT_METHODS_SUCCESS",
  GET_MERCHANT_SHIPMENT_METHODS_FAILURE: "GET_SHIPMENT_METHODS_FAILURE",

  UPDATE_ORDER_SHIPMENT_METHOD_REQUEST: "SET_ORDER_SHIPMENT_METHOD_REQUEST",
  UPDATE_ORDER_SHIPMENT_METHOD_SUCCESS: "SET_ORDER_SHIPMENT_METHOD_SUCCESS",
  UPDATE_ORDER_SHIPMENT_METHOD_FAILURE: "SET_ORDER_SHIPMENT_METHOD_FAILURE",

  UPDATE_SELECTED_SHIPMENT_INDEX: "UPDATE_SELECTED_SHIPMENT_INDEX",
  SELECT_SHIPMENT_INDEX: "SELECT_SHIPMENT_INDEX",
  EDIT_MERCHANT_SHIPMENT: "EDIT_MERCHANT_SHIPMENT",
};

const PAYMENT_CONSTANT = {
  GET_MERCHANT_PAYMENT_METHOD_REQUEST: "GET_PAYMENT_METHODS_REQUEST",
  GET_MERCHANT_PAYMENT_METHOD_SUCCESS: "GET_PAYMENT_METHODS_SUCCESS",
  GET_MERCHANT_PAYMENT_METHOD_FAILURE: "GET_PAYMENT_METHODS_FAILURE",

  UPDATE_ORDER_PAYMENT_METHOD_REQUEST: "UPDATE_CUSTOMER_PAYMENT_REQUEST",
  UPDATE_ORDER_PAYMENT_METHOD_SUCCESS: "UPDATE_CUSTOMER_PAYMENT_SUCCESS",
  UPDATE_ORDER_PAYMENT_METHOD_FAILURE: "UPDATE_CUSTOMER_PAYMENT_FAILURE",

  CREDIT_CARD_AS_DEAFULT_PAYMENT_METHOD_REQUEST: "ADD_CREDIT_CARD_REQUEST",
  CREDIT_CARD_AS_DEAFULT_PAYMENT_METHOD_SUCCESS: "ADD_CREDIT_CARD_SUCCESS",
  CREDIT_CARD_AS_DEAFULT_PAYMENT_METHOD_FAILURE: "ADD_CREDIT_CARD_FAILURE",

  VALIDATE_PAYMENT_METHOD_REQUEST: "VALIDATE_PAYMENT_METHOD_REQUEST",
  VALIDATE_PAYMENT_METHOD_SUCCESS: "VALIDATE_PAYMENT_METHOD_SUCCESS",
  VALIDATE_PAYMENT_METHOD_FAILURE: "VALIDATE_PAYMENT_METHOD_FAILURE",
  VALIDATE_PAYMENT_METHOD_BACK: "VALIDATE_PAYMENT_METHOD_BACK",

  DELETE_CREDIT_CARD_REQUEST: "DELETE_CREDIT_CARD_REQUEST",
  DELETE_CREDIT_CARD_SUCCESS: "DELETE_CREDIT_CARD_SUCCESS",
  DELETE_CREDIT_CARD_FAILURE: "DELETE_CREDIT_CARD_FAILURE",

  // LOCAL STATE UPDATES
  CLEAR_ERROR_UPDATE_ORDER_PAYMENT_METHOD: "CLEAR_ERROR_ON_PAYMENT_FORM",
  SET_ERROR_UPDATE_ORDER_PAYMENT_METHOD: "ADD_ERROR_ON_PAYMENT_FORM",

  UPDATE_SELECTED_PAYMENT_INDEX: "UPDATE_SELECTED_PAYMENT_INDEX",
  PAYMENT_METHOD_SAVE_FOR_NEXT_TIME: "PAYMENT_METHOD_SAVE_FOR_NEXT_TIME",
  UPDATE_DEFAULT_PAYMENT_ACCOUNT_NO: "UPDATE_DEFAULT_PAYMENT_ACCOUNT_NO",

  PAYMENT_VERIFICATION_REQUIRED: "PAYMENT_VERIFICATION_REQUIRED",

  GENERAL: {
    SAVE_PAYMENT_INSTRUMENT_REQUEST: "SAVE_PAYMENT_INSTRUMENT_REQUEST",
    SAVE_PAYMENT_INSTRUMENT_SUCCESS: "SAVE_PAYMENT_INSTRUMENT_SUCCESS",
    SAVE_PAYMENT_INSTRUMENT_FAILURE: "SAVE_PAYMENT_INSTRUMENT_FAILURE",

    EP_MOBILE_NUMBER_REQUEST: "SAVE_EP_MOBILE_NUMBER_REQUEST",

    PAYMENT_PROCESSOR_DETAIL_REQUEST: "REQUEST_PAYMENT_PROCESSOR_DETAIL_REQUEST",
    PAYMENT_PROCESSOR_DETAIL_SUCCESS: "REQUEST_PAYMENT_PROCESSOR_DETAIL_SUCCESS",
    PAYMENT_PROCESSOR_DETAIL_FAILURE: "REQUEST_PAYMENT_PROCESSOR_DETAIL_FAILURE",
  },
  NIFT: {
    UPDATE_MSGS: "UPDATE_NIFT_MESSAGE_STATES",
    CLEAR_MSGS: "CLEAR_NIFT_MESSAGES",
    CLEAR_NIFT_DETAILS: "CLEAR_NIFT_DETAILS",

    GET_NIFT_DETAILS_REQUEST: "GET_NIFT_CREDENTIALS_REQUEST",
    GET_NIFT_DETAILS_SUCCESS: "GET_NIFT_CREDENTIALS_SUCCESS",
    GET_NIFT_DETAILS_FAILURE: "GET_NIFT_CREDENTIALS_FAILURE",

    LOAD_NIFT_PLUGIN_SCRIPT: "LOAD_NIFT_PLUGIN_SCRIPT",
    LOAD_SESSION_JS: "LOAD_SESSION_JS",
    RE_LOAD_NIFT_SCRIPT: "RE_LOAD_NIFT_SCRIPT",

    UPDATE_NIFT_RESP_REQUEST: "UPDATE_NIFT_RESP_REQUEST",
    UPDATE_NIFT_RESP_SUCCESS: "UPDATE_NIFT_RESP_SUCCESS",
    UPDATE_NIFT_RESP_FAILURE: "UPDATE_NIFT_RESP_FAILURE",

    UPDATE_PAYMENT_FAILURE_ATTEMPT: "UPDATE_PAYMENT_FAILURE_ATTEMPT",

    NIFT_SCRIPT_ERROR_COUNT: "NIFT_SCRIPT_ERROR_COUNT"
  },
  QIST_PAY: {
    GET_QIST_PAY_DETAILS_REQUEST: "GET_QIST_PAY_CREDENTIALS_REQUEST",
    GET_QIST_PAY_DETAILS_SUCCESS: "GET_QIST_PAY_CREDENTIALS_SUCCESS",
    GET_QIST_PAY_DETAILS_FAILURE: "GET_QIST_PAY_CREDENTIALS_FAILURE",

    HIDE_LOADER: "QIST_PAY_HIDE_LOADER",
    SHOW_LOADER: "QIST_PAY_SHOW_LOADER"
  }
};

const CART_CONSTANTS = {
  CART_MENU_OPEN_REQUEST: "CART_MENU_OPEN_REQUEST",
  CART_REVIEW_OPEN_REQUEST: "CART_REVIEW_OPEN_REQUEST",

  GET_CART_DETAILS_REQUEST: "GET_CUSTOMER_CART_DETAILS_REQUEST",
  GET_CART_DETAILS_SUCCESS: "GET_CUSTOMER_CART_DETAILS_SUCCESS",
  GET_CART_DETAILS_FAILURE: "GET_CUSTOMER_CART_DETAILS_FAILURE",

  UPDATE_CART_DETAILS_REQUEST: "UPDATE_CUSTOMER_CART_DETAILS_REQUEST",
  UPDATE_CART_DETAILS_SUCCESS: "UPDATE_CUSTOMER_CART_DETAILS_SUCCESS",
  UPDATE_CART_DETAILS_FAILURE: "UPDATE_CUSTOMER_CART_DETAILS_FAILURE",

  PLACE_ORDER_REQUEST: "PLACE_ORDER_DETAILS_REQUEST",
  PLACE_ORDER_SUCCESS: "PLACE_ORDER_DETAILS_SUCCESS",
  PLACE_ORDER_FAILURE: "PLACE_ORDER_DETAILS_FAILURE",

  VOUCHER_REQUEST: "VOUCHER_CODE_REQUEST",
  VOUCHER_SUCCESS: "VOUCHER_CODE_SUCCESS",
  VOUCHER_FAILURE: "VOUCHER_CODE_FAILURE",

  DEVICE_FINGERPRINT_FOUND: "DEVICE_FINGERPRINT_FOUND_SUCCESS",
  DEVICE_FINGERPRINT_ERROR: "DEVICE_FINGERPRINT_ERROR_FOUND",

  POST_CHECKOUT_VERIFICATION_CHECK: "POST_CUSTOMER_CHECKOUT_VERIFICATION_CHECK",
  POST_CHECKOUT_VERIFICATION_COMPLETED: "POST_CHECKOUT_VERIFICATION_COMPLETED",

  VALIDATE_PAYMENT_INFO_REQUEST: "VALIDATE_THIRD_PARTY_PAYMENT_INFO_REQUEST",
  VALIDATE_PAYMENT_INFO_SUCCESS: "VALIDATE_THIRD_PARTY_PAYMENT_INFO_SUCCESS",
  VALIDATE_PAYMENT_INFO_FAILURE: "VALIDATE_THIRD_PARTY_PAYMENT_INFO_FAILURE",

  CLEAR_PAYMENT_VERIFIED: "CLEAR_PAYMENT_VERIFIED"
};

const CATALOG_ORDERS = {
  INITIATE_CATALOG_ORDER_REQUEST: "INITIATE_MERCHANT_CATALOG_ORDER_REQUEST",
  INITIATE_CATALOG_ORDER_SUCCESS: "INITIATE_MERCHANT_CATALOG_ORDER_SUCCESS",
  INITIATE_CATALOG_ORDER_FAILURE: "INITIATE_MERCHANT_CATALOG_ORDER_FAILURE",
};

export {
  ALERT_CONSTANTS,
  ACTION_CONSTANTS,
  ANALYTICS_CONSTANTS,
  ERROR_CONSTANTS,
  FORM_CONSTANTS,
  LANG_CONSTANTS,
  META_CONSTANTS,
  NETWORK_CONSTANTS,
  CUSTOMER_CONSTANTS,
  MAP_CONSTANTS,
  CONFIG_CONSTANTS,
  ADDRESS_CONSTANTS,
  SHIPMENT_CONSTANTS,
  PAYMENT_CONSTANT,
  CART_CONSTANTS,
  CATALOG_ORDERS,
};
