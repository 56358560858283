/* eslint-disable no-debugger */
// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "../../../components/Alert";

import {
  addressAction,
  alertActions,
  mapActions,
} from "../../../store/actions";
import {
  HELPER,
  ROUTE_CONSTANTS,
  ANALYTICS_HELPER,
  GOOGLE_ANALYTICS_HELPER,
  history,
} from "../../../utils";
import { useAddressOnNotMandatory } from '../../../hooks'
import { MAP_CONSTANTS } from "../../../store/actionTypes";

let timeoutTime;
let defaultDropdownState = {
  value: null,
  label: null,
};
let defaultErrorState = {
  country_id: "",
  province_id: "",
  city_id: "",
  area_id: "",
  address: "",
  latitude: "",
  longitude: ""
};

function EditAddressForm() {
  let dispatch = useDispatch();
  const translate = useTranslate();
  const { address, setAddress } = useAddressOnNotMandatory()

  const { address_count } = useSelector(state => state.redirect);
  const { showPageLoader } = useSelector(state => state.loading);
  const { metaData, defaultCountry } = useSelector(state => state.meta);
  const { is_pin_location_mandatory, is_international_shipment } = useSelector(state => state.configuration.config);
  const { searchPlace, setCoordinatesLat, setCoordinatesLng, clearAddress } = useSelector(state => state.map);
  const { selectedDropdown, locationDetails, countryDropdownLoading } = useSelector(state => state.address);

  const { location } = history;
  const [address_id, setAddress_id] = useState(null);
  const [country_id, setCountry_id] = useState(defaultDropdownState);
  const [province_id, setProvince_id] = useState(defaultDropdownState);
  const [city_id, setCity_id] = useState(defaultDropdownState);
  const [area_id, setArea_id] = useState(defaultDropdownState);
  // const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [is_default, setIs_default] = useState(0);
  const [errors, setErrors] = useState(defaultErrorState);
  const [countryData, setCountryData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [areasData, setAreasData] = useState([]);
  const [dropdownLoaded, setDropdownLoadedState] = useState(false);
  const [zip_code, setzip_code] = useState("");

  const [province_name, setprovince_name] = useState('')
	const [city_name, setcity_name] = useState('')
	const [area_name, setarea_name] = useState('')


  const errorCountry = HELPER.isEmpty(errors.country_id) ? false : true;
  const errorProvince = HELPER.isEmpty(errors.province_id) ? false : true;
  const errorCity = HELPER.isEmpty(errors.city_id) ? false : true;
  const errorAddress = HELPER.isEmpty(errors.address) ? false : true;
  const errorArea= HELPER.isEmpty(errors.area_id) ? false : true;

  let showAllFields=  locationDetails?.error_msg
  
  let isDisabled=((!!is_pin_location_mandatory && setCoordinatesLat) || is_international_shipment) && !showAllFields
  let isCountryDisabled= locationDetails?.country && locationDetails?.country_id !== -1
	let isProvinceDisabled= locationDetails?.state && locationDetails?.state_id !== -1
	let isCityDisabled= locationDetails?.city && locationDetails?.city_id !== -1

  const checkErrorCondition = () => {
    const errorCountryId = !HELPER.isEmpty(country_id) ? false : true;
    const errorProvinceId = !HELPER.isEmpty(province_id) ? false : true;
    const errorCityId = !HELPER.isEmpty(city_id) ? false : true;
    const errorAddressName = !HELPER.isEmpty(address) ? false : true;

    const errorProvinceName =
			(province_id?.value === -1 || location.state?.address?.is_manual_province_id) && HELPER.isEmpty(province_name) ? true : false
		const errorCityName = (city_id?.value === -1 || location.state?.address?.is_manual_city_id) && HELPER.isEmpty(city_name) ? true : false
		const errorAreaName = (area_id?.value === -1 || location.state?.address?.is_manual_area_id) && HELPER.isEmpty(area_name) ? true : false
    // const errorAreaId = !HELPER.isEmpty(area_id) ? false : true;
    const checkErrorCondition =
      errorCountryId || errorProvinceId || errorCityId || errorAddressName  || 
      errorProvinceName || errorCityName || errorAreaName ||
      (is_international_shipment && (typeof country_id === 'object' ? !country_id?.value : !HELPER.isEmpty(country_id)))
        ? true
        : false;
    return checkErrorCondition;
  };

  const checkError = checkErrorCondition();


  const handleCountryChange = (e, options) => {
    if(country_id?.value === options?.value) return
    // Clear all selected country_id, province_id, city_id, area_id and respective dropdown values
    setCountry_id(defaultDropdownState);
    setProvince_id(defaultDropdownState);
    setCity_id(defaultDropdownState);
    setArea_id(defaultDropdownState);

    setProvinceData([]);
    setCitiesData([]);
    setAreasData([]);

    let selected_id = options?.value;

    if (!HELPER.isEmpty(selected_id)) {
      // set country id
      let selected_country_id = {
        value: selected_id,
        label: options?.label,
      };
      // handleAddressUpdateClear({ name: "country", options })
      setCountry_id(selected_country_id);

      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.COUNTRY.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.COUNTRY.LABEL,
          properties: {}
        }
      );
      // send selected_country_id.value parameter to parse provinces from HELPER
      const provinces = HELPER.findProvinceByCountryId(
        countryData,
        selected_id
      );
      // save parsed provinces data in provinces dropdown
      setProvinceData(provinces);
    }
  };

  const handleProvinceChange = (e, options) => {
    if(province_id?.value === options?.value) return
    // Clear all selected country_id, province_id, city_id, area_id and respective dropdown values
    setProvince_id(defaultDropdownState);
    setCity_id(defaultDropdownState);
    setArea_id(defaultDropdownState);

    setCitiesData([]);
    setAreasData([]);

    let selected_id = options?.value;

    if (!HELPER.isEmpty(selected_id)) {
      // set province id
      let selected_province_id = {
        value: selected_id,
        label: options?.label,
      };
      // handleAddressUpdateClear({ name: "state", options })
      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.PROVINCE.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.PROVINCE.LABEL,
          properties: {}
        }
      );
      setProvince_id(selected_province_id);

      // send selected_province_id.value parameter to parse cities from HELPER
      const cities = HELPER.findCityByProvinceId(provinceData, selected_id);
      // save parsed cities data in cities dropdown
      setCitiesData(cities);
    }
  };

  const handleCityChange = (e, options) => {
    if(city_id?.value === options?.value) return
    // Clear all selected country_id, province_id, city_id, area_id and respective dropdown values
    setCity_id(defaultDropdownState);
    setArea_id(defaultDropdownState);

    setAreasData([]);

    let selected_id = options?.value;

    if (!HELPER.isEmpty(selected_id)) {
      // set city id
      let selected_city_id = {
        value: selected_id,
        label: options?.label,
      };
      // handleAddressUpdateClear({ name: "city", options })
      setCity_id(selected_city_id);

      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.CITY.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.CITY.LABEL,
          properties: {}
        }
      );
      // send selected_city_id.value parameter to parse areas from HELPER
      const areas = HELPER.findAreaByCityId(citiesData, selected_id);
      // save parsed areas data in areas dropdown
      setAreasData(areas);
    }
  };

  const handleAreaChange = (e, options) => {
    if(area_id?.value === options?.value) return
    // Clear all selected area_id and respective dropdown values
    setArea_id(defaultDropdownState);

    let selected_id = options?.value;

    if (!HELPER.isEmpty(selected_id)) {
      // set area id
      let selected_area_id = {
        value: selected_id,
        label: options?.label,
      };
      // save parsed areas data in areas dropdown
      setArea_id(selected_area_id);
      // handleAddressUpdateClear({ name: "area", options })
      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.AREA.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.AREA.LABEL,
          properties: {}
        }
      );
    }
  };

  const handleSubmit = () => {
    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.SUBMIT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.SUBMIT.LABEL,
        properties: {}
      }
    );
    // e.preventDefault();
    let validationCheck = handleValidation();
    if (
      validationCheck.country_id === "" &&
      validationCheck.province_id === "" &&
      validationCheck.city_id === "" &&
      validationCheck.area_id === "" &&
      validationCheck.address === "" &&
      validationCheck.latitude === "" &&
      validationCheck.longitude === "" &&
      !showPageLoader
    ) {
      if (HELPER.isEmpty(address_id)) {
        const addressObject = {
          country_id: country_id.value,
          province_id: province_id.value,
          city_id: city_id.value,
          area_id: area_id?.value,
          address,
          latitude,
          longitude,
          is_default: is_default ? 1 : 0,
          zip_code,

          province_name,
          area_name,
          city_name,
        };
        const dropdown = {
          countryData,
          provinceData,
          citiesData,
          areasData,
        };
        dispatch(addressAction.add_new_customer_address(addressObject, dropdown, history, false));
      } else {
        const addressObject = {
          address_id,
          country_id: country_id.value,
          province_id: province_id.value,
          city_id: city_id.value,
          area_id: area_id?.value,
          address,
          latitude,
          longitude,
          is_default: is_default ? 1 : 0,
          zip_code,

          province_name,
          area_name,
          city_name,
        };
        dispatch(addressAction.update_customer_address(addressObject, history, false));
      }
    } else {
      timeoutTime = setTimeout(() => {
        setErrors(defaultErrorState);
      }, 2500);
    }
  };

  const moveBackwards = () => {
    history.push({
      pathname: ROUTE_CONSTANTS.ADDRESS_LIST,
    });
  };

  const handleValidation = () => {
    // same as above, but feel free to move this into a class method now.
    let validation_errors = {
      country_id: "",
      province_id: "",
      city_id: "",
      area_id: "",
      address: "",
      latitude: "",
      longitude: ""
    };
    if (HELPER.isEmpty(latitude) && !!is_pin_location_mandatory) {
      validation_errors.latitude = translate("mandatory_pin_placeholder");
      dispatch(alertActions.error(translate("mandatory_pin_placeholder")));
    } else if (HELPER.isEmpty(longitude) && !!is_pin_location_mandatory) {
      validation_errors.longitude = translate("mandatory_pin_placeholder");
      dispatch(alertActions.error(translate("mandatory_pin_placeholder")));
    } else if (HELPER.isEmpty(country_id.value)) {
      validation_errors.country_id = translate("VALIDATIONS.IS_REQUIRED");
    } else if (HELPER.isEmpty(province_id.value) || (province_id?.value === -1 && !province_name)) {
      validation_errors.province_id = translate("VALIDATIONS.IS_REQUIRED");
    } else if (HELPER.isEmpty(city_id.value) || (city_id?.value === -1 && !city_name)) {
      validation_errors.city_id = translate("VALIDATIONS.IS_REQUIRED");
    } else if (!address) {
      validation_errors.address = translate("VALIDATIONS.IS_REQUIRED");
    } else if (address.length < 5) {
      validation_errors.address = translate("address_field_min_validation");
    }
    else if (HELPER.isEmpty(area_id.value) || (area_id?.value === -1 && !area_name)) {
      validation_errors.area_id = translate("VALIDATIONS.IS_REQUIRED");
    }
    setErrors(validation_errors);
    return validation_errors;
  };

  //componentDidMount
  useEffect(() => {
    //UPDATE_PLACESSEARCH for map full screen
    location.state?.address && dispatch(mapActions.UPDATE_PLACESSEARCH(location.state?.address?.address));
    
    // FIREBASE_ANALYTICS_HELPER.checkoutSteps(FIREBASE_ANALYTICS_HELPER.CHECKOUT.ADDRESS.TIER);
    ANALYTICS_HELPER._checkoutSteps(3, GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS);
    dispatch(alertActions.clear());
    dispatch(mapActions.INITIATE_MAP());
    if (parseInt(address_count) === 0) {
      setIs_default(1);
    }
    const location_address = location.state?.address;
    if (location_address === undefined && HELPER.isEmpty(searchPlace)) {
      dispatch(mapActions.GET_LOCATION_PIN(true));
    }
    //Initialize country dropdown
    if (!HELPER.isEmpty(metaData) && !countryDropdownLoading && !dropdownLoaded) {
      // Check if reducer has metaData values and assign it to meta state if not, then call meta data api asap
      setCountryData(metaData);
      setDefaultCountryDropdown();
      if (location_address !== undefined && location_address?.edit_address === 1 && !clearAddress) {
        setSelectedAddress(location_address)
      }
      setSelectedAddressDropdown(address);
    }
    // returned function will be called on component unmount
    return () => {
      clearTimeout(timeoutTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const address = location.state?.address;

    if (!HELPER.isEmpty(metaData) && !dropdownLoaded) {
      setCountryData(metaData);
      setDefaultCountryDropdown();
    }
    if (!HELPER.isEmpty(selectedDropdown) && address !== undefined && !dropdownLoaded) {
      if (address !== undefined && address?.edit_address === 1 && !clearAddress) {
        setSelectedAddress(address)
      }
      setSelectedAddressDropdown(address)
    }

    // Get location details api response
    if (!HELPER.isEmpty(locationDetails) && !clearAddress) {
      setAddressByLocationPin();
    }

    if (!HELPER.isEmpty(setCoordinatesLat) && !clearAddress) {
      setLatitude(setCoordinatesLat);
      setLongitude(setCoordinatesLng);
    }
    else {
      setLatitude(null);
      setLongitude(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedDropdown,
    defaultCountry,
    metaData,
    setCoordinatesLat,
    setCoordinatesLng,
    locationDetails,
  ]);

  const setSelectedAddress = (location_address) => {
    setAddress_id(location_address?.address_id);
    setCountry_id({
      value: location_address?.country_id,
      label: location_address?.country_name,
    });
    setProvince_id({
      value: location_address?.province_id,
      label: location_address?.province_name,
    });
    setCity_id({
      value: location_address?.city_id,
      label: location_address?.city_name,
    });
    setArea_id({
      value: location_address?.area_id,
      label: location_address?.area_name,
    });
    
    setprovince_name(location_address?.province_name || "")
		setcity_name(location_address?.city_name || "")
		setarea_name(location_address?.area_name || "")

    setzip_code(location_address?.zip_code);
    setAddress(location_address?.address);
    dispatch(mapActions.UPDATE_PLACESSEARCH(location_address?.address));
    setIs_default(location_address?.is_default);
    setLatitude(location_address?.lat);
    setLongitude(location_address?.long);
    if (HELPER.isEmpty(locationDetails) && !HELPER.isEmpty(location_address?.lat) && !HELPER.isEmpty(location_address?.long)) {
      dispatch(mapActions.update_position(parseFloat(location_address?.lat), parseFloat(location_address?.long)));
    }
  };

  const setAddressByLocationPin = () => {
    var provinces,
      cities,
      areas = [];
    const loc_details_country_id = locationDetails.country_id;
    const loc_details_state_id = locationDetails.state_id;
    const loc_details_city_id = locationDetails.city_id;
    const loc_details_area_id = locationDetails.area_id;
    // Get province, city and area values from metaData;
    provinces = HELPER.findProvinceByCountryId(
      metaData,
      loc_details_country_id
    );
    if (!HELPER.isEmpty(loc_details_state_id)) {
      cities = HELPER.findCityByProvinceId(provinces, loc_details_state_id);
      if (!HELPER.isEmpty(loc_details_city_id)) {
        areas = HELPER.findAreaByCityId(cities, loc_details_city_id);
      }
    }

    setCountry_id({
      value: loc_details_country_id,
      label: locationDetails.country,
    });
    setProvinceData(provinces);
    setProvince_id({
      value: loc_details_state_id,
      label: locationDetails.state,
    });
    setCitiesData(cities || []);
    setCity_id({
      value: loc_details_city_id,
      label: locationDetails.city,
    });
    setAreasData(areas);
    setArea_id({
      value: loc_details_area_id,
      label: locationDetails.area,
    });

    setprovince_name(locationDetails?.state || "")
    setcity_name(locationDetails?.city || "")
    setarea_name(locationDetails?.area || "")

    setAddress(locationDetails.address);
    dispatch(mapActions.UPDATE_PLACESSEARCH(locationDetails.address));

  };

  const setSelectedAddressDropdown = (address) => {
    const _countryData = !HELPER.isEmpty(selectedDropdown.countryData)
      ? selectedDropdown.countryData
      : [
        {
          value: address?.country_id,
          label: address?.country_name,
        },
      ];
    const _provinceData = !HELPER.isEmpty(selectedDropdown.provinceData)
      ? selectedDropdown.provinceData
      : [
        {
          value: address?.province_id,
          label: address?.province_name,
        },
      ];
    const _citiesData = !HELPER.isEmpty(selectedDropdown.citiesData)
      ? selectedDropdown.citiesData
      : [
        {
          value: address?.city_id,
          label: address?.city_name,
        },
      ];
    const _areasData = !HELPER.isEmpty(selectedDropdown.areasData)
      ? selectedDropdown.areasData
      : [
        {
          value: address?.area_id,
          label: address?.area,
        },
      ];
    setCountryData(_countryData);
    setProvinceData(_provinceData);
    setCitiesData(_citiesData);
    setAreasData(_areasData);
    setDropdownLoadedState(true)
  };

  const setDefaultCountryDropdown = () => {
    if (!HELPER.isEmpty(defaultCountry) && !HELPER.isEmpty(metaData)) {
      const provinces = HELPER.findProvinceByCountryId(
        metaData,
        defaultCountry?.value
      );
      defaultCountry?.value && setCountry_id(defaultCountry);
      setProvinceData(provinces);
    }
  };

  // const onKeyDownHandler = (e) => {
  //   if (e.keyCode === 13) {
  //     handleSubmit();
  //   }
  // };

  useEffect(()=>{
    is_international_shipment && !setCoordinatesLat &&
    dispatch(mapActions.GET_LOCATION_PIN(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="signInView">
        <h1>{translate("ADDRESS.TITLE")}</h1>
        <p className="signviewsmalltext">
          {translate("ADDRESS.ADD.PLACEHOLDER")}
        </p>
        <Alert />
        <form className="myForm addressForm">
          <div className="addressForm-fields">
            {/* Address */}
            <div>
            <TextField
              type="textarea"
              margin="normal"
              variant="outlined"
              className="col-md-12"
              label={translate("ADDRESS.FORM.LABEL.ADDRESS")}
              name="address"
              placeholder={translate("ADDRESS.FORM.PLACEHOLDER.ADDRESS")}
              rows={6}
              multiline={!is_international_shipment}
              onChange={(e) => {
                // LOAD GTM EVENT
                ANALYTICS_HELPER._generalEventLog(
                  GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
                  {
                    action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.ADDRESS.ACTION,
                    label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.ADDRESS.LABEL,
                    properties: {},
                  },
                );
                dispatch({type: MAP_CONSTANTS.SEARCHED_ADDRESS , value: e.target.value});
                setAddress(e.target.value);
              }}
              inputProps={{
                id: `${is_international_shipment ? "map-address" : ""}`,
              }}
              value={address}
              required={true}
              error={errorAddress}
              helperText={errors.address}
              autoFocus={false}
            />
            </div>

            {(is_international_shipment && !setCoordinatesLat && !showAllFields) ?
            "" :
            <div>
            <div className="inline-items">
                  <Autocomplete
                    disabled={isDisabled || isCountryDisabled}
                    options={countryData}
                    getOptionLabel={(option) => (option.label ? option.label : "")}
                    getOptionSelected={(option, value) => {
                      return value.value;
                    }}
                    value={(!HELPER.isEmpty(country_id.value) ? country_id : null)}
                    onChange={handleCountryChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="normal"
                        name="country_id"
                        label={translate("ADDRESS.FORM.LABEL.COUNTRY")}
                        placeholder={translate("ADDRESS.FORM.PLACEHOLDER.COUNTRY")}
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        required={true}
                        error={errorCountry}
                        helperText={errors.country_id}
                      />
                    )}
                  />
                  {/* Province */}
                  {province_id?.value === -1 || 
                  (location.state?.address?.is_manual_province_id && HELPER.isEmpty(locationDetails)) ?
                    <TextField
                    margin="normal"
                    variant="outlined"
                    label={translate("ADDRESS.FORM.LABEL.PROVINCE")}
                    placeholder={translate("ADDRESS.FORM.LABEL.PROVINCE")}
                    onChange={(e)=> setprovince_name(e.target.value)}
                    value={province_name}
                    required={true}
                    error={errorProvince}
                    helperText={errors.province_id}
                    />
                  :
                  <>
                  {provinceData && (
                    <Autocomplete
                    disabled={isDisabled || isProvinceDisabled}
                      options={provinceData}
                      getOptionLabel={(option) => (option.label ? option.label : "")}
                      getOptionSelected={(option, value) => {
                        return value.value;
                      }}
                      value={(!HELPER.isEmpty(province_id.value) ? province_id : null)}
                      // autoComplete={false}
                      onChange={handleProvinceChange}
                      ListboxProps={{ style: { maxHeight: "10rem" } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          name="province_id"
                          label={translate("ADDRESS.FORM.LABEL.PROVINCE")}
                          placeholder={translate("ADDRESS.FORM.PLACEHOLDER.PROVINCE")}
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          required={true}
                          error={errorProvince}
                          helperText={errors.province_id}
                          autoFocus={false}
                        />
                      )}
                    />
                  )}
                  </>}
                </div>
                  <div className="inline-items">
                  {/* Cities */}
                  {city_id?.value === -1 || 
                  (location.state?.address?.is_manual_city_id && HELPER.isEmpty(locationDetails)) ?
                    <TextField
                    margin="normal"
                    variant="outlined"
                    label={translate("ADDRESS.FORM.LABEL.CITY")}
                    placeholder={translate("ADDRESS.FORM.LABEL.CITY")}
                    onChange={(e)=> setcity_name(e.target.value)}
                    value={city_name}
                    required={true}
                    error={errorCity}
                    helperText={errors.city_id}
                    />
                  :
                  <>
                  {citiesData && (
                    <Autocomplete
                    disabled={isDisabled || isCityDisabled}
                      options={citiesData}
                      value={(!HELPER.isEmpty(city_id.value) ? city_id : null)}
                      getOptionLabel={(option) => (option.label ? option.label : "")}
                      getOptionSelected={(option, value) => {
                        return value.value;
                      }}
                      onChange={handleCityChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          name="city_id"
                          label={translate("ADDRESS.FORM.LABEL.CITY")}
                          placeholder={translate("ADDRESS.FORM.PLACEHOLDER.CITY")}
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          required={true}
                          error={errorCity}
                          helperText={errors.city_id}
                          autoFocus={false}
                        />
                      )}
                    />
                  )}
                  </>}

                  <TextField
                    type="number"
                    // type="text"
                    margin="normal"
                    variant="outlined"
                    label={translate("ADDRESS.FORM.LABEL.ZIPCODE")}
                    name="zip_code"
                    placeholder={translate("ADDRESS.FORM.LABEL.ZIPCODE")}
                    onChange={(e)=> setzip_code(e.target.value)}
                    value={zip_code}
                    autoFocus={false}
                  />
                </div>

                  {/* Area */}
                  {area_id?.value === -1 || 
                  (location.state?.address?.is_manual_area_id && HELPER.isEmpty(locationDetails)) ?
                    <TextField
                    margin="normal"
                    variant="outlined"
                    label={translate("ADDRESS.FORM.LABEL.AREA")}
                    placeholder={translate("ADDRESS.FORM.LABEL.AREA")}
                    onChange={(e)=> setarea_name(e.target.value)}
                    value={area_name}
                    required={true}
                    fullWidth
                    error={errorArea}
                    helperText={errors.area_id}
                    />
                  :
                <Autocomplete
                    disabled={is_international_shipment && !showAllFields }

                    options={areasData}
                    getOptionLabel={(option) => (option.label ? option.label : "")}
                    getOptionSelected={(option, value) => {
                      return value.value;
                    }}
                    value={(!HELPER.isEmpty(area_id.value) ? area_id : null)}
                    onChange={handleAreaChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="normal"
                        name="area_id"
                        label={translate("ADDRESS.FORM.LABEL.AREA")}
                        placeholder={translate("ADDRESS.FORM.PLACEHOLDER.AREA")}
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        required={true}
                        autoFocus={false}
                        error={errorArea}
                        helperText={errors.area_id}
                      />
                    )}
                  />
                 }
              </div> 
            }
         </div>

          <div className="checkBoxCard">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="is_default"
                  value={0}
                  onChange={() => {
                    setIs_default(!is_default);
                  }}
                  checked={Number(is_default) === 1 ? true : false}
                  disabled={parseInt(address_count) === 0 ? true : false}
                />
              }
              label={translate("ADDRESS.SET_DEAFULT")}
            />
          </div>

          {locationDetails?.error_msg && (locationDetails?.area_id === -1 ||
					locationDetails?.city_id === -1 ||
					locationDetails?.state_id === -1) ?
          <div className="recheck-address-text mb-2">
            <i className={`icomoon-info`} />
            &nbsp;
            {locationDetails?.error_msg}
          </div>
          :
          <></>
          }

          <div className="address-optionBtn optionBtn">
            <button
              type="button"
              id="back-btn"
              className="btn btn-outline-primary width150"
              onClick={moveBackwards}
            >
              {translate("back_btn")}
            </button>
            <button
              type="button"
              id="address-submit"
              className={"btn btn-primary width150  "}
              onClick={handleSubmit}
              disabled={checkError ? true : false}
            >
              {translate("next_btn")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditAddressForm;
