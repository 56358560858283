import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { HELPER } from "../../utils";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = ({ children, onClose, hideCrossBtn, ...other }) => {
  return (
    <MuiDialogTitle
      // disableTypography
      {...other}
      id="terms-dialog-title"
      className="inviteHeaderWrap"
    >
      <div>{children}</div>
      {!hideCrossBtn ? (
        <span className="closeIconDialog">
          <i className="icomoon-cross" onClick={onClose} />
        </span>
      ) : null}
    </MuiDialogTitle>
  );
};

function CustomizedDialog({
  open,
  content,
  title,
  successBtn,
  cancelBtn,
  onCancelHandler,
  onSuccesslHandler,
  setopen,
  hideBackdrop,
  bottom,
  dialogBtmClassName,
  contentClassName,
  titleClassName,
  disablebackdropclick,
  resetValue,
  hideCrossBtn,
  onClose,
  headerLine,
  dialogContentClass,
}) {
  const handleClose = () => {
    if (resetValue) {
      resetValue("");
    }
    setopen(false);
  };

  return (
    <div>
      <Dialog
        // disableEnforceFocus //<-- for input 
        data-testid="dialog-main"
        className={`${bottom ? dialogBtmClassName : ""} middleDialog`}
        disableEscapeKeyDown
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (disablebackdropclick) {
            if (reason !== "backdropClick") {
              handleClose();
            }
          } else {
            handleClose();
          }
        }}
        hideBackdrop={hideBackdrop || false}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {headerLine &&
          <div className="Dialog-headerLine mt-3" onClick={onClose || handleClose}></div>
        }
        
        {HELPER.isNotEmpty(title) && (
          <DialogTitle
            id="alert-dialog-slide-title"
            data-testid="dialog-title"
            onClose={() => handleClose()}
            hideCrossBtn={hideCrossBtn}
          >
            <span className={titleClassName}>{title}</span>
          </DialogTitle>
        )}

        {HELPER.isNotEmpty(content) && (
          <DialogContent className={dialogContentClass}>            
            <DialogContentText
              id="alert-dialog-slide-description"
              className={contentClassName}
              component="div"
            >

          {!hideCrossBtn && !title ? (
            <span className="dialog-close-content-icon" onClick={onClose} >
               <i className="icomoon-cross"></i>
            </span>
          ) : null}

              {content}
            </DialogContentText>
          </DialogContent>
        )}

        <DialogActions className="ModalButtons">
          {cancelBtn && (
              <Button
              type="outline"
              onClick={onCancelHandler || handleClose}
            >
              {cancelBtn}
            </Button>
          )}
          {successBtn && (
             <Button
             type="primary"
             onClick={onSuccesslHandler || handleClose}>
              {successBtn}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(CustomizedDialog);
