import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import queryString from "query-string";
import ReactHtmlParser from "html-react-parser";
import { loadVGSCollect } from "@vgs/collect-js";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  IconButton
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import HtmlTooltip from "../../../components/HtmlTooltip";

import {
  CONSTANTS,
  HELPER,
} from "../../../utils";
import {
  cartAction,
  formAction,
  paymentAction
} from "../../../store/actions";

let defaultErrorState = {
  cc_cvv: "",
};
const css = HELPER.getStyledCCAttributes().css;

function CheckoutCvvRequirement(props) {
  const {
    errors_cc_cvv,
    errors_cc_cvv_message,
  } = props;

  let dispatch = useDispatch();
  const translate = useTranslate();

  const {
    cc_payment_verified,
    payment_verification_required
  } = useSelector(state => state.payment);
  const { payment_method } = useSelector(state => state.cart);
  const { showCartReview } = useSelector(state => state.action);
  // Save query string parameters
  const params = queryString.parse(location.search);
  //Order reference recieved incase of 3ds verified payment
  const secure_id = params?.secure_id;
  const vgsEnabled = payment_method?.vgs_enabled;
  const vgsCredentials = payment_method?.vgs_config;
  //Credit card cvv
  const [required_cc_cvv, setRequiredCc_cvv] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState(defaultErrorState);

  //componentDidMount
  useEffect(() => {
    // IF cc_payment_verified && CC IS NOT 3DS ONE THEN CVV IS REQUIRED AND payment_method?.input_type WILL BE CVV
    if (
      cc_payment_verified &&
      // cc_payment_3ds_required === 0 &&
      payment_method?.input_type ===
      CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.CREDIT_CARD
      && HELPER.isEmpty(secure_id)
    ) {
      setRequiredCc_cvv(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //componentWillRecieveProps
  useEffect(() => {
    if (errors_cc_cvv || errors_cc_cvv_message === translate("VALIDATIONS.IS_REQUIRED")) {
      //  && (errors_cc_cvv_message === "request" || errors_cc_cvv_message === translate("VALIDATIONS.IS_REQUIRED"))
      handleFormSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errors_cc_cvv, errors_cc_cvv_message
  ]);

  useEffect(()=>{
    if(errors?.cc_cvv){
      if(showCartReview){
        dispatch(cartAction.TOGGLE_CART_REVIEW_POPUP());
        HELPER.scrollScreen(false)
        // scroll.scrollToBottom()
      }
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors?.cc_cvv])

  useEffect(()=>{
    if(showCartReview){
      setErrors(defaultErrorState)
    } 
  }, [showCartReview])

  //componentWillRecieveProps
  useEffect(() => {
    if (required_cc_cvv) {
      initializeVGSForm();
      if (
        payment_method?.input_type === CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.CVV && !payment_verification_required
      ) {
        dispatch(paymentAction.payment_method_verification(payment_method?.input_type));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required_cc_cvv]);

  //componentWillRecieveProps
  useEffect(() => {
    //IF INPUT_TYPE OF PAYMENT_METHOD IS CARD, THEN VALIDATE INSTRUMENT TOKEN
    if (payment_method?.input_type === CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.CVV) {
      setRequiredCc_cvv(true);
    }
    // IF cc_payment_verified && CC IS NOT 3DS ONE THEN CVV IS REQUIRED AND payment_method?.input_type WILL BE CVV
    if (
      cc_payment_verified &&
      // cc_payment_3ds_required === 0 &&
      payment_method?.input_type === CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.CVV
    ) {
      setRequiredCc_cvv(true);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cc_payment_verified,
    payment_method,
  ]);

  const initializeVGSForm = () => {
    /* LOAD VGS FORM STARTS HERE */
    const loadForm = async () => {
      const vgs_collect = await loadVGSCollect({
        vaultId: vgsCredentials?.vault,
        environment: vgsCredentials?.environment,
        version: vgsCredentials?.version,
      }).catch((err) => { window.onerror(err)})
      initForm(vgs_collect);
    };
    vgsCredentials?.vault && loadForm();
    /* LOAD VGS FORM ENDS HERE */
  }

  const handleFormSubmit = () => {
    let validationCheck = handleValidation();
    if (!validationCheck && !HELPER.isEmpty(form) && form.state?.card_cvc) {
      dispatch(formAction.SHOW_LOADER())
      form.submit("/post", {}, (status, data) => {
        if (status === 200) {
          dispatch(formAction.HIDE_LOADER())
          const cvvHash = vgsEnabled ? data?.json?.card_cvc : data?.json?.card_cvv;
          const ccData = {
            cc_cvv: cvvHash,
          };
          props.updateCvv(ccData);
        } else {
          dispatch(formAction.HIDE_LOADER())
        }
      });
    }
  };

  const initForm = (vgs_collect) => {
    const form = vgs_collect.init(() => {
      // console.log(state);
    });
    const cvvFieldName = vgsEnabled ? "card_cvc" : "card_cvv";
    const cvvFieldId = vgsEnabled ? "#card-cvc" : "#card-cvc";
    form.field(cvvFieldId, {
      type: "card-security-code",
      hideValue: true,
      name: cvvFieldName,
      successColor: "#1b1d1f",
      autoComplete: "cc-csc",
      isValid: true,
      showCardIcon: {
        right: "7px",
        width: "37px",
        height: "25px",
      },
      errorColor: "#e86060",
      placeholder: translate("PAYMENT.LABEL.CARD.CVV"),
      maxLength: 3,
      validations: ["required", "validCardSecurityCode"],
      css,
    });
    // const cvc = form.field(cvvFieldId, {
    //   type: "card-security-code",
    //   name: cvvFieldName,
    //   successColor: "#1b1d1f",
    //   isValid: true,
    //   required: required_cc_cvv,
    //   errorColor: "#e86060",
    //   placeholder: translate("PAYMENT.LABEL.CARD.CVV"),
    //   maxLength: 3,
    //   validations: ["required", "validCardSecurityCode"],
    //   css,
    // });
    // cardNumber.setCVCDependency(cvc);
    setForm(form);
  };

  const handleValidation = () => {
    const state = form?.state;
    // same as above, but feel free to move this into a class method now.
    let validation_errors = {
      cc_cvv: "",
    };
    let errorFound = false;
    if (vgsEnabled) {
      const card_cvc = checkForErrors(state?.card_cvc, "CVV");
      if (!HELPER.isEmpty(card_cvc)) {
        validation_errors.cc_cvv = card_cvc;
        errorFound = true;
      }
    } else {
      const card_cvv = checkForErrors(state?.card_cvv, "CVV");
      if (!HELPER.isEmpty(card_cvv)) {
        validation_errors.cc_cvv = card_cvv;
        errorFound = true;
      }
    }
    setErrors(validation_errors);
    return errorFound;
  };

  const checkForErrors = (fieldName, placeholder) => {
    const errorMessages = fieldName?.errorMessages;
    if (!HELPER.isEmpty(errorMessages)) {
      let errorMsg = "";
      errorMessages.map((msg) => {
        errorMsg += placeholder + " " + msg + "<br/>";
        return null;
      });
      return errorMsg === "" ? null : errorMsg;
    }
    return null;
  };

  return (
    <div className="twoField creditCardDiv cvvInnerButton">
      {required_cc_cvv ? (
        <div className="subhead cvv">
          {/* PAGE CONTENT STARTS HERE */}
          <form id="collect-form" className="cvvCollectForm creditCardCollectForm">
            <div className="col-12 cvv">
              <label className={!HELPER.isEmpty(errors.cc_cvv) ? "error-label" : ""}>
                <div id="card-cvc" className="field" />
              </label>
              {!HELPER.isEmpty(errors.cc_cvv) ? (
                <p className="error">{ReactHtmlParser(HELPER.parseMessage(errors.cc_cvv))}</p>
              ) : (
                ""
              )}
              <IconButton
                aria-label="delete"
                color="primary"
                disableFocusRipple={true}
              >
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  // delay={{ show: 250, hide: 400 }}
                  overlay={HtmlTooltip}
                  popperConfig={{
                    props: {
                      state: CONSTANTS.CHECKOUT_INFO.PAYMENT_METHOD.TOOLTIP.CVV,
                    },
                  }}
                >
                  <InfoIcon />
                </OverlayTrigger>
              </IconButton>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CheckoutCvvRequirement;
