// @flow
/* eslint-disable no-undef */
import * as React from "react";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap } from "react-google-maps";

import MapComponent from "./components/map";

import "./GoogleMap.css";

const GoogleMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_MAP_URL+"&libraries=places",
    loadingElement: <div className="GmapLoadingElement" />,
    containerElement: <div className="GmapContainerElement" />,
    mapElement: <div className="GmapMapElement" />,
    isMarkerShown: true,
  }),
  withScriptjs,
  withGoogleMap
// )(() => <></>);
)((props) => <MapComponent p={props}  isFullScreen={false}/>);

export default GoogleMap;