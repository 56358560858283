import { SHIPMENT_CONSTANTS, ERROR_CONSTANTS } from "../actionTypes";
import { apiService } from "../middlewares/api_service";
import {
  alertActions,
  LOG_ORDER_EXPIRED,
} from "./index";
import { errorAction } from "./errorAction";
import {
  CONSTANTS,
  HELPER,
  REDIRECTION_RULE
} from "../../utils";

export const shippingAction = {
  get_shipping_method,
  post_shipping_method,
  select_shipment_index,
  update_selected_shipment_index,
  EDIT_MERCHANT_SHIPMENT
};

function get_shipping_method(requestData) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getShippingMethods(requestData)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const data = response?.data?.body;
          const shipment_methods = data.shipment_methods;
          let selectedIndex = Object.keys(shipment_methods).find(key => shipment_methods[key].is_active === 1 && shipment_methods[key].shipment_enabled === 1);
          if(HELPER.isEmpty(selectedIndex)){
            let _selectedIndex = Object.keys(shipment_methods).find(key => shipment_methods[key].shipment_enabled === 1);
            selectedIndex =  shipment_methods[_selectedIndex];
          }else{
            selectedIndex = shipment_methods[selectedIndex]
          }
          dispatch(success(data, selectedIndex));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {

          dispatch(failure(error_message?.message));
          dispatch(alertActions.error(error_message?.message));

          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: SHIPMENT_CONSTANTS.GET_MERCHANT_SHIPMENT_METHODS_REQUEST };
  }
  function success(data, selectedIndex) {
    return {
      type: SHIPMENT_CONSTANTS.GET_MERCHANT_SHIPMENT_METHODS_SUCCESS,
      response: data,
      selectedIndex
    };
  }
  function failure() {
    return { type: SHIPMENT_CONSTANTS.GET_MERCHANT_SHIPMENT_METHODS_FAILURE };
  }
}

function post_shipping_method(data) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .setShippingMethod(data)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const data = response?.data?.body;
          dispatch(success(data));
          //REDIRECT FROM SHIPPING SCREEN
          REDIRECTION_RULE.POST_SHIPMENT_REDIRECTION_RULE();
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const errorBody = error_response?.data?.body;
        const error_message = errorAction(error_response);
        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          dispatch(failure(error_message?.message));
          dispatch(alertActions.error(error_message?.message));

          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: SHIPMENT_CONSTANTS.UPDATE_ORDER_SHIPMENT_METHOD_REQUEST };
  }
  function success(data) {
    return {
      type: SHIPMENT_CONSTANTS.UPDATE_ORDER_SHIPMENT_METHOD_SUCCESS,
      response: data,
    };
  }
  function failure() {
    return { type: SHIPMENT_CONSTANTS.UPDATE_ORDER_SHIPMENT_METHOD_FAILURE };
  }
}

function update_selected_shipment_index(index) {
  return (dispatch) => {
    dispatch(request(index));
  };

  function request(index) {
    return {
      type: SHIPMENT_CONSTANTS.UPDATE_SELECTED_SHIPMENT_INDEX,
      selected_shipment_index: index,
    };
  }
}


function select_shipment_index(index) {
  return (dispatch) => {
    dispatch(request(index));
  };

  function request(index) {
    return {
      type: SHIPMENT_CONSTANTS.SELECT_SHIPMENT_INDEX,
      selected_shipment_index: index,
    };
  }
}

function EDIT_MERCHANT_SHIPMENT(shipment) {
  return (dispatch) => {
    dispatch(request(shipment));
  };

  function request(shipment) {
    return {
      type: SHIPMENT_CONSTANTS.EDIT_MERCHANT_SHIPMENT,
      shipment,
    };
  }
}
