import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'

import {
    HELPER,
    ANALYTICS_HELPER,
} from '../../utils';
import { store } from "../../store";
import axios from 'axios';

let analytics;
const segmentEnabled = parseInt(process.env.REACT_APP_SEGMENT_ENABLED) === 1

const sendApiEvent = (url,data) =>{
  if(segmentEnabled && process.env.REACT_APP_SEGMENT_API_KEY_BASE64){
    const baseUrl="https://api.segment.io/v1/"
    const options = {
      headers: {'Authorization': `Basic ${process.env.REACT_APP_SEGMENT_API_KEY_BASE64}`}
    };
    try{
      setTimeout(() => {
        const state = store?.getState();
        const { is_customer_app, segment_id } = state?.configuration?.config
        data={
          ...data,
          userId: JSON.parse(localStorage.getItem("ajs_user_id")) || segment_id,
          anonymousId: JSON.parse(localStorage.getItem("ajs_anonymous_id")) || segment_id,
        }
        if (is_customer_app === 1) {
            axios.post(`${baseUrl}${url}`, data, options);
        }
      }, 0);
    }
    catch(err){
      console.error(err)
    }
  }
}

function initialize(segment_id) {
    if (segmentEnabled) {
        if (process.env.REACT_APP_SEGMENT_API_KEY || segment_id) {
            analytics = Analytics({
                app: 'bSecure Checkout App',
                plugins: [
                    segmentPlugin({
                        writeKey: HELPER.isNotEmpty(segment_id) ? segment_id : process.env.REACT_APP_SEGMENT_API_KEY
                    })
                ]
            })
            return analytics;
        }
    }
}

const callAnalytics = () => {
    if (segmentEnabled) {
        if (HELPER.isNotEmpty(analytics)) {
            return analytics
        } else {
            return initialize();
        }
    }
}

function _setUserSessionByID(user_id = "") {
  if(HELPER.isNotEmpty(user_id) && segmentEnabled){
    const { name, phone_number, email } = store.getState().configuration?.customer;
    let obj={
      name: name,
      email: email,
      phone: phone_number,
     }
    
    sendApiEvent("identify", {traits: obj})

    if (HELPER.sendEventToAndroid("segmentIdentify") && HELPER.isNotEmpty(user_id)) {
      window.Android.segmentIdentify(user_id, JSON.stringify(obj));
    } else if (HELPER.sendEventToiOS("segmentIdentify") && HELPER.isNotEmpty(user_id)) {
      window.webkit.messageHandlers.segmentIdentify.postMessage(
        JSON.stringify({user_id, obj})
      );
    }
    else {
      const analytics = callAnalytics();
        analytics?.identify(user_id, obj)
      }
  }
}

function _createEvent(name, obj) {
    if(segmentEnabled){
      let _apiObj={
        event: name,
        properties: obj
      }
      try{
        sendApiEvent("track", _apiObj)

        if (HELPER.sendEventToAndroid("segmentTrack")  && HELPER.isNotEmpty(name)) {
          window.Android.segmentTrack(name, JSON.stringify(obj));
        } else if (HELPER.sendEventToiOS("segmentTrack") && HELPER.isNotEmpty(name)) {
          window.webkit.messageHandlers.segmentTrack.postMessage(
            JSON.stringify({name, obj})
          );
        }
        else {
          const analytics = callAnalytics();
          analytics?.track(name, obj);
        }
      }
      catch{
        sendApiEvent("track", _apiObj)
        if (HELPER.sendEventToAndroid("segmentTrack")  && HELPER.isNotEmpty(name)) {
          window.Android.segmentTrack(name, JSON.stringify(obj));
        } else if (HELPER.sendEventToiOS("segmentTrack")  && HELPER.isNotEmpty(name)) {
          window.webkit.messageHandlers.segmentTrack.postMessage(
            JSON.stringify({name, obj})
          );
        }
        else {
        const analytics = callAnalytics();
        analytics?.track(name, obj);
        }
      }
    }
  }


function setPageView(location) {
    if(segmentEnabled){
      const {hash, href,  pathname, search} = window.location;
      let obj= { name: location, hash, url: href, path: pathname, search, title: "taptap",
                height: window?.innerHeight, width: window?.innerWidth}
  
      sendApiEvent("page", {
        name: location,
        properties: obj
      })

      if (HELPER.sendEventToAndroid("segmentPage") && HELPER.isNotEmpty(location)) {
        window.Android.segmentPage(location, JSON.stringify(obj));
      } else if (HELPER.sendEventToiOS("segmentPage")  && HELPER.isNotEmpty(location)) {
        window.webkit.messageHandlers.segmentPage.postMessage(
          JSON.stringify({
            name: location, obj: obj
          })
        );
      }
      else {
        const analytics = callAnalytics();
        analytics.page({
          name: location
        });
      }
    }
}


function _initOrderLogging(response) {
    if (segmentEnabled) {
        const state = store.getState();
        const { is_customer_app } = state.configuration?.config
        ANALYTICS_HELPER._setUserProperties()

        const cart = response?.cart
        // Prepare ecommerce params
        const cartItems = {
            currency: cart.summary.currency_code,
            value: cart.summary.total_amount,
            coupon: '',
            items: cart.items
        };
        if (is_customer_app === 1) {
            ANALYTICS_HELPER._checkoutStarted(cartItems)
        }
        else {
            _createEvent("add_to_cart", cartItems);
            // _createEvent("begin_checkout ", cartItems);
            ANALYTICS_HELPER._checkoutStarted(cartItems)
        }
        _createEvent("cart_properties", {
            customer_name: response?.customer?.customer_name,
            phone_number: response?.customer?.phone_number,
            address_count: response?.app_setup?.address_count,
            delivery_address_id: response?.app_setup?.delivery_address_id,
            payment_method_id: response?.app_setup?.payment_method_id,
            plugin_version: response?.app_setup?.plugin_version,
            screen: response?.app_setup?.screen,
            shipment_method_id: response?.app_setup?.shipment_method_id,
            store: {
                label: response?.config?.store_url,
            },
        });
    }
}


const navigationEventLog = (name, obj) => {
    if (segmentEnabled) {
        _createEvent(name, obj)
    }
}

const SEGMENT_ANALYTICS_HELPER = {
    initialize,
    setPageView,
    _createEvent,
    _initOrderLogging,
    navigationEventLog,
    _setUserSessionByID,
    callAnalytics
};

export default SEGMENT_ANALYTICS_HELPER;