import React from "react";
import { useSelector } from "react-redux";


function FullScreenError() {
  const { message } = useSelector(state => state.alert);
  return message &&
    <div id="mandatoryPin">
    <div className="pinStripe">
        <p>
        {message}
        </p>
      </div>
    </div>
}


export default FullScreenError;
