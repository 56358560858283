/* eslint-disable no-unused-vars */
import React, {useEffect} from "react";
import LoadingButton from '../../components/LoadingButton'
import AppWrapper from '../../components/App'
import { BREADCRUMB_SETTINGS_JSON, HELPER, history, ROUTE_CONSTANTS } from "../../utils";
import CheckoutCartItem from "../Checkout/CheckoutInfo/cart_items";
import OrderReceipt from "../Order/Receipt";
import { useTranslate } from "react-redux-multilingual/lib/context";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { verificationAction } from "../../store/actions";

function Abandoned() {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const { showBtnLoader, disableNextBtn } = useSelector(state => state.loading);
    const { order_ref } = useSelector(state => state.configuration);
    const {cart_details } = useSelector(state => state.cart);
    const {
      initiateSend,
    } = useSelector(state => state.configuration);
    const { items, summary} = cart_details
    const { total_amount, currency_code} = summary

    let params = queryString.parse(location.search);
    const paramOrderRef = params?.ref;

    useEffect(() => {
        if(paramOrderRef !== order_ref){
          dispatch(verificationAction.INITIATE_ORDER_ABANDONED(paramOrderRef, null, history));
        }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramOrderRef])

    const handleSubmit = () => {
      if (
        HELPER.isNotEmpty(order_ref) &&
        !initiateSend
      ) {
        history.replace({
          pathname: ROUTE_CONSTANTS.CHECKOUT,
          search: "?ref=" + order_ref,
        });
      }
    };

  return (
    <>
      <AppWrapper
        showMerchantLogo={true}
        showAccountDropdown={false}
        showLangIcon={false}
        allowProfileAPI={false}
        breadcrumb={{
          type: BREADCRUMB_SETTINGS_JSON.ABANDONED.TYPE,
          options: BREADCRUMB_SETTINGS_JSON.ABANDONED,
          show: BREADCRUMB_SETTINGS_JSON.ABANDONED.SHOW,
        }}
      >
      
        <div className="screen bg-white">
        <div className="container mt-2">
        <div className="row">
            <div className="col-sm-12">
            <div className="signInView bg-white mt-5 bottom-padding">
                <h3>{translate("ADANDONED.HEADING")}</h3>
                  <p className="signviewsmalltext mb-5">
                    {translate("ADANDONED.SUB_HEADING")}
                  </p>
                {HELPER.isNotEmpty(items) &&
                  <>
                  <CheckoutCartItem />
                  <OrderReceipt showPaidStamp={false} showTransactionSlip={false} />
                  </>
                }
            </div>
            </div>

        
        </div>
        <div className={`bottomFixedBar totalFixed`}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
               
                <div className="receiptTotal">
                  <span className="total">{translate("total_text")}</span>
                  <span className="totalPrice">
                    {HELPER.showPrice(currency_code, total_amount)}
                  </span>
                </div>
                <button
                  type="button"
                  onClick={handleSubmit}
                  id="place-order"
                  className={`btn btn-outline-primary btn-block ${(disableNextBtn ? "notClickedBtn" : showBtnLoader ? "notClickedBtn" : "clickedBtn")} `}
                  disabled={disableNextBtn ? true : showBtnLoader ? true : false}
                >
                  <LoadingButton
                    loadingState={showBtnLoader ? true : false}
                    showBlueLoader={true}
                    preloadingText={showBtnLoader ? translate("processing_btn") :  translate("ADANDONED.CONTINUE")}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
     
        </div>
        </div>

      </AppWrapper>
     
     
    </>
  );
}


export default Abandoned;
