// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import Modal from "../../../components/Modal";
import AppWrapper from "../../../components/App";

import ReactHtmlParser from "html-react-parser";
import "animate.css";
import { HELPER } from "../../../utils";

function OrderStatusUpdate(props) {
  const {
    showLangIcon,
    pageHeading,
    screenSubHeading,
    breadcrumbOptionParams,
    child,
  } = props;


  return (
    <>
      <AppWrapper
        showMerchantLogo={true}
        showAccountDropdown={true}
        showLangIcon={showLangIcon}
        allowProfileAPI={true}
        showFooter={false}
        breadcrumb={{
          type: breadcrumbOptionParams.TYPE,
          options: breadcrumbOptionParams,
          show: breadcrumbOptionParams.SHOW
        }}
      >
        <div className="screen midWrap bg-white">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="signInView midView bg-white">
                  <h1>{pageHeading}</h1>
                  <p
                    className="signviewsmalltext"
                  >
                    {!!screenSubHeading && ReactHtmlParser(HELPER.parseMessage(screenSubHeading))}
                  </p>
                  {child}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
      <Modal />
    </>
  );
}


export default OrderStatusUpdate;
