// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Dropdown } from "react-bootstrap";

import {
    HELPER,
    GOOGLE_ANALYTICS_HELPER,
    ANALYTICS_HELPER
} from "../../../utils";
import CustomerNumber from "../../../components/CustomerNumber";

import { customerAction } from "../../../store/actions";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        className="nav-link"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            ANALYTICS_HELPER._navigationEventLog(
                GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.NAVIGATION,
                {
                    action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.NAVIGATION.PROFILE_DROPDOWN.ACTION,
                    label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.NAVIGATION.PROFILE_DROPDOWN.LABEL,
                    properties: {}
                }
            );
            onClick(e);
        }}
    >
        {children}
    </a>
));

const detectNameAlias = (customer_name) => {
    if(HELPER.isNotEmpty(customer_name)){
        const capitalizeUsername = HELPER.capitalizeFirstLetter(customer_name);
        var UsernameTrimmed = capitalizeUsername.match(/\b(\w)/g);
        return Array.isArray(UsernameTrimmed) ? UsernameTrimmed.join("") : "";
    }
    return "G";
};

const detectFullName = (customer_name) => {
    return HELPER.capitalizeFirstLetter(customer_name);
};

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        const value = "";
        // LOAD GTM EVENT

        return (
            <div className="accountDrop">
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <ul className="dropdown-list">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children.toLowerCase().startsWith(value)
                        )}
                    </ul>
                </div>
            </div>
        );
    }
);

function AccountDropdown(props) {
    let dispatch = useDispatch();

    const { callProfileApi } = useSelector(state => state.loading)
    const { profileLoading, customer } = useSelector(state => state.configuration);
    const {
        phone_number,
        name,
        email
    } = customer
    // 
    const {
        allowProfileAPI,
        avatarURL,
    } = props;

    //componentDidMount
    useEffect(() => {
        /*
          LOAD USER NAME AND CHECK WHETHER TO CALL PROFILE API OR NOT
        */
        if ((HELPER.isEmpty(name) || HELPER.isEmpty(email)) && allowProfileAPI && !profileLoading) {
            dispatch(customerAction.get_profile());
        }
        // returned function will be called on component unmount
        return () => {
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (callProfileApi) {
            dispatch(customerAction.get_profile());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callProfileApi]);

    return (
        <>
            <div className="headerDropdown">
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        <span
                            className="avatar"
                            style={{
                                backgroundImage: "url(" + avatarURL + ")",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                            }}
                        ></span>
                        <span className="text-default customerDetails">
                            {" "}
                            <span className="name-full">{detectFullName(name)}</span>
                            <span className="name-alias">{detectNameAlias(name)}</span>
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu}>
                        <Dropdown.Item href={"/#"} className="active noEvents">
                            <div className="icon">
                                <span className="icomoon-navigate-profile"></span>
                            </div>
                            <span className="item-text">
                                {HELPER.capitalizeFirstLetter(name)}
                            </span>
                        </Dropdown.Item>
                        {!HELPER.isEmpty(phone_number) ? (
                            <Dropdown.Item href={"/#"} className="active noEvents">
                                <div className="icon">
                                    <span className="icomoon-breadcrumb-phone-filled"></span>
                                </div>
                                <span className="item-text">
                                    <CustomerNumber />
                                </span>
                            </Dropdown.Item>
                        ) : (
                            ""
                        )}
                        {/* <Dropdown.Item href={"/#"}>
            <div className="icon">
              <span className="icomoon-review-address"></span>
            </div>
            <span className="item-text">Home address</span>
          </Dropdown.Item>
          <Dropdown.Item href={"/#"}>
            <div className="icon">
              <span className="icon-icon-payment"></span>
            </div>
            <span className="item-text">4242424242424242</span>
          </Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
}

export default AccountDropdown;
