// @flow
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { parsePhoneNumber, isValidNumber } from "libphonenumber-js";
import PhoneNumberComponent from "../../../components/PhoneNumberComponent";

import {
    ANALYTICS_HELPER,
    GOOGLE_ANALYTICS_HELPER,
    HELPER
} from "../../../utils";
import {
    formAction,
    metaAction,
} from "../../../store/actions";

function PhoneField() {
    let dispatch = useDispatch();
    const translate = useTranslate();
    const { action, form, meta } = useSelector(state => state);
    const { contact_number, selected_country } = form;
    const { locale_updated } = action;
    const {
        defaultPhoneCode,
        phoneCodeDropdownList,
        defaultCountry,
    } = meta;

    const [default_country, setDefaultCountry] = useState([]);
    const [country, setCountry] = useState(selected_country);
    const [phoneNumber, setPhoneNumber] = useState(contact_number);
    const [errors, setErrors] = useState("");

    //componentDidMount
    useEffect(() => {
        if (!locale_updated && HELPER.isEmpty(phoneCodeDropdownList)) {
            //Call meta data API so that default country is selected and we are able to select network
            dispatch(metaAction.COUNTRY_DROPDOWN_LIST());
        }
        if (!HELPER.isEmpty(phoneNumber) && !HELPER.isEmpty(country)) {
            setPhoneNumber(phoneNumber);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //For network API call set default country code
        if (!HELPER.isEmpty(defaultPhoneCode?.id)) {
            const code = defaultPhoneCode?.id;
            const selected_country = HELPER.findInJson(phoneCodeDropdownList, code);
            setDefaultCountry(selected_country);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCountry, defaultPhoneCode]);

    useEffect(() => {
        //For network API call set default country code
        if (!HELPER.isEmpty(phoneNumber)) {
            //Save country to reducer
            let validationCheck = handleValidation();
            if (validationCheck.phone_number === "") {
                setErrors("");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneNumber]);

    const validatePhone = (e) => {
        let code = country.dialCode
        if (HELPER.isEmpty(phoneNumber)) {
            return false
        } else if (phoneNumber.length <= 4) {
            return false
        } else if (phoneNumber.length > 4 && phoneNumber.substr(0, code.length) === country.dialCode && !HELPER.isEmpty(phoneNumber.slice(code.length))) {
            return parsePhoneNumber("+" + phoneNumber);
        } else if (phoneNumber.substr(0, code.length) === country.dialCode && HELPER.isEmpty(phoneNumber.slice(0, code.length))) {
            return false;
        } else if (phoneNumber.length > 4) {
            return parsePhoneNumber("+" + country.dialCode + phoneNumber);
        }
    };

    const handleValidation = (e) => {
        let errors = "";
        const phone = validatePhone();
        if (!phoneNumber) {
            errors = translate("VALIDATIONS.IS_REQUIRED");
        } else if (HELPER.isEmpty(phone?.countryCallingCode)) {
            errors = translate("invalid_phone_number");
        } else if (!HELPER.isEmpty(phoneNumber) && isValidNumber(phone.number) === false) {
            errors = translate("invalid_phone_number");
        }
        setErrors(errors);
        return errors;
    };

    const handleChange = (phone, dialCode, country) => {
        // Set errors to empty
        setErrors("");
        //Set phone number and country
        setCountry(country);
        setPhoneNumber(phone);
        dispatch(formAction.UPDATE_COUNTRY(country));
        const countryCallingCode = country.dialCode;
        dispatch(formAction.UPDATE_PHONE_NUMBER(phone.replace(countryCallingCode, "")));


        // LOAD GTM EVENT
        ANALYTICS_HELPER._generalEventLog(
            GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.PAYMENT,
            {
                action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.INPUT.PHONE.ACTION,
                label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.INPUT.PHONE.LABEL,
                properties: {}
            }
        );
    };

    return (
        <>
            <div className="country-phone-input">
                <form id="phone-screen-form">
                    {
                        !HELPER.isEmpty(default_country) ? (
                            <div className={`dropInput ${!HELPER.isEmpty(errors) ? "formInput md-form error" : ""}`}>
                                <div
                                    className={`input-group ${!HELPER.isEmpty(errors) ? "error" : ""}`}
                                    unselectable="on"
                                    onDoubleClick={(e) => e.preventDefault()}
                                    onCut={(e) => e.preventDefault()}
                                >
                                        <PhoneNumberComponent
                                            countryCode={!HELPER.isEmpty(country) ? country?.dialCode : ""}
                                            phone={phoneNumber}
                                            handleChange={handleChange}
                                            defaultCountry={default_country}
                                            error={!HELPER.isEmpty(errors) ? true : false}
                                            errorMessage={errors}
                                        />
                                </div>
                                {
                                    !HELPER.isEmpty(errors) ? (
                                        <span className="errorMessage">{errors}</span>
                                    ) : ""
                                }
                            </div>
                        ) : ""
                    }
                </form>
            </div>
        </>
    );
}


export default PhoneField;
