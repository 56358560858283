// @flow

import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import {
  ROUTE_CONSTANTS,
  CONSTANTS,
  CHECKOUT_NAVIGATION,
  CART_HELPER,
  GOOGLE_ANALYTICS_HELPER,
  history,
  ANALYTICS_HELPER,
  // LOCAL_STORAGE_SERVICE,
} from "../../../../../utils";
import {
  cartAction, mapActions
} from "../../../../../store/actions";

let currentPage,
  addressIcon,
  verificationIcon,
  shippingIcon,
  paymentIcon,
  cartIcon;

function CheckoutBreadcrumb() {
  const dispatch = useDispatch();

  const { placement_type } = useSelector(state => state.cart.cart_details.summary);
  const { showCartMenu, showCartReview } = useSelector(state => state.action);
  const { shipment_method_id } = useSelector(state => state.redirect);
  const { is_pin_location_mandatory, is_international_shipment } = useSelector(state => state.configuration.config);
  const { locationPermission, currentPositionByBtn } = useSelector(state => state.map);
  const {
    payment_method,
    shipment_method
  } = useSelector((state) => state.cart);

  const translate = useTranslate();

  const { location, } = history;

  const isInvoice = CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE === placement_type;
  const isPaymentGateway = CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY === placement_type;
  const isUniversal = CONSTANTS?.ORDER_PLACEMENT_TYPE.UNIVERSAL === placement_type;
  const isGiveWp = CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP === placement_type;

  const path = location.pathname;

  const NAVIGATION_RULES = !(isPaymentGateway || isInvoice || isGiveWp)
    ? CHECKOUT_NAVIGATION.NORMAL_CHECKOUT : CHECKOUT_NAVIGATION.PAYMENT_GATEWAY;

  const NAVIGATION_STEP_COUNT = NAVIGATION_RULES.STEP_COUNT;
  const NAVIGATION_ICON_LIST = CHECKOUT_NAVIGATION.ICONS;

  const VERIFICATION_ROUTES = path === ROUTE_CONSTANTS.PHONE_SCREEN;

  const ADDRESS_ROUTES =
    path === ROUTE_CONSTANTS.ADD_NEW_ADDRESS ||
    path === ROUTE_CONSTANTS.ADDRESS_LIST ||
    path === ROUTE_CONSTANTS.UPDATE_ADDRESS ||
    path === ROUTE_CONSTANTS.MAP_FULL_SCREEN;

  const SHIPMENT_ROUTES = path === ROUTE_CONSTANTS.SHIPPING;
  const PAYMENT_ROUTES =
    path === ROUTE_CONSTANTS.PAYMENT || path === ROUTE_CONSTANTS.CREDIT_CARD || path === ROUTE_CONSTANTS.BANK_OTP;
  const CART_ROUTES = path === ROUTE_CONSTANTS.CART;


  const activeClass = "active activeText animate__animated animate__fadeInRight";
  const disabledClass = [ROUTE_CONSTANTS.CREDIT_CARD].includes(path);

  currentPage = ADDRESS_ROUTES
    ? NAVIGATION_STEP_COUNT.ADDRESS_SCREEN
    : SHIPMENT_ROUTES
      ? NAVIGATION_STEP_COUNT.SHIPMENT_SCREEN
      : PAYMENT_ROUTES
        ? NAVIGATION_STEP_COUNT.PAYMENT_SCREEN
        : CART_ROUTES
          ? NAVIGATION_STEP_COUNT.CART_SCREEN
          : NAVIGATION_STEP_COUNT.IDLE_SCREEN;


  verificationIcon = VERIFICATION_ROUTES || currentPage > NAVIGATION_STEP_COUNT.VERIFICATION_SCREEN ? NAVIGATION_ICON_LIST.VERIFICATION : "stepNo";
  addressIcon = ADDRESS_ROUTES || currentPage > NAVIGATION_STEP_COUNT.ADDRESS_SCREEN ? NAVIGATION_ICON_LIST.ADDRESS : "stepNo";
  shippingIcon = SHIPMENT_ROUTES || currentPage > NAVIGATION_STEP_COUNT.SHIPMENT_SCREEN ? NAVIGATION_ICON_LIST.SHIPMENT : "stepNo";
  paymentIcon = PAYMENT_ROUTES || currentPage > NAVIGATION_STEP_COUNT.PAYMENT_SCREEN ? NAVIGATION_ICON_LIST.PAYMENT : "stepNo";
  cartIcon = CART_ROUTES || currentPage > NAVIGATION_STEP_COUNT.CART_SCREEN ? NAVIGATION_ICON_LIST.CART : "stepNo";

  const moveToCart = () => {
    if (currentPage > NAVIGATION_STEP_COUNT.CART_SCREEN) {
      showCartReview && dispatch(cartAction.TOGGLE_CART_REVIEW_POPUP());
      // LOAD GTM EVENT
      ANALYTICS_HELPER._navigationEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.BREADCRUMB,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.BREADCRUMB.NAVIGATION.REVIEW.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.BREADCRUMB.NAVIGATION.REVIEW.LABEL,
          properties: {}
        }
      );
      history.push({
        pathname: ROUTE_CONSTANTS.CART,
      });
    }
  };

  const moveToEditAddress = () => {
    if (currentPage > NAVIGATION_STEP_COUNT.ADDRESS_SCREEN) {
      showCartReview && dispatch(cartAction.TOGGLE_CART_REVIEW_POPUP());
      // LOAD GTM EVENT
      ANALYTICS_HELPER._navigationEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.BREADCRUMB,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.BREADCRUMB.NAVIGATION.ADDRESS.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.BREADCRUMB.NAVIGATION.ADDRESS.LABEL,
          properties: {}
        }
      );
      history.push({
        pathname: ROUTE_CONSTANTS.ADDRESS_LIST,
      });
    }
  };

  const moveToEditPayment = () => {
    if (currentPage > NAVIGATION_STEP_COUNT.PAYMENT_SCREEN && payment_method?.disable_edit === 0) {
      showCartReview && dispatch(cartAction.TOGGLE_CART_REVIEW_POPUP());
      // LOAD GTM EVENT
      ANALYTICS_HELPER._navigationEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.BREADCRUMB,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.BREADCRUMB.NAVIGATION.PAYMENT.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.BREADCRUMB.NAVIGATION.PAYMENT.LABEL,
          properties: {}
        }
      );
      history.push({
        pathname: ROUTE_CONSTANTS.PAYMENT,
      }); 
    }
  };

  const moveToEditShipping = () => {
    if (currentPage > NAVIGATION_STEP_COUNT.SHIPMENT_SCREEN && shipment_method_id !== 0  && shipment_method?.disable_edit !== 1) {
      showCartReview && dispatch(cartAction.TOGGLE_CART_REVIEW_POPUP());
      // LOAD GTM EVENT
      ANALYTICS_HELPER._navigationEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.BREADCRUMB,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.BREADCRUMB.NAVIGATION.SHIPPING.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.BREADCRUMB.NAVIGATION.SHIPPING.LABEL,
          properties: {}
        }
      );
      history.push({
        pathname: ROUTE_CONSTANTS.SHIPPING,
      });
    }
  };

  const toggleCartReview = () => {
    dispatch(cartAction.TOGGLE_CART_REVIEW_POPUP());
  };

  const askPermission = () => {
    dispatch(mapActions.GET_LOCATION_PIN(true));
    dispatch(mapActions.MAP_PERMISSION_PROMPT(true));
    dispatch(mapActions.SET_FULL_SCREEN_MODE(true));
  };

  return (
    <>
      <div className={`stepStatus  ${showCartMenu && (CART_HELPER.CART_DEVICE_TYPE() === CONSTANTS.DEVICE_TYPE.MOBILE) ? "d-none" : ""}`}>
        <div className={`stepStatusOptions`}>
          <div className={`stepIcons ${VERIFICATION_ROUTES ? activeClass : ""}`}>
            <div className={`icon ${VERIFICATION_ROUTES ? "" : currentPage < NAVIGATION_STEP_COUNT.VERIFICATION_SCREEN ? "" : "iconPrev"}`}>
              <span className={`${verificationIcon} ${currentPage === NAVIGATION_STEP_COUNT.VERIFICATION_SCREEN ? "iconW" : "iconT "}`}></span>
            </div>
            <div className="statusText">
              {translate("ADDRESS.BREADCRUMB.HEADING")}
            </div>
          </div>
          {!(isPaymentGateway || isInvoice || isGiveWp) ? (
            <div
              className={`stepIcons ${ADDRESS_ROUTES ? activeClass : ""} ${disabledClass || isUniversal ? "noEvents" : ""} ${(currentPage > NAVIGATION_STEP_COUNT.ADDRESS_SCREEN && !isUniversal) ? "clickable" : ""}`}
              onClick={() => {
                moveToEditAddress();
              }}
            >
              <div className={`icon ${ADDRESS_ROUTES ? "" : currentPage < NAVIGATION_STEP_COUNT.ADDRESS_SCREEN ? "" : "iconPrev"}`}>
                <span className={`${addressIcon} ${currentPage === NAVIGATION_STEP_COUNT.ADDRESS_SCREEN ? "iconW" : "iconT "}`}>
                  {`${ADDRESS_ROUTES || currentPage > NAVIGATION_STEP_COUNT.ADDRESS_SCREEN ? "" : NAVIGATION_STEP_COUNT.ADDRESS_SCREEN}`}
                </span>
              </div>
              <div className="statusText">
                {translate("ADDRESS.BREADCRUMB.HEADING")}
              </div>
            </div>
          ) : (
            ""
          )}

          {!(isPaymentGateway || isInvoice || isGiveWp) ? (
            <div
              className={`stepIcons ${SHIPMENT_ROUTES ? activeClass : ""} ${disabledClass ? "noEvents" : ""} ${currentPage > NAVIGATION_STEP_COUNT.SHIPMENT_SCREEN ? "clickable" : ""}`}
              onClick={() => {
                moveToEditShipping();
              }}
            >
              <div className={`icon ${SHIPMENT_ROUTES || shipment_method_id === 0 ? "" : currentPage < NAVIGATION_STEP_COUNT.SHIPMENT_SCREEN ? "" : "iconPrev"}`}>
                <span className={`${shippingIcon} ${currentPage === NAVIGATION_STEP_COUNT.SHIPMENT_SCREEN || shipment_method_id === 0 ? "iconW" : "iconT "}`}>
                  {`${SHIPMENT_ROUTES || currentPage > NAVIGATION_STEP_COUNT.SHIPMENT_SCREEN ? "" : NAVIGATION_STEP_COUNT.SHIPMENT_SCREEN}`}
                </span>
              </div>
              <div className="statusText">
                {translate("SHIPPING.BREADCRUMB.HEADING")}
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            className={`stepIcons ${PAYMENT_ROUTES ? activeClass : ""} ${currentPage > NAVIGATION_STEP_COUNT.PAYMENT_SCREEN ? "clickable" : ""}`}
            onClick={() => {
              moveToEditPayment();
            }}
          >
            <div className={`icon ${PAYMENT_ROUTES ? "" : currentPage < NAVIGATION_STEP_COUNT.PAYMENT_SCREEN ? "" : "iconPrev"}`}>
              <span className={`${paymentIcon} ${currentPage === NAVIGATION_STEP_COUNT.PAYMENT_SCREEN ? "iconW" : "iconT "}`}>
                {`${PAYMENT_ROUTES || currentPage > NAVIGATION_STEP_COUNT.PAYMENT_SCREEN ? "" : NAVIGATION_STEP_COUNT.PAYMENT_SCREEN}`}
              </span>
            </div>
            <div className="statusText">
              {translate("PAYMENT.BREADCRUMB.HEADING")}
            </div>
          </div>
          <div
            className={`stepIcons ${CART_ROUTES ? activeClass : ""} ${currentPage > NAVIGATION_STEP_COUNT.CART_SCREEN ? "clickable" : ""}`}
            onClick={() => { moveToCart(); }}
          >
            <div className={`icon ${CART_ROUTES ? "" : currentPage < NAVIGATION_STEP_COUNT.CART_SCREEN ? "" : "iconPrev"}`}>
              <span className={`${cartIcon} ${currentPage === NAVIGATION_STEP_COUNT.CART_SCREEN ? "iconW" : "iconT "}`}>
                {`${CART_ROUTES || currentPage > NAVIGATION_STEP_COUNT.CART_SCREEN ? "" : NAVIGATION_STEP_COUNT.CART_SCREEN}`}
              </span>
            </div>
            <div className="statusText">{translate("cart_breadcrumb_heading")}</div>
          </div>
        </div>
        {
          [ROUTE_CONSTANTS.ADD_NEW_ADDRESS, ROUTE_CONSTANTS.UPDATE_ADDRESS].includes(path)
          && !is_pin_location_mandatory &&
          (!currentPositionByBtn || (locationPermission === CONSTANTS.PERMISSIONS.DENIED) || (locationPermission !== CONSTANTS.PERMISSIONS.GRANTED)) &&
          !is_international_shipment &&
          <div className={`stepStatusReview`}>
            <button type="button" className="btn btn-outline-primary btn-block mapLocationBtn"
              onClick={askPermission} disabled={locationPermission === CONSTANTS.PERMISSIONS.DENIED}>
              <i className="icomoon-map-link"></i>
              <span>{translate(`${locationPermission === CONSTANTS.PERMISSIONS.DENIED ? "LOCATION.MANUAL_LOCATION" : "LOCATION.USE_CURRENT_LOCATION"}`)}</span>
            </button>
          </div>
        }

        {
          CART_ROUTES ?
          <div className={`stepStatusReview ${CART_ROUTES ? "cartStatusReview" : ""}`}>
            <button type="button" id="place-order" className="btn btn-outline-primary btn-block clickedBtn " onClick={toggleCartReview}>
              <span>
                {translate("REVIEW.BUTTON.VIEW")}
              </span>
              <i className={`${showCartReview ? "icomoon-navigate-top" : "icomoon-navigate-bottom"}`}></i></button>
          </div> : ""
        }
      </div>
    </>
  );
}


export default CheckoutBreadcrumb;
