import { useState} from 'react'
import { useSelector } from "react-redux";

function useAddressOnNotMandatory() {
    const { is_pin_location_mandatory } = useSelector(state => state.configuration.config);
    const { locationDetails, } = useSelector(state => state.address);
    const { setCoordinatesLat } = useSelector(state => state.map);
    const [address, setAddress] = useState("");
    //on no pin location mandatory clear address & loc if any field updates
    const handleAddressUpdateClear = ({ name, options }) => {
        if (!is_pin_location_mandatory && ((locationDetails[name] && options?.label !== locationDetails[name]) ||
            //for edit case match label from address field
            (!locationDetails[name] && setCoordinatesLat && !address.toLowerCase()?.includes(options?.label.toLowerCase())))) {
             !locationDetails?.error_msg && setAddress("");
            // dispatch(addressAction.empty_location_details(!locationDetails?.error_msg ))
        }
    };


    return {
        handleAddressUpdateClear,
        address, setAddress,
    };
}
export default useAddressOnNotMandatory;