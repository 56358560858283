import { MAP_CONSTANTS, ADDRESS_CONSTANTS } from "../actionTypes";

export const mapActions = {
  set_position,
  update_position,
  SET_FULL_SCREEN_MODE,
  UPDATE_PLACESSEARCH,
  GET_LOCATION_PIN,
  INITIATE_MAP,
  UPDATE_PERMISSION_LOCATION,
  MAP_PERMISSION_PROMPT,
  CONFIRM_ADDRESS_FULLSCREEN_MAP
};

function CONFIRM_ADDRESS_FULLSCREEN_MAP() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: ADDRESS_CONSTANTS.CONFIRM_ADDRESS_FULLSCREEN_MAP };
  }
}

function UPDATE_PERMISSION_LOCATION(locationPermission) {
  return { type: MAP_CONSTANTS.UPDATE_PERMISSION_LOCATION, locationPermission };
}

function INITIATE_MAP() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: MAP_CONSTANTS.INITIATE_MAP };
  }
}

function GET_LOCATION_PIN(state) {
  return (dispatch) => {
    dispatch(request(state));
  };

  function request(state) {
    return { type: MAP_CONSTANTS.GET_MY_LOCATION, state };
  }
}

function UPDATE_PLACESSEARCH(address) {
  return (dispatch) => {
    dispatch(request(address));
  };

  function request(address) {
    return { type: MAP_CONSTANTS.UPDATE_PLACE_SEARCH_REQUEST, address };
  }
}

function set_position(lat, lng) {
  return (dispatch) => {
    dispatch(request(lat, lng));
  };

  function request(lat, lng) {
    return { type: MAP_CONSTANTS.ADD_ADDRESS_LAT_LNG_REQUEST, lat, lng };
  }
}

function update_position(lat, lng) {
  return (dispatch) => {
    dispatch(request(lat, lng));
  };

  function request(lat, lng) {
    return { type: MAP_CONSTANTS.UPDATE_ADDRESS_LAT_LNG_REQUEST, lat, lng };
  }
}


function SET_FULL_SCREEN_MODE(state) {
  return (dispatch) => {
    dispatch(request(state));
  };

  function request(state) {
    return { type: MAP_CONSTANTS.FULL_SCREEN_MODE, state };
  }
}

function MAP_PERMISSION_PROMPT(state) {
  return (dispatch) => {
    dispatch(request(state));
  };

  function request(state) {
    return { type: MAP_CONSTANTS.MAP_PERMISSION_PROMPT, state };
  }
}
