// @flow

import * as React from "react";
import CheckoutWrapper from "../../../components/CheckoutWrapper";

import { BREADCRUMB_SETTINGS_JSON } from "../../../utils";
import CartForm from "./form";

function Cart() {
  return (
    <CheckoutWrapper
      fullscreenModeEnabled={false}
      child={<CartForm />}
      breadcrumbSettings={BREADCRUMB_SETTINGS_JSON.REVIEW}
    />
  );
}

export default Cart;
