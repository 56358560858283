import {
  CART_CONSTANTS,
  SHIPMENT_CONSTANTS,
  CONFIG_CONSTANTS
} from "../actionTypes";

const initState = {
  shipmentMethods: [],
  selected_shipment_index: null,
  currency: null,
  error: null,
  selectedIndex: 0,
  editShipment: { merchantId: "", storeId: "" }
};

const shipmentReducer = (state = initState, action) => {
  switch (action.type) {
    case CONFIG_CONSTANTS.INITIATE_ORDER_REQUEST:
    case CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_REQUEST:
      return initState;
    // GET ALL SHIPMENT METHODS
    case SHIPMENT_CONSTANTS.GET_MERCHANT_SHIPMENT_METHODS_REQUEST:
      return state;
    case SHIPMENT_CONSTANTS.GET_MERCHANT_SHIPMENT_METHODS_SUCCESS:
      return {
        ...state,
        shipmentMethods: action.response?.shipment_methods,
        error: action.response?.error,
        currency: action.response?.currency_code,
        selectedIndex: action.selectedIndex,
      };
    case SHIPMENT_CONSTANTS.GET_MERCHANT_SHIPMENT_METHODS_FAILURE:
      return state;

    case SHIPMENT_CONSTANTS.UPDATE_SELECTED_SHIPMENT_INDEX:
      return {
        ...state,
        selected_shipment_index: action?.selected_shipment_index,
      };
    case SHIPMENT_CONSTANTS.SELECT_SHIPMENT_INDEX:
      return {
        ...state,
        selectedIndex: action?.selected_shipment_index
      };
    case SHIPMENT_CONSTANTS.EDIT_MERCHANT_SHIPMENT:
      return {
        ...state,
        editShipment: {
          merchantId: action?.shipment?.merchant_id,
          storeId: action?.shipment?.store_id
        }
      };
    case CART_CONSTANTS.GET_CART_DETAILS_REQUEST:
      return {
        ...state,
        shipmentMethods: initState?.shipmentMethods,
        error: initState?.error,
        currency: initState?.currency,
        editShipment: initState.editShipment
      };
    default:
      return state;
  }
};
export default shipmentReducer;
