import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-redux-multilingual/lib/context';
import Alert from '../../../components/Alert';
import Dialog from '../../../components/CustomizeDialog/CustomizeDialog';
import DropdownMethods from '../../../components/DropdownMethods';
import LoadingButton from '../../../components/LoadingButton';

import { alertActions, verificationAction } from '../../../store/actions';
import { history } from '../../../utils';

export default function SelectPhoneDialog({ open }) {
  const translate = useTranslate()
  const [visible, setvisible] = useState(true);
  const [valueItem, setvalueItem] = useState("");
  const dispatch= useDispatch()
  const { other_phone_numbers, phone_number } = useSelector(state => state.configuration?.customer);

  useEffect(() => {
    if(!valueItem){
      let _isSelected;
      if(phone_number){
        _isSelected= other_phone_numbers.find(e => e?.phone_number === phone_number) || other_phone_numbers[0]
      }
      else{
        _isSelected = other_phone_numbers.find(e => e?.is_selected) || other_phone_numbers[0]
      }
      setvalueItem(_isSelected)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone_number])
  


  const handleSelect=(selected)=>{
    setvalueItem(selected)
    setvisible(false)
  }

  const handleSubmit=async ()=>{
    dispatch(alertActions.clear());
    let obj={ customer_identifier: valueItem?.identifier}
    dispatch(verificationAction.OTP_SEND(obj, history));

  }

  const handleClose = () =>{
    dispatch(alertActions.clear());
    dispatch(verificationAction.MULTI_PHONE_DIALOG(false))
  }

  return(
        <Dialog
        open={!!open}
        disablebackdropclick={true}
        setopen={handleClose}
        title={"Select Phone"}
        content={
          <div className='multi-phone-container'>
            <p>{translate("OTP.DIFFERENT_PHONE")}</p>

            <Alert />
            <DropdownMethods
              value={valueItem?.phone_number || valueItem}
              setvisible={setvisible}
              visible={visible}
              handleSelect={handleSelect}
              listItems={other_phone_numbers}
              startIcon={'icomoon-breadcrumb-phone theme-color'}
              selectValue="phone_number"
            />

            {!visible && (
              <button
                type="submit"
                className="btn btn-primary w-100 mt-3"
                disabled={false}
                onClick={handleSubmit}
              >
                <LoadingButton
                  loadingState={false}
                  preloadingText={translate("GENERAL.PROCEED")}
                  loadingText={translate("GENERAL.PROCEED")}
                />
              </button>
            )}
          </div>
        }
        hideCrossBtn={false}
      />
  )
}
