import { useRef, useEffect } from "react";

function useCancellablePromise() {
    const promises = useRef();

    function makeCancelable(promise) {
        let isCanceled = false;
        const wrappedPromise =
          new Promise((resolve, reject) => {
            promise
              .then((val) => (isCanceled ? null : resolve(val)))
              .catch((error) => (isCanceled ? null : reject(error)));
          });
        return {
          promise: wrappedPromise,
          cancel() {
            isCanceled = true;
          },
        };
      }

    useEffect(
      () => {
        promises.current = promises.current || [];
        return function cancel() {
          promises.current.forEach(p => p.cancel());
          promises.current = [];
        };
      }, []
    );
    
    function cancellablePromise(p) {
        const cPromise = makeCancelable(p);
        promises.current.push(cPromise);
        return cPromise.promise;
      }

    return { cancellablePromise };
}

export default useCancellablePromise;