// @flow
//eslint-disable-next-line

import * as React from "react";
import { Tooltip } from "react-bootstrap";
import { useTranslate } from "react-redux-multilingual/lib/context";
import DBTToolTipText from "../../components/DBTToolTipText";

import { CONSTANTS, IMAGE_SRC } from "../../utils";

function RenderContent(props) {
  const { content } = props;
  const translate = useTranslate();

  const renderSwitch = () => {
    const state = content?.state;
    switch (state) {
      case CONSTANTS.CHECKOUT_INFO.PAYMENT_METHOD.TOOLTIP.CVV:
        return (<div className="d-flex cvvTooltip"><span> {translate("CVV_TOOLTIP.TOOLTIP_TEXT")} </span> &nbsp;<img src={IMAGE_SRC.CVV_TOOLTIP} alt="cvv icon" /></div>);
      case CONSTANTS.CHECKOUT_INFO.PAYMENT_METHOD.TOOLTIP.DBT:
          return (
            <DBTToolTipText
              merchant_bank_details={content?.merchant_bank_details}
              showBankDetails={false}
              showCustomMsg={true}
            />
          );
      case CONSTANTS.CHECKOUT_INFO.PAYMENT_METHOD.PAYMENT_GUARENTEED:
        return (<div>{translate("PAYMENT_PROTECTION.TOOLTIP_TEXT")} &nbsp;<i dir="ltr" className="toolTipAnchor" onClick={e=> {e.stopPropagation(); window.open("https://" + translate("PAYMENT_PROTECTION.TOOLTIP_LINK"),'_blank')}}>{translate("PAYMENT_PROTECTION.TOOLTIP_LINK")}</i></div>);
      default:
        return <div> {content?.state} </div>;
    }
  };
  return renderSwitch();
}

function RenderTooltip(props){
  return (
    <>
      <Tooltip id="button-tooltip" className={`tooltipWhite newToolTip ${props.popper?.state?.options?.props.toolTipParentClass}`} {...props}>
        <div className="toolTipContent"><RenderContent content={props.popper?.state?.options?.props} /></div>
      </Tooltip>
    </>
  );
};

export default RenderTooltip;
