import { Component } from "react";
import { createPortal } from "react-dom";
import { MAP } from "react-google-maps/lib/constants";
import PropTypes from "prop-types";

export default class MapControl extends Component {
  static contextTypes = { [MAP]: PropTypes.object };

  componentWillMount() {
    this.map = this.context[MAP];
    this.controlDiv = document.createElement("div");
    this.controlDiv.setAttribute("id", this.props.parentClass);
    // this.map.controls[this.props.style].push(this.controlDiv);
    this.map.controls[this.props.position].push(this.controlDiv);
  }
  componentWillUnmount() {
    // this.map.controls[this.props.position].removeAt(this.divIndex);
  }
  render() {
    return createPortal(this.props.children, this.controlDiv);
  }
}

// import { Component, useEffect } from "react";
// import React from "react";
// import { createPortal } from "react-dom";
// import { MAP } from "react-google-maps/lib/constants";
// import PropTypes from "prop-types";

// export default class MapControl extends Component {
//   static contextTypes = { [MAP]: PropTypes.object };
//   render() {
//     const self = this;
//     return (
//       <MapControls
//         self={self}
//         position={this.props.position}
//         children={this.props.children}
//         MAP={MAP}
//       />
//     );
//   }
// }
// function MapControls({ self, position, children, MAP }) {
//   const controlDiv = document.createElement("div");
//   useEffect(() => {
//     self.map = self.context[MAP];
//     self.map.controls[position].push(controlDiv);
//     // return () => self.map.controls[position].removeAt(self.divIndex);
//   }, []);
//   return createPortal(children, controlDiv);
// }
