import {
  CONFIG_CONSTANTS,
  ADDRESS_CONSTANTS,
  SHIPMENT_CONSTANTS,
  PAYMENT_CONSTANT,
  FORM_CONSTANTS,
  CART_CONSTANTS
} from "../actionTypes";
import { HELPER, } from "../../utils";

const initState = {
  address_count: null,
  delivery_address_id: null,
  payment_method_id: null,
  shipment_method_id: null,
  initial_screen: null,
  placement_type: null,
  route: {
    to: location.pathname,
    from: location.pathname
  },
  otp_msg: ""
};
var address_count, delivery_address_id, payment_method_id, shipment_method_id;
const redirectionReducer = (state = initState, action) => {
  switch (action.type) {
    case CART_CONSTANTS.GET_CART_DETAILS_SUCCESS:
      return {
        ...state,
        delivery_address_id: parseInt(action.cart_data?.app_setup?.delivery_address_id),
        address_count: parseInt(action.cart_data?.app_setup?.address_count),
        payment_method_id: action.cart_data?.app_setup?.payment_method_id,
        shipment_method_id: action.cart_data?.app_setup?.shipment_method_id,
      };
    case ADDRESS_CONSTANTS.UPDATE_ORDER_ADDRESS_SUCCESS:
      return {
        ...state,
        delivery_address_id: parseInt(action.response?.app_setup?.delivery_address_id),
        address_count: parseInt(action.response?.app_setup?.address_count),
        payment_method_id: action.response?.app_setup?.payment_method_id,
        shipment_method_id: action.response?.app_setup?.shipment_method_id,
      };
    case PAYMENT_CONSTANT.UPDATE_ORDER_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        payment_method_id: action?.paymentMethod?.payment_method_id,
        address_count: action?.paymentMethod?.address_count,
        delivery_address_id: action?.paymentMethod?.delivery_address_id,
        shipment_method_id: action?.paymentMethod?.shipment_method_id,
      };
    case ADDRESS_CONSTANTS.ADD_NEW_ADDRESS_SUCCESS:
      return {
        ...state,
        delivery_address_id: parseInt(action.address.id)
      };
    case SHIPMENT_CONSTANTS.UPDATE_ORDER_SHIPMENT_METHOD_SUCCESS:
      return {
        ...state,
        shipment_method_id: action.response.shipment_method_id,
        payment_method_id: action?.response?.payment_method_id,
        address_count: action?.response?.address_count,
        delivery_address_id: action?.response?.delivery_address_id,
      };
    case ADDRESS_CONSTANTS.SET_SELECTED_ADDRESS_INDEX:
      return {
        ...state,
        delivery_address_id: parseInt(action.selected_index)
      };
    // VERIFY OTP
    case CONFIG_CONSTANTS.OTP_VERIFY_REQUEST:
      return state;
    case CONFIG_CONSTANTS.OTP_VERIFY_SUCCESS:
      if (!HELPER.isEmpty(action?.data?.app_setup?.address_count)) {
        return {
          ...state,
          address_count: action?.data?.app_setup?.address_count,
          delivery_address_id: action?.data?.app_setup?.delivery_address_id,
          payment_method_id: action?.data?.app_setup?.payment_method_id,
          shipment_method_id: action?.data?.app_setup?.shipment_method_id,
        };
      } else {
        return state;
      }
    case CONFIG_CONSTANTS.OTP_VERIFY_FAILURE:
      return state;
    // VERIFY ACCESS TOKEN
    case CONFIG_CONSTANTS.INITIATE_ORDER_REQUEST:
    case CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_REQUEST:
      return state;
    case CONFIG_CONSTANTS.INITIATE_ORDER_SUCCESS:
      delivery_address_id = action?.response?.app_setup?.delivery_address_id;
      payment_method_id = action?.response?.app_setup?.payment_method_id;
      shipment_method_id = action?.response?.app_setup?.shipment_method_id;
      return {
        ...state,
        address_count: action?.response?.app_setup?.address_count,
        delivery_address_id,
        payment_method_id,
        shipment_method_id,
        initial_screen: action?.response?.app_setup?.screen,
        placement_type: action?.response?.cart?.summary?.placement_type,
        otp_msg: action?.response?.app_setup?.otp_msg
      };
    case CONFIG_CONSTANTS.PHONE_VERIFICATION_SUCCESS:
      return {
        ...state,
        otp_msg: action?.response?.otp?.otp_msg
      };
    case CART_CONSTANTS.PLACE_ORDER_SUCCESS:
      return {
        ...state,
        otp_msg: action?.response?.otp_msg
      };
    case CONFIG_CONSTANTS.INITIATE_ORDER_FAILURE:
      return state;

    case ADDRESS_CONSTANTS.DELETE_CUSTOMER_ADDRESS_SUCCESS:
      address_count = action.address?.address_count;
      return {
        ...state,
        address_count,
      };

    case ADDRESS_CONSTANTS.GET_CUSTOMER_ADDRESS_LIST_SUCCESS:
      address_count = action.response?.address_count;

      return {
        ...state,
        address_count,
      };
    case FORM_CONSTANTS.MAINTAIN_ROUTER_HISTORY:
      return {
        ...state,
        route: action.route,
      };
    default:
      return state;
  }
};
export default redirectionReducer;
