// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import {
  CONSTANTS,
  IMAGE_SRC,
  HELPER,
  ANALYTICS_HELPER,
  GOOGLE_ANALYTICS_HELPER
} from "../../../utils";
import DBTTransactionSlip from "./DBTTransactionSlip";
import EasyPaisaReceipt from "./EasyPaisaOTCTransactionSlip";
import html2canvas from "html2canvas";
import { dialogAction } from "../../../store/actions";

const FORM_TYPE = CONSTANTS.PAYMENT_METHOD.FORM_TYPE;

function ThirdPartyTransactionSlips(props) {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const { payment_method, merchant_bank_details } = useSelector(state => state.cart);
  const { setModel } = useSelector(state => state.action);

  const {
    transaction_data,
    cart_summary,
  } = props;

  // You can now get a ref directly to the DOM button:
  const captureRef = React.createRef();

  const [filename, setFilename] = useState("download");
  const transaction_id = transaction_data?.payment_token;
  const showDBTDivider =
    !HELPER.isEmpty(payment_method) &&
      payment_method?.form_type === FORM_TYPE.DBT &&
      cart_summary?.total_amount !== 0
      ? true
      : false;
  const showEPTransaction = !HELPER.isEmpty(transaction_id) ? true : false;

  const saveScreenshotTitle = (payment_method, unique_identifier) => {
    let _filename = null;
    if (!HELPER.isEmpty(unique_identifier)) {
      _filename = "-" + unique_identifier;
      setFilename(payment_method + _filename);
    } else {
      setFilename(payment_method);
    }
  };

  const setMainWrapperClass = () => {
    if (showDBTDivider) return "blue-divider";
    else if (showEPTransaction) return "green-divider";
  };

  const setTransactionSlipHeader = () => {
    if (showDBTDivider)
      return (
        <>
          <div className="dbtLogo"></div>
          <h5>{merchant_bank_details?.bank_name}</h5>
        </>
      );
    else if (showEPTransaction) return <div className="easyPaisaLogo"></div>;
  };

  const getScreenshotHandler = () => {
    if (setModel) {
      dispatch(dialogAction.closeModal());
    }
 
    html2canvas(captureRef.current, {
      logging: true,
      allowTaint: false,
      useCORS: true,
      backgroundColor: "#ffffff",
      scale: 1,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
    }).then((canvas) => {
      let image = canvas.toDataURL("image/jpeg");
      const base64Canvas = image.split(";base64,")[1];
      HELPER.downloadURI(image, filename + ".png");

      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.PAYMENT,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.DOWNLOAD.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.DOWNLOAD.LABEL,
          properties: {}
        }
      );
      if (HELPER.sendEventToAndroid("saveTransactionSlip")) {
        window.Android.saveTransactionSlip(base64Canvas);
      }
      if (HELPER.sendEventToiOS()) {
        window.webkit.messageHandlers.iosListener.postMessage(base64Canvas);
      }
    })
  };

  return showEPTransaction || showDBTDivider ? (
    <div ref={captureRef} className="to-capture" crossOrigin="anonymous">
      <div className={"transactionSlipMainWrapper " + setMainWrapperClass()}>
        {" "}
        <div className="transactionSlipTopHeader">
          {setTransactionSlipHeader()}

          <div
            className="screen-capture"
            id="screen-capture"
            onClick={getScreenshotHandler}
          >
            {" "}
            <span>
              <img
                src={IMAGE_SRC.CAPTURE_SCREEN}
                alt="capture"
                crossOrigin="anonymous"
              />
              <span className="saveText">{translate("save_btn")}</span>
            </span>
          </div>
        </div>
        {showEPTransaction ? (
          <EasyPaisaReceipt
            transaction_data={transaction_data}
            saveScreenshotTitle={saveScreenshotTitle}
          />
        ) : (
          ""
        )}
        {showDBTDivider ? (
          <DBTTransactionSlip
            transaction_data={transaction_data}
            saveScreenshotTitle={saveScreenshotTitle}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    ""
  );
}

export default ThirdPartyTransactionSlips;
