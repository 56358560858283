import { IMAGE_SRC } from "../images";

export const CARD_SELECTOR_OPTION_LIST = [
  {
    id: "unknown",
    name: "Unknown",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_UNKNOWN_CARD,
  },
  {
    id: "visa",
    name: "VISA",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_VISA_CARD,
  },
  {
    id: "mastercard",
    name: "Mastercard",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_MASTER_CARD,
  },
  {
    id: "unionpay",
    name: "Unionpay",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_UNIONPAY_CARD,
  },
  {
    id: "american express",
    name: "American Express",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_AMERICAN_EXPRESS_CARD,
  },
  {
    id: "maestro",
    name: "Maestro Card",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_MAESTRO_CARD,
  },
  {
    id: "jcb",
    name: "JCB",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_JCB_CARD,
  },
  {
    id: "Diners Club",
    name: "Diners Club",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_DINNER_CLUB_CARD,
  },
  {
    id: "Diners Club US",
    name: "Diners Club US",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_DINNER_CLUB_CARD,
  },
  {
    id: "Diners Club Carte Blanche",
    name: "Diners Club Carte Blanche",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_DINNER_CLUB_CARD,
  },
  {
    id: "discover",
    name: "Discover Card",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_DISCOVER_CARD,
  },
  {
    id: "solo",
    name: "Solo Card",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_SOLO_CARD,
  },
  {
    id: "laser",
    name: "Laser Card",
    displayText: "",
    locale: "en-US",
    flag: IMAGE_SRC.CC_LASER_CARD,
  },
];
