// @flow
//eslint-disable-next-line
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import Countdown, { zeroPad } from "react-countdown-now";

import { verificationAction } from "../../../../../store/actions";
import {
    LOCAL_STORAGE_SERVICE,
    ROUTE_CONSTANTS,
    GOOGLE_ANALYTICS_HELPER,
    ANALYTICS_HELPER,
    history
} from "../../../../../utils";

function OTPBreadcrumb() {
    let dispatch = useDispatch();

    const { configuration } = useSelector(state => state);
    const {
        retryOtp,
        disableOTPTimer,
        config,
    } = configuration;
    const { otp_timer } = config;

    const translate = useTranslate();
    const [otpIcon, setOtpIcon] = useState("icomoon-breadcrumb-otp-filled");
    const [timer, setTimer] = useState(LOCAL_STORAGE_SERVICE._getTimer(otp_timer));
    const [key, setKey] = useState(0);
    const [allowRetry, setallowRetry] = useState(false);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>{translate("OTP.RESEND.TITLE")}</span>;
        } else {
            return (
                <span>
                    {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        }
    };

    useEffect(() => {
        if (otp_timer) {
            setKey(key + 1);
            setTimer(LOCAL_STORAGE_SERVICE._getTimer(otp_timer))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otp_timer]);
    
    useEffect(() => {
        if (retryOtp) {
            setTimer(LOCAL_STORAGE_SERVICE._getTimer(otp_timer))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retryOtp]);

    useEffect(() => {
        if (disableOTPTimer) {
            setKey(key + 1);
        } else {
            dispatch(verificationAction.SET_OTP_TIMER_STATE(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableOTPTimer]);

    const onStart = () => {
        setallowRetry(false)
        dispatch(verificationAction.SET_OTP_TIMER_STATE(true));
        setOtpIcon("icomoon-breadcrumb-otp-filled");
    };

    const onComplete = () => {
        setallowRetry(true)
        dispatch(verificationAction.SET_OTP_TIMER_STATE(false));
        setOtpIcon("icomoon-refresh");
    };

    const retryOTP = () => {
        if(!allowRetry) return
        // LOCAL_STORAGE_SERVICE._nextScreen();
        // Check if network id is recieved via route parameters from select-network screen
        dispatch(verificationAction.OTP_RESEND(null));

        // LOAD GTM EVENT
        ANALYTICS_HELPER._generalEventLog(
            GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.OTP,
            {
                action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.OTP.RETRY.ACTION,
                label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.OTP.RETRY.LABEL,
                properties: {}
            }
        );

        history.push({
            pathname: ROUTE_CONSTANTS.RETRY_OTP,
        });
        // Set timer again and disable otp div so that no one can click on resend-otp btn for another x mins as per otp_timer value
        dispatch(verificationAction.SET_OTP_TIMER_STATE(true));

        setKey(key + 1);
    };

    return (
        <span onClick={retryOTP} id="retry-otp" className="retryOtp">
            <div className={"icon"}>
                <span className={"iconW font18 " + otpIcon}></span>
            </div>
            <div className={"statusText"}>
                {" "}
                <Countdown
                    key={key}
                    autoStart={true}
                    renderer={renderer}
                    date={timer}
                    controlled={false}
                    onStart={onStart}
                    onComplete={onComplete}
                />
            </div>
        </span>
    );
}

export default OTPBreadcrumb;