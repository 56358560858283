import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useNativeLoaderHandler
} from "../../hooks"
import {
  HELPER
} from "../../utils"
function PageLoader() {
  const { showPageLoader, splashLoading, showPleaseWaitLoader } = useSelector(state => state.loading);
  useEffect(() => {
    if (HELPER.sendEventToAndroid("activateProcessOrderAnimation")) {
      window.Android.activateProcessOrderAnimation(splashLoading);
    } else if (HELPER.sendEventToiOS("activateProcessOrderAnimation")) {
      window.webkit.messageHandlers.activateProcessOrderAnimation.postMessage(
        JSON.stringify({ enable: splashLoading })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splashLoading]);
  useEffect(() => {
    if (HELPER.sendEventToAndroid("enableLoader")) {
      window.Android.enableLoader(showPageLoader, "#6F11C6");
    } else if (HELPER.sendEventToiOS("enableLoader")) {
      window.webkit.messageHandlers.enableLoader.postMessage(
        JSON.stringify({ enable: showPageLoader, loaderBackgroundHexCode: "#6F11C6" })
      );
    }
    // if (HELPER.sendEventToAndroid("showLoaderAnimation")) {
    //   window.Android.showLoaderAnimation((showPageLoader));
    // } else if (HELPER.sendEventToiOS("showLoaderAnimation")) {
    //   window.webkit.messageHandlers.showLoaderAnimation.postMessage(
    //     JSON.stringify({ allow: showPageLoader })
    //   );
    //   window.webkit.messageHandlers.showLoaderWait.postMessage(
    //     JSON.stringify({ allow: showPageLoader })
    //   );
    // }
    // if (HELPER.sendEventToAndroid("showLoaderWait")) {
    //   window.Android.showLoaderWait((showPleaseWaitLoader));
    // } else if (HELPER.sendEventToiOS("showLoaderWait")) {
    //   window.webkit.messageHandlers.showLoaderWait.postMessage(
    //     JSON.stringify({ allow: showPleaseWaitLoader })
    //   );
    // }
    if (HELPER.sendEventToAndroid("showLoaderWait")) {
      window.Android.showLoaderWait((showPleaseWaitLoader));
    } else if (HELPER.sendEventToiOS("showLoaderWait")) {
      window.webkit.messageHandlers.showLoaderWait.postMessage(
        JSON.stringify({ allow: showPleaseWaitLoader })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPageLoader]);
  return useNativeLoaderHandler();
}
export default PageLoader;
