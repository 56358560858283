// @flow

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// import { AppWrapper, CreditCardScan, MetaTags, MandatoryPin, FullScreenError } from "../index";
import AppWrapper from "../../components/App";
import CreditCardScan from "../../components/CreditCardScan";
import MetaTags from "../../components/MetaTags";
import MandatoryPin from "../../components/MandatoryPin";
import FullScreenError from "../../components/FullScreenError";

import CheckoutMap from "./map";
import {
  ROUTE_CONSTANTS as routeName,
  BREADCRUMB_SETTINGS_JSON,
  HELPER,
  CONSTANTS
} from "../../utils";
import { mapActions } from '../../store/actions'

function CheckoutWrapper(props) {
  const dispatch = useDispatch()
  const {
    child,
    showMap,
    showCCScan,
    fullscreenModeEnabled,
    location,
    breadcrumbSettings,
    showLangIcon= true
    // subChild,
  } = props;
  const { locationPermission } = useSelector(state => state.map);
  const { action } = useSelector(state => state);
  const { showCartMenu } = action;

  const path = location?.pathname;
  const ADDRESS_ROUTES =
    path === routeName.ADD_NEW_ADDRESS ||
    path === routeName.UPDATE_ADDRESS ||
    path === routeName.MAP_FULL_SCREEN;

  function handlePermission() {
    function successCallback() {
      // if (HELPER.sendEventToAndroid("askForLocationPermission")) {
      //   window.Android.askForLocationPermission(false);
      // }
      locationPermission !== CONSTANTS.PERMISSIONS.GRANTED && dispatch(mapActions.UPDATE_PERMISSION_LOCATION(CONSTANTS.PERMISSIONS.GRANTED));
    }
    function errorCallback() {
      // if (HELPER.sendEventToAndroid("askForLocationPermission")) {
      //   window.Android.askForLocationPermission(false);
      // }
      locationPermission !== CONSTANTS.PERMISSIONS.DENIED && dispatch(mapActions.UPDATE_PERMISSION_LOCATION(CONSTANTS.PERMISSIONS.DENIED));
    }

    if (navigator?.permissions?.query) {
      navigator.permissions.query({ name: "geolocation" })
        .then(function (result) {
          locationPermission !== result.state && dispatch(mapActions.UPDATE_PERMISSION_LOCATION(result.state));
        });
    }
    else {
      //for mobile ios resolved ask permission again on refresh if blocked
      if (navigator.geolocation) {
        // if (HELPER.sendEventToAndroid("askForLocationPermission")) {
        //   window.Android.askForLocationPermission(true);
        // }
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      }
    }
  }
  useEffect(() => {
    if (ADDRESS_ROUTES) {
      //get permission
      handlePermission()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  const showChildObject = () => {
    return (
      <>
        <div className={`${fullscreenModeEnabled ? "fs-container" : "container"} ${showCartMenu ? " SlideOpenFsContainer" : ""}`}>
          <div className="row">
            <div className="col-sm-12">
              <div className="signInView bg-white">{child}</div>
            </div>
          </div>

        </div>
      </>
    );
  };

  return (
    <>
      <MetaTags />
      <AppWrapper
        showMerchantLogo={true}
        showAccountDropdown={true}
        showLangIcon={showLangIcon}
        allowProfileAPI={true}
        breadcrumb={{
          type: !HELPER.isEmpty(breadcrumbSettings) ? breadcrumbSettings.TYPE : BREADCRUMB_SETTINGS_JSON.CHECKOUT.TYPE,
          options: !HELPER.isEmpty(breadcrumbSettings) ? breadcrumbSettings : BREADCRUMB_SETTINGS_JSON.CHECKOUT,
          show: !HELPER.isEmpty(breadcrumbSettings) ? breadcrumbSettings.SHOW : BREADCRUMB_SETTINGS_JSON.CHECKOUT.SHOW
        }}
      >
        {fullscreenModeEnabled && <FullScreenError />}
        {ADDRESS_ROUTES ? <MandatoryPin /> : ""}
        {!fullscreenModeEnabled ? (
          <div className="screen bg-white">
            <CheckoutMap showMap={showMap} />
            <CreditCardScan showCCScan={showCCScan} />
            {showChildObject()}
          </div>
        ) : (
          showChildObject()

        )}
      </AppWrapper>
    </>
  );
}

export default withRouter(CheckoutWrapper);