import React, { useEffect } from "react";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import {
    ANALYTICS_HELPER,
    GOOGLE_ANALYTICS_HELPER
} from "../../../../utils";

const DialogTitle = ((props) => {
    const { children, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other} id="terms-dialog-title">
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className="btn-primary closeright"
                    onClick={onClose}
                >
                <span className="icomoon-close"></span>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

function TermsDialog(props) {
    const translate = useTranslate();

    useEffect(() => {
        // LOAD GTM EVENT
        ANALYTICS_HELPER._generalEventLog(
            GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.NAVIGATION,
            {
                action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.NAVIGATION.POPUP.TERMS.ACTION,
                label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.NAVIGATION.POPUP.TERMS.LABEL,
                properties: {}
            }
        );
    }, [])

    return (
        <>
            <DialogTitle onClose={() => props.handleClose()}></DialogTitle>
            <DialogContent id="terms-dialog-content">
                <Typography gutterBottom variant={"h5"}>
                    {translate("DIALOG_CONTENT.TERMS.HEADING")}
                </Typography>
                <Typography gutterBottom>
                    {translate("DIALOG_CONTENT.TERMS.CONTENT_1")}
                    <br />
                    {translate("DIALOG_CONTENT.TERMS.CONTENT_2")}
                </Typography>
            </DialogContent>
        </>
    );
}

export default TermsDialog;
