// @flow
//eslint-disable-next-line

import * as React from "react";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { HELPER } from "../../../../../utils";

function GeneralBreadcrumb(props) {
    const { options } = props;
    const translate = useTranslate();

    const {
        ICON,
        ICON_CLASS,
        HEADER_CLASS,
        LABEL,
    } = options;

    return (
        <span>
            {!HELPER.isEmpty(ICON) && (
                <div className={`icon `}>
                    <i className={`${ICON} ${ICON_CLASS}`}></i>
                </div>
            )}
            <div className={"statusText" + HEADER_CLASS}>{translate(LABEL)}</div>
        </span>
    );
}

export default GeneralBreadcrumb;
