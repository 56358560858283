// @flow

import React from "react";
// import { SSOWrapper } from "../../components";
import SSOWrapper from "../../components/SSOWrapper";
import OneTapLoginForm from "./form";

import {
  BREADCRUMB_SETTINGS_JSON
} from "../../utils";

function OneTapLogin() {

  return (
      <SSOWrapper
        child={
            <OneTapLoginForm />
        }
        breadcrumbOptions={{
          type: BREADCRUMB_SETTINGS_JSON.ONE_TAP_LOGIN.TYPE,
          options: BREADCRUMB_SETTINGS_JSON.ONE_TAP_LOGIN,
          show: BREADCRUMB_SETTINGS_JSON.ONE_TAP_LOGIN.SHOW
        }}
        showAccountDropdown={false}
        showLangIcon={true}
        showMerchantLogo={true}
        allowProfileAPI={false}
        showPoweredBy={true}
        showFooter={false}
      ></SSOWrapper>
  );
}

export default OneTapLogin;
