// @flow
//eslint-disable-next-line

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { TextField } from "@material-ui/core";
import LoadingButton from "../../../components/LoadingButton";
import Alert from "../../../components/Alert";

import {
  cartAction,
  alertActions,
  paymentAction,
} from "../../../store/actions";
import {
  LOCAL_STORAGE_SERVICE,
  ROUTE_CONSTANTS,
  CONSTANTS,
  REDIRECTION_RULE,
  history
} from "../../../utils";

function CartForm() {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const {
    cc_payment_otp_required,
    cc_payment_email_otp_required,
  } = useSelector(state => state.payment);
  const { showBtnLoader } = useSelector(state => state.loading);
  const { locale } = useSelector(state => state.Intl);
  const { payment_failure_response, pay_limit_reached } = useSelector(state => state.cart);

  const backBtn = LOCAL_STORAGE_SERVICE._isFirstScreen();

  // State assign
  const [verify_otp, setVerify_otp] = useState("");
  const [email_otp, setEmail_otp] = useState("");
  //componentDidMount
  useEffect(() => {
    REDIRECTION_RULE.PRE_PAYMENT_VERIFICATION_REDIRECTION_RULE()
    // Clear alerts
    dispatch(alertActions.clear());
    // LOCAL_STORAGE_SERVICE._isFirstScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const { payment_failure } = payment_failure_response;
    // const { payment_failure, failure_redirect_url } = payment_failure_response;
    //CHECK IF PAYMENT IS FAILED THEN SHOW PAYMENT FAILURE PAGE AND RE-RUN PAYMENT FLOW
    if (payment_failure === 1) {
      history.push({
        pathname: ROUTE_CONSTANTS.ORDER_FAILURE,
      });
    }
    //CHECK IF PAYMENT LIMIT IS EXCEEDED THEN SHOW OUT OF RETRIES AND REDIRECT TO STORE
    if (pay_limit_reached) {
      history.push({
        pathname: ROUTE_CONSTANTS.OUT_OF_RETRIES,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, payment_failure_response]);

  const submitOTP = (e) => {
    e.preventDefault();
    if (!showBtnLoader && verify_otp.length >= 4 && verify_otp.length <= 10) {
      const cnic_value = LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value");
      const requestData = {
        customer_cnic: cnic_value,
        cc_otp: cc_payment_otp_required === 1 ? verify_otp : null,
        email_otp: cc_payment_email_otp_required === 1 ? email_otp : null,
      };
      dispatch(cartAction.place_order(requestData, history));
    } else {
      dispatch(alertActions.error(translate("bank_otp_validation_message")));
    }
  };

  const handleChange = (e) => {
    const num = e.target.value;
    const digits = num.substr(0, 8);
    if (/^[.*^a-zA-Z0-9]*$/.test(digits)) {
      setVerify_otp(digits);
    }
  };

  const handleEmailOTPChange = (e) => {
    const num = e.target.value;
    const digits = num.substr(0, 8);
    if (/^[.*^a-zA-Z0-9]*$/.test(digits)) {
      setEmail_otp(digits);
    }
    // setEmail_otp(digits);
  };

  const disableKeys = (e) => {
    if (e.charCode === 43 || e.charCode === 45 || e.charCode === 46) {
      e.preventDefault();
    }
  };
  const moveBackwards = () => {
    LOCAL_STORAGE_SERVICE._PreviousScreen();
    history.goBack();
    dispatch(paymentAction.move_back_from_bank_otp_screen());
  };
  const setPageTile = () => {
    let title = translate("bank_otp_heading");
    if (cc_payment_otp_required === 1 && cc_payment_email_otp_required === 1) {
      title = translate("bank_otp_heading");
    } else if (
      cc_payment_otp_required === 1 &&
      cc_payment_email_otp_required === 0
    ) {
      title = translate("bank_otp_heading");
    } else if (
      cc_payment_otp_required === 0 &&
      cc_payment_email_otp_required === 1
    ) {
      title = translate("email_otp_heading");
    }
    return title;
  };

  return (
    <>
      <h1>{setPageTile()}</h1>
      <Alert />
      <form className="myForm" onSubmit={submitOTP}>
        <p className="Otptext">
          {cc_payment_otp_required === 1 && cc_payment_email_otp_required === 1
            ? translate("bank_and_email_otp_placeholder")
            : translate("bank_otp_placeholder")}
        </p>
        {cc_payment_otp_required === 1 ? (
          <TextField
            autoComplete="new-password"
            type="text"
            margin="normal"
            variant="outlined"
            className="col-md-12"
            name="card_holder_name"
            autoFocus={true}
            value={verify_otp}
            onChange={handleChange}
            label={
              locale === CONSTANTS.IS_URDU ? "" : (cc_payment_otp_required === 1 &&
              cc_payment_email_otp_required === 1
                ? translate("sms_otp_code_label")
                : translate("bank_otp_code_label"))
            }
            placeholder={
              cc_payment_otp_required === 1 &&
              cc_payment_email_otp_required === 1
                ? translate("sms_otp_code_placeholder")
                : translate("bank_otp_code_placeholder")
            }
            required={true}
            // pattern="[0-9]{10}"
            maxLength={8}
            minLength={4}
            InputProps={{
              maxLength: 8,
              minLength: 4,
              autoComplete: "new-password",
            }}
            onKeyPress={disableKeys}
          />
        ) : (
          ""
        )}
        {cc_payment_email_otp_required === 1 ? (
          <TextField
            autoComplete="new-password"
            margin="normal"
            type="text"
            variant="outlined"
            className="col-md-12"
            name="card_holder_name"
            value={email_otp}
            onChange={handleEmailOTPChange}
            label={locale === CONSTANTS.IS_URDU ? "" : translate("email_otp_code_label")}
            placeholder={translate("email_otp_code_placeholder")}
            required={true}
            // pattern="[0-9]{10}"
            maxLength={8}
            minLength={4}
            InputProps={{
              maxLength: 8,
              minLength: 4,
              autoComplete: "new-password",
            }}
            onKeyPress={disableKeys}
          />
        ) : (
          ""
        )}{" "}
        <div className="optionBtn">
          {!backBtn && (
            <button
              type="button"
              id="back-btn"
              className="btn btn-outline-primary width150"
              onClick={moveBackwards}
            >
              {translate("back_btn")}
            </button>
          )}
          <button
            type="submit"
            id="otp-submit"
            className="btn btn-primary width150"
            disabled={showBtnLoader ? true : false}
          >
            <LoadingButton
              loadingState={showBtnLoader}
              preloadingText={translate("next_btn")}
              loadingText={translate("next_btn")}
            />
          </button>
        </div>
      </form>
    </>
  );
}

export default CartForm;
