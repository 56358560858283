export const ROUTE_CONSTANTS = {
    BASE: "/",
    BUY_NOW: "/buy-now",
    UI_ELEMENTS: "/ui-elements",
    CHECKOUT: "/checkout",
    UNIVERSAL_CHECKOUT: "/universal_checkout",
    PAYMENT_CHECKOUT: "/secure-payments",
    INVOICE_CHECKOUT: "/invoice-checkout",
    ONE_TAP_LOGIN: "/one-tap-login",
    ONE_TAP_LOGIN_WITH_REF: "/one-tap-login?ref=",
    PHONE_SCREEN: "/verify-phone",
    CHANGE_NETWORK: "/change-network",
    OTP_SCREEN: "/otp-screen",
    RETRY_OTP: "/retry-otp",
    MAP_FULL_SCREEN: "/map",
    ADD_NEW_ADDRESS: "/add-new-address",
    ADDRESS_LIST: "/edit-address",
    UPDATE_ADDRESS: "/address",
    SHIPPING: "/shipping",
    PAYMENT: "/payment",
    CREDIT_CARD: "/credit-card",
    BANK_OTP: "/checkout-otp",
    CART: "/cart",
    ORDER_SUCCESS: "/success",
    ORDER_FAILURE: "/failure",
    OUT_OF_RETRIES: "/out-of-retries",
    PAYMENT_PROCESSING: "/payment-processing",
    PAYMENT_INFO: "/payment-info",
    PROCESSING: "/processing",
    QIST_PAY: "/qist-pay",
    STATUS: "/status",
    GENERAL_ERROR_PAGE: "/error",
    ABANDONED: "/abandoned-order",
    ANY: "*",
};

