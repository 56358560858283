import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Helmet } from "react-helmet";

import { META_TAGS, HELPER } from "../../utils";

function MetaTags() {
  const { merchant_name, fav_icon_url } = useSelector(state => state.configuration.config);

  const [merchantName, setMerchantName] = useState(merchant_name);
  const [faviconURL, setFaviconURL] = useState(fav_icon_url);

  const metaTitle = () => {
    return (
      HELPER.capitalizeFirstLetter(merchantName) +
      " Secure Checkout Powered by bSecure"
    );
  };

  const metaDescription = () => {
    return (
      "Complete your order securely with " +
      HELPER.capitalizeFirstLetter(merchantName) +
      " powered by bSecure"
    );
  };

  useEffect(() => {
    if (!HELPER.isEmpty(merchant_name)) {
      setMerchantName(merchant_name);
    }
    if (!HELPER.isEmpty(fav_icon_url)) {
      setFaviconURL(fav_icon_url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant_name, fav_icon_url]);
  return (
    <>
      <Helmet>
        <title>
          {!HELPER.isEmpty(merchantName) ? metaTitle() : META_TAGS.META_TITLE}
        </title>
        <meta
          name="description"
          content={
            !HELPER.isEmpty(merchantName)
              ? metaDescription()
              : META_TAGS.META_DESCRIPTION
          }
        />
        <meta
          name="og:title"
          content={
            !HELPER.isEmpty(merchantName)
              ? metaTitle()
              : META_TAGS.OPEN_GRAPH_TITLE
          }
        />
        <meta
          name="og:image"
          content={
            !HELPER.isEmpty(faviconURL)
              ? faviconURL
              : META_TAGS.OPEN_GRAPH_IMAGE
          }
        />
        <meta
          name="og:description"
          content={
            !HELPER.isEmpty(merchantName)
              ? metaDescription()
              : META_TAGS.OPEN_GRAPH_DESCRIPTION
          }
        />
      </Helmet>
    </>
  );
}

export default MetaTags;
