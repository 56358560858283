import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";

import { Card } from "react-bootstrap";

import { HELPER, SUPPORTED_LANG } from "../../../../utils";
import { languageAction } from "../../../../store/actions";

const DialogTitle = (props) => {
    const { onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
            {onClose ? (
                <IconButton aria-label="close" onClick={onClose}>
                <span className="icomoon-close"></span>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};


const LocaleUpdate = (locale) => {
    let htmlEl = document.querySelector("html");
    // htmlEl.setAttribute("dir", direction);
    htmlEl.setAttribute("lang", locale);

    let lang_direction = HELPER.getLngDirection(locale);
    let bodyEl = document.querySelector("body");
    bodyEl.setAttribute("dir", lang_direction);
    return true;
};

function LanguageDialog() {
    let dispatch = useDispatch();
    const translate = useTranslate();

    const { locale } = useSelector(state => state.Intl);

    useEffect(() => {
        if (locale) {
            LocaleUpdate(locale)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    const changeLngLocale = (lang_locale) => {
        dispatch(languageAction.updateLanguage(lang_locale))
        LocaleUpdate(lang_locale)

        dispatch(languageAction.closeLanguagePopup())
    };

    return (
        <>
            <DialogTitle
                disableTypography
                id="customized-dialog-title"
                className="langCloseIcon"
                onClose={() => { dispatch(languageAction.closeLanguagePopup()) }}
            ></DialogTitle>
            <div className="dialogPopupInv langDialog">
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        component={"div"}
                    >
                        <span className="langSuggestion">
                            {translate("suggested_language_text")}
                        </span>
                        <div className="dropdownAccordion">
                            <div className="customRadio">
                                <div className="dropdownAccordion">
                                    <div className="customRadio">
                                        {SUPPORTED_LANG.map((language, i) =>
                                            language?.lng_priority === 1 ? (
                                                <Card
                                                    onClick={() => changeLngLocale(language.locale)}
                                                    className={
                                                        language.locale === locale ? "activeShow" : ""
                                                    }
                                                    key={"languages-" + i}
                                                >
                                                    <div
                                                        className="card-header-left card-header"
                                                        dir="ltr"
                                                    >
                                                        <h5 className="LangName">
                                                            {language.language} {language.region}
                                                        </h5>
                                                        <h6 className="LangCountry">
                                                            {language.country}
                                                        </h6>
                                                    </div>
                                                </Card>
                                            ) : (
                                                ""
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        component={"div"}
                    >
                        <span className="langSuggestion">
                            {translate("select_region_text")}
                        </span>
                        <div className="dropdownAccordion">
                            <div className="customRadio">
                                {SUPPORTED_LANG.map((language, i) => (
                                    <Card
                                        onClick={() => changeLngLocale(language.locale)}
                                        className={
                                            language.locale === locale ? "activeShow" : ""
                                        }
                                        key={"region-" + i}
                                    >
                                        <div className="card-header-left card-header" dir="ltr">
                                            <h5 className="LangName">
                                                {language.language} {language.region}
                                            </h5>
                                            <h6 className="LangCountry">{language.country}</h6>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </div>
        </>
    );
}

export default LanguageDialog;
