// @flow
//eslint-disable-next-line

import * as React from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import {
  HELPER
} from "../../utils";

function ReturntoMerchant() {
  const translate = useTranslate();
  const { merchant_name } = useSelector(state => state.configuration.config);

  const merchant_public_company_name = HELPER.isEmpty(merchant_name)
    ? "Merchant"
    : merchant_name;
  return (
    <>
      {translate("return_to_merchant_heading") +
        " " +
        merchant_public_company_name}
    </>
  );
}

export default ReturntoMerchant;
