

// @flow
//eslint-disable-next-line

import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Card } from "react-bootstrap";

import { MenuWrapper } from "../../../../containers";
import { HELPER } from "../../../../utils";
import { cartAction } from "../../../../store/actions";

const MobileMenuDropdown = () => {
    const { showCartMenu } = useSelector(state => state.action);
    const { items } = useSelector(state => state.cart.cart_details);
    const dispatch = useDispatch();

    const accordianToggle = () => {
        dispatch(cartAction.TOGGLE_CART_ICON());
    };

    return (
        (!HELPER.isEmpty(items) && showCartMenu) ?
            <div
                className={"cartMenu cartSlideWrapper"}
            >
                <div className="accordion">
                    <Card>
                        <div className={showCartMenu ? "show" : "collapse"}>
                            <Card.Body>
                                <MenuWrapper />
                            </Card.Body>
                        </div>
                        <div
                            className={showCartMenu ? "closeSlideCart" : "closeSlideCart d-none"}
                        >
                            <button onClick={accordianToggle}>
                                <i className="icomoon-navigate-bottom"></i>
                            </button>
                        </div>
                    </Card>
                </div>
            </div> : ""
    )
};



export default MobileMenuDropdown;
