// @flow
//eslint-disable-next-line

import React from "react";

import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";

import {
    // IMAGE_SRC,
    GOOGLE_ANALYTICS_HELPER,
    HELPER,
    ANALYTICS_HELPER
} from "../../../../utils";
import { MenuWrapper } from "../../../../containers";
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
        className="nav-link"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            ANALYTICS_HELPER._navigationEventLog(
                GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.NAVIGATION,
                {
                    action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.NAVIGATION.CART_MENU.ACTION,
                    label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.NAVIGATION.CART_MENU.LABEL,
                    properties: {}
                }
            );
            onClick(e);
        }}
    >
        {children}
    </span>
));


// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        const value = "";

        return (
            <div className="cartDropdown">
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <ul className="dropdown-list">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children.toLowerCase().startsWith(value)
                        )}
                    </ul>
                </div>
            </div>
        );
    }
);

function WebMenuWrapper() {
    const { items, count } = useSelector(state => state.cart.cart_details);

    return (
        !HELPER.isEmpty(items) ? <div className="cartDropdown">
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <div className="cartIcon">
                        <span className="nav-link">
                            <i className="icomoon-navigate-cart"></i>
                            {count >= 0 ? <div className="cartCounter"><span>{count}</span></div> : ""}
                        </span>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu}>
                    <div className="cartMenu cartSlideWrapper">
                        <div>
                            <MenuWrapper />
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div> : ""
    )
};


export default WebMenuWrapper;
