// @flow
//eslint-disable-next-line

import React, { useEffect, useState } from "react";
import { useTranslate } from "react-redux-multilingual/lib/context";
import { useSelector, useDispatch } from "react-redux";

import queryString from "query-string";
import Alert from "../../../components/Alert";
import Modal from "../../../components/Modal";
import LoadingButton from "../../../components/LoadingButton";
import ReturntoMerchant from "../../../components/ReturntoMerchant";
import CnicField from "../../../components/FormElements/Cnic";

import {
  OrderReceipt,
} from "../../../containers";
import CheckoutVoucherComponent from '../VoucherComponent'
import {
  cartAction,
  alertActions,
  paymentAction,
  logoutAction,
  mapActions,
} from "../../../store/actions";
import {
  CONSTANTS,
  ROUTE_CONSTANTS,
  HELPER,
  LOCAL_STORAGE_SERVICE,
  REDIRECTION_RULE,
  ANALYTICS_HELPER,
  GOOGLE_ANALYTICS_HELPER,
  history,
  // FIREBASE_ANALYTICS_HELPER
} from "../../../utils";
import {
  usePullToRefreshHandler,
} from "../../../hooks";
import { CART_CONSTANTS, FORM_CONSTANTS } from "../../../store/actionTypes";
import HBLValidationScript from "../../CreditCard/Forms/HBL/validation";
import CheckoutInfo from "../CheckoutInfo";
import CheckoutCartItem from "../CheckoutInfo/cart_items";
import TermsAndConditions from "../TermsAndConditions";
import useNameEmail from "../../../hooks/useNameEmail";

const FORM_TYPE = CONSTANTS.PAYMENT_METHOD.FORM_TYPE;
const INSTRUCTIONS = CONSTANTS.PAYMENT_METHOD.INSTRUCTIONS;


let defaultErrorState = {
  name: "",
  email: "",
};

function CartForm() {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { location, } = history


  const {
    payment_verification_required,
    cc_payment_verified,
    cc_payment_redirect_url,
    cc_payment_3ds_required,
    cc_payment_otp_required,
    cc_payment_email_otp_required,
    cc_redirection,
  } = useSelector(state => state.payment);
  const { enableFullScreenMode } = useSelector(state => state.map);
  const { config, customer, profileLoading } = useSelector(state => state.configuration);
  const { is_pin_location_mandatory, is_customer_app } = config
  const { showCartReview } = useSelector(state => state.action);
  const { showBtnLoader, disableNextBtn } = useSelector(state => state.loading);
  const {
    payment_method,
    cnic_requirement,
    ask_for_verification,
    cart_success,
    order_placed,
    pay_limit_reached,
    payment_failure_response,
    cart_details,
    device_fingerprint,
    cart_details_loading,
  } = useSelector(state => state.cart);
  const { device_fingerprint_response } = device_fingerprint;
  const { total_amount, currency_code, placement_type, voucher_applicable } = cart_details.summary

  const cnicRequired = parseInt(cnic_requirement.cnic_required) === 1;
  let _totalAmount= HELPER.makeInteger(total_amount)
  const cnicValidationLimit =  parseFloat(_totalAmount) >= parseFloat(cnic_requirement.cnic_required_amount);

  const { confirm_btn_text, footer_note_review } = payment_method;
  const isPaymentGateway =
    CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY === placement_type;

  //Credit card cvv
  const [cc_cvv, setCc_cvv] = useState("");
  const [cnic_validation_max_limit, setCnic_validation_limit] = useState(null);

  const [errors_cnic_number, setErrorsCnic_number] = useState(null);
  const [errors_cc_cvv, setErrorsCvv] = useState(false);
  const [errors_cc_cvv_message, setErrorsCvvMessage] = useState(null);

  // Save query string parameters
  const params = queryString.parse(location.search);
  //Order reference recieved incase of 3ds verified payment
  const order_ref = params?.order_ref;
  const secure_id = params?.secure_id;

  let isBankOtp=cc_payment_verified && (cc_payment_otp_required === 1 || cc_payment_email_otp_required === 1)
  let callPlaceOrder= !HELPER.isEmpty(order_ref) && !showBtnLoader && !HELPER.isEmpty(secure_id)
  let redirectUrl3ds=cc_payment_verified && cc_payment_3ds_required === 1 && !HELPER.isEmpty(cc_payment_redirect_url)

  const [errors, setErrors] = useState(defaultErrorState);
  const {name, email, DisplayFields} = useNameEmail({errors})

  const showNameEmail=!customer?.name || customer?.name === "Guest" || !customer.email

  //componentDidMount
  useEffect(() => {
    // FIREBASE_ANALYTICS_HELPER.checkoutSteps(FIREBASE_ANALYTICS_HELPER.CHECKOUT.REVIEW.TIER);
    ANALYTICS_HELPER._checkoutSteps(6, GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.REVIEW);
    if (HELPER.isEmpty(secure_id)) {
      REDIRECTION_RULE.PRE_CART_REDIRECTION_RULE()
    }
    if (!!is_pin_location_mandatory && !enableFullScreenMode) {
      dispatch(mapActions.SET_FULL_SCREEN_MODE(true))
    }
    const QISSTPAY = payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.QISSTPAY &&
      payment_method?.input_type === CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.NONE
    if (!QISSTPAY) {
      dispatch(alertActions.clear());
    }
    if (!redirectUrl3ds && !callPlaceOrder && !isBankOtp && 
      (!cart_details_loading || HELPER.isEmpty(payment_method))) {
        if(document.getElementById("iframeDiv3DS")){
          window.location.reload()
        }else{
          dispatch(cartAction.checkout_detail());
        }
    }
    // IF cart/ HAS ORDER_REF PARAMETER THAT MATCHES WITH ORDER_REF IN LOCAL STORAGE
    // THEN CALL SUBMIT CART DETAILS API SINCE THIS IS 3DS CASE AND DOESN'T HAVE CVV PARAMETER THEREFORE REQUEST DATA WILL BE
    if (callPlaceOrder) {
      const requestData = {
        customer_cnic: LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value"),
      };
      dispatch(cartAction.place_order(requestData));
    }

    // IF cc_payment_verified && CC IS 3DS AND cc_payment_redirect_url IS NOT empty
    if (redirectUrl3ds ) {
      dispatch({type: CART_CONSTANTS.CLEAR_PAYMENT_VERIFIED});
      // dispatch(formAction.SHOW_PLEASE_WAIT_LOADER());
      HELPER.redirectToPath(cc_payment_redirect_url, false);
    }

    // IF cc_payment_verified && OTP is required for credit card validation
    else if (isBankOtp) {
      LOCAL_STORAGE_SERVICE._nextScreen();
      history.push({
        pathname: ROUTE_CONSTANTS.BANK_OTP,
      });
    }

    // returned function will be called on component unmount
    return () => {
      if (!HELPER.isEmpty(cart_success.redirect_url)) {
        const redirect_url = cart_success.redirect_url;
        const store_url = cart_success.store_url;
        // Send message to bulider website for hosted checkout //
        HELPER.sendMerchantBeforeUnloadListener(redirect_url, store_url);
      }
      if (window.opener !== null) {
        // window.onunload = null;
        window.onbeforeunload = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //componentDidMount
  useEffect(() => {
    if (!HELPER.isEmpty(cc_cvv)) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cc_cvv]);

  //componentWillRecieveProps
  useEffect(() => {
    if (
      payment_verification_required &&
      cc_payment_verified &&
      cc_payment_3ds_required !== 1 &&
      !showBtnLoader
      && cc_payment_otp_required === 0 && cc_payment_email_otp_required === 0
    ) {
      if ((CONSTANTS.PAYMENT_METHOD.PAYMENT_INPUT_TYPE.BANK_ACCOUNT_NUMBER.includes(payment_method?.input_type) && CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.BANK_ACCOUNT_NUMBER.includes(payment_method?.form_type) && Math.ceil(_totalAmount) !== 0)) {
        const requestData = {
          customer_cnic: LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value"),
        };
        dispatch(cartAction.place_order(requestData));
      } else if (HELPER.isNotEmpty(cart_success)) {
        const requestData = {
          customer_cnic: LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value"),
          cc_cvv: parseInt(cc_cvv),
        };
        dispatch(cartAction.place_order(requestData));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    payment_verification_required,
    cc_payment_3ds_required,
    cc_payment_verified
  ]);



  //componentWillRecieveProps
  useEffect(() => {
    //CHECK IF PRE-PAYMENT IS VERIFIED AND REQUIRED THEN CALL PLACE ORDER API
    if (
      payment_verification_required &&
      cc_payment_verified &&
      (cc_payment_otp_required === 1 || cc_payment_email_otp_required === 1) &&
      !showBtnLoader
    ) {
      LOCAL_STORAGE_SERVICE._nextScreen();
      history.push({
        pathname: ROUTE_CONSTANTS.BANK_OTP,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cc_payment_otp_required,
    payment_verification_required,
    cc_payment_verified,
  ]);

  //componentWillRecieveProps
  useEffect(() => {
    // IF cc_payment_verified && CC IS 3DS AND cc_payment_redirect_url IS NOT EMPTY
    if (
      cc_payment_verified &&
      cc_payment_3ds_required === 1 &&
      !HELPER.isEmpty(cc_payment_redirect_url)
    ) {
      dispatch({type: CART_CONSTANTS.CLEAR_PAYMENT_VERIFIED});
      // dispatch(formAction.SHOW_PLEASE_WAIT_LOADER());
      HELPER.redirectToPath(cc_payment_redirect_url, false);
    }
    else if(cc_payment_verified &&
      cc_payment_3ds_required === 1 &&
      HELPER.isEmpty(cc_payment_redirect_url) &&
      cc_redirection === 0){
        dispatch(cartAction.place_order({}));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cc_payment_verified, cc_payment_3ds_required, cc_payment_redirect_url]);


  //componentWillRecieveProps
  useEffect(() => {
    const redirect_url = cart_success.redirect_url;
    const store_url = cart_success.store_url;
    // CHECK CONDITIONS FOR CUSTOMER VERIFICATION POPUP
    if (ask_for_verification) {
      const modal = {
        title: translate("VERIFICATION.POPUP_TITLE"),
        content: "",
        cancelBtn:
          ask_for_verification === 1 && !HELPER.isEmpty(redirect_url) ? (
            <ReturntoMerchant />
          ) : (
            translate("cancel_btn")
          ),
        successBtn: translate("VERIFICATION.SUCCESS_BTN"),
        disableBackdropClick: true,
        cancelBtnId: "cancel",
        successBtnId: "return-to-merchant",
      };
      dispatch(cartAction.verify_customer_after_checkout(modal));
      HELPER.sendMerchantBeforeUnloadListener(redirect_url, store_url);

      setTimeout(() => {
        dispatch(logoutAction.CLEAR_REDUX_STORE());
        HELPER.redirectToPathAndClearLocalStorage(redirect_url, store_url);
      }, 15000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ask_for_verification, cart_success]);

  //componentWillRecieveProps
  useEffect(() => {
    // Set max limit of cnic field validation
    if (!HELPER.isEmpty(cnic_requirement?.customer_cnic_validation)) {
      setCnic_validation_limit(
        parseInt(cnic_requirement?.customer_cnic_validation)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnic_requirement]);

  //componentWillRecieveProps
  useEffect(() => {
    const { payment_failure } = payment_failure_response;
    //CHECK IF PAYMENT IS FAILED THEN SHOW PAYMENT FAILURE PAGE AND RE-RUN PAYMENT FLOW
    if (payment_failure === 1) {
      history.push({
        pathname: ROUTE_CONSTANTS.ORDER_FAILURE,
      });
    }
    //CHECK IF PAYMENT LIMIT IS EXCEEDED THEN SHOW OUT OF RETRIES AND REDIRECT TO STORE
    if (pay_limit_reached) {
      history.push({
        pathname: ROUTE_CONSTANTS.OUT_OF_RETRIES,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_failure_response]);

  //componentWillRecieveProps
  useEffect(() => {
    if (order_placed && CONSTANTS.PAYMENT_METHOD.PAYMENT_INSTRUCTION_TYPE.EASYPAISA.includes(payment_method?.form_type)) {
      if (payment_method?.input_type === INSTRUCTIONS.EASYPAISA) {
        history.push({
          pathname: ROUTE_CONSTANTS.PAYMENT_PROCESSING,
        });
      } else if (payment_method?.input_type === INSTRUCTIONS.JAZZCASH) {
        history.push({
          pathname: ROUTE_CONSTANTS.PAYMENT_PROCESSING,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_placed, payment_method]);

  //componentWillRecieveProps
  useEffect(() => {
    if (
      payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.QISSTPAY ||
      payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.NIFT ||
      CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.WALLET.includes(payment_method?.form_type) ||
      CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.BANK_ACCOUNT_NUMBER.includes(payment_method?.form_type)
    ) {
      dispatch(paymentAction.payment_method_verification(payment_method?.form_type));
    }

    if(!HELPER?.stringToBoolean(voucher_applicable)){
      dispatch({ type: FORM_CONSTANTS.CLEAR_VOUCHER_MESSAGE })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_method]);

  const updateCvv = (cvv) => {
    setCc_cvv(cvv.cc_cvv);
  };

  const clearCnicErrors = () => {
    setErrorsCnic_number(false);
  };

  const handleNameEmailValidation = () => {
    let validation_errors = {
      name: "",
      email: "",
    };
    if (!name) {
      validation_errors.name = translate("VALIDATIONS.IS_REQUIRED");
    } else if (name.length < 3) {
      validation_errors.name = translate("VALIDATIONS.NAME_MIN");
    } else if (name.length > 50) {
      validation_errors.name = translate("name_field_max_validation");
    } else if (!email) {
      validation_errors.email = translate("VALIDATIONS.IS_REQUIRED");
    } else if (
      !HELPER.isEmpty(email) &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    ) {
      validation_errors.email = translate("invalid_email_address");
    } else if (email.length > 50) {
      validation_errors.email = translate("email_field_max_validation");
    }

    setErrors(validation_errors);
    setTimeout(() => {
      setErrors(defaultErrorState);
    }, 5000)
    return validation_errors;
  }

  const handleSubmit = async () => {

   if(showNameEmail){
    const validation_errors= handleNameEmailValidation()
    if(validation_errors?.name || validation_errors?.email) {
      HELPER.scrollTo("name-email",-150)
      // scroller.scrollTo("name-email", { offset: -150, smooth: true });
      return
    }
   } 

    let validationCheck = handleValidation();
    
    // LOAD GTM EVENT
    ANALYTICS_HELPER._purchaseOrder(cart_details);
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.REVIEW,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.REVIEW.BUY_NOW.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.REVIEW.BUY_NOW.LABEL,
        properties: {
          errors: validationCheck
        }
      }
    );

  
    let requestData;
    const cnic = LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value");

    const GENERAL_CARD_USECASE = payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.GENERAL &&
      payment_method?.input_type === CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.CREDIT_CARD
    const NIFT_CARD_USECASE = payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.NIFT &&
      ([CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.NIFT_BANK, CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.NIFT_CC_FORM].includes(payment_method?.input_type))
    const QISSTPAY_USECASE = payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.QISSTPAY &&
      payment_method?.input_type === CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.NONE;

    //Check if payment method is credit card and then auto-redirect to credit-card screenn
    if (validationCheck.cnic_number === "" && validationCheck.cc_cvv === "") {

      let _status= await HELPER.updateRequestDataNameEmail()
      if(!_status) return

      if ((GENERAL_CARD_USECASE || NIFT_CARD_USECASE || QISSTPAY_USECASE) && payment_verification_required &&
        Math.ceil(_totalAmount) !== 0
      ) {
        LOCAL_STORAGE_SERVICE._nextScreen();
        history.push({
          pathname: ROUTE_CONSTANTS.CREDIT_CARD,
        });
      } else if (payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.API_BASED
        &&
        payment_method?.input_type === CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.NONE &&
        Math.ceil(_totalAmount) !== 0
      ) {
        requestData = {
          customer_cnic: cnic
        };
        const validateToken = {
          input_type: payment_method?.input_type,
          device_fingerprint: device_fingerprint_response,
        };
        const validateTokenData = {
          input_type: payment_method?.input_type,
          requestData: validateToken,
          cartRequestData: requestData,
          history,
        };
        dispatch(paymentAction.validate_payment_method(validateTokenData));
      }
      else {
        if (
          (payment_method?.form_type === FORM_TYPE.APG.WALLET &&
            Math.ceil(_totalAmount) !== 0) ||
          ((CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.BANK_ACCOUNT_NUMBER.includes(payment_method?.form_type)) &&
            payment_verification_required &&
            Math.ceil(_totalAmount) !== 0)
        ) {
          requestData = {
            customer_cnic: cnic
          };
          const validateTokenData = {
            input_type: payment_method?.form_type,
            requestData: requestData,
            cartRequestData: requestData,
            history,
          };
          dispatch(paymentAction.validate_payment_method(validateTokenData));
        } else if (payment_method?.input_type === CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.CVV && payment_verification_required && Math.ceil(_totalAmount) !== 0) {
          requestData = {
            customer_cnic: cnic,
            cc_cvv
          };
          const validateToken = {
            input_type: payment_method?.input_type,
            cc_cvv,
            device_fingerprint: device_fingerprint_response
          };
          const validateTokenData = {
            input_type: payment_method?.input_type,
            requestData: validateToken,
            cartRequestData: requestData,
            history,
          };
          dispatch(paymentAction.validate_payment_method(validateTokenData));
        } else if (!showBtnLoader) {
          requestData = {
            customer_cnic: cnic
          };
          dispatch(cartAction.place_order(requestData));
        }
      }
    }
  };

  const handleValidation = () => {
    let validation_errors = {
      cnic_number: "",
      cc_cvv: "",
    };
    const cnic = LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value");
    let errorText = "";
    setErrorsCnic_number(false);
    setErrorsCvv(false);
    setErrorsCvvMessage("");

    //Check for error condition
    if (payment_method?.input_type === "cvv" && HELPER.isEmpty(cc_cvv)) {
      if (HELPER.isEmpty(cc_cvv)) {
        errorText = translate("VALIDATIONS.IS_REQUIRED");
        if (errors_cc_cvv_message === errorText) {
          errorText = "request";
        }
        validation_errors.cc_cvv = errorText;
      } else {
        errorText = "request";
        validation_errors.cc_cvv = errorText;
      }
      setErrorsCvv(true);
      setErrorsCvvMessage(errorText);
      HELPER.scrollTo("cc_cvv", -200)
      // scroller.scrollTo("cc_cvv", { offset: -200, smooth: true });
    } else if (
      parseInt(cnic_requirement?.cnic_required) === 1 &&
      parseFloat(_totalAmount) >=
      parseFloat(cnic_requirement.cnic_required_amount) &&
      HELPER.isEmpty(cnic)
    ) {
      errorText = translate("VALIDATIONS.IS_REQUIRED");
      validation_errors.cnic_number = errorText;
      setErrorsCnic_number(true);
      HELPER.scrollTo("cnic_number", -200)
      // scroller.scrollTo("cnic_number", { offset: -200, smooth: true });
    } else if (
      parseInt(cnic_requirement?.cnic_required) === 1 &&
      parseFloat(_totalAmount) >=
      parseFloat(cnic_requirement.cnic_required_amount) &&
      cnic.length !== cnic_validation_max_limit
    ) {
      errorText = translate("PAYMENT.VALIDATION.CNIC_FIELD", {
        x: cnic_validation_max_limit,
      });
      validation_errors.cnic_number = errorText;
      setErrorsCnic_number(true);
      HELPER.scrollTo("cnic_number", -200)
      // scroller.scrollTo("cnic_number", { offset: -200, smooth: true });
    }
    return validation_errors;
  };

  return (
    <>
      <div className={`bottom-padding ${HELPER.isEmpty(footer_note_review) ? "" : "btm-200"}`}>
        <div className={`${showCartReview ? "mt-3" : "d-none"}`}>
          {usePullToRefreshHandler(false)}
          {/* SHOW ALERT MESSAGES START */}
          <Alert />
          {/* SHOW ALERT MESSAGES START */}

          {/* Cart Items Section Starts Here */}
          {!isPaymentGateway ? <CheckoutCartItem /> : ""}

          {/* Order Receipt Section Starts Here */}
          <OrderReceipt showPaidStamp={false} showTransactionSlip={false} />
          {/* Order Receipt Section Ends Here */}
        </div>

        <div className={`${showCartReview ? "d-none" : "cartView"}`}>
          {usePullToRefreshHandler(is_customer_app)}
          <h1>{translate("cart_placeholder")}</h1>
          <p className="signviewsmalltext cartViewSmallText">{translate("order_detail_review")}</p>

          {/* SHOW ALERT MESSAGES START */}
          <Alert />
          {/* SHOW ALERT MESSAGES START */}

          {/* CHECKOUT INFO START */}
          <CheckoutInfo
            errors_cc_cvv={errors_cc_cvv}
            errors_cc_cvv_message={errors_cc_cvv_message}
            updateCvv={updateCvv}
          />
          {/* CHECKOUT INFO ENDS */}
          {showNameEmail && !profileLoading &&
            <div className={`review-name-email danger`}>
              <h6 className="name-email">{translate("REVIEW.PERSONAL_DETAILS")}</h6>
              {DisplayFields()}
            </div>
          }

          {/* Cart Items Section Starts Here */}

          <div className="checkoutDetails">
            {/* Voucher Section Starts Here */}
            {HELPER?.stringToBoolean(voucher_applicable) ? (
              <CheckoutVoucherComponent />
            ) : (
              ""
            )}
            {/* Voucher Section Starts Here */}

            {/* CNIC Section Starts Here */}
            {
              cnicValidationLimit && cnicRequired ? (
                <div className="voucher cnicRequirement">
                  <CnicField errors_cnic={errors_cnic_number} update_cnic_error={clearCnicErrors} />
                </div>
              ) : (
                ""
              )
            }
            {/* CNIC Section Ends Here */}

            {/* Terms And Conditions Section Starts Here */}
            <TermsAndConditions />
            {/* Terms And Conditions Section Starts Here */}
          </div>
        </div>

        {/* Cart Screen Fixed Blue Bar Section Starts Here */}
        <div className={`bottomFixedBar totalFixed ${HELPER.isEmpty(footer_note_review) ? "" : "footerReviewFixed"}`}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                {!HELPER.isEmpty(footer_note_review) ? <div className="footerReview">
                  <span className="mainfooterReviewText">
                    <span className={"iconW icomoon-info-outline subFooterReviewIcon"}></span>
                    <span className="subFooterReviewText">{footer_note_review}</span>
                  </span>
                </div>
                  : ""}
                <div className="receiptTotal">
                  <span className="total">{translate("total_text")}</span>
                  <span className="totalPrice">
                    {HELPER.showPrice(currency_code, total_amount)}
                  </span>
                </div>
                <button
                  type="button"
                  onClick={handleSubmit}
                  id="place-order"
                  className={`btn btn-outline-primary btn-block ${(disableNextBtn ? "notClickedBtn" : showBtnLoader ? "notClickedBtn" : "clickedBtn")} `}
                  disabled={disableNextBtn ? true : showBtnLoader ? true : false}
                >
                  <LoadingButton
                    loadingState={showBtnLoader ? true : false}
                    showBlueLoader={true}
                    preloadingText={showBtnLoader ? translate("processing_btn") : !HELPER.isEmpty(confirm_btn_text) ? confirm_btn_text : translate("buy_now")}
                    loadingText={showBtnLoader ? translate("processing_btn") : !HELPER.isEmpty(confirm_btn_text) ? confirm_btn_text : translate("buy_now")}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Cart Screen Fixed Blue Bar Section Ends Here */}
      </div>
      {/* Modal For Verification Popup Starts Here */}
      <Modal />
      {/* Modal For Verification Popup Ends Here */}
      {/* HBL Validation Script Starts Here */}
      <HBLValidationScript />
      {/* HBL Validation Script Ends Here */}
    </>
  );
}

export default CartForm;
