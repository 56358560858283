import React, {useState, useEffect} from 'react'
import { useTranslate } from "react-redux-multilingual/lib/context";
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from "react-redux";
import { ANALYTICS_HELPER, GOOGLE_ANALYTICS_HELPER, TEXT_FORMAT } from '../utils';
import { formAction } from '../store/actions';


export default function useNameEmail({ disableEmail, disableName, errors}) {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const form_name = useSelector(state => state.form.name)
    const form_email = useSelector(state => state.form.email)
    const [name, setName] = useState(form_name);
    const [email, setEmail] = useState(form_email);
    
    useEffect(() => {
        dispatch(formAction.UPDATE_CUSTOMER_EMAIL(email));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [email]);
    
      useEffect(() => {
        dispatch(formAction.UPDATE_CUSTOMER_NAME(name));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [name]);

      useEffect(() => {
        setName(form_name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [form_name]);
    
    
      useEffect(() => {
        setEmail(form_email);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [form_email]);

    const handleNameChange = (e) => {
        const element = e.target
        let name = TEXT_FORMAT.allowAlphabetsWithSpaceOnly(
          TEXT_FORMAT.allowAlphabetsWithSpaceOnly(element.value)
        );
    
        // LOAD GTM EVENT
        ANALYTICS_HELPER._generalEventLog(
          GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
          {
            action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.NAME.ACTION,
            label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.NAME.LABEL,
            properties: {
            }
          }
        );
        setName(name);
      };
    
      const handleEmailChange = (e) => {
        // LOAD GTM EVENT
        ANALYTICS_HELPER._generalEventLog(
          GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
          {
            action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.EMAIL.ACTION,
            label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.EMAIL.LABEL,
            properties: {
            }
          }
        );
        setEmail(e.target.value);
      };
    
      const DisplayFields=()=>{
        return (
            <>
            <div>
            {/* Full Name */}
            <TextField
            type="text"
            margin="normal"
            variant="outlined"
            className="col-md-12"
            label={translate("ADDRESS.FORM.LABEL.NAME")}
            name="name"
            placeholder={translate("ADDRESS.FORM.PLACEHOLDER.NAME")}
            required={true}
            onChange={handleNameChange}
            value={name}
            error={!!errors.name}
            autoFocus={true}
            disabled={disableName}
            helperText={errors.name}
            />
            </div>
            <div>
            {/* Email */}
            <TextField
            type="email"
            margin="normal"
            variant="outlined"
            className="col-md-12"
            label={translate("ADDRESS.FORM.LABEL.EMAIL")}
            name="email"
            placeholder={translate("ADDRESS.FORM.PLACEHOLDER.EMAIL")}
            onChange={handleEmailChange}
            required={true}
            value={email}
            error={!!errors.email}
            disabled={disableEmail}
            helperText={errors.email}
            />
            </div>
        </>
        )
      }
      
    return {name, email, setName, setEmail, DisplayFields}
}
