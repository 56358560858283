import {CONSTANTS} from '../../utils'

function PAYMENT_METHOD_PAYLOAD_UPDATE(payment_methods) {
    let selected_payment_method = {};
    let _index=0;
    payment_methods &&
        payment_methods.map((payment_method) => {
            payment_method.index = _index;
            //add id ,form_type, index in add_new
            if(!Array.isArray(payment_method.instruments.add_new)){
                payment_method.instruments.add_new.id = payment_method?.id;
                payment_method.instruments.add_new.name = payment_method?.name;
                payment_method.instruments.add_new.form_type = payment_method?.form_type;
                payment_method.instruments.add_new.index = _index;
                if(CONSTANTS.PAYMENT_METHOD.FORM_TYPE.EASY_PAISA_ACCOUNT){
                    payment_method.instruments.add_new.customer_mobile_number=payment_method?.customer_mobile_number;
                }
            }
            if (payment_method.is_selected) {
                if(!Array.isArray(payment_method.instruments.add_new)){
                    selected_payment_method = payment_method?.instruments?.add_new;
                }
                else{
                    // payment_method?.instruments obj not required
                    // eslint-disable-next-line no-unused-vars
                    const {instruments, ...others} = payment_method
                    selected_payment_method = others;
                }
            }
            if(payment_method.instruments?.list?.length > 0){
                //add id ,form_type, index in add_new
                payment_method.instruments.add_new.id = payment_method?.id;
                payment_method.instruments.add_new.form_type = payment_method?.form_type;
                payment_method.instruments.add_new.index = _index;

                payment_method.instruments?.list.map(e => { 
                    //add _index in instruments value
                    _index=_index + 1
                    e.index=_index
                    //instructions added in jazzcash and easypaisa for differenciation
                    if(CONSTANTS.PAYMENT_METHOD.FORM_TYPE.EASY_PAISA_ACCOUNT){
                        e.instructions = payment_method?.instructions
                    }
                    if (e.is_selected) {
                        selected_payment_method = e;
                    }
                })
            }
            _index=_index + 1
            return null;
        });
    return {
        methods: payment_methods,
        selected_payment_method
    };
}

const APP_HELPER = {
    PAYMENT_METHOD_PAYLOAD_UPDATE
};

export default APP_HELPER;