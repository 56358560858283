import {
    GOOGLE_ANALYTICS_HELPER,
    // FIREBASE_ANALYTICS_HELPER,
    SEGMENT_ANALYTICS_HELPER,
    HELPER,
    CONSTANTS
} from "../../utils";
import {
    store
} from "../../store";

const OBJECT= {
    USER_ID: "user_id",
    META: "meta_properties",
    USER_PROPERTIES: "user_properties",
    INIT_APP: "init_app",
    NAVIGATE_APP: "navigate_app",
    PRODUCT: "product",
    CLICK: "click",
    DETAIL: "detail",
    PURCHASE_ITEM: {purchase: "purchase", addTransaction: "add_transaction", ecommerce: "ecommerce"},
    STEP_CHECKOUT: "checkout_step",

    CHECKOUT_STARTED: "Checkout Started",
    CHECKOUT_COMPLETED: "Checkout Completed",
    PAYMENT_FAILURE: "Payment Failure",
    VOUCHER_INPUT: "Voucher Input",
    VOUCHER_SUBMIT: "Voucher Submit",
    TERMS_POPUP: "Terms Popup Is Pressed",
    CNIC_INPUT: "CNIC Input",
    APP_EXCEPTION: "app_exception",
    CHECKOUT_LOAD_TIME: "Checkout load tracking",
}

function _initializeAnalytics() {
    // FIREBASE_ANALYTICS_HELPER._setUserSessionByID(order_ref);
}

function _initializeGAAnalytics(gaID) {
    GOOGLE_ANALYTICS_HELPER.initializeGA(gaID);
}

function _initializeSegmentAnalytics(segment_id, user_id) {
    SEGMENT_ANALYTICS_HELPER.initialize(segment_id)
    SEGMENT_ANALYTICS_HELPER._setUserSessionByID(user_id)
    // FIREBASE_ANALYTICS_HELPER._setUserSessionByID(user_id);
    GOOGLE_ANALYTICS_HELPER._setUserSessionByID(user_id)
}

function _setUserProperties() {
    const { name } = store.getState().configuration?.customer;
    let obj={
        name
    }
    // FIREBASE_ANALYTICS_HELPER._setUserProperties(obj)
    SEGMENT_ANALYTICS_HELPER._createEvent(OBJECT.USER_PROPERTIES,obj)
}

function _generalEventLog(event, options) {
    // FIREBASE_ANALYTICS_HELPER.createEvent(event, options)
    // LOAD GTM EVENT
    GOOGLE_ANALYTICS_HELPER.generalEventLog(event, options);
    SEGMENT_ANALYTICS_HELPER._createEvent(event ,options)
}

function _setPageView(route) {
    GOOGLE_ANALYTICS_HELPER.setPageView(route.view)
    // FIREBASE_ANALYTICS_HELPER.setPageView({
    //     path: !HELPER.isEmpty(route.path) ? route.path : route,
    //     component: !HELPER.isEmpty(route.component) ? route.component : route,
    //     pageTitle:  !HELPER.isEmpty(route.view) ? route.view : route,
    // })
    SEGMENT_ANALYTICS_HELPER.setPageView(route.view)
}

function _cartActions(product, quantity = 1, operation = CONSTANTS.CART.ACTIONS.ADD_QTY) {
    let state = store.getState();
    const { currency_code } = state.config.config;
    let action = "add_to_cart"
    if(operation === CONSTANTS.CART.ACTIONS.ADD_QTY){
        action = "add_to_cart"
    }else {
        action = "remove_from_cart"
    }
    let item = { ...product , item_qty: quantity, currency_code };
    // FIREBASE_ANALYTICS_HELPER.actionOnCart(item, action)
    SEGMENT_ANALYTICS_HELPER._createEvent(action, item)
}

function _initOrderLogging(order_ref) {
    // FIREBASE_ANALYTICS_HELPER.initOrderLogging(order_ref);
    GOOGLE_ANALYTICS_HELPER.initOrderLogging(order_ref);
    SEGMENT_ANALYTICS_HELPER._initOrderLogging(order_ref)
}

function _navigationEventLog(event, options){
    let obj = {
        navigation: event,
        action: options?.action,
        label: options?.label,
    }
    _createEventObj( OBJECT.NAVIGATE_APP, obj);
    // FIREBASE_ANALYTICS_HELPER.navigationEventLog(event, options);
    GOOGLE_ANALYTICS_HELPER.navigationEventLog(event, options)
}

function _productClick(productObj, position) {
    let obj={
        type: OBJECT.CLICK,
        'name': productObj.product_name,  
        'id': productObj.product_id,
        'price': productObj.product_total,
        'position': position
    }
    GOOGLE_ANALYTICS_HELPER._productClick(obj)
    SEGMENT_ANALYTICS_HELPER._createEvent(OBJECT.PRODUCT,obj)
}

function _productDetail(productObj) {
    let obj= {
        type: OBJECT.DETAIL,
        'name': productObj.product_name,                      // Name or ID is required.
        'id': productObj.product_id,
        'price': productObj.product_total,                 // Product variant (string).
        'position': 2
    }
    SEGMENT_ANALYTICS_HELPER._createEvent(OBJECT.PRODUCT,obj)
}


function _purchaseOrder(cart_details) {
    let state = store.getState();
    const { order_ref, config } = state.configuration;
    const { merchant_name } = config;

    const cart_items = cart_details?.items;
    const cartItems = cart_items.map((product, i) => {
        return ({
            position: i,
            name: product?.product_name,
            id: product?.product_id,
            price: product?.product_total,
            sku: product?.product_sku,
            quantity: product?.product_qty,
        })
    });
    let purchase= {
        id: order_ref, 
        affiliation: merchant_name, 
        revenue: cart_details?.summary?.total_amount, 
        shipping: cart_details?.summary?.shipment_charges,
        currencyCode: cart_details?.summary?.currency_code,
        analytic_currency_code: cart_details?.summary?.analytic_currency_code,
        products: cartItems
    }
    let addTransaction=
    {
        id: order_ref, // the same as for addItem to connect them
        affiliation: merchant_name, //store or affiliation name
        revenue: cart_details?.summary?.total_amount, // obviously it's price * quantity
        shipping: cart_details?.summary?.shipment_charges,
        currencyCode: cart_details?.summary?.currency_code,
        products: cartItems
    }
    let ecommerce= {
        purchase: {
            actionField: {
                id: order_ref,    // Transaction ID. Required for purchases and refunds.
                affiliation: merchant_name, //store or affiliation name
                revenue: cart_details?.summary?.total_amount, // Total transaction value (incl. tax and shipping)
                shipping: cart_details?.summary?.shipment_charges,
            },
            products: cartItems
        }
    }
    GOOGLE_ANALYTICS_HELPER._purchaseOrder(purchase, addTransaction, ecommerce)
}

function _checkoutSteps(step, name) {
    let obj= { step: step, option: name }
    GOOGLE_ANALYTICS_HELPER._checkoutSteps(step, name)
    SEGMENT_ANALYTICS_HELPER._createEvent( OBJECT.STEP_CHECKOUT , obj )
}

function _createEventObj(method, obj) {
    // FIREBASE_ANALYTICS_HELPER.createEvent(method,obj)
    if(method && typeof method === 'string'){
        SEGMENT_ANALYTICS_HELPER._createEvent( method, obj);
        GOOGLE_ANALYTICS_HELPER._createEvent( method, obj);
    }
}

function _checkoutStarted(cart_data) {
    const {items, value, currency, coupon } = cart_data
    _createEventObj(OBJECT.CHECKOUT_STARTED, {
        product_ids: items?.reduce((pre,cur) => cur.product_id + ', ' + pre, '').slice(0,-1),
        product_names: items?.reduce((pre,cur) => cur.product_name + ', ' + pre, '').slice(0,-1),
        no_of_products: items?.length,
        total_amount: value,
        currency: currency,
        coupon,
        // discount_amount: summary?.discount_amount,
        merchant_id: items?.merchant_id,
        products: items?.map(e => ({
            merchant_id: e.merchant_id,
            quantity: e.product_qty,
            product_id: e.product_id,
            product_name: e.product_name,
            store_name: e.store?.name,
            store_id: e.store.store_id,
            product_price: e.product_price,
            product_discount:  e.product?.selected_variant?.discount,
            // discounted_price: e.discounted_price,
            product_image:  e.product_image,
            product_sku:   e.product_sku,
            product_retail_price:  e.product_sale_price,
            variant_id: e?.variant_id,
            // variant_name:  e?.variant_name,
            attributes:  !HELPER.isEmpty(e?.product_attributes_raw) ? Object.values(e?.product_attributes_raw)?.map(e => ({
              name: e.name,
              option_value: e.value[0]?.name,
            })) : [],
          }))
    })
}

function _checkoutCompleted(response, cart_details, voucher) {
     const {items, summary, count} = cart_details
    _createEventObj(OBJECT.CHECKOUT_COMPLETED, {
        order_id: response.order_id,
        payment_mode: response.payment_gateway_name,
        merchant_name: response.merchant_name,
        product_discount: response?.discount,
        voucher: voucher?.error ? "" : voucher?.code,

        product_ids: items?.reduce((pre,cur) => cur.product_id + ', ' + pre, '').slice(0,-1),
        product_names: items?.reduce((pre,cur) => cur.product_name + ', ' + pre, '').slice(0,-1),
        no_of_products: count,
        total_amount: summary?.total_amount,
        currency_code: summary?.currency_code,
        analytic_currency_code: summary?.analytic_currency_code,
        discount_amount: summary?.discount_amount,
        sub_total_amount: summary?.sub_total_amount,
        shipment_charges: summary?.shipment_charges,
        merchant_id: items?.merchant_id,
        products: items?.map(e => ({
            merchant_id: e.merchant_id,
            quantity: e.product_qty,
            product_id: e.product_id,
            product_name: e.product_name,
            store_name: e.store?.name,
            store_id: e.store.store_id,
            product_price: e.product_price,
            product_discount:  e.product?.selected_variant?.discount,
            // discounted_price: e.discounted_price,
            product_image:  e.product_image,
            product_sku:   e.product_sku,
            product_retail_price:  e.product_sale_price,
            variant_id: e?.variant_id,
            // variant_name:  e?.variant_name,
            attributes:  !HELPER.isEmpty(e?.product_attributes_raw) ? Object.values(e?.product_attributes_raw)?.map(e => ({
              name: e.name,
              option_value: e.value[0]?.name,
            })) : [],
          }))
    })
}

function _paymentFailure(message) {
    const { cart} = store.getState()
    _createEventObj(OBJECT.PAYMENT_FAILURE, {
        total_amount: cart.cart_details.summary?.total_amount,
        payment_mode: cart?.payment_method?.name,
        reason:  Array.isArray(message) ? message[0] : message,
    })
}


function _generalEventG_F(event, options) {
    SEGMENT_ANALYTICS_HELPER._createEvent(event, options)
    // FIREBASE_ANALYTICS_HELPER.createEvent(event, options)
    // LOAD GTM EVENT
    GOOGLE_ANALYTICS_HELPER.generalEventLog(event, options);
}

function _cartSuccess(data) {
    data?.shipment_method?.name && _createEventObj("add_shipping_info", {shipping_tier: data?.shipment_method?.name});
    data?.payment_method?.name && _createEventObj("add_payment_info", {payment_type: data?.payment_method?.name?.split("(")[0]});
}

function _voucherSubmit() {
    _createEventObj(OBJECT.VOUCHER_SUBMIT,{})
}

function _voucherInput() {
    _createEventObj(OBJECT.VOUCHER_INPUT,{})
}

function _termPopup() {
    _createEventObj(OBJECT.TERMS_POPUP,{})
}

function _cnicInput() {
    _createEventObj(OBJECT.CNIC_INPUT,{})
}
   
function _appException(message, error = {}) {
    _createEventObj(OBJECT.APP_EXCEPTION, { message: HELPER.isNotEmpty(message) ? message : error})
}

function _checkoutLoad(checkout_load_time) {
    _createEventObj(OBJECT.CHECKOUT_LOAD_TIME, {time: checkout_load_time})
}

const ANALYTICS_HELPER = {
    _initializeAnalytics,
    _initializeGAAnalytics,
    _initializeSegmentAnalytics,

    _setUserProperties,
    _setPageView,
    _cartActions,
    _generalEventLog,
    _generalEventG_F,
    _initOrderLogging,
    _navigationEventLog,

    _productClick,
    _productDetail,
    _purchaseOrder,
    _checkoutSteps,

    _checkoutCompleted,
    _paymentFailure,
    _checkoutStarted,
    _createEventObj,

    _cartSuccess,
    _voucherSubmit,
    _voucherInput,
    _termPopup,
    _cnicInput,
    _appException,
    _checkoutLoad,
};
export default ANALYTICS_HELPER;