// @flow
//eslint-disable-next-line

import React from "react";
import { GoogleMap } from "../../../containers";

function MapComponent() {

  return (
    <div className="FullScreenGmapWrapper ">
        <GoogleMap isFullScreen={true}/>
    </div>
  );
}

export default MapComponent;
