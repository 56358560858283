import {
  FORM_CONSTANTS,
  ANALYTICS_CONSTANTS
} from "../actionTypes";

export const formAction = {
  UPDATE_COUNTRY,
  UPDATE_COUNTRY_LIST,
  UPDATE_PHONE_NUMBER,
  UPDATE_CUSTOMER_NAME,
  UPDATE_CUSTOMER_EMAIL,
  SHOW_PLEASE_WAIT_LOADER,
  SHOW_LOADER,
  HIDE_LOADER,
  MAINTAIN_ROUTER_HISTORY,
  NO_INTERNET_CONNECTION,
  CHECKOUT_STEPS_TRIGGER
};

function NO_INTERNET_CONNECTION(state) {
  return (dispatch) => {
    dispatch(request(state));
  };

  function request(state) {
    return { type: FORM_CONSTANTS.NO_INTERNET_CONNECTION, state };
  }
}

function SHOW_PLEASE_WAIT_LOADER() {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: FORM_CONSTANTS.SHOW_PLEASE_WAIT_LOADER };
  }
}

function SHOW_LOADER() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: FORM_CONSTANTS.SHOW_LOADER };
  }
}

function HIDE_LOADER() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: FORM_CONSTANTS.HIDE_LOADER };
  }
}

function UPDATE_COUNTRY_LIST(response) {
  return (dispatch) => {
    dispatch(request(response));
  };

  function request(response) {
    return { type: FORM_CONSTANTS.UPDATE_COUNTRY_LIST, response };
  }
}

function UPDATE_COUNTRY(response) {
  return (dispatch) => {
    dispatch(request(response));
  };

  function request(response) {
    return { type: FORM_CONSTANTS.UPDATE_COUNTRY, response };
  }
}

function UPDATE_PHONE_NUMBER(response) {
  return (dispatch) => {
    dispatch(request(response));
  };

  function request(response) {
    return { type: FORM_CONSTANTS.UPDATE_PHONE_NUMBER, response };
  }
}

function UPDATE_CUSTOMER_NAME(response) {
  return (dispatch) => {
    dispatch(request(response));
  };

  function request(response) {
    return { type: FORM_CONSTANTS.UPDATE_CUSTOMER_NAME, response };
  }
}


function UPDATE_CUSTOMER_EMAIL(response) {
  return (dispatch) => {
    dispatch(request(response));
  };

  function request(response) {
    return { type: FORM_CONSTANTS.UPDATE_CUSTOMER_EMAIL, response };
  }
}

function MAINTAIN_ROUTER_HISTORY(route) {
  return (dispatch) => {
    dispatch(request(route));
  };

  function request(route) {
    return { type: FORM_CONSTANTS.MAINTAIN_ROUTER_HISTORY, route };
  }
}


function CHECKOUT_STEPS_TRIGGER(step) {
  return (dispatch, getState) => {
    const { checkoutStep } = getState().analytics; 
    if(checkoutStep < step?.STEP){
      dispatch({ type: ANALYTICS_CONSTANTS.CHECKOUT_STEP, step });
    }else {
      false
    }
  };
}
