import {
  ACTION_CONSTANTS,
  CONFIG_CONSTANTS,
  CUSTOMER_CONSTANTS,
  NETWORK_CONSTANTS,
} from "../../store/actionTypes";
import { HELPER, LOCAL_STORAGE_SERVICE } from "../../utils";
import ANALYTICS_HELPER from "../../utils/analytics/general";

const initState = {
  customerIdentifySend: false,
  customer_identifier: false,

  initiateSend: false,

  userIdentified: null, // this variable will identify whether orderInitiate API is to be called if user is not identified?
  order_ref: null,
  redirect: false,

  sendOTP: false,
  retryOtp: false,
  disableOTPTimer: false,
  isPhoneDialog: false,

  sendPhoneVerification: false,
  breadcrumbSettings: {
    disableClick: false,
  },

  config: {
    default_lang: "",
    desktop_logo_url: "",
    fav_icon_url: "",
    gtm_id: "",
    is_pin_location_mandatory: 0,
    is_international_shipment: 0,
    international_countries: "",
    merchant_name: "",
    mobile_logo_url: "",
    otp_timer: 0,
    theme_colour: process.env.REACT_APP_DEFAULT_APP_THEME_COLOR,
    store_url: "",
    is_customer_app: 0,
    user_journey: "",
    allow_order_cancellation: 0,
    cancel_url: "",
  },
  customer: {
    name: "",
    country_code: "",
    network_code: "",
    phone_number: "",
    network_name: "",
    network_id: "",
    email: "",
    session_id: "",
    user_id: "",
    has_multiple_phone_numbers: 0,
    other_phone_numbers: [],
  },
  profileLoading: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case CONFIG_CONSTANTS.CUSTOMER_IDENTIFIER_DELETE:
      return {
        ...state,
        customerIdentifySend: false,
        customer_identifier: false,
      };
    // VALIDATE TOKEN
    case CONFIG_CONSTANTS.IDENTIFY_CUSTOMER_VALIDATION_REQUEST:
      return {
        ...state,
        order_ref: action.reference_token,
        customerIdentifySend: true
      };
    case CONFIG_CONSTANTS.IDENTIFY_CUSTOMER_VALIDATION_SUCCESSFULL:
      LOCAL_STORAGE_SERVICE._saveToLocalStorage("locale", action?.data?.locale);
      return {
        ...state,
        customerIdentifySend: true,
        userIdentified: action?.data?.user_identified,
        customer_identifier: action?.data?.user_details?.customer_identifier,
        customer: {
          ...state.customer,
          name: !HELPER.isEmpty(action?.data?.user_details?.user_name) ? action?.data?.user_details?.user_name : "Guest",
          phone_number: action?.data?.user_details?.phone_number,
        },
        config: {
          ...state.config,
          merchant_name: action?.data?.config?.merchant_name,
          default_lang: action?.data?.config?.default_lang,
          desktop_logo_url: action?.data?.config?.desktop_logo_url,
          mobile_logo_url: action?.data?.config?.mobile_logo_url,
          fav_icon_url: action?.data?.config?.fav_icon_url,
          gtm_id: action?.data?.config?.gtm_id,
          ga_id: action?.data?.config?.ga_id,
          theme_colour: action?.data?.config?.theme_colour,
        }
      };
    case CONFIG_CONSTANTS.IDENTIFY_CUSTOMER_VALIDATION_UNSUCCESSFULL:
      return {
        ...state,
        userIdentified: action?.data?.user_identified,
        customer_identifier: false,
        customerIdentifySend: false,
        config: {
          ...state.config,
          theme_colour: action?.data?.config?.theme_colour,
        }
      };
    case CONFIG_CONSTANTS.IDENTIFY_CUSTOMER_VALIDATION_FAILURE:
      return {
        ...state,
        userIdentified: 0,
        customer_identifier: false,
        customerIdentifySend: false,
      };
    case CONFIG_CONSTANTS.INITIATE_ORDER_REQUEST:
      return {
        ...state,
        initiateSend: true
      };
    case CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_SUCCESS:
      return {
        ...state,
        order_ref: action.reference_token,
        redirect: true,
        initiateSend: false,
        customer_identifier: false,
        config: {
          ...state.config,
          default_lang: action?.response?.config?.default_lang,
          desktop_logo_url: action?.response?.config?.desktop_logo_url,
          fav_icon_url: action?.response?.config?.fav_icon_url,
          gtm_id: action?.response?.config?.gtm_id,
          ga_id: action?.response?.config?.ga_id,
          is_pin_location_mandatory: action?.response?.order_config?.address?.is_pin_location_mandatory,
          is_international_shipment: action?.response?.order_config?.address?.international_shipment?.enabled,
          international_countries: action?.response?.order_config?.address?.international_shipment?.enabled_countries,
          merchant_name: action?.response?.config?.merchant_name,
          mobile_logo_url: action?.response?.config?.mobile_logo_url,
          otp_timer: parseInt(action?.response?.config?.otp_timer),
          theme_colour: action?.response?.config?.theme_colour,
          store_url: action?.response?.config?.store_url,
          is_customer_app: action?.response?.config?.is_customer_app,
          segment_id: action?.response?.config?.segment_id,
          user_journey: action?.data?.app_setup?.user_journey,
          allow_order_cancellation: action?.response?.config?.allow_order_cancellation,
          cancel_url: action?.response?.config?.cancel_url,
        },
        customer: {
          ...state.customer,
          name: !HELPER.isEmpty(action?.response?.customer?.customer_name) ? action?.response?.customer?.customer_name : "Guest",
          phone_number: action?.response?.customer?.phone_number,
          network_name: null,
          network_id: null,
          email: "",
          session_id: action?.response?.customer?.session_id,
          user_id: action?.response?.customer?.user_id,
          has_multiple_phone_numbers: action?.response?.customer?.has_multiple_phone_numbers,
          other_phone_numbers: action?.response?.customer?.other_phone_numbers,
        },
      };
    case CONFIG_CONSTANTS.INITIATE_ORDER_SUCCESS:
      LOCAL_STORAGE_SERVICE._saveToLocalStorage("screen", 1);
      LOCAL_STORAGE_SERVICE._updateAccessToken(action?.response?.customer?.access_token);
      ANALYTICS_HELPER._checkoutLoad(action?.response?.order_config?.checkout_load_time)
      return {
        ...state,
        order_ref: action.reference_token,
        redirect: true,
        initiateSend: true,
        customer_identifier: false,
        config: {
          ...state.config,
          default_lang: action?.response?.config?.default_lang,
          desktop_logo_url: action?.response?.config?.desktop_logo_url,
          fav_icon_url: action?.response?.config?.fav_icon_url,
          gtm_id: action?.response?.config?.gtm_id,
          ga_id: action?.response?.config?.ga_id,
          is_pin_location_mandatory: action?.response?.order_config?.address?.is_pin_location_mandatory,
          is_international_shipment: action?.response?.order_config?.address?.international_shipment?.enabled,
          international_countries: action?.response?.order_config?.address?.international_shipment?.enabled_countries,
          merchant_name: action?.response?.config?.merchant_name,
          mobile_logo_url: action?.response?.config?.mobile_logo_url,
          otp_timer: parseInt(action?.response?.config?.otp_timer),
          theme_colour: action?.response?.config?.theme_colour,
          store_url: action?.response?.config?.store_url,
          is_customer_app: action?.response?.config?.is_customer_app,
          segment_id: action?.response?.config?.segment_id,
          user_journey: action?.data?.app_setup?.user_journey,
          allow_order_cancellation: action?.response?.config?.allow_order_cancellation,
          cancel_url: action?.response?.config?.cancel_url,
        },
        customer: {
          ...state.customer,
          name: !HELPER.isEmpty(action?.response?.customer?.customer_name) ? action?.response?.customer?.customer_name : "Guest",
          phone_number: action?.response?.customer?.phone_number,
          network_name: null,
          network_id: null,
          email: "",
          session_id: action?.response?.customer?.session_id,
          user_id: action?.response?.customer?.user_id,
          has_multiple_phone_numbers: action?.response?.customer?.has_multiple_phone_numbers,
          other_phone_numbers: action?.response?.customer?.other_phone_numbers,
        },
      };
    case ACTION_CONSTANTS.ORDER_EXPIRED:
      return {
        ...state,
        config: {
          ...state.config,
          store_url: HELPER.isEmpty(action?.data?.store_url) ? state.config.store_url : action?.data?.store_url,
        },
      };
    case CONFIG_CONSTANTS.INITIATE_ORDER_FAILURE:
    case CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_FAILURE:
      return {
        ...state,
        initiateSend: false
      };
    //verify phone
    case CONFIG_CONSTANTS.PHONE_VERIFICATION_REQUEST:
      return {
        ...state,
        sendPhoneVerification: true,
        config: {
          ...state.config,
          otp_timer: 0,
        },
      };
    case CONFIG_CONSTANTS.PHONE_VERIFICATION_SUCCESS:
      return {
        ...state,
        sendPhoneVerification: false,
        customer: {
          ...state.customer,
          phone_number: action?.response?.otp?.phone_number,
        },
        config: {
          ...state.config,
          otp_timer: parseInt(action?.response?.otp_timer),
        },
        isPhoneDialog: false
      };
    case CONFIG_CONSTANTS.MULTI_PHONE_DIALOG:
        return {
          ...state,
          isPhoneDialog: action.bool
        };  
    case CONFIG_CONSTANTS.PHONE_VERIFICATION_FAILURE:
      return {
        ...state,
        sendPhoneVerification: false
      };
    // VERIFY OTP
    case CONFIG_CONSTANTS.OTP_VERIFY_REQUEST:
      return {
        ...state,
        sendOTP: true,
      };
    case CONFIG_CONSTANTS.OTP_VERIFY_SUCCESS:
      LOCAL_STORAGE_SERVICE._setFirstScreen()
      return {
        ...state,
        sendOTP: false,
        user_journey: action?.data?.app_setup?.user_journey,
      };
    case CONFIG_CONSTANTS.OTP_VERIFY_FAILURE:
      return { ...state, sendOTP: false };
    // RESEND OTP
    case CONFIG_CONSTANTS.OTP_RETRY_REQUEST:
      return {
        ...state,
        retryOtp: true
      };
    case CONFIG_CONSTANTS.OTP_RETRY_SUCCESS:
      return {
        ...state,
        retryOtp: false,
      };
    case CONFIG_CONSTANTS.OTP_RETRY_FAILURE:
      return {
        ...state,
        disableOTPTimer: true,
        retryOtp: false,
      };
    case CONFIG_CONSTANTS.DISABLE_OTP_TIMER:
      return {
        ...state,
        breadcrumbSettings: {
          ...state.breadcrumbSettings,
          disableClick: action?.state,
        },
      };
    // SEND OTP

    //CUSTOMER PROFILE

    // VERIFY OTP
    case CUSTOMER_CONSTANTS.PROFILE_REQUEST:
      return { ...state,  profileLoading: true, };
    case CUSTOMER_CONSTANTS.PROFILE_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          name: !HELPER.isEmpty(action.data?.name) ? action.data?.name : "Guest",
          country_code: action.data?.country_code,
          network_code: action.data?.network_code,
          phone_number: action.data?.phone,
          email: action.data?.email
        },
        profileLoading: false,
      };
    case CUSTOMER_CONSTANTS.PROFILE_FAILURE:
      return { ...state, profileLoading: false };
   
    case CUSTOMER_CONSTANTS.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          name: action.data?.name,
          email: action.data?.email
        },
      };

    case NETWORK_CONSTANTS.NETWORK_UPDATE_REQUEST:
      return {
        ...state,
        customer: {
          ...state.customer,
          network_id: action.network_id,
          network_name: action.network_name,
        },
      };
    default:
      return state;
  }
};
export default authReducer;
