import {
  ANALYTICS_CONSTANTS
} from "../actionTypes";


const initState = {
  checkoutStep: "",
  checkoutStepName: ""
};

const analyticsReducer = (state = initState, action) => {
  switch (action.type) {
    case ANALYTICS_CONSTANTS.CHECKOUT_STEP:
      return {
        ...state,
        checkoutStep: action.step?.STEP,
        checkoutStepName: action.step?.NAME
      }
    default:
      return state;
  }
};

export default analyticsReducer;
