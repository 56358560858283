// @flow
//eslint-disable-next-line

import React from "react";

// import { CheckoutWrapper } from "../../../components";
import CheckoutWrapper from "../../../components/CheckoutWrapper";

import { BREADCRUMB_SETTINGS_JSON } from "../../../utils";
import AddressForm from "./form";

function AddNewAddress() {

  // This functional component specifies to show map component and contain add new address form
  return (
    <CheckoutWrapper
      fullscreenModeEnabled={false}
      showMap={true}
      child={<AddressForm />}
      breadcrumbSettings={BREADCRUMB_SETTINGS_JSON.CHECKOUT}
    />
  );
}

export default AddNewAddress;
