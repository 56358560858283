// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import InfoIcon from "@material-ui/icons/Info";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { IconButton } from "@material-ui/core";
import  HtmlTooltip from "../../../../components/HtmlTooltip";
import DBTToolTipText from "../../../../components/DBTToolTipText";
import {
  CONSTANTS,
  HELPER
} from "../../../../utils";

function DBTTransactionSlip({ saveScreenshotTitle }) {
  const translate = useTranslate();
  const { locale } = useSelector(state => state.Intl);
  const { merchant_name } = useSelector(state => state.configuration.config);
  const { cart_details, merchant_bank_details, order_id } = useSelector(state => state.cart);
  const { currency_code, total_amount } = cart_details.summary;

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    saveScreenshotTitle(merchant_name + " bSecure " + order_id);
    // returned function will be called on component unmount
    return () => {};
  });


  return (
    <>
      {" "}
      <div className="transactionSlipDetails">
        <div className="amountPay">
          <h4>{translate("transation_reciept_heading")}</h4>
          <h2>
            {HELPER.showPrice(currency_code, total_amount ?? 0)}
          </h2>
        </div>
        <div className="transaction-detail">
          <h4>{translate("dbt_account_number")}</h4>
          <h5>{merchant_bank_details?.bank_account_number}</h5>
        </div>
        <div className="transaction-detail text-black">
          <h4>{translate("dbt_account_title")}</h4>
          <h5>{merchant_bank_details?.bank_account_title}</h5>
        </div>
        <div className="transaction-detail text-black">
          <h4>{translate("dbt_iban_number")}</h4>
          <h5>{merchant_bank_details?.iban_number}</h5>
        </div>
        <div className="transaction-detail text-black">
          <h4>{translate("dbt_branch_code")}</h4>
          <h5>{merchant_bank_details?.bank_branch_code}</h5>
        </div>
        <p>
          {locale === CONSTANTS.IS_URDU ? (
            ""
          ) : (
            <strong>
              <DBTToolTipText
                merchant_bank_details={merchant_bank_details}
                showBankDetails={false}
                showCustomMsg={false}
              />
            </strong>
          )}
        </p>
        <p>
          <p>
            <span>
              <IconButton
                aria-label="delete"
                color="primary"
                disableFocusRipple={true}
              >
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 1000 }}
                  overlay={HtmlTooltip}
                  popperConfig={{
                    props: {
                      state: CONSTANTS.CHECKOUT_INFO.PAYMENT_METHOD.TOOLTIP.DBT,
                      merchant_bank_details,
                    },
                  }}
                >
                  <InfoIcon />
                </OverlayTrigger>
              </IconButton>
            </span>
            <span>{translate("contact_detail")}</span>
          </p>
        </p>
      </div>
    </>
  );
}

export default DBTTransactionSlip;
