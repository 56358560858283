// @flow
//eslint-disable-next-line
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import queryString from "query-string";
import AppWrapper from "../../components/App";
import MetaTags from "../../components/MetaTags";
import Home from '../../components/Home'
import {
  verificationAction,
  errorClientAccess,
  catalogAction,
} from "../../store/actions";
import {
  BREADCRUMB_SETTINGS_JSON,
  HELPER,
  LOCAL_STORAGE_SERVICE,
  ROUTE_CONSTANTS,
  history,
  ANALYTICS_HELPER
} from "../../utils";

// import SplashJson from "./loadingLottie.json"
// import Lottie from 'react-lottie';

let timeoutTime

const UniversalHomeConfiguration = () => {
  // const defaultOptions = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: SplashJson,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     }
  //   };

  return ( <>
    <div className="screen midWrap bg-white d-none">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="homeLoading">
                <div className="heading">
                {/* <Lottie 
                  options={defaultOptions}
                  width={250}
                  height={250}
                /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>)
}


const HomeConfiguration = ({order_ref=""}) => {
  return (
    (
      location.pathname === ROUTE_CONSTANTS.CHECKOUT ||
      location.pathname === ROUTE_CONSTANTS.PAYMENT_CHECKOUT ||
      location.pathname === ROUTE_CONSTANTS.INVOICE_CHECKOUT
    ) ? <Home order_ref={order_ref}/> : <UniversalHomeConfiguration />
  )
}
function ConfigurationWrapper() {
  let dispatch = useDispatch();

  const {
    userIdentified,
    customerIdentifySend,
    initiateSend,
    customer_identifier,
  } = useSelector(state => state.configuration);

  const {
    location,
  } = history;


  // Save query string parameters
  let params = queryString.parse(location.search);
  const order_ref = params?.ref;

  //componentDidMount
  useEffect(() => {
    //If order is of type: APP 
    if (
      location.pathname === ROUTE_CONSTANTS.CHECKOUT && 
      HELPER.isNotEmpty(order_ref)
    ) {
      ANALYTICS_HELPER._initializeAnalytics(order_ref);
      LOCAL_STORAGE_SERVICE._clearLocalStorage();
      const tokenExists = LOCAL_STORAGE_SERVICE._getAccessToken();
      // on app load check if any access_token exists in browser and customer_identifier param does not exists this means identifyCustomer api is to be called
      if (
        !HELPER.isEmpty(tokenExists) &&
        HELPER.isEmpty(customer_identifier) &&
        !customerIdentifySend &&
        !initiateSend
      ) {
        // if yes, call api get-token-info (pass current access token & order_ref)
        dispatch(verificationAction.IDENTIFY_CUSTOMER_TOKEN(order_ref));
      }
      // on app load check if any customer_identifier param does not exists this means orderInitiate api is to be called without parameter: customer_identifier
      else if (
        HELPER.isEmpty(tokenExists) &&
        HELPER.isEmpty(customer_identifier) &&
        HELPER.isNotEmpty(order_ref) &&
        !initiateSend &&
        !customerIdentifySend
      ) {
        dispatch(verificationAction.INITIATE_ORDER(order_ref, null));
      }
      // on app load check if any customer_identifier param exists this means orderInitiate api is to be called with parameter: customer_identifier
      else if (
        !HELPER.isEmpty(customer_identifier) &&
        HELPER.isNotEmpty(order_ref) &&
        !initiateSend
      ) {
        dispatch(verificationAction.INITIATE_ORDER(order_ref, customer_identifier));
      }
    }else if (
      (
        location.pathname === ROUTE_CONSTANTS.UNIVERSAL_CHECKOUT ||
        location.pathname === ROUTE_CONSTANTS.PAYMENT_CHECKOUT
      ) &&
      HELPER.isNotEmpty(order_ref) &&
      !initiateSend
    ) {
      ANALYTICS_HELPER._initializeAnalytics(order_ref);
      LOCAL_STORAGE_SERVICE._clearLocalStorage();
      // on app load check if any access_token exists in browser and customer_identifier param does not exists this means identifyCustomer api is to be called
        dispatch(verificationAction.INITIATE_ORDER(order_ref, null));
    }
    //If order is of type: PRODUCT_LINK
    else if (
      location.pathname === ROUTE_CONSTANTS.BUY_NOW && 
      HELPER.isNotEmpty(order_ref)
    ) {
      ANALYTICS_HELPER._initializeAnalytics(order_ref);
      const product_ref = params?.product_ref;
      if (HELPER.isEmpty(product_ref)) {
        const state = {
          code: 400,
          description: "Product not found",
        };
        dispatch(errorClientAccess(state, history));
      } else {
        dispatch(catalogAction?.initiate_product_catalogue(product_ref, history));
      }
    }
    //If order is of type: PRODUCT_LINK
    // else if (location.pathname === ROUTE_CONSTANTS.INVOICE_CHECKOUT && HELPER.isEmpty(source)) {
    //   timeoutTime = setTimeout(() => {
    //     window.location.assign(`${ROUTE_CONSTANTS.CHECKOUT}?ref=${order_ref}`);
    //   }, 3000);
    //   timeoutTime = setTimeout(() => {
    //     clearTimeout(timeoutTime);
    //   }, 3010);
    // }
    // returned function will be called on component unmount
    return () => {
      clearTimeout(timeoutTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      userIdentified === 0 &&
      !customerIdentifySend &&
      !initiateSend &&
      (
        location.pathname === ROUTE_CONSTANTS.CHECKOUT ||
        location.pathname === ROUTE_CONSTANTS.UNIVERSAL_CHECKOUT ||
        location.pathname === ROUTE_CONSTANTS.PAYMENT_CHECKOUT
      ) &&
      HELPER.isNotEmpty(order_ref)
    ) {
      // User is not identified thus call order initiate api without customer_identifier parameter
      dispatch(verificationAction.INITIATE_ORDER(order_ref, null, history));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdentified, customerIdentifySend, initiateSend]);

  return (
    <>
      {(
        location.pathname === ROUTE_CONSTANTS.CHECKOUT ||
        location.pathname === ROUTE_CONSTANTS.UNIVERSAL_CHECKOUT ||
        location.pathname === ROUTE_CONSTANTS.PAYMENT_CHECKOUT
      ) ? <MetaTags /> : ""}
      <AppWrapper
        showMerchantLogo={false}
        showAccountDropdown={false}
        showLangIcon={false}
        allowProfileAPI={false}
        showPoweredBy={true}
        showFooter={false}
        breadcrumb={{
            type: BREADCRUMB_SETTINGS_JSON.DEFAULT.TYPE,
            options: BREADCRUMB_SETTINGS_JSON.DEFAULT,
            show: BREADCRUMB_SETTINGS_JSON.DEFAULT.SHOW,
        }}
        homePageHeader={true}
      >
        {location.pathname === ROUTE_CONSTANTS.INVOICE_CHECKOUT ? <HomeConfiguration order_ref={order_ref} /> : <HomeConfiguration />}
      </AppWrapper>
    </>
  );
}

export default ConfigurationWrapper;
