// @flow

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { TextField, Paper } from "@material-ui/core";

import {
  CONSTANTS,
  ANALYTICS_HELPER,
  GOOGLE_ANALYTICS_HELPER,
  HELPER
} from "../../../utils";
import { cartAction } from "../../../store/actions";

const maxCharLimit = 30;

function CheckoutVoucherComponent() {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const { locale } = useSelector(state => state.Intl);
  const { voucher, voucherRequest, vouchers } = useSelector(state => state.cart);
  const { voucher_details, currency_code, discount_breakdown, } = useSelector(state => state.cart.cart_details.summary);

  const [vouchCode, setVouchCode] = useState("");

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    setVouchCode(voucher?.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vouchCode !== voucher?.code) {
      setVouchCode(voucher?.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher]);

  const handleChange = (e) => {
    let value = e.target.value;
    //If text-field doesn't return any value then set @string :vouchCode as voucher code value and call api
    if (
      value !== null &&
      Math.max(0, parseInt(value)).toString().slice(0, maxCharLimit)
    ) {
      dispatch(cartAction?.update_voucher_code(value));
    } else {
      dispatch(cartAction?.update_voucher_code(vouchCode));
    }
    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.REVIEW,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.REVIEW.VOUCHER.INPUT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.REVIEW.VOUCHER.INPUT.LABEL,
        properties: {}
      }
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    let requestData = {
      voucher_code: vouchCode,
    };
    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.REVIEW,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.REVIEW.VOUCHER.SUBMIT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.REVIEW.VOUCHER.SUBMIT.LABEL,
        properties: {
          voucher: requestData
        }
      }
    );
    ANALYTICS_HELPER._voucherSubmit()
    dispatch(cartAction?.submit_voucher_code(requestData));
  };

  /*
   * @boolean voucher?.error : voucher api error state
   * @string vouchCode : functionanl component state
   */

  const errorClass = voucherRequest
    ? " success"
    : voucher?.error
      ? " danger"
      : vouchCode !== ""
        ? " primary"
        : "";

  const errorDir =
    locale === CONSTANTS.IS_ENGLISH
      ? "vouchAdornment"
      : "vouchAdornment rtl-position";

  const VoucherApplyBtn = (
    <>
    {!voucherRequest ? 
        <button
            type="button"
            className={"btn btn-primary btn-block btn-voucher"}
            id="voucher-apply"
            onClick={handleSubmit}
          >
            {translate("apply_btn")}
          </button>
          :
          <span
            id="voucher-apply"
            className={errorDir + " " + errorClass}
          >
            {translate("applied_btn")}
          </span>
      }
    </>
   
  );

  /*
   * Define inputProps position
   */
  const InputProps =
    locale === CONSTANTS.IS_ENGLISH
      ? {
        endAdornment: VoucherApplyBtn,
      }
      : {
        startAdornment: VoucherApplyBtn,
      };
  /*
   * @boolean voucherRequest : voucher api state
   * @string voucher?.errorMessage : voucher api error message
   * @boolean voucher?.error : voucher api error state
   * @string vouchCode : functionanl component state
   */

  const textFieldAdditionalClasses =
    voucherRequest &&
      voucher?.errorMessage !== "" &&
      !voucher?.error &&
      vouchCode !== ""
      ? " success"
      : voucher?.error
        ? " danger"
        : vouchCode === ""
          ? " secondary"
          : "";
  const vouchTextFieldStyle = "col-md-12 col-sm-12 col-xs-12 voucher_code";

  const SaveDiscountVoucher = ({ discount }) => {
    let fixed_discount = HELPER.formatPrice(currency_code, discount?.value ?? 0).default + " " + translate("CART.OFF")
    let percentage_discount = HELPER.formatPrice(currency_code, discount?.value ?? 0).charges + "% " + translate("CART.OFF") + (discount?.cap_amount ? translate("CART.UPTO") + discount?.amount : "") 
    return(
    <div className="vouchRedeemed receiptInfo">
      <div className="receiptSub">
        <span className="left">
          <div>
            <p>
              <span className="discount-tag icomoon-discount"></span>
                <span dir="ltr">{CONSTANTS.DISCOUNT_TYPE[1] === CONSTANTS.DISCOUNT_TYPE[discount?.type] ? fixed_discount : percentage_discount}</span>
            </p>
          </div>
        </span>
          <span className="right">SAVE {discount?.amount}</span>
      </div>
      </div>
    )
  }

  const applyMyVoucher= (code, voucherify_voucher_id=null) =>{
    dispatch(cartAction?.update_voucher_code(code,voucherify_voucher_id));
  }

  return (
    <div className="voucher">
      <TextField
        label={translate("voucher_label")}
        fullWidth={true}
        type="text"
        margin="normal"
        variant="outlined"
        focused
        className={vouchTextFieldStyle + textFieldAdditionalClasses}
        name="voucher"
        placeholder={translate("voucher_placeholder")}
        onChange={handleChange}
        autoFocus={false}
        key={2}
        value={vouchCode}
        error={voucher?.error ?? false}
        helperText={voucher?.errorMessage ?? false}
        autoComplete="new-password"
        inputProps={{
          autoComplete: "new-password",
          form: {
            autoComplete: "off",
            maxLength: maxCharLimit,
          },
          maxLength: maxCharLimit,
          id: "voucher-field",
        }}
        InputProps={InputProps}
        onKeyPress={handleKeyPress}
        onClick={()=> ANALYTICS_HELPER._voucherInput()}
      />
      {(voucher_details || discount_breakdown.length > 0) &&
        <Paper className="dialog mt-3 d-none">
          <div className="row">
            <div className="col-12">
            {voucher_details && <SaveDiscountVoucher discount={voucher_details} />}
            {discount_breakdown.map((discount, index) => <SaveDiscountVoucher discount={discount} key={index}/>)}
            </div>
          </div>
        </Paper>
      }

      {vouchers?.length > 0 &&
        <div className="my-vouchers-container">
        <Paper className="dialog mt-3 ">
            {vouchers.map((e,index) =>(
              <>
              {voucher.voucherify_voucher_id === e.id ?
                <></>
                :
              <div className="vouchRedeemed receiptInfo" key={index} onClick={()=>applyMyVoucher(e.code,e?.id)}>
              <div className="receiptSub">
                <span className="left">
                  <div>
                    <p>
                      <span className="discount-tag icomoon-discount"></span>
                        <span dir="ltr">{e.name}</span>
                    </p>
                  </div>
                </span>
                  <span className="right">SAVE {e?.amount}</span>
              </div>
              </div>
              }
              </>
            ))}
        </Paper>
        </div>
      }
    </div>
  );
}
export default CheckoutVoucherComponent;
