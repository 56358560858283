import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

import { IntlReducer as Intl } from "react-redux-multilingual";

import alertReducer from "./alert.reducer";
import actionReducer from "./action.reducer";
import addressReducer from "./address.reducer";
import configReducer from "./configuration.reducer";
import cartReducer from "./cart.reducer";
import mapReducer from "./map.reducer";
import metaData from "./metaData.reducer";
import numberReducer from "./number.reducer";
import networkReducer from "./network.reducer";
import paymentReducer from "./payment.reducer";
import redirectionReducer from "./redirection.reducer";
import shipmentReducer from "./shipment.reducer";
import errorReducer from "./error.reducer";
import loadingReducer from "./loading.reducer";
import analyticsReducer from "./analytics.reducer";

const configPersistConfig = {
  key: "config",
  storage: storage,
  whitelist: [
    "customer_identifier",
    "order_ref",
    "config",
    "customer",
  ],
};

const cartPersistConfig = {
  key: "cart",
  storage: storage,
  blacklist: [
    "ask_for_verification",
    "cnic_requirement",
    "delivery_address",
    // "shipment_method",
    "device_fingerprint",
    "payment_failure_response",
    "cart_success",
    "cart_details_loading",
  ],
};

const paymentPersistConfig = {
  key: "payment",
  storage: storage,
  whitelist: [
    "cc_payment_3ds_required",
    "cc_payment_email_otp_required",
    "cc_payment_otp_required",
    "cc_payment_3ds_required",
    "cc_payment_redirect_url",
    "paymentattempts",
    "payment_redirect_urls",
    "nift_details_loading",
    "nift_resp_code",
    "nift_details",
    "selected_payment_method",
    "nift_script_error_count"
  ],
};

const errorPersistConfig = {
  key: "error",
  storage: storage,
  whitelist: [],
};

const IntlPersistConfig = {
  key: "intl",
  storage: storage,
  whitelist: [],
};
const addressPersistConfig = {
  key: "address",
  storage: storage,
  whitelist: [
    'selectedDropdown',
    'selectedAddress',
    'locationDetails',
    'locationDetailsMap'
  ],
};

const metaPersistConfig = {
  key: "meta",
  storage: storage,
  whitelist: [
    'metaData'
  ],
};

const mapPersistConfig = {
  key: "map",
  storage: storage,
  whitelist: [
    'setCoordinatesLat',
    'setCoordinatesLng',
    'updateCoordinatesLat',
    'updateCoordinatesLng',
    'searchPlace',
    'locationPermission',
    'currentPositionByBtn',
    'setCoordinatesLatMap',
    'setCoordinatesLngMap',
  ],
};

const redirectPersistConfig = {
  key: "redirect",
  storage: storage,
  whitelist: [
    'initial_screen',
    'address_count',
    'delivery_address_id',
    'payment_method_id',
    'shipment_method_id',
    'placement_type',
    'route',
    'otp_msg'
  ],
};

const formPersistConfig = {
  key: "form",
  storage: storage,
  whitelist: [
    'email',
    'name'
  ],
};

const shipmentPersistConfig = {
  key: "shipment",
  storage: storage,
  whitelist: [
    'editShipment',
  ],
};

const analyticsPersistConfig = {
  key: "analytics",
  storage: storage,
  whitelist: [
    '*',
  ],
};

const loadingPersist = {
  key: "loading",
  storage: storage,
  whitelist: [
    'splashLoading',
  ],
};

const rootReducer = combineReducers({
  action: actionReducer,
  alert: alertReducer,
  analytics: persistReducer(analyticsPersistConfig, analyticsReducer),
  address: persistReducer(addressPersistConfig, addressReducer),
  configuration: persistReducer(configPersistConfig, configReducer),
  cart: persistReducer(cartPersistConfig, cartReducer),
  error: persistReducer(errorPersistConfig, errorReducer),
  form: persistReducer(formPersistConfig, numberReducer),
  Intl: persistReducer(IntlPersistConfig, Intl),
  loading: persistReducer(loadingPersist, loadingReducer),
  meta: persistReducer(metaPersistConfig, metaData),
  map: persistReducer(mapPersistConfig, mapReducer),
  network: networkReducer,
  payment: persistReducer(paymentPersistConfig, paymentReducer),
  redirect: persistReducer(redirectPersistConfig, redirectionReducer),
  shipment: persistReducer(shipmentPersistConfig, shipmentReducer),
});

export default rootReducer;
