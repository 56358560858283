// @flow
//eslint-disable-next-line

import * as React from "react";
import { history, LOCAL_STORAGE_SERVICE } from "../../utils";

function BackButton(props) {
  const { btnTitle, handleBack } = props;
  function moveBackwards() {
    LOCAL_STORAGE_SERVICE._PreviousScreen();
    history.goBack();
  }

  return (
    <>
      <button
        type="button"
        id="back-btn"
        className="btn btn-outline-primary width150"
        onClick={() => {handleBack ? handleBack() : moveBackwards()}}
      >
        {btnTitle}
      </button>
    </>
  );
}


export default BackButton;
