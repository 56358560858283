import React,{useEffect} from "react";
import IMAGE_SHIPMENT from '../../assets/images/logo/shipment.svg'
import IMAGE_THUMB from '../../assets/images/logo/thumb.svg'
import IMAGE_CART from '../../assets/images/logo/cart.svg'
import AppWrapper from "../App";
import { BREADCRUMB_SETTINGS_JSON, ROUTE_CONSTANTS } from "../../utils";

let displayContent = [
	{ id: 1, name: 'Lightning Fast Checkout', icon: IMAGE_SHIPMENT },
	{ id: 2, name: 'Buyers Protection - Guaranteed', icon: IMAGE_THUMB },
	{ id: 3, name: 'Hassle-Free Experience', icon: IMAGE_CART },
]


function Home({order_ref}) {

  useEffect(() => {
		const textAnimalted = document.querySelector('.text-animated')
		textAnimalted.innerHTML = textAnimalted.innerText
      .split('')
      .map((char, i) => {
        if (i === 5) i = i * 0.955
        else if (i > 12) i = i * 1.15
        else if (i > 7) i = i * 1.1305
        return `<span style="transform:rotate(${
          char === 'i' ? i * 1.037 * 14 : i * 14
        }deg);">${char}</span>`
      })
      .join('')
	}, [])

  return (
    <>
      <AppWrapper
        showPoweredBy={true}
        showAccountDropdown={false}
        showLangIcon={false}
        showMerchantLogo={false}
        allowProfileAPI={false}
        showFooter={false}
        breadcrumb={{
          type: BREADCRUMB_SETTINGS_JSON.DEFAULT.TYPE,
          options: BREADCRUMB_SETTINGS_JSON.DEFAULT,
          show: BREADCRUMB_SETTINGS_JSON.DEFAULT.SHOW
        }}
        homePageHeader={true}
      >
        <div className="animation-loader">
          <div className="loader-animation-container">
            <div className="circle">
              <div className="logo"></div>
              <div className="text-animated">
                <p>Loading your checkout</p>
              </div>
            </div>

            <section className="heading-main">
              <h4 className="heading">
                Fast Checkout via <span>bSecure</span>
              </h4>
              <span className="sub-heading">
                Just a little wait as we are loading our secure checkout journey
              </span>
            </section>

            <section className="points-container">
              {displayContent.map((e) => (
                <div key={e.id} className="point">
                  <div className="img-container">
                    <img loading="lazy" width="17" src={e.icon} alt="logo" />
                  </div>
                  <span>{e.name}</span>
                </div>
              ))}
            </section>
            {order_ref && (
              <button id="phone-number-submit" type="button" className="btn btn-primary" onClick={() => { window.location.assign(`${ROUTE_CONSTANTS.CHECKOUT}?ref=${order_ref}`); return true; }}>
                <span>Continue with Payment</span>
              </button>
            )}
          </div>
        </div>
      </AppWrapper>
    </>
  );
}
export default Home;

