import React from "react";
import { useTranslate } from "react-redux-multilingual/lib/context";
import { CONSTANTS, HELPER } from "../../utils";
import { store } from "../../store";
import { isMobile } from "react-device-detect";

function CART_DEVICE_TYPE() {
  var device_type = CONSTANTS.DEVICE_TYPE.WEB;
  if (isMobile || document.documentElement.clientWidth < 500) {
    device_type = CONSTANTS.DEVICE_TYPE.MOBILE
  }
  return device_type;
}

function RECEIPT_CONTAINER(
  translation_key,
  value,
  isDiscount = false,
  showMiscCharges = ""
) {
  const translate = useTranslate();
  const { merchant_breakdown, currency_code } = store.getState().cart.cart_details.summary;
  return (
    <>
      {showMiscCharges && !HELPER.isEmpty(merchant_breakdown)
        ? SHOW_MISC_RECEIPT_INFO()
        : ""}
      <div className="receiptSub bigReceiptHeadingBox" key={translation_key}>
        <span className="left">{translate(translation_key)}:</span>
        <span
          className="right"
        >
          {isDiscount ? "- " : ""}
          {HELPER.formatPrice(currency_code, value ?? 0).default}
        </span>
      </div>
    </>
  );
};

function DiscountItem({ discount }) {
  const translate = useTranslate();
  const { currency_code } = store.getState().cart.cart_details.summary;
  return (
    <div className="receiptSub" key={"d-" + 1 + discount?.name}>
      <span className="left">
        <div>
          <p>
            <span className="discount-tag icomoon-discount"></span>
            <span dir={"ltr"}>{HELPER.titleCase(discount?.name)} </span>
            {/* <span className="close-discount">
                  <span className="icomoon-close"></span>
                </span> */}
          </p>
          <span className="discount-pricing">
            {CONSTANTS.DISCOUNT_TYPE[1] ===
              CONSTANTS.DISCOUNT_TYPE[discount?.type]
              ? HELPER.showPrice(
                currency_code,
                discount?.value ?? 0,
                false
              ) +
              " " +
              translate("discount_off")
              :
              discount?.value + "% " + translate("discount_off") + (discount?.cap_amount ? translate("CART.UPTO") + HELPER.showPrice(currency_code, discount?.amount) : "")}
          </span>{" "}
        </div>
      </span>
      <span
        className="right"
      >
        - {HELPER.showPrice(currency_code, discount?.amount)}
      </span>
    </div>
  )
}
const showDiscountBreakdownInfo = (translation_key, discount) => {
  return (
    <div className="receiptInfo" key={translation_key}>
      {discount?.store_name &&
        <div className="storeInfo">
          {
            HELPER.isNotEmpty(discount?.icon) ?
              <div className="store-icon">
                <img src={discount?.icon} alt="bSecure" />
              </div> : ""
          }
          <div className="store-name">
            {discount?.store_name}
          </div>
        </div>
      }
      {
        discount?.discount?.map(e => <DiscountItem discount={e} key={`discount-${e?.name}`} />)
      }
    </div>
  );
};

const DISCOUNT_RECEIPT_SECTION = (translation_key, value) => {
  const { discount_breakdown } = store.getState().cart.cart_details.summary;
  let discount_breakdown_list = HELPER.isNotEmpty(discount_breakdown)
    ? discount_breakdown.map((discount, i) => {
      return showDiscountBreakdownInfo("discount-" + i, discount);
    }) : "";
  return (
    <>
      {value ?
          <div className="receiptDiscounts" key={translation_key}>
            {discount_breakdown_list}
            <div className="receiptInfo receiptDiscountBox">
              {CART_HELPER.RECEIPT_CONTAINER(translation_key, value, true)}
            </div>
          </div> : ""
      }
    </>
  );
};

const ADD_CHARGES_BREAKDOWN = () => {
  const { currency_code, additional_charges } = store.getState().cart.cart_details.summary;
  return additional_charges.map((additional_charge, index) => {
    return <div className="receiptSub bigReceiptHeadingBox" key={index}>
      <span className="left">{`${additional_charge?.name} ${CONSTANTS.DISCOUNT_TYPE[1] === CONSTANTS.DISCOUNT_TYPE[additional_charge?.type] ? "" : `- (${additional_charge?.value}%)`}`}:</span>
      <span className="right">{HELPER.showPrice(currency_code, additional_charge?.amount)}</span>
    </div>
  });
};

const SHOW_MISC_RECEIPT_INFO = () => {
  const { currency_code, merchant_breakdown } = store.getState().cart.cart_details.summary;

  return merchant_breakdown.map((fee, index) => {
    let feeValue = fee?.value;
    return (
      <div className={`receiptSubItems`} key={index}>
        <span className="left">{fee?.key}:</span>
        <span className="right">
          {HELPER.showPrice(currency_code, feeValue)}
        </span>
      </div>
    );
  });
};

const PRODUCT_ATTRIBUTES = (product_attributes) => {
  if (!HELPER.isEmpty(product_attributes)) {
    let content = "";
    let html = "<span className='cartVariations'><div>";
    Object.keys(product_attributes).forEach((value) => {
      content +=
        value + ": <span>" + product_attributes[value] + "</span><br />";
    });
    html += content;
    html += "</div></span>";
    return html;
  }
};

const CART_HELPER = {
  CART_DEVICE_TYPE,
  RECEIPT_CONTAINER,
  ADD_CHARGES_BREAKDOWN,
  DISCOUNT_RECEIPT_SECTION,
  PRODUCT_ATTRIBUTES

};
export default CART_HELPER;
