// @flow
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";
import Divider from "@material-ui/core/Divider";

import { IconButton } from "@material-ui/core";

import ScriptTag from "react-script-tag";
import PageLoader from "../../../../components/PageLoader";

import {
  history,
  CONSTANTS,
  HELPER,
  LOCAL_STORAGE_SERVICE,
  IMAGE_SRC,
  ROUTE_CONSTANTS
} from "../../../../utils";
import {
  niftAction,
  cartAction,
} from "../../../../store/actions";
import NIFTForm from "./form";

let timeoutTime;
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function NIFTCCForm() {
  let dispatch = useDispatch();
  const translate = useTranslate();
  const { payment_method } = useSelector(state => state.cart);
  const { showBtnLoader, showBackBtnLoader } = useSelector(state => state.loading);
  const {
    nift_details,
    loadPluginScript,
    loadSessionScript,
    nift_details_loading,
    nift_resp_code,
    nift_reload_start,
    nift_script_error_count
  } = useSelector(state => state.payment);
  const {
    PGWHPCTransactionParameters,
    plugin_js,
    session_js
  } = nift_details

  const [scriptsLoaded, updateScriptsLoaded] = useState(false);

  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      clearTimeout(timeoutTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD) {
    //   if (scriptsLoaded) {
    //     dispatch(niftAction.CLEAR_CREDENTIALS());
    //   }
    // }
    if (
      !showBtnLoader && parseInt(nift_resp_code) === parseInt(CONSTANTS.HTTP_RESPONSE.SUCCESS)
    ) {
      var requestData = {
        customer_cnic: LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value"),
      };
      dispatch(cartAction.place_order(requestData, history));
    }
    if (
      nift_details_loading === "" &&
      parseInt(nift_resp_code) !== parseInt(CONSTANTS.HTTP_RESPONSE.SUCCESS)
      && nift_reload_start
    ) {
      dispatch(niftAction.RELOAD_NIFT_SCRIPT(false));
      sleep(1000).then(() => {
        window.location.pathname === ROUTE_CONSTANTS.CREDIT_CARD &&
         window.location.reload();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptsLoaded, nift_resp_code, nift_details_loading]);

  const updateNIFTScriptLoadingState = () => {
    updateScriptsLoaded(true);
  };

  const moveBackwards = () => {
    history.goBack();
  };

  const callApiErrorHandle=async(error_obj)=>{
    if(nift_script_error_count === 2) return
    const response = {
      error_reporting: 1,
      status: error_obj,
      nift_response_code: 422,
      nift_response: error_obj,
      nift_request: { PGWHPCTransactionParameters },
      customer_cnic: LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value")
    };
    await dispatch(niftAction.FORM_SUBMIT(response, history));
    setTimeout(() => {
      window.location.reload()
    }, 3000);
  }

  return (
    <>
      {(loadSessionScript || loadPluginScript) && !scriptsLoaded ? (
        <PageLoader />
      ) : (
        ""
      )}

        {!loadSessionScript && !scriptsLoaded ? (
          <ScriptTag
            isHydrating={false}
            type="text/javascript"
            src={plugin_js}
            onLoad={() => {
              dispatch(niftAction.LOAD_PLUGIN_JS(true));
            }}
            // eslint-disable-next-line no-unused-vars
            onError={function(error) {
              let _error="Unable to load session file. Report it to administrator"
              callApiErrorHandle({message:_error, src: this.src})
              dispatch(niftAction.SHOW_ERRORS("danger", _error));
            }}
            async
          />
        ) : (
          ""
        )}
        {loadPluginScript && !loadSessionScript && !scriptsLoaded ? (
          <ScriptTag
            type="text/javascript"
            src={session_js}
            onLoad={() => {
              dispatch(niftAction.LOAD_SESSION_JS(true));
            }}
            // eslint-disable-next-line no-unused-vars
            onError={function(error){
              let _error="Unable to load session file. Report it to administrator"
              callApiErrorHandle({message:_error, src: this.src})
              dispatch(niftAction.SHOW_ERRORS("danger", "Unable to load plugin. Report it to administrator"));
            }}
            async
          />
        ) : (
          ""
        )}
        {loadSessionScript && !scriptsLoaded && !HELPER.isEmpty(PGWHPCTransactionParameters) ? (
          <NIFTForm
            loadingState={updateNIFTScriptLoadingState}
            niftDetails={PGWHPCTransactionParameters}
          />
        ) : (
          ""
        )}
        {CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.NIFT_CC_FORM === payment_method?.input_type ?
        <div className="PGWHPCCARDContainer niftFormCSS" id="PGWHPCCARDContainer2"></div> 
        :
        <div className="PGWHPCACCTContainer niftFormCSS" id="PGWHPCACCTContainer2"></div> 
       }
      
      <button
        type="button"
        id="back-btn"
        className="btn btn-outline-primary btn-block niftBackBtn"
        onClick={moveBackwards}
        disabled={showBackBtnLoader ? true : false}
      >
        {translate("back_btn")}
      </button>
        
      <div className="niftVerifiedText">
        <img src={IMAGE_SRC.NIFT_EPAY} alt="nift icon" className="niftImg"/>
        <Divider orientation="vertical" className="divider"/>
        <div className="niftText">
          <span>All your payments are processed and verified by </span>
          <b className="red">NIFT</b><b className="blue">ePay</b>
        </div>
      </div>
      
      {/* <span className="niftInfoIcon">
        <IconButton
          aria-label="delete"
          color="primary"
          disableFocusRipple={true}
        >
          <span className={"iconW icomoon-info-outline"}></span>
        </IconButton>
        Refresh your page if you don't see the 'Submit' button
      </span> */}
      <div className="pb-6"></div>
    </>
  );
}

export default NIFTCCForm;
