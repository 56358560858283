// @flow

import React from "react";
import { useTranslate } from "react-redux-multilingual/lib/context";
import { useSelector, useDispatch } from "react-redux";

import ReactHtmlParser from "html-react-parser";
import { Accordion, Card } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import HtmlTooltip from "../../../components/HtmlTooltip";
import { shippingAction } from "../../../store/actions";
import {
  HELPER,
  ANALYTICS_HELPER,
  GOOGLE_ANALYTICS_HELPER
} from "../../../utils";

let isSelected, shipmentCharges

const shipmentErrpr = (shipmentMethod) => {
  return(
    <div className="contentAlert">
      <div className={`toolTipContentAlert`}>
          < span className="icomoon-info"></span>
      </div>
      <div className="alertMsg">
          <div className="alertHeading">{HELPER.titleCase(shipmentMethod.name)}</div>
          <div className="alertDescription">{shipmentMethod.error}</div>
      </div>
    </div>)
}
const ShipmentErrorHeader = ({ shipmentMethod }) => {
  const translate = useTranslate();

  return(
    <div className="shipmentHeaderActions">
      <div className="card-header card-payment-protection">
        <div className="payment-protection">
          <div className="protection">
              <h6 className="mt-1 overflowText">{ translate("SHIPPING.LIST.METHOD_NOT_AVAILABLE") }</h6>
              <span>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  disableFocusRipple={true}
                  className="toolTipButton"
                >
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 1500 }}
                    overlay={HtmlTooltip}
                    popperConfig={{
                      props: {
                        state: shipmentErrpr(shipmentMethod),
                        toolTipParentClass: "toolTipParent"
                      },
                    }}
                  >
                    <InfoIcon />
                  </OverlayTrigger>
                </IconButton>
              </span>
          </div>
        </div>
      </div>
    </div>)
}

function ShippingMethods() {
  let dispatch = useDispatch();
  const { selectedIndex, shipmentMethods, currency } = useSelector(state => state.shipment);

  const selectShipment = (element) => {
    if (selectedIndex?.id !== element?.id  && element.shipment_enabled === 1) {
      dispatch(shippingAction.select_shipment_index(element));
      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.SHIPMENT,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.SHIPMENT.SELECTION.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.SHIPMENT.SELECTION.LABEL,
          properties: {
            selected: element.id
          }
        }
      );
    }
  };


  const showShipment = (shipmentMethod) => {
    return (
      <>
        <Accordion.Toggle
          className="shipmentMethodHeader"
          eventKey={shipmentMethod.id}
          id={"shipment-method-" + shipmentMethod.id}
          component={"div"}
        >
          <div className="shipmentHeaderBody">
            <div className={"head icon"}>
              {HELPER.isNotEmpty(shipmentMethod.shipment_icon) ?
                <img src={!HELPER.isEmpty(shipmentMethod.shipment_icon) ? shipmentMethod.shipment_icon : ""} alt={"credit card"} />
                : ""}
            </div>
            <div className={`head information ${parseInt(selectedIndex?.id) === shipmentMethod.id ? "active" : ""}`} >
              <h5 className={"mt-0 overflowText"}> {HELPER.titleCase(shipmentMethod.name)} </h5>
            </div>
          </div>
        </Accordion.Toggle >
        {
          shipmentMethod.shipment_enabled === 0 ? <ShipmentErrorHeader shipmentMethod={shipmentMethod} />: ""
        }
      </>
    );
  };

  return (
    <>
      <div className="customRadio shippingCustomRadio shipmentMethod">
        <Accordion defaultActiveKey={0}>
          {shipmentMethods.map((shipmentMethod) => {
            shipmentCharges = !HELPER.isEmpty(shipmentMethod.charges)
              ? shipmentMethod.charges
              : 0;

            return (
              <Card
                onClick={() => selectShipment(shipmentMethod)}
                className={`${parseInt(selectedIndex?.id) === shipmentMethod.id ? "activeShow" : ""} ${shipmentMethod.shipment_enabled === 0 ? "shipmentDisabled" : ""}`}
                key={shipmentMethod.id}
              >
                {showShipment(shipmentMethod)}
                <Accordion.Collapse
                  eventKey={shipmentMethod.shipment_enabled === 0 ? -1 : shipmentMethod.id}
                  className={`shipmentMethodBody ${isSelected && shipmentMethod.shipment_enabled === 1 ? "show" : ""}`}
                >
                  <Card.Body className={!HELPER.isEmpty(shipmentMethod.description) ? "description" : ""} >
                    <div className={`${!HELPER.isEmpty(shipmentMethod.description) ? "shipmentPrice" : ""}`} >
                      {
                        ReactHtmlParser(HELPER.parseMessage(HELPER.showPrice(currency, shipmentCharges)))
                      }
                    </div>
                    {!HELPER.isEmpty(shipmentMethod.description) ? <div className="shipmentDesc">{HELPER.titleCase(shipmentMethod.description)}</div> : ""}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      </div>
    </>
  );
}

export default ShippingMethods;