// @flow
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";
import { TextField } from "@material-ui/core";
import {
  TEXT_FORMAT,
  HELPER,
  LOCAL_STORAGE_SERVICE,
  GOOGLE_ANALYTICS_HELPER,
  ANALYTICS_HELPER
} from "../../../utils";

function CnicField({ errors_cnic , update_cnic_error, hideText }) {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const { cnic_required_amount, cnic_required, customer_cnic, customer_cnic_validation } = useSelector(state => state.cart.cnic_requirement);
  const { total_amount } = useSelector(state => state.cart.cart_details.summary);
  let _total=HELPER.makeInteger(total_amount)
  const check_for_cnic = LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value");
  
  const [cnic_number, setCnic] = useState(null);
  const [cnic_validation_max_limit, setCnic_validation_limit] = useState(15);
  const [required_cnic_number, setRequiredCnic_number] = useState(false);
  const [errors_cnic_number, setErrorsCnic_number] = useState("");
  const cnicValidationLimit =  parseFloat(_total) >= parseFloat(cnic_required_amount);


  //componentWillRecieveProps
  useEffect(() => {
    if(HELPER.isEmpty(errors_cnic)){
      setErrorsCnic_number(errors_cnic);
    }
    setRequiredCnic_number(parseInt(cnic_required) === 1);
    setCnic(customer_cnic);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const handleValidation = () => {
    let validation_errors = {
      cnic_number: "",
    };
    let errorText = "";
    //set errors to empty
    setErrorsCnic_number("");
    //Check for error condition
    if (
      required_cnic_number &&
      cnicValidationLimit &&
      HELPER.isEmpty(cnic_number)
    ) {
      errorText = translate("VALIDATIONS.IS_REQUIRED");
      validation_errors.cnic_number = errorText;
      setErrorsCnic_number(errorText);
      // scroller.scrollTo("cnic_number", { offset: -200, smooth: true });
    } else if (
      required_cnic_number &&
      !HELPER.isEmpty(cnic_number) &&
      cnic_number.length !== cnic_validation_max_limit
    ) {
      errorText = translate("PAYMENT.VALIDATION.CNIC_FIELD", {
        x: cnic_validation_max_limit,
      });
      validation_errors.cnic_number = errorText;
      setErrorsCnic_number(errorText);
      // scroller.scrollTo("cnic_number", { offset: -200, smooth: true });
    }
    return validation_errors;
  };


  //componentWillRecieveProps
  useEffect(() => {
    // Set max limit of cnic field validation
    if (!HELPER.isEmpty(customer_cnic_validation)) {
      setCnic_validation_limit(parseInt(customer_cnic_validation));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_cnic_validation]);

  //componentWillRecieveProps
  useEffect(() => {

    // Check for order cnic requirement
    if (!HELPER.isEmpty(cnic_required)) {
      // If customer already has cnic_number than show cnic number and disable the field for further changes
      if (!HELPER.isEmpty(customer_cnic)) {
        let cnic_value = TEXT_FORMAT.format_CNIC(
          TEXT_FORMAT.format_CNIC(customer_cnic)
        );
        setCnic(cnic_value);
        if (HELPER.isEmpty(check_for_cnic)) {
          LOCAL_STORAGE_SERVICE._saveToLocalStorage("cnic_value", cnic_value);
        }
        // setDisableCnic_number(true);
      }

      //check local storage if it has cnic value
      if (
        !HELPER.isEmpty(check_for_cnic) &&
        check_for_cnic !== customer_cnic
      ) {
        LOCAL_STORAGE_SERVICE._updateInLocalStorage("cnic_value", check_for_cnic);
        setCnic(check_for_cnic);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnic_required, customer_cnic]);


  //componentWillRecieveProps
  useEffect(() => {
    if (!HELPER.isEmpty(errors_cnic)) {
      setErrorsCnic_number(errors_cnic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors_cnic]);

  const handleSubmit = (e) => {
    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.REVIEW,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.REVIEW.CNIC_INPUT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.REVIEW.CNIC_INPUT.LABEL,
        properties: {
          errors: handleValidation()
        }
      }
    );

    //Validation check
    handleValidation();
    const cnic_number_updated = e.target.value;
    let cnic_value = TEXT_FORMAT.format_CNIC(
      TEXT_FORMAT.format_CNIC(cnic_number_updated)
    );
    setCnic(cnic_value);
    LOCAL_STORAGE_SERVICE._updateInLocalStorage("cnic_value", cnic_value);
    if (cnic_value.length === cnic_validation_max_limit) {
      setErrorsCnic_number(false);
      update_cnic_error && update_cnic_error();
    }
  };

  return (
    <TextField
      autoComplete="new-password"
      type="text"
      margin="normal"
      variant="outlined"
      className="col-md-12 col-sm-12 col-xs-12 cnicField mt-4"
      name="cnic_number"
      placeholder={translate("REVIEW.PLACEHOLDER.CNIC")}
      required={required_cnic_number}
      onClick={()=> ANALYTICS_HELPER._cnicInput()}
      key={1}
      onChange={handleSubmit}
      value={LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value") ?? ""}
      error={HELPER.isEmpty(errors_cnic_number) ? false : true}
      // autoFocus={required_cnic_number}
      helperText={hideText ? "" : translate("cnic_requirement_message")}
      inputProps={{
        maxLength: cnic_validation_max_limit,
        inputMode: "numeric",
        id: "cnic-field",
        
      }}
    />
  );
}


export default CnicField;
