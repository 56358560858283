const FORM_TYPE = {
  DEFAULT: "none",
  CREDIT_CARD: {
    GENERAL: "none",
    API_BASED: "credit_card",
    NIFT: "nift",
    QISSTPAY: "qisst_pay_form",
  },
  QISSTPAY: "qisst_pay_form",
  EASY_PAISA_ACCOUNT: "mobile_account_number",
  DBT: "direct_bank_transfer",
  APG: {
    WALLET: "apg_wallet",
    BANK_ACCOUNT_NUMBER: "apg_bank_account",
  },
  PAYFAST: {
    BANK_ACCOUNT_NUMBER: "apps_bank_account",
  },
}

const PAYMENT_METHOD = {
  INPUT_TYPE: {
    CREDIT_CARD: "card",
    CVV: "cvv",
    NONE: "none",
    NIFT_BANK: "bank_transfer",
    NIFT_CC_FORM: "cc_form",
  },
  FORM_TYPE,
  INSTRUCTIONS: {
    EASYPAISA: "easypaisa",
    JAZZCASH: "jazzcash",
    NONE: "none"
  },
  MOBILE_ACCOUNT: {
    INSTRUCTIONS: {
      EASYPAISA: {
        TEXT_1: "PAYMENT_METHODS.INSTRUCTIONS.EASYPAISA.TEXT_1",
        TEXT_2: "PAYMENT_METHODS.INSTRUCTIONS.EASYPAISA.TEXT_2",
        TEXT_3: "PAYMENT_METHODS.INSTRUCTIONS.EASYPAISA.TEXT_3",
        TEXT_4: "PAYMENT_METHODS.INSTRUCTIONS.EASYPAISA.TEXT_4",
        TEXT_4_1: "PAYMENT_METHODS.INSTRUCTIONS.EASYPAISA.TEXT_4_1",
        TEXT_4_2: "PAYMENT_METHODS.INSTRUCTIONS.EASYPAISA.TEXT_4_2",
      },
      JAZZCASH: {
        TEXT_1: "PAYMENT_METHODS.INSTRUCTIONS.JAZZCASH.TEXT_1",
        TEXT_2: "PAYMENT_METHODS.INSTRUCTIONS.JAZZCASH.TEXT_2",
        TEXT_3: "PAYMENT_METHODS.INSTRUCTIONS.JAZZCASH.TEXT_3",
        TEXT_4: "PAYMENT_METHODS.INSTRUCTIONS.JAZZCASH.TEXT_4",
        TEXT_4_1: "PAYMENT_METHODS.INSTRUCTIONS.JAZZCASH.TEXT_4_1",
        TEXT_4_2: "PAYMENT_METHODS.INSTRUCTIONS.JAZZCASH.TEXT_4_2",
      }
    },
    SUGGESTION_LIST: {
      EASYPAISA: "PAYMENT_METHODS.SUGGESTION_LIST.EASYPAISA",
      JAZZCASH: "PAYMENT_METHODS.SUGGESTION_LIST.JAZZCASH",
    }
  },
  PAYMENT_INSTRUCTION_TYPE: {
    EASYPAISA: [FORM_TYPE.EASY_PAISA_ACCOUNT, FORM_TYPE.PAYFAST.BANK_ACCOUNT_NUMBER],
  },
  PAYMENT_FORM_TYPE: {
    DEFAULT: [FORM_TYPE.DEFAULT],
    REDIRECTION_BASED_CREDIT_CARD: [FORM_TYPE.CREDIT_CARD.GENERAL, FORM_TYPE.CREDIT_CARD.NIFT],
    API_BASED_CREDIT_CARD: [FORM_TYPE.CREDIT_CARD.API_BASED],
    WALLET: [FORM_TYPE.APG.WALLET, FORM_TYPE.PAYFAST.WALLET],
    BANK_ACCOUNT_NUMBER: [FORM_TYPE.APG.BANK_ACCOUNT_NUMBER, FORM_TYPE.PAYFAST.BANK_ACCOUNT_NUMBER],
    BANK_ACCOUNT_CODE: [FORM_TYPE.PAYFAST.BANK_ACCOUNT_NUMBER],
    EASY_PAISA_ACCOUNT: [FORM_TYPE.EASY_PAISA_ACCOUNT],
    QISSTPAY: [FORM_TYPE.QISSTPAY],
    DBT: [FORM_TYPE.DBT],
  },
  PAYMENT_INPUT_TYPE: {
    BANK_ACCOUNT_NUMBER: ["easypaisa", "jazzcash"],
  },
};

const CHECKOUT_STEP = {
  UNIVERSAL_ORDER: {
    NEW_USER_JOURNEY: {
      REVIEW: {
        STEP: 7,
        NAME: "REVIEW"
      },
      SHIPMENT: {
        STEP: 8,
        NAME: "SHIPMENT"
      },
      PAYMENT: {
        STEP: 9,
        NAME: "PAYMENT"
      },
      CONFIRM_ORDER: {
        STEP: 10,
        NAME: "CONFIRM ORDER"
      }
    },
    RETURNING_USER_JOURNEY: {
      REVIEW: {
        STEP: 5,
        NAME: "REVIEW"
      },
      SHIPMENT: {
        STEP: 6,
        NAME: "SHIPMENT"
      },
      PAYMENT: {
        STEP: 7,
        NAME: "PAYMENT"
      },
      CONFIRM_ORDER: {
        STEP: 8,
        NAME: "CONFIRM ORDER"
      }
    }
  },
  PAYMENT_GATEWAY: {
    NEW_USER_JOURNEY: {
      PAYMENT: {
        STEP: 1,
        NAME: "PAYMENT"
      },
      REVIEW: {
        STEP: 2,
        NAME: "REVIEW"
      },
      CONFIRM_ORDER: {
        STEP: 3,
        NAME: "CONFIRM ORDER"
      }
    },
    RETURNING_USER_JOURNEY: {
      OTP: {
        STEP: 1,
        NAME: "OTP"
      },
      PAYMENT: {
        STEP: 2,
        NAME: "PAYMENT"
      },
      REVIEW: {
        STEP: 3,
        NAME: "REVIEW"
      },
      CONFIRM_ORDER: {
        STEP: 4,
        NAME: "CONFIRM ORDER"
      }
    }
  },
  APP: {
    NEW_USER_JOURNEY: {
      LOGIN: {
        STEP: 1,
        NAME: "LOGIN"
      },
      OTP: {
        STEP: 2,
        NAME: "OTP"
      },
      ADD_ADDRESS: {
        STEP: 3,
        NAME: "ADDRESS"
      },
      SHIPMENT: {
        STEP: 4,
        NAME: "SHIPMENT"
      },
      PAYMENT: {
        STEP: 5,
        NAME: "PAYMENT"
      },
      REVIEW: {
        STEP: 6,
        NAME: "REVIEW"
      },
      CONFIRM_ORDER: {
        STEP: 7,
        NAME: "CONFIRM ORDER"
      }
    },
    RETURNING_USER_JOURNEY: {
      LOGIN: {
        STEP: 1,
        NAME: "LOGIN"
      },
      OTP: {
        STEP: 2,
        NAME: "OTP"
      },
      ADD_ADDRESS: {
        STEP: 3,
        NAME: "ADDRESS"
      },
      SHIPMENT: {
        STEP: 4,
        NAME: "SHIPMENT"
      },
      PAYMENT: {
        STEP: 5,
        NAME: "PAYMENT"
      },
      REVIEW: {
        STEP: 6,
        NAME: "REVIEW"
      },
      CONFIRM_ORDER: {
        STEP: 7,
        NAME: "CONFIRM ORDER"
      }
    }
  }
}

const APP_TYPE ={
  CHECKOUT: 1,
  SINGLE_SIGN_ON: 2,
}

const DIALOG = {
  CATEGORY: {
    POPUP: "POPUP",
    MODAL: "MODAL",
  },
  TYPE: {
    POPUP: {
      ORDER_EXPIRED: "ORDER_EXPIRED_POPUP",
      LANGUAGE: "LANGUAGE_POPUP",
    },
    MODAL: {
      TERMS: "TERMS_POPUP",
      PRIVACY: "PRIVACY_POPUP",
    }
  }
};

const ERROR_TYPE = {
  TOAST: "TOAST",
  ALERT: "ALERT"
};

const APP_ALERT_TYPE = {
  DEFAULT: 1,
  CUSTOM: 2,
};

const HTTP_RESPONSE = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  INPROCESSIBLE: 422,
  SERVER_ERROR: 500,
  UNDER_MAINTAINANCE: 503,
};

const SCREEN_REDIRECT = {
  PHONE: "phone",
  OTP: "otp",
  CHECKOUT: "checkout",
}

const ORDER_STATUS = {
  CREATED: 1,
  INITIATED: 2,
  PLACED: 3,
  AWAITING_CONFIRMATION: 4,
  CANCLED: 5,
  EXPIRED: 6,
  FAILED: 7,
  AWAITING_PAYMENT: 8,
};

const PAYMENT_STATUS = {
  PROCESSING: 0,
  SUCCESS: 1,
  FAILED: 2,
};

const USER_JOURNEY = {
  NEW_USER: 1,
  RETURNING_USER: 2,
};


export const CONSTANTS = {
  USER_JOURNEY,
  // Payment Screen
  DEFAULT_PAYMENT_METHOD_UI: "none",
  EASYPAISA_PAYMENT_METHOD_UI: "mobile_account_number",
  CC_PAYMENT_METHOD_UI: "credit_card",
  APP_TYPE,
  PAYMENT_METHOD,
  DIALOG,
  ERROR_TYPE,
  APP_ALERT_TYPE,
  YES: 1,
  NO: 0,
  //API RESPONSE
  HTTP_RESPONSE,
  //GENERAL
  IS_RTL: "rtl",
  IS_URDU: "ur",
  IS_ENGLISH: "en",
  SCREEN_REDIRECT,
  ORDER_STATUS,
  PAYMENT_STATUS,
  PAYMENT_SCREEN_RULE: {
    DEFAULT: "DEFAULT",
    DBT: "DIRECT_BANK_TRANSFER",
    CREDIT_CARD: {
      ADD_NEW: "ADD_NEW_CREDIT_CARD",
      SAVED: "SAVED_CREDIT_CARD",
    },
    EASY_PAISA: {
      ADD_NEW: "ADD_NEW_EASY_PAISA",
      SAVED: "SAVED_EASY_PAISA",
    },
    EASY_PAISA_ACCOUNT: "ep_mobile_account",
    EP_WALLET: "ep_wallet",
    APG_WALLET: {
      SAVED: "apg_wallet_saved",
      ADD_NEW: "add_new_apg_wallet"
    },
    QISSTPAY: "QISST_PAY",
    APG_BANK_ACCOUNT_NUMBER: {
      SAVED: "apg_bank_account_number_saved",
      ADD_NEW: "add_new_apg_bank_account_number"
    },
  },
  PAYMENT: {
    GATEWAY: {
      EASY_PAISA: "mobile_account_number",
      APG: "apg",
      DBT: "direct_bank_transfer",
      SAFEPAY: "safepay",
    },
  },
  CHECKOUT_INFO: {
    PAYMENT_METHOD: {
      TOOLTIP: {
        CVV: "cvv",
        DBT: "dbt",
        PAYMENT_GUARENTEED: "PAYMENT_GUARENTEED"
      },
    },
  },
  ORDER_PLACEMENT_TYPE: {
    APP: "App",
    MANUAL: "Manual",
    COD_VERIFICATION: "COD_verification",
    PAYMENT_GATEWAY: "Payment_gateway",
    INVOICE: "Invoice",
    UNIVERSAL: "Universal",
    GIVE_WP: 'Give_WP',
  },
  DISCOUNT_TYPE: {
    1: "flat",
    2: "percentage",
  },
  BREADCRUMB_LABEL: {
    CHECKOUT: "checkout",
    ORDER_STATUS: "order-status",
    GENERAL: "general",
    NONE: "none"
  },
  BREADCRUMB_TYPE: {
    GENERAL: 1,
    OTP: 2,
    CHECKOUT: 3,
    ORDER_STATUS: 4,
    NONE: 5,
    ABANDONED: 6,
  },
  DEVICE_TYPE: {
    MOBILE: "MOBILE",
    WEB: "WEB",
  },
  NIFT_RESPONSE_HANDLER: {
    UI: 1,
    API: 2,
  },
  PERMISSIONS: {
    GRANTED: "granted",
    DENIED: "denied",
    PROMPT: "prompt"
  },

 ALERTS : {
    GROUP: {
      default: 1,
      custom: 2,
    },
    WEB_TYPE: {
      error: "error",
      success: "success",
      secondary: "info",
      warning: "warning",
      clear: "clear",
    },
    MOBILE_TYPE: {
      default: "default",
      clear: "clear",
    },
  },
  CC_GATEWAY:{
    HBL: "hbl",
    PAYFAST: "payfast",
    ALFALAH_MPGS: "alfalah-mpgs",
  },
  CHECKOUT_STEP,
  VERIFICATION_TYPE :{
    PHONE: "phone",
    OTP: "otp",
  },
  DISCOUNT_TYPE_NAME: {
    FLAT: 1,
    PERCENTAGE: 2,
  },
};


export const SAVED_PAYEMENT_CONSTANTS = [
  CONSTANTS.PAYMENT_SCREEN_RULE.CREDIT_CARD.SAVED,
  CONSTANTS.PAYMENT_SCREEN_RULE.EASY_PAISA.SAVED,
  CONSTANTS.PAYMENT_SCREEN_RULE.APG_WALLET.SAVED,
  CONSTANTS.PAYMENT_SCREEN_RULE.APG_BANK_ACCOUNT_NUMBER.SAVED
];
