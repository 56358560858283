// @flow
//eslint-disable-next-line

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import queryString from "query-string";
import AppWrapper from "../../components/App";
import { cartAction, logoutAction } from "../../store/actions";
import ReturntoMerchant from "../../components/ReturntoMerchant";
import Modal from "../../components/Modal";
import EasyPaisaProcessing from "../../components/Processing/PaymentGateway/easy_paisa";
import Alert from "../../components/Alert";

import {
  BREADCRUMB_SETTINGS_JSON,
  HELPER,
  IMAGE_SRC,
  ROUTE_CONSTANTS,
  history
 } from "../../utils";

function Processing() {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const { payment_failure_response, ask_for_verification, cart_success } = useSelector(state => state.cart);
  const { location } = history
  // Get query string parameters
  const params = queryString.parse(location.search);

  // Get payment_ref from query string paramters
  const payment_ref = params?.payment_ref;
  const bypass_expiry_enabled = params?.bypass_expiry_enabled
  const IsEPProcessing = location.pathname === ROUTE_CONSTANTS.PAYMENT_PROCESSING

  //componentDidMount
  useEffect(() => {
    if (location.pathname === ROUTE_CONSTANTS.PAYMENT_INFO) {
      // Call payment-info api to identify payment response
      const requestData = {
        payment_ref
      };
      dispatch(cartAction.get_payment_info(requestData, bypass_expiry_enabled))
    }
    // returned function will be called on component unmount
    return () => {
      if (window.opener !== null) {
        // window.onunload = null;
        window.onbeforeunload = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //componentDidMount
  useEffect(() => {
    const {
      payment_failure,
      pay_limit_reached,
    } = payment_failure_response;
    
  const { failure_redirect_url } = payment_failure_response;

    //CHECK IF PAYMENT IS FAILED THEN SHOW PAYMENT FAILURE PAGE AND RE-RUN PAYMENT FLOW
    if (payment_failure === 1 && !HELPER.isEmpty(failure_redirect_url)) {
      history.push({
        pathname: ROUTE_CONSTANTS.ORDER_FAILURE,
      });
    }
    //CHECK IF PAYMENT LIMIT IS EXCEEDED THEN SHOW OUT OF RETRIES AND REDIRECT TO STORE
    if (pay_limit_reached) {
      history.push({
        pathname: ROUTE_CONSTANTS.OUT_OF_RETRIES,
      });
    }

    if (IsEPProcessing) {
      // Send message to bulider website for hosted checkout //
      const redirect_url = cart_success.redirect_url;
      const store_url = cart_success.store_url;
      HELPER.sendMerchantBeforeUnloadListener(redirect_url, store_url);
      // CHECK CONDITIONS FOR CUSTOMER VERIFICATION POPUP
      if (ask_for_verification) {
        const modal = {
          title: translate("VERIFICATION.POPUP_TITLE"),
          content: "",
          cancelBtn:
            ask_for_verification === 1 ? (
              <ReturntoMerchant />
            ) : (
              translate("cancel_btn")
            ),
          successBtn: translate("VERIFICATION.SUCCESS_BTN"),
          disableBackdropClick: true,
        };
        if (cart_success?.ask_for_verification !== 1) {
        dispatch(cartAction.verify_customer_after_checkout(modal))
          setTimeout(() => {
            dispatch(logoutAction.CLEAR_REDUX_STORE())
            HELPER.redirectToPathAndClearLocalStorage(redirect_url, store_url);
          }, 15000);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_failure_response, ask_for_verification]);



  // const breadcrumbOptions = {
  //       title: "",
  //       icon: breadcrumbOptionParams.breadcrumbIcon,
  //       iconPrefix: "",
  //       classes: `orderStatus ${breadcrumbOptionParams.breadcrumbClass}`,
  //       iconClasses: breadcrumbOptionParams.breadcrumbIcon,
  //       headerClasses: "",
  //       type: CONSTANTS.BREADCRUMB_LABEL.ORDER_STATUS,
  //       show: true
  //     }

  return (
    <AppWrapper
      showMerchantLogo={true}
      showLangIcon={true}
      showAccountDropdown={true}
      allowProfileAPI={true}
      showFooter={false}
      breadcrumb={{
        type: IsEPProcessing ? BREADCRUMB_SETTINGS_JSON.EP_PROCESSING.TYPE : BREADCRUMB_SETTINGS_JSON.PROCESSING.TYPE,
        options: IsEPProcessing ? BREADCRUMB_SETTINGS_JSON.EP_PROCESSING : BREADCRUMB_SETTINGS_JSON.PROCESSING,
        show: IsEPProcessing ? BREADCRUMB_SETTINGS_JSON.EP_PROCESSING.SHOW : BREADCRUMB_SETTINGS_JSON.PROCESSING.SHOW
      }}
    >
      <div className="screen midWrap">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="signInView midView">
                <h1>{translate("processing_heading")}</h1>

                <p className="signviewsmalltext">
                  {translate("processing_text")}
                </p>

                {/* SHOW ALERT MESSAGES START */}
                <Alert />
                {/* SHOW ALERT MESSAGES START */}
                {IsEPProcessing ? (
                  <EasyPaisaProcessing />
                ) : (
                  <img
                    src={IMAGE_SRC.BSECURE_ANNIMATION}
                    alt="Credit Card Screen"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal For Verification Popup Starts Here */}
      <Modal />
      {/* Modal For Verification Popup Ends Here */}
    </AppWrapper>
  );
}

export default Processing;
