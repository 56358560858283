// @flow
//eslint-disable-next-line

import React from "react";

// import { CheckoutWrapper } from "../../../components";
import CheckoutWrapper from "../../../components/CheckoutWrapper";

import { BREADCRUMB_SETTINGS_JSON } from "../../../utils";
import AddressComponent from "./main";

function EditAddress() {
  return (
    <CheckoutWrapper
      fullscreenModeEnabled={false}
      showMap={false}
      child={<AddressComponent />}
      breadcrumbSettings={BREADCRUMB_SETTINGS_JSON.CHECKOUT}
    />
  );
}

export default EditAddress;
