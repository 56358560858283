// @flow
//eslint-disable-next-line

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";
import Alert from "../../components/Alert";
import LoadingButton from "../../components/LoadingButton";
import PhoneNumberComponent from "../../components/PhoneNumberComponent";

import { parsePhoneNumber, isValidNumber } from "libphonenumber-js";

import {
  alertActions,
  formAction,
  networkAction,
  verificationAction,
  metaAction,
} from "../../store/actions";
import {
  ANALYTICS_HELPER,
  GOOGLE_ANALYTICS_HELPER,
  HELPER,
  REDIRECTION_RULE
} from "../../utils";

function VerifyPhoneForm() {
  let dispatch = useDispatch();
  const translate = useTranslate();


  const { sendPhoneVerification } = useSelector(state => state.configuration);
  const { networks, networkList, phone_number } = useSelector(state => state.network);
  const { locale_updated } = useSelector(state => state.action);
  const { contact_number, selected_country } = useSelector(state => state.form);
  const {
    defaultPhoneCode,
    phoneCodeDropdownList,
    defaultCountry,
  } = useSelector(state => state.meta);

  // network_name: customer.network_name,
  // networkId: customer.network_id,

  const networkId = location.state?.network_id;

  let errors = {
    phone_number: "",
  };

  const [phoneNumber, setPhoneNumber] = useState(contact_number);
  const [default_country, setDefaultCountry] = useState([]);
  const [country, setCountry] = useState(selected_country);
  const [network_id, setNetwork_id] = useState(null);
  const [network_name, setNetwork_name] = useState(null);

  const [error, setError] = useState(errors);

  //componentDidMount
  useEffect(() => {
    REDIRECTION_RULE.PRE_PHONE_REDIRECTION_RULE()
    if (!locale_updated && HELPER.isEmpty(phoneCodeDropdownList)) {
      // Clear alerts
      dispatch(alertActions.clear());
      //Call meta data API so that default country is selected and we are able to select network
      dispatch(metaAction.COUNTRY_DROPDOWN_LIST());

      //Case: Network Screen
      // Check if network id is recieved via route parameters from select-network screen
      if (!HELPER.isEmpty(networkId)) {
        setNetwork_id(networkId);
        setPhoneNumber(phone_number);
      }
    }
    if (!HELPER.isEmpty(phoneNumber) && !HELPER.isEmpty(country)) {
      setPhoneNumber(phoneNumber);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  useEffect(() => {
    //For network API call set default country code
    if (!HELPER.isEmpty(defaultPhoneCode?.id)) {
      const code = defaultPhoneCode?.id;
      const selected_country = HELPER.findInJson(phoneCodeDropdownList, code);
      setDefaultCountry(selected_country);
    }

    if (!HELPER.isEmpty(defaultCountry?.value)) {
      // Get all networks
      dispatch(networkAction.get_all_networks(defaultCountry?.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCountry, defaultPhoneCode]);

  const handleChange = (phone, dialCode, country) => {
    // Set errors to empty
    let errors = {
      phone_number: "",
    };
    setError(errors);
    //Set phone number and country
    setCountry(country);
    setPhoneNumber(phone);
    //Save country to reducer
    const countryCallingCode = country.dialCode;
    dispatch(formAction.UPDATE_COUNTRY(country));
    dispatch(formAction.UPDATE_PHONE_NUMBER(phone.replace(countryCallingCode, "")));
    if (HELPER.isEmpty(location.state?.network_id)) {
      setNetworkInfo(phone);
    }
  };

  const setNetworkInfo = (number) => {
    let network_name = HELPER.getNetworkIdFromPhoneNumber(networks, number);
    if (!HELPER.isEmpty(network_name) && number.length <= 2) {
      dispatch(networkAction.update_network_value(networkList, network_name));
    }
    setNetwork_id(HELPER.getNetworkIdFromName(networkList, network_name));
    setNetwork_name(network_name);
  };

  const validatePhone = () => {
    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.PHONE,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PHONE_NUMBER.INPUT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PHONE_NUMBER.INPUT.LABEL,
        properties: {}
      }
    );

    let code = country.dialCode
    if (phoneNumber.substr(0, code.length) === country.dialCode) {
      return parsePhoneNumber("+" + phoneNumber);
    } else {
      return parsePhoneNumber("+" + country.dialCode + phoneNumber);
    }
  };

  const submitForm = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const submitFormOnClick = (event) => {
    event.preventDefault();

    handleSubmit();
  };

  const handleSubmit = () => {
    HELPER.hideShowFooter(true)

    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.PHONE,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PHONE_NUMBER.SUBMIT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PHONE_NUMBER.SUBMIT.LABEL,
        properties: {

        }
      }
    );

    let validationCheck = handleValidation();
    if (validationCheck.phone_number === "") {
      // const countryCallingCode = country.dialCode;
      const data = {
        phone_number: phoneNumber.replace(country.dialCode, ""),
        country_code: country.dialCode,
        network_name,
        network_id,
      };
      dispatch(verificationAction.OTP_SEND(data, history));
    }
  };

  const handleValidation = () => {
    let errors = {
      phone_number: "",
    };
    const phone = validatePhone();
    if (!phoneNumber) {
      errors.phone_number = translate("VALIDATIONS.IS_REQUIRED");
    }
    if (!HELPER.isEmpty(phoneNumber) && isValidNumber(phone.number) === false) {
      errors.phone_number = translate("invalid_phone_number");
    }
    setError(errors);
    return errors;
  };

  // const handlePhoneSave = () => {
  //   dispatch(networkAction.update_network_phone(phoneNumber, network_id, network_name));
  // };

  return (
    <>
      <h1>{translate("PHONE.TITLE")}</h1>
      <p className="signviewPtext">{translate("PHONE.SUB_TITLE")}</p>
      <Alert />
      {
        !HELPER.isEmpty(default_country) ? (
          <div className="country-phone-input">
            <form onSubmit={submitFormOnClick} id="phone-screen-form">
              <div
                className={
                  error.phone_number !== ""
                    ? "dropInput formInput md-form error"
                    : "dropInput"
                }
              >
                <div
                  className={
                    error.phone_number !== ""
                      ? "input-group error"
                      : "input-group"
                  }
                  unselectable="on"
                  onDoubleClick={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                  onKeyDown={submitForm}
                  onClick={() => HELPER.hideShowFooter(true)}
                  onBlur={() => HELPER.hideShowFooter(false)}
                  onFocus={() => HELPER.hideShowFooter(true)}
                >
                    <PhoneNumberComponent
                      countryCode={!HELPER.isEmpty(country) ? country?.dialCode : ""}
                      phone={phoneNumber}
                      handleChange={handleChange}
                      defaultCountry={default_country}
                      error={!HELPER.isEmpty(error.phone_number) ? true : false}
                      errorMessage={error.phone_number}
                    />
                </div>
                {
                  error.phone_number !== "" ? (
                    <span className="errorMessage">{error.phone_number}</span>
                  ) : ""
                }
              </div>
              <div className="notCode">
                {/* <NotOnNetwork savePhoneNumber={handlePhoneSave} screen="phone" /> */}
              </div>
              <button
                id="phone-number-submit"
                type="submit"
                className="btn btn-primary width150"
                disabled={HELPER.isEmpty(phoneNumber) || sendPhoneVerification ? true : false}
              >
                <LoadingButton
                  loadingState={sendPhoneVerification}
                  preloadingText={translate("next_btn")}
                  loadingText={translate("next_btn")}
                />
              </button>
            </form>
          </div>
        ) : (
          <div></div>
        )
      }
    </>
  );
}


export default VerifyPhoneForm;
