// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import InfoIcon from "@material-ui/icons/Info";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { IconButton } from "@material-ui/core";
import HtmlTooltip from "../../../components/HtmlTooltip";
import CheckoutCvvRequirement from "./cvv_requirement";
import { CONSTANTS, HELPER, history, ROUTE_CONSTANTS, LOCAL_STORAGE_SERVICE } from "../../../utils";
import { shippingAction } from "../../../store/actions";
import Skeleton from "@material-ui/lab/Skeleton";

const FORM_TYPE = CONSTANTS.PAYMENT_METHOD.FORM_TYPE;

const CheckoutShipment = (shipmentMethod, currency_code, isUniversal) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const moveToEditShipping = (shipmentMethod) => {
    dispatch(shippingAction.EDIT_MERCHANT_SHIPMENT(shipmentMethod))
    history.push({
      pathname: ROUTE_CONSTANTS.SHIPPING
    });
  };

  return (
    <>
      <div
        className={`
        ${!HELPER.isEmpty(shipmentMethod.shipment_icon) ? "subhead-detail" : ""}
        ${isUniversal ? "subhead-outline" : ""}
      `}
        key={`${shipmentMethod?.name}-${shipmentMethod.id}`}
      >
        <div className={`companyName`}> {shipmentMethod.store_name} </div>
        <div className={"subhead "}>
          {
            !HELPER.isEmpty(shipmentMethod.shipment_icon) && !isUniversal ? (
              <div className={"icon"}>
                <img src={shipmentMethod.shipment_icon} alt={"credit card"} />
              </div>
            ) : ""
          }
          <div className={`information ${!HELPER.isEmpty(shipmentMethod.shipment_icon) ? "information-icon" : ""}`}>
            <span>
              {HELPER.isEmpty(shipmentMethod?.name)
                ? ""
                : HELPER.titleCase(shipmentMethod?.name) +
                " - " +
                HELPER.showPrice(currency_code, shipmentMethod?.cost ?? 0)}
            </span>
          </div>
          {isUniversal && shipmentMethod.disable_edit === 0 ? (
            <button
              type="button"
              onClick={() => {
                moveToEditShipping(shipmentMethod);
              }}
              className="edit"
              id="edit-shipping-universal"
            >
              {translate("edit_btn")}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

function CheckoutInfo(props) {
  const translate = useTranslate();
  const { errors_cc_cvv, errors_cc_cvv_message } = props;
  const {
    merchant_bank_details,
    payment_method,
    delivery_address,
    shipment_method,
    cart_details_loading
  } = useSelector((state) => state.cart);
  const { placement_type, currency_code } = useSelector((state) => state.cart.cart_details.summary);
  const [hideShipment, setShipmentHidden] = useState(true);

  const isPaymentGateway =
    CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY === placement_type;
  const isInvoice = CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE === placement_type;
  const isUniversal = CONSTANTS?.ORDER_PLACEMENT_TYPE.UNIVERSAL === placement_type;
  const isGiveWp = CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP === placement_type;
  //if customer app then not allow back from cart page
  React.useEffect(() => {
    if (!!isUniversal && !LOCAL_STORAGE_SERVICE._isFirstScreen()) {
      LOCAL_STORAGE_SERVICE._setFirstScreen()
    }
  }, [isUniversal])


  React.useEffect(() => {
    if (shipment_method.type !== 2 && !isUniversal) {
      setShipmentHidden(!(isPaymentGateway || isInvoice || isGiveWp) && (shipment_method.id > 0 && shipment_method?.type === 1));
    }else if(shipment_method.type === 2 ){
      setShipmentHidden(false)
    }
  }, [shipment_method])

  const moveToEditAddress = () => {
    history.push({
      pathname: ROUTE_CONSTANTS.ADDRESS_LIST
    });
  };

  const moveToEditPayment = () => {
    history.push({
      pathname: ROUTE_CONSTANTS.PAYMENT
    });
  };

  const moveToEditShipping = () => {
    history.push({
      pathname: ROUTE_CONSTANTS.SHIPPING
    });
  };

  if(cart_details_loading) return (
    <div className="checkOutInfo">
      <p>
        <Skeleton variant="rectangular" height={50} />
      </p>
      <p>
        <Skeleton variant="rectangular" height={50} />
      </p>
      <p>
        <Skeleton variant="rectangular" height={50} />
      </p>
    </div>
  )

  return (
    <div className="checkOutInfo">
      {!(isPaymentGateway || isInvoice || isGiveWp) ? (
        <div className="checkOutInfoBox">
          <div className="iconCheckout">
            <span className="icomoon-review-address"></span>
          </div>
          <div className="checkOutDetail">
            <div className="top">
              <div className="head">
                {translate("delivery_address_heading")}
              </div>
              {!isUniversal ? <button
                type="button"
                onClick={() => {
                  moveToEditAddress(delivery_address?.id);
                }}
                className="edit"
                id="edit-address"
              >
                {translate("edit_btn")}
              </button> : <div />}
            </div>
            <div className="main">
              <p className="subhead review-address">{delivery_address?.address}</p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {hideShipment ? (
        <div
          className={`checkOutInfoBox ${isUniversal ? "shippingInfoBoxUr" : ""}`}
        >
          <div className="iconCheckout">
            <span className="icomoon-breadcrumb-shipping-colored"></span>
          </div>
          <div className="checkOutDetail">
            <div className="top">
              <div className="head">{translate("shipment_method_heading")}</div>
              {isUniversal  ?  "" :
                <button
                  type="button"
                  onClick={() => {
                    moveToEditShipping(shipment_method?.id);
                  }}
                  className="edit"
                  id="edit-shipping"
                >
                  {translate("edit_btn")}
                </button>}
            </div>
            <div className={`main`}>
              {HELPER.isEmpty(shipment_method) ? "" : isUniversal && Object.keys(shipment_method).length > 0
                ? shipment_method.map((shipment) =>
                  CheckoutShipment(shipment, currency_code, isUniversal)
                )
                : CheckoutShipment(shipment_method, currency_code, isUniversal)}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="checkOutInfoBox">
        <div className="iconCheckout">
          <span className="icomoon-review-payment"></span>
        </div>
        <div className="checkOutDetail">
          <div className="top">
            <div className="head">{translate("payment_method_heading")}</div>
            { payment_method.disable_edit === 0 ? <button
              type="button"
              onClick={() => {
                moveToEditPayment(payment_method);
              }}
              id="edit-payment"
              className="edit"
            >
              {translate("edit_btn")}
            </button> : "" }
          </div>
          <div className="main">
            <div className="subhead">
              <div className="information">
                {payment_method?.name}
                {payment_method?.form_type === FORM_TYPE.DBT ? (
                  <div className="childhead">
                    <span>
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        disableFocusRipple={true}
                      >
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 1000 }}
                          overlay={HtmlTooltip}
                          popperConfig={{
                            props: {
                              state:
                                CONSTANTS.CHECKOUT_INFO.PAYMENT_METHOD.TOOLTIP
                                  .DBT,
                              merchant_bank_details
                            }
                          }}
                        >
                          <InfoIcon />
                        </OverlayTrigger>
                      </IconButton>
                    </span>
                    {merchant_bank_details?.bank_name}
                  </div>
                ) : (
                  <CheckoutCvvRequirement
                    errors_cc_cvv={errors_cc_cvv}
                    errors_cc_cvv_message={errors_cc_cvv_message}
                    updateCvv={props.updateCvv}
                  />
                )}
                {parseInt(payment_method.payment_protection_enabled) === 1 ? (
                  <div className="childhead">
                    <span>
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        disableFocusRipple={true}
                      >
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 1000 }}
                          overlay={HtmlTooltip}
                          popperConfig={{
                            props: {
                              state:
                                CONSTANTS.CHECKOUT_INFO.PAYMENT_METHOD
                                  .PAYMENT_GUARENTEED
                            }
                          }}
                        >
                          <InfoIcon />
                        </OverlayTrigger>
                      </IconButton>
                    </span>
                    {translate("CART.PAYMENT_PROTECTION")}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutInfo;
