import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Dialog,
    Slide
} from "@material-ui/core";

import {
    PrivacyDialog,
    TermsDialog,
    ExpiredOrderDialog,
    LanguageDialog,
} from "../Dialog";

import { dialogAction } from "../../store/actions";
import { CONSTANTS } from "../../utils";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MODAL = CONSTANTS.DIALOG.CATEGORY.MODAL
const POPUP = CONSTANTS.DIALOG.CATEGORY.POPUP


const TERMS_DIALOG = CONSTANTS.DIALOG.TYPE.MODAL.TERMS
const PRIVACY_DIALOG = CONSTANTS.DIALOG.TYPE.MODAL.PRIVACY
const LANGUAGE_DIALOG = CONSTANTS.DIALOG.TYPE.POPUP.LANGUAGE
const ORDER_EXPIRED_DIALOG = CONSTANTS.DIALOG.TYPE.POPUP.ORDER_EXPIRED

function DialogComponent() {
    let dispatch = useDispatch();

    const {
     setOpen,
    } = useSelector(state => state.action);
    const {type, category, } = useSelector(state => state.action.dialog);

    const [open, unsetOpen] = React.useState(setOpen);

    useEffect(() => {
        unsetOpen(setOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setOpen]);

    const handleClose = (event, reason) => {
       if (reason === "disableBackdropClick") {
          return category === POPUP;
        } else {
            unsetOpen(false);
            dispatch(dialogAction.closeDialog());
        }
    };

    return (
        <div>
            <Dialog
                onClose={handleClose}
                className={`${category === POPUP ? "dialogPopup" : "modalPopup"} ${type === LANGUAGE_DIALOG ? " dialogLanguage" : ""} ${type === ORDER_EXPIRED_DIALOG ? "dialogExpired" : ""}`}
                // disableBackdropClick={category === POPUP}
                open={open}
                TransitionComponent={Transition}
                keepMounted={category === POPUP}
                PaperProps={{
                    className: `${category === MODAL ? "modalPopup-paper" : "dialogPopup-paper"} ${type === TERMS_DIALOG ? "TermsPopup" : (type === PRIVACY_DIALOG ? "PrivacyPopup" : "")}`
                }}
            >
                {category === MODAL && type === TERMS_DIALOG ? <TermsDialog handleClose={handleClose} /> : ""}
                {category === MODAL && type === PRIVACY_DIALOG ? <PrivacyDialog handleClose={handleClose} /> : ""}
                {category === POPUP && type === ORDER_EXPIRED_DIALOG ? <ExpiredOrderDialog handleClose={handleClose} /> : ""}
                {category === POPUP && type === LANGUAGE_DIALOG ? <LanguageDialog handleClose={handleClose} /> : ""}
            </Dialog>
        </div >
    );
}

export default DialogComponent;
