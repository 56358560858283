import { ADDRESS_CONSTANTS, META_CONSTANTS, CONFIG_CONSTANTS } from "../actionTypes";
import {ROUTE_CONSTANTS} from '../../utils'

const initState = {
  addressBook: [],
  addressUpdated: false,
  selectedAddress: [],
  selectedProfile: [],
  countryDropdownLoading: false,
  selectedDropdown: [],
  selected_address_index: "",
  deleted_address_index: "",
  //Gets address location for get-address-location-api and returns user address
  locationDetails: [],
  find_geo_location: false,
  disableLocationBtn: false
};

const addressReducer = (state = initState, action) => {
  let fullScreen=window.location.pathname ===  ROUTE_CONSTANTS.MAP_FULL_SCREEN;
  
  switch (action.type) {
    case ADDRESS_CONSTANTS.SET_SELECTED_ADDRESS_INDEX:
      return {
        ...state,
        selected_address_index: parseInt(action.selected_index)
      };
    case CONFIG_CONSTANTS.INITIATE_ORDER_REQUEST:
    case CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_REQUEST:
      return initState;
    //GET ALL
    case ADDRESS_CONSTANTS.GET_CUSTOMER_ADDRESS_LIST_REQUEST:
      return state;
    case ADDRESS_CONSTANTS.GET_CUSTOMER_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        addressBook: action.response?.addresses,
        selectedAddress: [],
        locationDetails: [],
      };
    case ADDRESS_CONSTANTS.GET_CUSTOMER_ADDRESS_LIST_FAILURE:
      return state;
    // ADD NEW ADDRESS
    case ADDRESS_CONSTANTS.ADD_CUSTOMER_ADDRESS_REQUEST:
      return {
        ...state,
      };
    case ADDRESS_CONSTANTS.ADD_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        selectedAddress: action.address,
        locationDetails: [],
      };
    case ADDRESS_CONSTANTS.ADD_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
      };
    // ADD NEW ADDRESS
    case ADDRESS_CONSTANTS.ADD_NEW_ADDRESS_REQUEST:
      return {
        ...state,
      };
    case ADDRESS_CONSTANTS.ADD_NEW_ADDRESS_SUCCESS:
      return {
        ...state,
        selectedAddress: action.address,
        selectedProfile: action?.profile,
        selectedDropdown: action?.dropdown,
        addressUpdated: true,
        locationDetails: [],
      };
    case ADDRESS_CONSTANTS.ADD_NEW_ADDRESS_FAILURE:
      return {
        ...state,
      };
    // UPDATE
    case ADDRESS_CONSTANTS.UPDATE_CUSTOMER_ADDRESS_REQUEST:
      return {
        ...state,
      };
    case ADDRESS_CONSTANTS.UPDATE_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        selectedAddress: action?.data,
        locationDetails: [],
        addressUpdated: true,
      };
    case ADDRESS_CONSTANTS.UPDATE_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
      };
    case ADDRESS_CONSTANTS.DELETE_CUSTOMER_ADDRESS_REQUEST:
      return {
        ...state,
        deleted_address_index: action?.deleted_index
      };
    case ADDRESS_CONSTANTS.DELETE_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        addressBook: action.address?.addresses,
      };
    case ADDRESS_CONSTANTS.DELETE_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
        deleted_address_index: ""
      };
    case META_CONSTANTS.DEFAULT_META_REQUEST:
      return {
        ...state,
        countryDropdownLoading: true,
        selectedDropdown: [],
      };
    case META_CONSTANTS.DEFAULT_META_SUCCESS:
      return {
        ...state,
        countryDropdownLoading: false,
        selectedDropdown: action.metaData,
      };
    case META_CONSTANTS.DEFAULT_META_FAILURE:
      return {
        ...state,
        countryDropdownLoading: false,
      };
    case ADDRESS_CONSTANTS.GET_LOCATION_DETAILS_REQUEST:
      return {
        ...state,
        disableLocationBtn: false
      };
    case ADDRESS_CONSTANTS.GET_LOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        find_geo_location: true,
        locationDetails: fullScreen ? state.locationDetails : action.data,
        locationDetailsMap: fullScreen ? action.data : [],
      };
    //address locationDetails save on confirm button
    case ADDRESS_CONSTANTS.CONFIRM_ADDRESS_FULLSCREEN_MAP:
       return {
        ...state,
        find_geo_location: true,
        locationDetails: (state.locationDetailsMap?.address || state.locationDetailsMap?.country_id) ? state.locationDetailsMap : state.locationDetails,
        locationDetailsMap: [],
      };
    case ADDRESS_CONSTANTS.GET_LOCATION_DETAILS_FAILURE:
      return {
        ...state,
        disableLocationBtn: true
      };
    //on empty address dont clear locationDetails if fullScreen
    case ADDRESS_CONSTANTS.EMPTY_LOCATION_DETAILS: 
      return {
        ...state,
        locationDetails: fullScreen ? state.locationDetails: 
          !action.clearError && state.locationDetails?.error_msg ?  {error_msg: state.locationDetails?.error_msg} : [],
        selectedAddress: [],
        locationDetailsMap: [],
      };
    case ADDRESS_CONSTANTS.SELECT_CUSTOMER_ADDRESS:
      return {
        ...state,
        selectedAddress: action?.data,
        addressUpdated: false,
      };
    case ADDRESS_CONSTANTS.SET_LOCATION_DETAILS:
      return {
        ...state,
        locationDetails: action.response,
      };
    default:
      return state;
  }
};
export default addressReducer;
