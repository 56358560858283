// @flow
import React from "react";
import { useSelector } from "react-redux";
import { CONSTANTS, ROUTE_CONSTANTS } from '../../utils'
import { GoogleMap } from "../../containers";
// import GoogleMap from "../../containers/GoogleMap";

function CheckoutMap({ showMap }) {
  const { is_pin_location_mandatory, is_international_shipment } = useSelector(state => state.configuration.config);
  const { locationPermission, currentPositionByBtn } = useSelector(state => state.map);
  var map = showMap && (is_pin_location_mandatory ||
    is_international_shipment ||
    // for pin_location not mandatory
    // hide map if prompt permission
    ([CONSTANTS.PERMISSIONS.PROMPT, CONSTANTS.PERMISSIONS.GRANTED].includes(locationPermission) && currentPositionByBtn)) ? (
    <>
        <div className={`GmapWrapper customGmapWrapper 
        ${(!is_pin_location_mandatory && window.location.pathname !== ROUTE_CONSTANTS.MAP_FULL_SCREEN && locationPermission === CONSTANTS.PERMISSIONS.PROMPT && currentPositionByBtn)
          // || (is_international_shipment && !setCoordinatesLng)
            ? "d-none" : ""}`}>
          <GoogleMap />
      </div>
    </>
  ) : (
    <></>
  );
  return map;
}

export default CheckoutMap;
