// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { OrderReceipt, OrderStatusUpdate } from "../../../containers";
import ReturntoMerchant from "../../../components/ReturntoMerchant";

import {
  BREADCRUMB_SETTINGS_JSON,
  CONSTANTS,
  HELPER,
  IMAGE_SRC,
} from "../../../utils";
import { cartAction, logoutAction } from "../../../store/actions";


const REDIRECT_MERCHANT_TIMEOUT =
  process.env.REACT_APP_REDIRECT_TO_MERCHANT_TIMEOUT;
const RETURN_TO_MERCHANT_TIMEOUT =
  process.env.REACT_APP_RETURN_TO_MERCHANT_TIMEOUT;

const PRE_PAYMENT_REDIRECT_MERCHANT_TIMEOUT =
  process.env.REACT_APP_PRE_PAYMENT_REDIRECT_TO_MERCHANT_TIMEOUT;
const PRE_PAYMENT_RETURN_TO_MERCHANT_TIMEOUT =
  process.env.REACT_APP_PRE_PAYMENT_RETURN_TO_MERCHANT_TIMEOUT;

const PLACEMENT_STATUS = CONSTANTS.ORDER_STATUS;

function OrderSuccess() {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const { placement_status, cart_success,
    banner_title, banner_alert, banner_icon, banner_theme } = useSelector(state => state.cart);

  const redirect_url = cart_success?.redirect_url;
  const store_url = cart_success?.store_url;
  const showPaidStamp = cart_success?.showPaidStamp;
  const ask_for_verification = cart_success?.ask_for_verification;
  const verification_call = cart_success?.verification_call;
  const isProcessing =
    parseInt(PLACEMENT_STATUS.AWAITING_CONFIRMATION) ===
    parseInt(placement_status);

  const breadcrumbOptionParams = () => {
    let breadcrumb_state
    if (verification_call && isProcessing) {
      breadcrumb_state = BREADCRUMB_SETTINGS_JSON.PRE_PAYMENT_ORDER.VERIFICATION_CALL
    }
    else if (!verification_call && isProcessing) {
      breadcrumb_state = BREADCRUMB_SETTINGS_JSON.PRE_PAYMENT_ORDER.GENERAL
    }
    else if (verification_call && !isProcessing) {
      breadcrumb_state = BREADCRUMB_SETTINGS_JSON.PLACED_ORDER.VERIFICATION_CALL
    }
    else if (!verification_call && !isProcessing) {
      breadcrumb_state = BREADCRUMB_SETTINGS_JSON.PLACED_ORDER.GENERAL
    }
    else {
      breadcrumb_state = BREADCRUMB_SETTINGS_JSON.PLACED_ORDER.GENERAL
    }

    return breadcrumb_state;
  }

  const OrderSuccessComponent = () => {
    return (
      <>
        <OrderReceipt
          showPaidStamp={showPaidStamp === 1 ? true : false}
          transaction_data={cart_success}
          showTransactionSlip={true}
        />
        <div className="emoji d-none">
          <button className="nHref" type="button">
            <img src={IMAGE_SRC.EMOJI.ONE} alt="emoji" />
          </button>
          <button className="nHref" type="button">
            <img src={IMAGE_SRC.EMOJI.TWO} alt="emoji" />
          </button>
          <button className="nHref" type="button">
            <img src={IMAGE_SRC.EMOJI.THREE} alt="emoji" />
          </button>
          <button className="nHref" type="button">
            <img src={IMAGE_SRC.EMOJI.FOUR} alt="emoji" />
          </button>
          <button className="nHref" type="button">
            <img src={IMAGE_SRC.EMOJI.FIVE} alt="emoji" />
          </button>
        </div>
      </>
    );
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    document.body.className = "removeBackdrop";
    const return_to_merchant_timeout = !isProcessing
      ? RETURN_TO_MERCHANT_TIMEOUT
      : PRE_PAYMENT_RETURN_TO_MERCHANT_TIMEOUT;
    const redirect_to_merchant_timeout = !isProcessing
      ? REDIRECT_MERCHANT_TIMEOUT
      : PRE_PAYMENT_REDIRECT_MERCHANT_TIMEOUT;
    const disableBackdropClick = !isProcessing ? true : false;
    const modal = {
      title: translate("VERIFICATION.POPUP_TITLE"),
      content: "",
      cancelBtn:
        ask_for_verification === 1 && !HELPER.isEmpty(redirect_url) ? (
          <ReturntoMerchant />
        ) : (
          translate("cancel_btn")
        ),
      successBtn: translate("VERIFICATION.SUCCESS_BTN"),
      disableBackdropClick,
      cancelBtnId: "cancel",
      successBtnId: "return-to-merchant",
    };
    // Check props value
    if (ask_for_verification === 1) {
      dispatch(cartAction.verify_customer_after_checkout(modal));
    } else {
      if (!HELPER.isEmpty(redirect_url)) {
        setTimeout(() => {
          dispatch(cartAction.return_to_merchant_button(
            redirect_url,
            store_url,
            disableBackdropClick
          ));
        }, return_to_merchant_timeout);
        setTimeout(() => {
          dispatch(logoutAction.CLEAR_REDUX_STORE())
          HELPER.redirectToPathAndClearLocalStorage(redirect_url, store_url);
        }, redirect_to_merchant_timeout);
      }
    }

    // Send message to bulider website for hosted checkout //
    HELPER.sendMerchantBeforeUnloadListener(redirect_url, store_url);
    // returned function will be called on component unmount
    return () => {
      document.body.className = "";
      if (window.opener !== null) {
        // window.onunload = null;
        window.onbeforeunload = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let options=breadcrumbOptionParams()
  return (
    <OrderStatusUpdate
      pageHeading={banner_title}
      screenSubHeading={cart_success?.message }
      breadcrumbOptionParams={{...options, ICON: banner_icon, banner_alert, banner_theme}}
      child={OrderSuccessComponent()}
      showLangIcon={false}
    />
  );
}

export default OrderSuccess;
