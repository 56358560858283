import { ADDRESS_CONSTANTS, MAP_CONSTANTS, CONFIG_CONSTANTS } from "../actionTypes";
import { CONSTANTS, ROUTE_CONSTANTS } from '../../utils'

const initState = {
  // Add Address Lat Lng
  setCoordinatesLat: "",
  setCoordinatesLng: "",
  // Add Address Lat Lng Full Screen
  setCoordinatesLatMap: "",
  setCoordinatesLngMap: "",
  // Edit Address LAt Lng
  updateCoordinatesLat: "",
  updateCoordinatesLng: "",
  updateLocBounds: false,
  updateLocBoundsLat: "",
  updateLocBoundsLng: "",
  searchPlace: "",
  getMyLocation: false,
  //Full screen mode
  enableFullScreenMode: false,
  clearAddress: false,
  locationPermission: CONSTANTS.PERMISSIONS.PROMPT,
  currentPositionByBtn: false,
  searchAddress: "",
};

const mapReducer = (state = initState, action) => {
  let fullScreen = window.location.pathname === ROUTE_CONSTANTS.MAP_FULL_SCREEN;

  switch (action.type) {
    case MAP_CONSTANTS.SEARCHED_ADDRESS:
      return {
        ...state,
        updateLocBounds: false,
        searchAddress: action.value
      };
    case ADDRESS_CONSTANTS.GET_CUSTOMER_ADDRESS_LIST_REQUEST:
      return {
        ...state,
        updateCoordinatesLat: "",
        updateCoordinatesLng: "",
        setCoordinatesLat: "",
        setCoordinatesLng: "",
        updateLocBounds: false,
        clearAddress: false,
        currentPositionByBtn: false,
      };
    case CONFIG_CONSTANTS.INITIATE_ORDER_REQUEST:
    case CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_REQUEST:
      return initState;
    case MAP_CONSTANTS.ADD_ADDRESS_LAT_LNG_REQUEST:
      return {
        ...state,
        updateCoordinatesLat: "",
        updateCoordinatesLng: "",
        setCoordinatesLat: fullScreen ? state.setCoordinatesLat : action?.lat,
        setCoordinatesLng: fullScreen ? state.setCoordinatesLng : action?.lng,
        clearAddress: false,
        updateLocBounds: true,
        updateLocBoundsLat: fullScreen ? action?.lat : "",
        updateLocBoundsLng: fullScreen ? action?.lng : "",
        //full screen Map
        setCoordinatesLatMap: fullScreen ? action?.lat : "",
        setCoordinatesLngMap: fullScreen ? action?.lng : "",
      };
    //lat lng save on confirm button
    case ADDRESS_CONSTANTS.CONFIRM_ADDRESS_FULLSCREEN_MAP:
      return {
        ...state,
        updateCoordinatesLat: "",
        updateCoordinatesLng: "",
        setCoordinatesLat: state?.setCoordinatesLatMap || state?.setCoordinatesLat,
        setCoordinatesLng: state?.setCoordinatesLngMap || state?.setCoordinatesLng,
        clearAddress: false,
        updateLocBounds: true,
        updateLocBoundsLat: state?.setCoordinatesLatMap || state?.setCoordinatesLat,
        updateLocBoundsLng: state?.setCoordinatesLngMap || state?.setCoordinatesLng,
        //full screen Map
        setCoordinatesLatMap: "",
        setCoordinatesLngMap: "",
      };
    case MAP_CONSTANTS.UPDATE_ADDRESS_LAT_LNG_REQUEST:
      return {
        ...state,
        updateCoordinatesLat: action?.lat,
        updateCoordinatesLng: action?.lng,
        updateLocBoundsLat: action?.lat,
        updateLocBoundsLng: action?.lng,
        updateLocBounds: true,
        clearAddress: false,
      };
    case MAP_CONSTANTS.FULL_SCREEN_MODE:
      return {
        ...state,
        enableFullScreenMode: action?.state,
        clearAddress: false,
        updateLocBounds: false,
      };
    case MAP_CONSTANTS.MAP_PERMISSION_PROMPT:
      return {
        ...state,
        currentPositionByBtn: action?.state,
      };
    case ADDRESS_CONSTANTS.ADD_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        currentPositionByBtn: false,
        updateLocBounds: false,
      };
    case ADDRESS_CONSTANTS.UPDATE_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        currentPositionByBtn: false,
        updateLocBounds: false,
      };
    case ADDRESS_CONSTANTS.ADD_NEW_ADDRESS_SUCCESS:
      return {
        ...state,
        currentPositionByBtn: false,
        updateLocBounds: false,
      };

    case CONFIG_CONSTANTS.INITIATE_ORDER_SUCCESS:
      return {
        ...state,
        enableFullScreenMode: action?.response?.config?.is_pin_location_mandatory,
      };
    case ADDRESS_CONSTANTS.EMPTY_LOCATION_DETAILS:
      // full screen retain previous data
      return {
        ...state,
        // Add Address Lat Lng
        setCoordinatesLat: fullScreen ? state.setCoordinatesLat : "",
        setCoordinatesLng: fullScreen ? state.setCoordinatesLng : "",
        // Edit Address LAt Lng
        updateCoordinatesLat: "",
        updateCoordinatesLng: "",
        updateLocBounds: false,
        searchPlace: fullScreen && state.setCoordinatesLng ? state.searchPlace : "",
        clearAddress: fullScreen && state.setCoordinatesLng ? false : true,
        //full screen Map
        setCoordinatesLatMap: "",
        setCoordinatesLngMap: "",
      };
    case ADDRESS_CONSTANTS.GET_LOCATION_DETAILS_REQUEST:
      return {
        ...state,
        updateLocBounds: false,
      };
    case ADDRESS_CONSTANTS.GET_LOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        updateLocBounds: false,
        searchPlace: action.data.address || state?.searchAddress,
        searchAddress: "",
      };
    case ADDRESS_CONSTANTS.GET_LOCATION_DETAILS_FAILURE:
      return {
        ...state,
        updateLocBounds: true,
        searchPlace: state?.searchPlace,
        updateLocBoundsLat: action?.data?.latitude,
        updateLocBoundsLng: action?.data?.longitude,
        searchAddress: state?.searchPlace
      };
    case MAP_CONSTANTS.UPDATE_PLACE_SEARCH_REQUEST:
      return {
        ...state,
        searchPlace: action?.address,
      };
    case MAP_CONSTANTS.GET_MY_LOCATION:
      return {
        ...state,
        getMyLocation: action?.state,
        clearAddress: false,
      };
    case MAP_CONSTANTS.INITIATE_MAP:
      return {
        ...state,
        clearAddress: false,
        updateLocBounds: false,
      };
    case MAP_CONSTANTS.UPDATE_PERMISSION_LOCATION:
      return {
        ...state,
        locationPermission: action?.locationPermission,
      };
    case ADDRESS_CONSTANTS.SET_SELECTED_ADDRESS_INDEX:
      return initState;
    default:
      return state;
  }
};
export default mapReducer;
