// @flow
//eslint-disable-next-line

import * as React from "react";

function OrderStatusBreadcrumb(props) {
  const { options } = props;

  const {
    ICON,
  } = options;

  return (
    <span>
      <div className="transBgicon f22">
        <i className={"iconW " + ICON}></i>
      </div>
    </span>
  );
}

export default OrderStatusBreadcrumb;
