// @flow
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import {
  IMAGE_SRC,
  ANALYTICS_HELPER,
  GOOGLE_ANALYTICS_HELPER,
  HELPER,
  TEXT_FORMAT
} from "../../../../utils";
import {
  paymentAction,
} from "../../../../store/actions";

function EasyPaisaMAPaymentForm(props) {
  const {
    payment,
    errors,
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const { easyPaisaMobileAccount } = errors;
  const { selected_payment_method } = useSelector(state => state.payment);
  const { customer_mobile_number, account_no } = selected_payment_method;

  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (selected_payment_method?.index === payment?.index) {
      if (HELPER.isEmpty(payment?.customer_mobile_number) && payment?.customer_mobile_number !== account_no) {
        trimPhoneNumber(payment.customer_mobile_number);
      } else if (!HELPER.isEmpty(payment?.account_no) && account_no !== "") {
        trimPhoneNumber(payment.account_no);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selected_payment_method?.index === payment?.index) {
      if (!HELPER.isEmpty(account_no)) {
        let trim = account_no;
        if (trim.substr(0, 1) === "0") {
          trim = trim.substr(1);
        }
        if (phoneNumber !== trim) {
          trimPhoneNumber(account_no);
        }
      } else if (!HELPER.isEmpty(customer_mobile_number) && payment?.customer_mobile_number !== account_no) {
        let trim = customer_mobile_number;
        if (trim.substr(0, 1) === "0") {
          trim = trim.substr(1);
        }
        if (phoneNumber !== trim) {
          trimPhoneNumber(customer_mobile_number);
        }
      } else if (HELPER.isEmpty(customer_mobile_number) && payment?.customer_mobile_number !== account_no) {
        setPhoneNumber("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_payment_method?.index]);

  useEffect(() => {
    if (selected_payment_method?.index === payment?.index &&
      !HELPER.isEmpty(selected_payment_method?.account_no) &&
      selected_payment_method?.account_no !== phoneNumber) {
      trimPhoneNumber(selected_payment_method?.account_no);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account_no]);

  useEffect(() => {
    if (
      selected_payment_method?.index === payment?.index &&
      !HELPER.isEmpty(phoneNumber) &&
      selected_payment_method?.account_no !== phoneNumber
    ) {
      dispatch(paymentAction?.UPDATE_EP_PHONE_NUMBER("0" + phoneNumber));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  const handleChange = (e) => {
    const number = e.target.value;
    const phone = number.substr(0, 10);
    let digits = TEXT_FORMAT.allowNumberOnly(TEXT_FORMAT.allowNumberOnly(phone));
    setPhoneNumber(digits);
    if (phone.length === 10) {
      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.PAYMENT,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.INPUT.EP_PHONE.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.INPUT.EP_PHONE.LABEL,
          properties: {}
        }
      );
    }
    // dispatch(paymentAction?.UPDATE_EP_PHONE_NUMBER("0" + digits));
  };

  const trimPhoneNumber = (phone) => {
    if (!HELPER.isEmpty(phone)) {
      let easy_paisa_phone_number = phone;
      if (easy_paisa_phone_number.substr(0, 1) === "0") {
        easy_paisa_phone_number = easy_paisa_phone_number.substr(1);
      }
      setPhoneNumber(easy_paisa_phone_number);
    }
  };

  return (
    <>
      <div className="easypaisaMA">
        <div
          className={`dropInput ${!HELPER.isEmpty(easyPaisaMobileAccount) ? "formInput md-form error text-left" : ""}`}
        >
          <div
            className={
              !HELPER.isEmpty(easyPaisaMobileAccount)
                ? "input-group md-form error mb-2"
                : "input-group"
            }
          >
            <div id="country_code" className="" tabIndex="1">
              <input
                id="country_code"
                name="country_code"
                type="hidden"
                className=""
                value="+92"
              />
              <div className="">
                <button type="button" className="ep-dropdown">
                  <div className="flagImg">
                    <img src={IMAGE_SRC.PK_FLAG} alt="pakistan-flag" />
                  </div>
                  <div className="CCode">+92</div>
                </button>
              </div>
            </div>
            <input
              type="tel"
              autoComplete="off"
              id={`phone_number-${payment?.index} ep-phone-number-${payment?.index}`}
              name="phone_number"
              value={phoneNumber}
              className="form-control phone_with_ddd"
              placeholder={translate("PHONE.PLACEHOLDER")}
              onChange={handleChange}
              dir="ltr"
              disabled={!HELPER.isEmpty(payment?.token_id)}
              key={payment?.index}
            />
          </div>
          {!HELPER.isEmpty(easyPaisaMobileAccount) ? (
            <span className="errorMessage">{easyPaisaMobileAccount}</span>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}


export default EasyPaisaMAPaymentForm;

