// @flow

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import queryString from "query-string";
import { Helmet } from "react-helmet";

import {
    CONSTANTS,
    HELPER
} from "../../../../utils";
import {
    alertActions,
    cartAction,
} from "../../../../store/actions";

function HBLValidationScript() {
    let dispatch = useDispatch();
    const cart = useSelector(state => state.cart);
    const { device_fingerprint } = cart;

    const [formSubmit, formSubmitted] = useState(false);

    // Save query string parameters
    const params = queryString.parse(location.search);
    //Order reference recieved incase of 3ds verified payment
    const secure_id = params?.secure_id;

    useEffect(() => {
        if (device_fingerprint.error === CONSTANTS.YES) {
            dispatch(alertActions.error(device_fingerprint.message, false))
            dispatch(cartAction.DEVICE_FINGERPRINT_ERROR_FOUND())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device_fingerprint.error])

    useEffect(() => {
        if (!HELPER.isEmpty(device_fingerprint?.setup_data?.deviceDataCollectionUrl) && HELPER.isEmpty(secure_id)) {
            window.addEventListener("message", (event) => {
                //{MessageType: "profile.completed", Session Id: "0_57f063fd-659a4779-b45b-9e456fdb7935", Status: true}
                if (event.origin === device_fingerprint?.setup_data?.origin) {
                    let data = JSON.parse(event.data);
                    if (data !== undefined && data.Status) {
                        dispatch(cartAction.DEVICE_FINGERPRINT_FOUND(data.message))
                    }
                }
            }, false);
            checkIframeLoaded();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device_fingerprint?.setup_data?.deviceDataCollectionUrl])

    const checkIframeLoaded = () => {
        // Get a handle to the iframe element
        var iframe = document.getElementById('cSourceIframe');
        if(!iframe) return
        var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

        // Check if loading is complete
        if (iframeDoc.readyState === 'complete') {
            //iframe.contentWindow.alert("Hello");
            iframe.contentWindow.onload = function () {
                // alert("I am loaded");
            };
            // The loading is complete, call the function we want executed once the iframe is loaded
            loadingComplete();
            return;
        }

        // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
        window.setTimeout(checkIframeLoaded, 100);
    }

    const loadingComplete = () => {
        const ddcForm = document.getElementById('deviceFingerprintForm');
        if (ddcForm && !formSubmit) // ddc form exists
        {
            formSubmitted(true);
            ddcForm.submit();

        }

        return false;
    }

    return device_fingerprint.required === CONSTANTS.YES && device_fingerprint.error === CONSTANTS.NO && HELPER.isEmpty(secure_id) ? <>
        <iframe onLoad={loadingComplete} id="cSourceIframe" title="cyber source" name="ddc-iframe" height="10" width="10" className="d-none" >
        </iframe>
        <form id="deviceFingerprintForm" target="ddc-iframe" method="POST" action={device_fingerprint?.setup_data?.deviceDataCollectionUrl}>
            <input type="hidden" name="JWT" value={device_fingerprint?.setup_data?.accessToken} />
        </form>
        <Helmet>
            <p style={`background:url(${process.env.REACT_APP_DFP_BASE_URL}/clear.png?org_id=${device_fingerprint?.setup_data?.org_id}&session_id=${device_fingerprint?.setup_data?.session_id}&m=1)`}></p>
            <img src={`${process.env.REACT_APP_DFP_BASE_URL}/clear.png?org_id=${device_fingerprint?.setup_data?.org_id}&session_id=${device_fingerprint?.setup_data?.session_id}&m-2`} alt="" />

            <object type="application/x-shockwave-flash" data={`${process.env.REACT_APP_DFP_BASE_URL}/fp.swf?org_id=${device_fingerprint?.setup_data?.org_id}&session_id=${device_fingerprint?.setup_data?.session_id}`} width="1" height="1" id="thm_fp">
                <param name="movie" value={`${process.env.REACT_APP_DFP_BASE_URL}/fp.swf?org_id=${device_fingerprint?.setup_data?.org_id}&session_id=${device_fingerprint?.setup_data?.session_id}`} />
                <div></div>
            </object>
            <script type="text/babel" src={`${process.env.REACT_APP_DFP_BASE_URL}/tags.js?org_id=${device_fingerprint?.setup_data?.org_id}&session_id=${device_fingerprint?.setup_data?.session_id}`}></script>
        </Helmet>
        <noscript> <iframe style="width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;"
            src={`${process.env.REACT_APP_DFP_BASE_URL}/tags.js?org_id=${device_fingerprint?.setup_data?.org_id}&session_id=${device_fingerprint?.setup_data?.session_id}`}>
        </iframe>
        </noscript>
    </> : "";
}

export default HBLValidationScript
