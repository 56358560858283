import {
  CONSTANTS,
  ROUTE_CONSTANTS,
  HELPER,
  LOCAL_STORAGE_SERVICE,
  history,
} from "../../utils";
import {
  store
} from "../../store";
import CHECKOUT_STEPS_HELPER from "../../utils/analytics/checkoutSteps"

function PRE_PHONE_REDIRECTION_RULE() {
  const state = store.getState();
  const { initial_screen } = state.redirect
  const { verification_type } = state.cart
  const disabledScreens = [CONSTANTS.SCREEN_REDIRECT.CHECKOUT,CONSTANTS.SCREEN_REDIRECT.OTP];
  if (disabledScreens.includes(initial_screen) && initial_screen !== CONSTANTS.SCREEN_REDIRECT.PHONE)
  {
    (verification_type !== CONSTANTS.VERIFICATION_TYPE.PHONE) && history.goBack();
  }
  return true;
}

function POST_PHONE_REDIRECTION_RULE(data){
  CHECKOUT_STEPS_HELPER.ORDER_INITIATE(data)
  const { app_setup, cart } = data;
    // Check which screen to show next
    const screen = app_setup?.screen;
    CHECKOUT_STEPS_HELPER.ORDER_INITIATE(data)

    // If customer is re-visited and already verified
    const address_count = app_setup?.address_count;
    const delivery_address_id = app_setup?.delivery_address_id;
    const shipment_method_id = app_setup?.shipment_method_id;
    const payment_method_id = app_setup?.payment_method_id;
    const placement_type = cart?.summary?.placement_type;
    
    if (screen === CONSTANTS.SCREEN_REDIRECT.PHONE) {
      LOCAL_STORAGE_SERVICE._isFirstScreen();
      history.push(ROUTE_CONSTANTS.PHONE_SCREEN);
    } else if (screen === CONSTANTS.SCREEN_REDIRECT.OTP) {
      LOCAL_STORAGE_SERVICE._isFirstScreen();
      history.push({
        pathname: ROUTE_CONSTANTS.OTP_SCREEN,
      });
    } else if (screen === CONSTANTS.SCREEN_REDIRECT.CHECKOUT) {
      const isInvoice = CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE === placement_type;
      const isPaymentGateway = CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY === placement_type;
      const isGiveWp = CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP === placement_type;

      if (isPaymentGateway || isInvoice || isGiveWp) {
        if (payment_method_id > 0) {
          history.push({
            pathname: ROUTE_CONSTANTS.CART,
          });
        } else {
          history.push({
            pathname: ROUTE_CONSTANTS.PAYMENT,
          });
        }
      } else if (!(isPaymentGateway || isInvoice)) {
        if (delivery_address_id === null) {
          if (address_count === 0) {
            history.push({
              pathname: ROUTE_CONSTANTS.ADD_NEW_ADDRESS,
            });
          } else if (address_count === 1) {
            if (delivery_address_id === null) {
              history.push({
                pathname: ROUTE_CONSTANTS.ADDRESS_LIST,
              });
            } else if (shipment_method_id !== null && shipment_method_id >= 0) {
              if (payment_method_id > 0) {
                history.push({
                  pathname: ROUTE_CONSTANTS.CART,
                });
              } else {
                history.push({
                  pathname: ROUTE_CONSTANTS.PAYMENT,
                });
              }
            } else {
              history.push({
                pathname: ROUTE_CONSTANTS.SHIPPING,
              });
            }
          } else if (address_count > 1) {
            history.push({
              pathname: ROUTE_CONSTANTS.ADDRESS_LIST,
            });
          }
        } else if (delivery_address_id !== null) {
          if (address_count === 0) {
            history.push({
              pathname: ROUTE_CONSTANTS.ADD_NEW_ADDRESS,
            });
          } else if (address_count === 1) {
            if (shipment_method_id !== null && shipment_method_id >= 0) {
              if (payment_method_id > 0) {
                history.push({
                  pathname: ROUTE_CONSTANTS.CART,
                });
              } else {
                history.push({
                  pathname: ROUTE_CONSTANTS.PAYMENT,
                });
              }
            } else {
              history.push({
                pathname: ROUTE_CONSTANTS.SHIPPING,
              });
            }
          } else if (address_count > 1) {
            if (
              delivery_address_id !== null &&
              shipment_method_id !== null &&
              shipment_method_id >= 0
            ) {
              if (payment_method_id > 0) {
                history.push({
                  pathname: ROUTE_CONSTANTS.CART,
                });
              } else {
                history.push({
                  pathname: ROUTE_CONSTANTS.PAYMENT,
                });
              }
            } else {
              history.push({
                pathname: ROUTE_CONSTANTS.SHIPPING,
              });
            }
          }
        }
      }
    }
}

function PRE_OTP_REDIRECTION_RULE() {
  const state = store.getState();
  const { route, initial_screen } = state.redirect
  const { verification_type } = state.cart
  if (initial_screen === CONSTANTS.SCREEN_REDIRECT.CHECKOUT && ![ROUTE_CONSTANTS.ORDER_SUCCESS,ROUTE_CONSTANTS.OUT_OF_RETRIES].includes(route.from)){
    !verification_type && history.goBack();
  }
  //redirection_states, history
  //Should verify otp.
  return true;
}

function POST_OTP_REDIRECTION_RULE(data) {
  CHECKOUT_STEPS_HELPER.OTP_VERIFIED(data)
  if (!HELPER.isEmpty(data?.access_token)) {
    LOCAL_STORAGE_SERVICE._updateAccessToken(data?.access_token);
  }

  LOCAL_STORAGE_SERVICE._isFirstScreen();
  const state = store.getState();
  const { route } = state.redirect;
  // If customer is re-visited and already verified
  const address_count = data?.app_setup?.address_count;
  const delivery_address_id = data?.app_setup?.delivery_address_id;
  const payment_method_id = data?.app_setup?.payment_method_id;
  const shipment_method_id = data?.app_setup?.shipment_method_id;
  const placement_type = data?.placement_type;

  const isInvoice = CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE === placement_type;
  const isPaymentGateway = CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY === placement_type;
  const isGiveWp = CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP === placement_type;

  const redirectFromOTP =  [ROUTE_CONSTANTS.UNIVERSAL_CHECKOUT, ROUTE_CONSTANTS.CHECKOUT, ROUTE_CONSTANTS.PHONE_SCREEN, ROUTE_CONSTANTS.OTP_SCREEN, ROUTE_CONSTANTS.RETRY_OTP].includes(route.from)
  const redirectFromCart = [ROUTE_CONSTANTS.CART].includes(route.from);
  const redirectFromSuccess = [ROUTE_CONSTANTS.ORDER_SUCCESS,ROUTE_CONSTANTS.OUT_OF_RETRIES].includes(route.from);
  if ((isPaymentGateway || isInvoice || isGiveWp) && !redirectFromSuccess) {
    if (payment_method_id > 0) {
      history.push({
        pathname: ROUTE_CONSTANTS.CART,
      });
    } else {
      history.push({
        pathname: ROUTE_CONSTANTS.PAYMENT,
      });
    }
  } else if (redirectFromCart && !redirectFromSuccess) {
    const redirect_url = location.state.redirect_url;
    HELPER.redirectToPathAndClearLocalStorage(redirect_url);
  } else if (redirectFromOTP && !(redirectFromSuccess || isPaymentGateway || isInvoice)) {
    if(delivery_address_id === null){
      if (address_count === 0) {
        history.push({
          pathname: ROUTE_CONSTANTS.ADD_NEW_ADDRESS,
        });
      } else if (address_count === 1) {
        if (delivery_address_id === null) {
          history.push({
            pathname: ROUTE_CONSTANTS.ADDRESS_LIST,
          });
        } else {
          if (shipment_method_id !== null && shipment_method_id >= 0) {
            if (payment_method_id > 0) {
              history.push({
                pathname: ROUTE_CONSTANTS.CART,
              });
            } else {
              history.push({
                pathname: ROUTE_CONSTANTS.PAYMENT,
              });
            }
          } else {
            history.push({
              pathname: ROUTE_CONSTANTS.SHIPPING,
            });
          }
        }
      } else if (address_count > 1) {
        if (
          delivery_address_id !== null &&
          shipment_method_id !== null &&
          shipment_method_id >= 0
        ) {
          if (payment_method_id > 0) {
            history.push({
              pathname: ROUTE_CONSTANTS.CART,
            });
          } else {
            history.push({
              pathname: ROUTE_CONSTANTS.PAYMENT,
            });
          }
        } else {
          history.push({
            pathname: ROUTE_CONSTANTS.ADDRESS_LIST,
          });
        }
      }
    }else{
      if (address_count === 1) {
        if (shipment_method_id !== null && shipment_method_id >= 0) {
          if (payment_method_id > 0) {
            history.push({
              pathname: ROUTE_CONSTANTS.CART,
            });
          } else {
            history.push({
              pathname: ROUTE_CONSTANTS.PAYMENT,
            });
          }
        } else {
          history.push({
            pathname: ROUTE_CONSTANTS.SHIPPING,
          });
        }
      } else if (address_count > 1) {
        if (shipment_method_id !== null && shipment_method_id >= 0) {
          if (payment_method_id > 0) {
            history.push({
              pathname: ROUTE_CONSTANTS.CART,
            });
          } else {
            history.push({
              pathname: ROUTE_CONSTANTS.PAYMENT,
            });
          }
        } else {
          history.push({
            pathname: ROUTE_CONSTANTS.SHIPPING,
          });
        }
      }
    }
  } else {
    // console.log("redirectedFrom: ", route.from);
  }
  return true;
}


function PRE_ADDRESS_REDIRECTION_RULE() {
  LOCAL_STORAGE_SERVICE._isFirstScreen();
  //redirection_states, history
  //Should verify otp.
  return true;
}

function POST_ADDRESS_REDIRECTION_RULE(redirection_states) {
  const { selected_payment_method } = store.getState().payment;
  const { shipment_method_id, payment_method_id, placement_type } = redirection_states
  const isInvoice = CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE === placement_type;
  const isPaymentGateway = CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY === placement_type;
  const isGiveWp = CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP === placement_type;

  LOCAL_STORAGE_SERVICE._nextScreen();
  CHECKOUT_STEPS_HELPER.POST_ADDRESS_ANALYTICS_CHECKOUT_STEPS();
  if (HELPER.isEmpty(shipment_method_id)  && !(isInvoice || isPaymentGateway || isGiveWp)) {
    history.push({
      pathname: ROUTE_CONSTANTS.SHIPPING,
    });
  } else {
    if (HELPER.isEmpty(payment_method_id) ||
    selected_payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.API_BASED && 
    !selected_payment_method?.token_id) {
      history.push({
        pathname: ROUTE_CONSTANTS.PAYMENT,
      });
    } else {
      history.push({
        pathname: ROUTE_CONSTANTS.CART,
      });
    }
  }
}

function PRE_SHIPMENT_REDIRECTION_RULE() {
  const state = store.getState();
  const { delivery_address_id } = state.redirect
  const { editShipment } = state.shipment
  const { placement_type } = state.cart.cart_details.summary;
  const isUniversal = CONSTANTS?.ORDER_PLACEMENT_TYPE.UNIVERSAL === placement_type;
  if (HELPER.isEmpty(delivery_address_id)) {
    history.push({
      pathname: ROUTE_CONSTANTS.ADDRESS_LIST,
    });
  }else if(isUniversal){
    if(HELPER.isEmpty(editShipment?.storeId) || HELPER.isEmpty(editShipment?.merchantId)){
      history.push({
        pathname: ROUTE_CONSTANTS.CART,
      });
    }
  }
  return true;
}

function POST_SHIPMENT_REDIRECTION_RULE() {
  const state = store.getState();
  const { selected_payment_method } = state.payment;
  const { payment_method_id } = state.redirect
  LOCAL_STORAGE_SERVICE._nextScreen();
  CHECKOUT_STEPS_HELPER.POST_SHIPMENT_ANALYTICS_CHECKOUT_STEPS();
  if (HELPER.isEmpty(payment_method_id) ||
  selected_payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.API_BASED && 
  !selected_payment_method?.token_id) {
    history.push({
      pathname: ROUTE_CONSTANTS.PAYMENT,
    });
  } else {
    history.push({
      pathname: ROUTE_CONSTANTS.CART,
    });
  }
}

function PRE_PAYMENT_REDIRECTION_RULE() {
  const state = store.getState();
  const { delivery_address_id, shipment_method_id, placement_type } = state.redirect
  const isInvoice = CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE === placement_type;
  const isPaymentGateway = CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY === placement_type;
  const isGiveWp = CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP === placement_type;

  if(!(isInvoice || isPaymentGateway || isGiveWp)){
    if (HELPER.isEmpty(delivery_address_id)) {
      history.push({
        pathname: ROUTE_CONSTANTS.ADDRESS_LIST,
      });
    } else if (HELPER.isEmpty(shipment_method_id) ) {
      history.push({
        pathname: ROUTE_CONSTANTS.SHIPPING,
      });
    }
  }
  return true;
}
function POST_PAYMENT_REDIRECTION_RULE() {
  const state = store.getState();
  const { route } = state.redirect
  const { placement_type } = state.cart.cart_details.summary;
  const { selected_payment_method } = state.payment;
  const form_type = selected_payment_method?.form_type;
  const isInvoice = CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE === placement_type;
  const isPaymentGateway = CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY === placement_type;
  const isGiveWp = CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP === placement_type;

  LOCAL_STORAGE_SERVICE._nextScreen();
  if (form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.API_BASED && HELPER.isEmpty(selected_payment_method?.token_id)) {
    history.push({
      pathname: ROUTE_CONSTANTS.CREDIT_CARD,
    });
  } else {
    CHECKOUT_STEPS_HELPER.POST_PAYMENT_ANALYTICS_CHECKOUT_STEPS();
    if ((isInvoice || isPaymentGateway || isGiveWp)) {
      history.push({
        pathname: ROUTE_CONSTANTS.CART,
      });
    } else {
      // If vustomer is re-visited and already verified
      if ([ROUTE_CONSTANTS.ADDRESS_LIST,ROUTE_CONSTANTS.UPDATE_ADDRESS, ROUTE_CONSTANTS.OTP_SCREEN, ROUTE_CONSTANTS.CHECKOUT, ROUTE_CONSTANTS.CART].includes(route?.from)) {
        history.push({
          pathname: ROUTE_CONSTANTS.CART,
        });
      } else {
        history.push({
          pathname: ROUTE_CONSTANTS.CART,
        });
      }
    }
  }

}

function PRE_CART_REDIRECTION_RULE() {
  const state = store.getState();
  const { delivery_address_id, shipment_method_id, payment_method_id, placement_type } = state.redirect
  const isInvoice = CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE === placement_type;
  const isPaymentGateway = CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY === placement_type;
  const isGiveWp = CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP === placement_type;

  if(!(isInvoice || isPaymentGateway || isGiveWp)){
    if (HELPER.isEmpty(delivery_address_id)) {
      history.push({
        pathname: ROUTE_CONSTANTS.ADDRESS_LIST,
      });
    } else if (HELPER.isEmpty(shipment_method_id)) {
      history.push({
        pathname: ROUTE_CONSTANTS.SHIPPING,
      });
    }
  }else if (HELPER.isEmpty(payment_method_id)) {
    history.push({
      pathname: ROUTE_CONSTANTS.PAYMENT,
    });
  }
  return true;
}

function PRE_PAYMENT_VERIFICATION_REDIRECTION_RULE() {
  const state = store.getState();
  const { route } = state.redirect
  if (HELPER.isEmpty(location.state) || route.from !== ROUTE_CONSTANTS.CART) {
    history.goBack();
  }
  //redirection_states, history
  //Should verify otp.
  return true;
}

const REDIRECTION_RULE = {
  POST_ADDRESS_REDIRECTION_RULE,
  POST_SHIPMENT_REDIRECTION_RULE,
  POST_PAYMENT_REDIRECTION_RULE,
  PRE_ADDRESS_REDIRECTION_RULE,
  PRE_SHIPMENT_REDIRECTION_RULE,
  PRE_PAYMENT_REDIRECTION_RULE,
  PRE_CART_REDIRECTION_RULE,
  PRE_PHONE_REDIRECTION_RULE,
  POST_PHONE_REDIRECTION_RULE,
  PRE_OTP_REDIRECTION_RULE,
  POST_OTP_REDIRECTION_RULE,
  PRE_PAYMENT_VERIFICATION_REDIRECTION_RULE
};
export default REDIRECTION_RULE;