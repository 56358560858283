// import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";
import {
    ANALYTICS_HELPER,
    HELPER
} from "../../utils";

import { store } from "../../store";


const GTM_EVENT_CATEGORY = {
    NAVIGATION: "General Navigation",
    BREADCRUMB: "Breadcrumb Navigation",
    LOGIN: "Login",
    PHONE: "Phone screen",
    OTP: "OTP",
    ADDRESS: "Address",
    SHIPMENT: "Shipping",
    PAYMENT: "Payment",
    REVIEW: "Review",
    FAILURE: "Failure",
    MERCHANT: "Merchant Info",
    USER_JOURNEY: "User Journey",
    PLACEMENT_TYPE: "Order Placement Type"
}


const GTM_EVENT = {
    NAVIGATION: {
        LANG_CHANGE: {
            ACTION: "Change language button",
            LABEL: "Globe button",
        },
        PROFILE_DROPDOWN: {
            ACTION: "Profile dropdown is clicked",
            LABEL: "Profile button",
        },
        CART_MENU: {
            ACTION: "Cart button is pressed",
            LABEL: "Cart button",
        },
        POPUP: {
            TERMS: {
                ACTION: "Terms Popup is pressed",
                LABEL: "Terms Popup",
            },
            PRIVACY: {
                ACTION: "Privacy Popup is pressed",
                LABEL: "Privacy Popup",
            },
        },
    },
    BREADCRUMB: {
        NAVIGATION: {
            ADDRESS: {
                ACTION: "Move to address screen",
                LABEL: "Address",
            },
            SHIPPING: {
                ACTION: "Move to shipping screen",
                LABEL: "Shipping",
            },
            PAYMENT: {
                ACTION: "Move to payment screen",
                LABEL: "Payment",
            },
            REVIEW: {
                ACTION: "Move to review screen",
                LABEL: "Review",
            }
        },
    },
    MERCHANT: {
        PLUGIN: {
            ACTION: "Plugin Version",
            LABEL: "",
        },
        STORE: {
            ACTION: "Store Url",
            LABEL: "",
        }
    },
    PHONE_NUMBER: {
        INPUT: {
            ACTION: "Phone number input is pressed",
            LABEL: "Phone input",
        },
        SUBMIT: {
            ACTION: "Phone Number submission button",
            LABEL: "Step buttons",
        },
    },
    OTP: {
        INPUT: {
            ACTION: "OTP code input",
            LABEL: "OTP input",
        },
        RETRY: {
            ACTION: "Retry OTP button pressed",
            LABEL: "OTP retry",
        },
        SUBMIT: {
            ACTION: "OTP code submission button",
            LABEL: "Step button",
        },
    },
    ADDRESS: {
        INPUT: {
            NAME: {
                ACTION: "Name input is pressed",
                LABEL: "Name input",
            },
            EMAIL: {
                ACTION: "Email input is pressed",
                LABEL: "Email input",
            },
            COUNTRY: {
                ACTION: "Country input is pressed",
                LABEL: "Country input",
            },
            PROVINCE: {
                ACTION: "Province input is pressed",
                LABEL: "Province input",
            },
            CITY: {
                ACTION: "City input is pressed",
                LABEL: "City input",
            },
            AREA: {
                ACTION: "Area input is pressed",
                LABEL: "Area input",
            },
            ADDRESS: {
                ACTION: "Address input is pressed",
                LABEL: "Address input",
            },
        },
        SELECTION: {
            ACTION: "Address input is selected",
            LABEL: "Address input",
        },
        ADD: {
            ACTION: "Add new address button is pressed",
            LABEL: "Add new address button",
        },
        EDIT: {
            ACTION: "Edit Address button is pressed",
            LABEL: "Edit address button",
        },
        DELETE: {
            ACTION: "Address delete button is pressed",
            LABEL: "Delete address",
        },
        SUBMIT: {
            ACTION: "Address submission button",
            LABEL: "Step button",
        },
    },
    SHIPMENT: {
        SELECTION: {
            ACTION: "Shipping input is selected",
            LABEL: "Shipping method input",
        },
        SUBMIT: {
            ACTION: "Shipping submission button",
            LABEL: "Step button",
        },
    },
    PAYMENT: {
        SELECTION: {
            ACTION: "Payment input is selected",
            LABEL: "Payment method input",
        },
        INPUT: {
            PHONE: {
                ACTION: "Payment - phone input",
                LABEL: "Phone input inside Payments",
            },
            EMAIL: {
                ACTION: "Payment - email input",
                LABEL: "Email input inside payments",
            },
            EP_PHONE: {
                ACTION: "Payment - easy paisa phone input",
                LABEL: "easy paisa phone input inside Payments",
            },
            BANK_ACCOUNT_NUMBER: {
                ACTION: "Payment - bank account number input",
                LABEL: "Bank account number input Inside Payments",
            },
            WALLET_NUMBER: {
                ACTION: "Payment - wallet number input",
                LABEL: "Wallet number input inside payments",
            },
        },
        SUBMIT: {
            ACTION: "Payment submission button",
            LABEL: "Step button",
        },
        DOWNLOAD: {
            ACTION: "Transaction slip downlaoded",
            LABEL: "Transaction slip",
        },
    },
    REVIEW: {
        CNIC_INPUT: {
            ACTION: "CNIC input",
            LABEL: "CNIC input",
        },
        VOUCHER: {
            INPUT: {
                ACTION: "Voucher input",
                LABEL: "Voucher input",
            },
            SUBMIT: {
                ACTION: "Voucher submit",
                LABEL: "Voucher submit",
            }
        },
        BUY_NOW: {
            ACTION: "Buy now button is pressed",
            LABEL: "Step button",
        },
    },
}


function initializeGA(gaID) {
    ReactGA4.initialize(gaID);
}

function _setUserSessionByID(user_id) {
    user_id && ReactGA4?.set({ userId: user_id })
}

function setPageView(location) {
    ReactGA4.send({ hitType: "pageview", page: location, title: location  });
}

function generalEventLog(SUB_EVENT, properties) {
    window.bSecureCheckout = window.bSecureCheckout || [];
    const action = properties?.action;
    const label = properties?.label;

    delete properties.action;
    delete properties.label;

    window.bSecureCheckout.push({
        event: SUB_EVENT,
        eventProps: {
            category: SUB_EVENT,
            action,
            label,
            value: {
                nonInteraction: false,
            },
        }
    });

    let _obj={
        category: SUB_EVENT,
        action,
        label,
        nonInteraction: false
    }

    // ReactGA.event(_obj);

    if(SUB_EVENT && typeof SUB_EVENT === 'string'){
        ReactGA4.event(_obj);
    }

    return true;
}

function navigationEventLog(type, properties) {
    window.bSecureCheckout = window.bSecureCheckout || [];
    const action = properties?.action;
    const label = properties?.label;

    delete properties.action;
    delete properties.label;

    window.bSecureCheckout.push({
        event: type,
        eventProps: {
            category: type,
            action,
            label,
            value: {
                ...properties,
                nonInteraction: false,
            },
        }
    });

    let _obj={
        category: type,
        action,
        label,
        nonInteraction: false
    }

    // ReactGA.event(_obj);
    if(type && typeof type === 'string'){
        ReactGA4.event(_obj);
    }

    return true;
}

/**
 * Call this function when a user clicks on a product link. This function uses the event
 * callback datalayer variable to handle navigation after the ecommerce data has been sent
 * to Google Analytics.
 * @param {Object} productObj An object representing a product.
 */
function _productClick(obj) {
    // ReactGA.plugin.execute('ec', 'setAction', 'click', obj);

    ReactGA4.event("select_item",
    {items: [{
        item_name: obj.name,
        item_id: obj.id,
        price: obj.price,
        position: obj.position,
        index: obj.position,
        quantity: '1'
      }]}
    );
    return null;
}


function _purchaseOrder(purchase, addTransaction, ecommerce) {
    
    // ReactGA.plugin.execute('ec', 'setAction', 'purchase', purchase);
    // ReactGA.plugin.execute('ec', 'setAction', 'addTransaction', addTransaction);
    // ReactGA.plugin.execute('ec', 'clear');
    window.bSecureCheckout = window.bSecureCheckout || [];

    window.bSecureCheckout.push({
        ecommerce: ecommerce
    });

    const _purchase={
        items: purchase?.products?.map(e => ({
            item_id: e.id,
            item_name: e.name,
            index: e.position,
            position: e.position,
            price: e.price,
            quantity: e.quantity,
            sku: e.sku,
        })),
        transcation_id: purchase?.id,
        affiliation: purchase?.affiliation,
        revenue: purchase?.revenue,
        shipping: purchase?.shipping,
        currency: purchase?.analytic_currency_code,
        value: purchase?.revenue,
    }
    ReactGA4.event("purchase",_purchase);
}

function initOrderLogging(response) {
    const state = store.getState();
    const { is_customer_app } = state.configuration?.config
    const cart = response?.cart
    
    if(is_customer_app !== 1){
        try {
            GOOGLE_ANALYTICS_HELPER._addItemsToCart(cart);
        }
        catch(err) {
            window.onerror(err);
        }
    }
    // ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: 1, option: GTM_EVENT_CATEGORY.LOGIN })
    ANALYTICS_HELPER._generalEventG_F(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.MERCHANT,
        {
            action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.MERCHANT.PLUGIN.ACTION,
            label: response?.app_setup?.plugin_version,
            properties: {}
        }
    );

    ANALYTICS_HELPER._generalEventG_F(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.MERCHANT,
        {
            action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.MERCHANT.STORE.ACTION,
            label: response?.config?.store_url,
            properties: {}
        }
    );
    

    const _begin_checkout={
        items: cart?.items?.map(e=> ({
            item_id: e.product_id,
            item_name: e.product_name,
            currency: cart?.summary?.analytic_currency_code,
            discount: e.product_discount,
            item_brand: e.store?.name,
            item_variant: e.variant_id,
            price: e.product_sub_total,
            quantity: e.quantity
        })),
        currency: cart?.summary?.analytic_currency_code,
        value: typeof cart?.summary?.total_amount === "string" ? parseFloat(cart?.summary?.total_amount?.replace(",","")) : cart?.summary?.total_amount,
        coupon: cart?.summary?.voucher_code,
    }
    ReactGA4.event("begin_checkout",_begin_checkout);

}

function _addItemsToCart(cart) {
    const cart_items = cart?.items;
    var _items = []
    const cartItems = cart_items.map((product, i) => {
        let _addProduct={
            id: product?.product_id,
            name: HELPER.titleCase(product?.product_name),
            sku: product?.product_sku,
            price: product?.product_total,
            quantity: product?.product_qty,
            currency: cart?.summary?.analytic_currency_code,
        }
        _items.push({..._addProduct,  item_id: product?.product_id,
            item_name: HELPER.titleCase(product?.product_name),})

        // ReactGA.plugin.execute('ec', 'addProduct',_addProduct );
        // ReactGA.plugin.execute('ec', 'setAction', 'add');

        return ({
            position: i,
            name: HELPER.titleCase(product?.product_name),       // Name or ID is required.
            id: product?.product_id,
            price: product?.product_total,
            sku: product?.product_sku,
            quantity: product?.product_qty,
            // 'brand': 'Google',
            // 'category': 'Apparel',
            // 'variant': 'Black',
            // 'list': 'Search Results',
        })
    });
    ReactGA4.event("add_to_cart",{ items: _items, currency: cart?.summary?.analytic_currency_code } );

    // ReactGA.plugin.execute('ec', 'setAction', 'view_item_list', {
    //     "items": cartItems
    // });

    let _impression={
        currencyCode: cart?.summary?.analytic_currency_code,
        impressions: cartItems,
    }
    // ReactGA.plugin.execute('ec', 'addImpression', _impression);
 
    let ga4_impression={
        items: _impression?.impressions?.map(e => ({...e,
            item_id: e.id,
            item_name: e.name,
            currency: cart?.summary?.analytic_currency_code,
        })),
        item_list_id: "related_products",
        item_list_name: "Related products",
    }
    ReactGA4.event("view_item_list", ga4_impression);


    // Measures product impressions and also tracks a standard
    // pageview for the tag configuration.
    // Product impressions are sent by pushing an impressions object
    // containing one or more impressionFieldObjects.
    //Measuring Product Impressions
    window.bSecureCheckout = window.bSecureCheckout || [];
    window.bSecureCheckout.push({
        ecommerce: {
            currencyCode: cart?.summary?.analytic_currency_code,
            impressions: cartItems,
        }
    })
}

function _checkoutSteps(step, name) {
    // ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: step, option: HELPER.titleCase(name) })
    ReactGA4.event( 'checkout', { step: step, option: HELPER.titleCase(name) })
    return true;
}

const _createEvent = (name, action) => {
    ReactGA4.event(name, action);
  };

const GOOGLE_ANALYTICS_HELPER = {
    GTM_EVENT_CATEGORY,
    GTM_EVENT,
    initializeGA,
    setPageView,
    generalEventLog,
    navigationEventLog,

    _addItemsToCart,
    _checkoutSteps,
    initOrderLogging,

    _productClick,

    _purchaseOrder,

    _setUserSessionByID,
    _createEvent,
};

export default GOOGLE_ANALYTICS_HELPER;

