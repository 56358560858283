// @flow
import React from "react";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { TextField } from "@material-ui/core";
import { HELPER } from "../../../../utils";

function EditCreditCardPaymentForm({ payment }) {
  const translate = useTranslate();

  return (
    <>
      {/* Card Name */}
      <TextField
        type="text"
        margin="normal"
        variant="outlined"
        className="col-md-12 disabled"
        label={translate("PAYMENT.LABEL.CARD.NAME")}
        name="Card Name"
        placeholder={translate("PAYMENT.LABEL.CARD.NAME")}
        value={payment?.name}
        disabled={!HELPER.isEmpty(payment?.token_id)}
      />
    </>
  );
}

export default EditCreditCardPaymentForm;
