// @flow
//eslint-disable-next-line
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import AppBreadcrumb from "../../components/App/AppBreadcrumb";
import AppFooter from "../../components/App/AppFooter";
import DialogComponent from "../../components/Dialog/dialog";

import {
  usePageViews,
  useBackActionHandler,
  usePullToRefreshHandler,
} from "../../hooks";
import {
  AccountInfo,
  MerchantLogo,
  LanguageInfo,
  MobileMenuDropdown,
  CartMenu,
} from "../../containers";
import {
  CONSTANTS,
  HELPER,
  LOCAL_STORAGE_SERVICE,
  ROUTE_CONSTANTS,
  history
} from "../../utils";
import { verificationAction } from "../../store/actions";

const PLACEMENT_STATUS = CONSTANTS.ORDER_STATUS;

function AppWrapper({
  showAccountDropdown,
  showLangIcon,
  showMerchantLogo,
  showFooter,
  breadcrumb,
  allowProfileAPI=true,
  children
}) {
  let dispatch = useDispatch();
  const { location } = history;
  const { networkAvailable } = useSelector(state => state.action);
  const { is_customer_app, theme_colour, allow_order_cancellation } = useSelector(state => state.configuration.config);
  const { placement_status, order_processed } = useSelector(state => state.cart);
  const { loadingStatus } = useSelector(state => state.loading);

  const { handleBackNavigationSettings } = useBackActionHandler()

  //componentDidMount
  useEffect(() => {

    HELPER.scrollScreen()
    if (!HELPER.isEmpty(theme_colour)) {
      document.documentElement.style.setProperty(
        "--theme-custom-color",
        theme_colour
      );
    }

    //popState
    window.addEventListener('popstate', () => {
      let screen = LOCAL_STORAGE_SERVICE._getFromLocalStorage("screen")
      if (screen > 1 && is_customer_app === 1) {
        // LOCAL_STORAGE_SERVICE._PreviousScreen()
        handleBackNavigationSettings();
      }
    });

    // returned function will be called on component unmount
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //componentWillRecieveProps
  useEffect(() => {
    /*
      UPDATE APP THEME COLOR
    */
    if (!HELPER.isEmpty(theme_colour)) {
      document.documentElement.style.setProperty(
        "--theme-custom-color",
        theme_colour
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme_colour]);

  return (
    <>
      <div className={!networkAvailable ? "noEvents" : ""}>
        {usePullToRefreshHandler((is_customer_app && (parseInt(PLACEMENT_STATUS.PLACED) !== parseInt(placement_status))))}
        {usePageViews()}
        <div className="mainWrap">
          <header className={!is_customer_app && breadcrumb.options.HEADER_PADDING ? "header-fixed" : ""}>
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-5">
                  {showMerchantLogo ? (
                    <MerchantLogo imgClasses="header-brand-img" />
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-flex order-lg-3 ml-auto">
                  {!order_processed && allow_order_cancellation === CONSTANTS.YES  ? <div className="dropdown d-flex languageIcon">
                      <span className="nav-link text-danger" onClick={() => 
                      dispatch(verificationAction.CANCEL_ORDER({status: CONSTANTS.ORDER_STATUS.CANCLED}))
                      }> Cancel Order </span>
                  </div> : ""}
                  {showLangIcon ? <LanguageInfo /> : ""}
                  {breadcrumb.options.SHOW_CART_ICON && location.pathname !== ROUTE_CONSTANTS.CART ? <CartMenu /> : ""}
                  {showAccountDropdown ? <AccountInfo allowProfileAPI={allowProfileAPI} /> : ""}
                </div>
              </div>
            </div>
            <div className={`progressWrap ${breadcrumb.options.NO_PROGRESS ? "d-none" : ""}`}>
              <div
                className="progressLine animate__animated animate__fadeInLeft"
                style={{ width: `${loadingStatus}%` }}
              ></div>
            </div>
          </header>
        </div>
        <div className={(!is_customer_app && breadcrumb.options.HEADER_PADDING) ? "header-padding" : ""}>
          {breadcrumb.options.SHOW_CART_ICON ? <MobileMenuDropdown /> : ""}
          <div className={`contentWrapper custom-animate 
          `}>
            {/* // ${(!is_customer_app && breadcrumb.options.HEADER_PADDING) ? "contentWrapperPadding" : ""} */}
            {breadcrumb.show ? <AppBreadcrumb properties={breadcrumb} /> : ""}
            {children}
            {showFooter ? <AppFooter /> : ""}
          </div>
          <DialogComponent />
        </div>
      </div>
    </>
  );
}

export default withRouter(AppWrapper);
