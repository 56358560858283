// @flow

import React, { useEffect } from "react";
import { useTranslate } from "react-redux-multilingual/lib/context";
import { FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { HELPER } from "../../../utils";
import { mapActions } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

function AddAddressForm(props) {
  const translate = useTranslate();

  const {
    country_id,
    province_id,
    city_id,
    area_id,
    address,
    countryData,
    citiesData,
    provinceData,
    areasData,
    errors,
    is_default,
    address_count,
    zip_code,
    is_pin_location_mandatory,
    setCoordinatesLat,
    showAllFields,

    province_name,
    setprovince_name,
    city_name,
    setcity_name,
    area_name,
    setarea_name,

    isCountryDisabled=false,
    isProvinceDisabled=false,
    isCityDisabled=false,
  } = props.state;
  let dispatch = useDispatch();
  const errorCountry = HELPER.isEmpty(errors.country_id) ? false : true;
  const errorProvince = HELPER.isEmpty(errors.province_id) ? false : true;
  const errorCity = HELPER.isEmpty(errors.city_id) ? false : true;
  const errorAddress = HELPER.isEmpty(errors.address) ? false : true;
  const errorArea = HELPER.isEmpty(errors.area_id) ? false : true;
  const {is_international_shipment } = useSelector(state => state.configuration.config);

  let isDisabled=((!!is_pin_location_mandatory && setCoordinatesLat) || is_international_shipment) && !showAllFields
  
  useEffect(()=>{
    is_international_shipment && !setCoordinatesLat &&
    dispatch(mapActions.GET_LOCATION_PIN(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
     <div className="addressForm-fields">
        {/* Address */}
        <div>
      <TextField
          type="textarea"
          // type="text"
          margin="normal"
          variant="outlined"
          className="col-md-12"
          label={translate("ADDRESS.FORM.LABEL.ADDRESS")}
          name="address"
          placeholder={translate("ADDRESS.FORM.PLACEHOLDER.ADDRESS")}
          rows={6}
          required={true}
          onChange={props.handleChange}
          value={address}
          helperText={errors.address}
          error={errorAddress}
          autoFocus={false}
          multiline={!is_international_shipment}
          inputProps={{
            id: `${is_international_shipment ? "map-address" : ""}`,
          }}
        />
        </div>
        {(is_international_shipment && !setCoordinatesLat && !showAllFields) ?
            "" :
        <div>
          {/* Country */}
          <div className="inline-items">
            <Autocomplete
                disabled={isDisabled || isCountryDisabled}
                options={countryData}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                getOptionSelected={(option, value) => {
                  return value?.value;
                }}
                value={(!HELPER.isEmpty(country_id.value) ? country_id : null)}
                onChange={props.handleCountryChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    name="country_id"
                    label={translate("ADDRESS.FORM.LABEL.COUNTRY")}
                    placeholder="Country"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    required={true}
                    error={errorCountry}
                    helperText={errors.country_id}
                    autoFocus={false}
                  />
                )}
              />
              
              {/* Province */}
              {province_id?.value === -1 ?
                    <TextField
                    margin="normal"
                    variant="outlined"
                    label={translate("ADDRESS.FORM.LABEL.PROVINCE")}
                    placeholder={translate("ADDRESS.FORM.LABEL.PROVINCE")}
                    onChange={(e)=> setprovince_name(e.target.value)}
                    value={province_name}
                    required={true}
                    error={errorProvince}
                    helperText={errors.province_id}
                    />
                  :
                  <>
              {provinceData && (
              <Autocomplete
                disabled={isDisabled || isProvinceDisabled}
                  options={provinceData}
                  getOptionLabel={(option) => (option.label ? option.label : "")}
                  getOptionSelected={(option, value) => {
                    return value?.value;
                  }}
                  ListboxProps={{ style: { maxHeight: "10rem" } }}
                  value={(!HELPER.isEmpty(province_id.value) ? province_id : null)}
                  onChange={props.handleProvinceChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      margin="normal"
                      name="province_id"
                      label={translate("ADDRESS.FORM.LABEL.PROVINCE")}
                      placeholder={translate("ADDRESS.FORM.LABEL.PROVINCE")}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      required={true}
                      error={errorProvince}
                      helperText={errors.province_id}
                      autoFocus={false}
                    />
                  )}
                />
              )}
              </>}
          </div>
          <div className="inline-items">
              {/* Cities */}
              {city_id?.value === -1 ?
                    <TextField
                    margin="normal"
                    variant="outlined"
                    label={translate("ADDRESS.FORM.LABEL.CITY")}
                    placeholder={translate("ADDRESS.FORM.LABEL.CITY")}
                    onChange={(e)=> setcity_name(e.target.value)}
                    value={city_name}
                    required={true}
                    error={errorCity}
                    helperText={errors.city_id}
                    />
                  :
                  <>
              {citiesData && (
              <Autocomplete
                disabled={isDisabled || isCityDisabled}
                  options={citiesData}
                  getOptionLabel={(option) => (option.label ? option.label : "")}
                  getOptionSelected={(option, value) => {
                    return value?.value;
                  }}
                  value={(!HELPER.isEmpty(city_id.value) ? city_id : null)}
                  onChange={props.handleCityChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      margin="normal"
                      name="city_id"
                      label={translate("ADDRESS.FORM.LABEL.CITY")}
                      placeholder={translate("ADDRESS.FORM.LABEL.CITY")}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      required={true}
                      error={errorCity}
                      helperText={errors.city_id}
                      autoFocus={false}
                    />
                  )}
                />
            )}
            </>}

            <TextField
              type="number"
              // type="text"
              margin="normal"
              variant="outlined"
              label={translate("ADDRESS.FORM.LABEL.ZIPCODE")}
              name="zip_code"
              placeholder={translate("ADDRESS.FORM.LABEL.ZIPCODE")}
              onChange={props.handleZipcode}
              value={zip_code}
              autoFocus={false}
            />
          </div>
              {/* Area */}
              {area_id?.value === -1 ?
                    <TextField
                    margin="normal"
                    variant="outlined"
                    label={translate("ADDRESS.FORM.LABEL.AREA")}
                    placeholder={translate("ADDRESS.FORM.LABEL.AREA")}
                    onChange={(e)=> setarea_name(e.target.value)}
                    value={area_name}
                    required={true}
                    fullWidth
                    error={errorArea}
                    helperText={errors.area_id}
                    />
                  :
              <Autocomplete
                disabled={is_international_shipment && !showAllFields }
                options={areasData}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                getOptionSelected={(option, value) => {
                  return value?.value;
                }}
                value={(!HELPER.isEmpty(area_id.value) ? area_id : null)}
                onChange={props.handleAreaChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    name="area_id"
                    label={translate("ADDRESS.FORM.LABEL.AREA")}
                    placeholder={translate("ADDRESS.FORM.LABEL.AREA")}
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    required={true}
                    autoFocus={false}
                    error={errorArea}
                    helperText={errors.area_id}
                  />
                )}
            />
              }
          </div>
        }
        </div>

      <div className="checkBoxCard">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="is_default"
              value={Number(is_default)}
              onChange={props.handleDefaultChange}
              checked={Number(is_default) === 1 ? true : false}
              disabled={parseInt(address_count) === 0 ? true : false}
            />
          }
          label={translate("ADDRESS.SET_DEAFULT")}
        />
      </div>
    </>
  );
}

export default AddAddressForm;
