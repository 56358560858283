// @flow
/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { TextField } from "@material-ui/core";
// import { scroller } from "react-scroll";

import {
    HELPER,
    ANALYTICS_HELPER,
    GOOGLE_ANALYTICS_HELPER
} from "../../../utils";
import { formAction } from "../../../store/actions";

function EmailField() {
    const translate = useTranslate();
    let dispatch = useDispatch();

    const [customer_email, setCustomerEmail] = useState("");
    const [errors, setErrors] = useState("");

    const onChangeValidation = (e) => {
        e.preventDefault();
        const email = e.target.value
        setCustomerEmail(email);
        // same as above, but feel free to move this into a class method now.
        let validation_errors = "";
        let validation_field_name = "";
        if (!email) {
            validation_errors = translate("VALIDATIONS.IS_REQUIRED");
            validation_field_name = "email";
        } else if (
            !HELPER.isEmpty(email) &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        ) {
            validation_errors = translate("invalid_email_address");
            validation_field_name = "email";
        } else if (email.length > 50) {
            validation_errors = translate("email_field_max_validation");
            validation_field_name = "email";
        }
        if (validation_field_name !== "") {
            HELPER.scrollTo(validation_field_name, -200)
            // scroller.scrollTo(validation_field_name, { offset: -200, smooth: true });
        }
        setErrors(validation_errors);

        if (validation_errors === "") {
            onChangeListener();
        }
        return true;
    };

    const onChangeListener = (e) => {
        // LOAD GTM EVENT
        ANALYTICS_HELPER._generalEventLog(
            GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.PAYMENT,
            {
                action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.INPUT.EMAIL.ACTION,
                label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.INPUT.EMAIL.LABEL,
                properties: {}
            }
        );
        dispatch(formAction.UPDATE_CUSTOMER_EMAIL(customer_email))
    };

    return (
        <TextField
            inputProps={{
                autoComplete: 'off',
                form: {
                    autoComplete: 'off',
                },
            }}
            type="email"
            margin="normal"
            variant="outlined"
            className="col-md-12 email_address"
            label={translate("ADDRESS.FORM.LABEL.EMAIL")}
            name="email"
            placeholder={translate("ADDRESS.FORM.PLACEHOLDER.EMAIL")}
            onChange={onChangeValidation}
            required={true}
            value={customer_email}
            error={HELPER.isEmpty(errors) ? false : true}
            // disabled={disableEmail}
            helperText={errors}
        />
    );
}

export default EmailField;
