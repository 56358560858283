import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { HELPER, CONSTANTS } from "../../utils";
import { alertActions } from "../../store/actions";


const useMobileAlerts = () => {
  let dispatch = useDispatch();
  const { message, type,  autoDismiss } = useSelector(state => state.alert);
  const { background, color, alertType } = useSelector(state => state.alert.mobile);

  const clearAlert = () => {
    if(autoDismiss){
      dispatch(alertActions.clear())
    }
  };

  //clear alert msg in mobile after 3 sec
  const clearMessage =() => {
    setTimeout(clearAlert, 3000)
  }

  useEffect(()=>{
    if(!HELPER.isEmpty(message) && !HELPER.isEmpty(type)){
      if (
        HELPER.sendEventToAndroid("alertMessage")) {
          window.Android.alertMessage(
            alertType.android,
            Array.isArray(message) ? message[0] : message,
            CONSTANTS.APP_ALERT_TYPE.CUSTOM,
            background,
            color,
            autoDismiss
          );
        clearMessage()
      } else if (
        HELPER.sendEventToiOS("alertMessage")) {
          window.webkit.messageHandlers.alertMessage.postMessage(JSON.stringify({
            type: alertType.ios,
            msg: Array.isArray(message) ? message[0] : message,
            group:CONSTANTS.APP_ALERT_TYPE.DEFAULT,
            background,
            color,
            autoDismiss
          }));
        clearMessage()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[message, type])

};

export default useMobileAlerts;
