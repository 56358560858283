// @flow

import * as React from "react";

import SSOWrapper from "../../components/SSOWrapper";
import {
  BREADCRUMB_SETTINGS_JSON,
} from "../../utils";
import VerifyPhoneForm from "./form";

function VerifyPhone() {
  return (
    <SSOWrapper
      child={<VerifyPhoneForm />}
      allowProfileAPI={false}
      showAccountDropdown={false}
      showFooter={true}
      showLangIcon={true}
      showMerchantLogo={true}
      showPoweredBy={true}
      breadcrumbOptions={
        {
          type: BREADCRUMB_SETTINGS_JSON.PHONE_SCREEN.TYPE,
          options: BREADCRUMB_SETTINGS_JSON.PHONE_SCREEN,
          show: BREADCRUMB_SETTINGS_JSON.PHONE_SCREEN.SHOW
        }
      }
    ></SSOWrapper>
  );
}

export default VerifyPhone;
