// @flow
import React from "react";
import DBTToolTipText from "../../../../components/DBTToolTipText";

function DirectBankTransferPaymentForm(props) {
  const { payment } = props;
  return (
    <>
      <div className="direct-bank-transfer">
        <DBTToolTipText
          merchant_bank_details={payment?.merchant_bank_details}
          showBankDetails={true}
          showCustomMsg={true}
        />
      </div>
    </>
  );
}

export default DirectBankTransferPaymentForm;
