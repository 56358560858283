// @flow
//eslint-disable-next-line

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { TextField } from "@material-ui/core";
import { isMobile } from "react-device-detect";

import CheckoutWrapper from "../../../components/CheckoutWrapper";
import MapFullScreenPopUp from "../../../components/MapFullScreenPopUp";
import {
  BREADCRUMB_SETTINGS_JSON,
  HELPER,
  IMAGE_SRC,
  history,
  CONSTANTS
} from "../../../utils";
import { addressAction, mapActions } from "../../../store/actions";
import MapComponent from "./map";
import { MAP_CONSTANTS } from "../../../store/actionTypes";

function FullScreenMapView() {
  let dispatch = useDispatch();
  const translate = useTranslate();
  
  const { searchPlace, locationPermission, setCoordinatesLat, setCoordinatesLatMap } = useSelector(state => state.map);
  const { disableLocationBtn } = useSelector(state => state.address);
  const { is_pin_location_mandatory } = useSelector(state => state.configuration.config);
  const [address, setAddress] = useState("");
  const [loadMap, setMapLoad] = useState(false);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (!HELPER.isEmpty(searchPlace)) {
      setAddress(searchPlace)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPlace]);
  
  useEffect(() => {
    if (!HELPER.isEmpty(searchPlace)) {
      setAddress(searchPlace)
    }
    
    if (!setCoordinatesLat) {
      getPinLocation()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddressChange = (e) => {
    setAddress(e.target.value)
    dispatch({type: MAP_CONSTANTS.SEARCHED_ADDRESS , value: e.target.value});
  };

  const handleMapLoading = () => {
    setMapLoad(true)
  };

  const resetLocation = () => {
    setAddress("")
    dispatch(addressAction.empty_location_details());
  };

  const getPinLocation = () => {
    dispatch(mapActions.GET_LOCATION_PIN(true));
  };

  const closeMapDirection = () => {
    dispatch(mapActions.CONFIRM_ADDRESS_FULLSCREEN_MAP());
    history.goBack();
  };

  return (
    <>
      <div className={"FullScreenAddressWrapper"}>
        <div className={isMobile ? "address-pin-container mobile-screen" : "address-pin-container"}>
          <div className="map-box">
            <form onSubmit={e => e.preventDefault()}>
              {/* Address */}
              <TextField
                type="text"
                margin="normal"
                variant="outlined"
                className="col-md-12 msgfieldBot"
                inputProps={{
                  id: "map-address",
                }}
                name="address"
                placeholder={translate("ADDRESS.FORM.LABEL.MAP")}
                required={true}
                onChange={handleAddressChange}
                value={address}
                autoFocus={false}
              />
            </form>
            <div className="formRightSide">
              <button type="button">
                {
                  !HELPER.isEmpty(address) ? <img src={IMAGE_SRC.ICONS.CLOSE} alt="map-search-icon" onLoad={handleMapLoading} onClick={resetLocation} /> : <img src={IMAGE_SRC.ICONS.SEARCH} alt="map-search-icon" onLoad={handleMapLoading} />
                }
              </button>
              <button type="button" className="mapDirection">
                <span onClick={getPinLocation}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><circle className="st0" cx="18" cy="18" r="17.6" /><path className="st1" d="M18.2 13.8c-2.4 0-4.3 2-4.3 4.4 0 2.4 1.9 4.4 4.3 4.4s4.3-2 4.3-4.4C22.5 15.8 20.6 13.8 18.2 13.8zM27.9 17.1c-0.5-4.5-4.1-8.2-8.6-8.7V6.2h-2.2v2.2C12.6 9 9 12.6 8.5 17.1H6.2v2.2h2.2c0.5 4.5 4.1 8.2 8.6 8.7v2.2h2.2V28c4.5-0.5 8.1-4.1 8.6-8.7h2.2v-2.2H27.9zM18.2 25.8c-4.2 0-7.6-3.4-7.6-7.6s3.4-7.6 7.6-7.6 7.6 3.4 7.6 7.6S22.4 25.8 18.2 25.8z" /></svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {
        loadMap ? <MapComponent /> : ""
      }
      <div id="mapTopLayer">
      <div className={"FullScreenMinimize"}>
        <button 
          title="Toggle fullscreen view"
          aria-label="Toggle fullscreen view"
          type="button"
            className={"fullscreen-control"}
            onClick={()=> history.goBack()}
        >
          <i className="icomoon-icon-minimize"></i>
        </button>
        </div>
      </div>
      {locationPermission !== CONSTANTS.PERMISSIONS.GRANTED && !setCoordinatesLat &&
        (!is_pin_location_mandatory && locationPermission !== CONSTANTS.PERMISSIONS.DENIED || is_pin_location_mandatory) &&
        <div className={"FullScreenDialog"}>
          <MapFullScreenPopUp is_pin_location_mandatory={is_pin_location_mandatory} locationPermission={locationPermission} />
        </div>
      }

      <div className={"FullScreenLocationBtnWrapper"}>
        <div className={`confirm-location ${disableLocationBtn ? "disabled" : ""}`} >
          <button
            type="button"
            id="back-btn"
            className="btn btn-primary"
            onClick={closeMapDirection}
            disabled={is_pin_location_mandatory && !setCoordinatesLat && !setCoordinatesLatMap}
          >
            { translate("LOCATION.CONFIRM_LOCATION") }
          </button>
        </div>
      </div>
    </>
  )
}

function FullScreenMap() {
  return (
    <CheckoutWrapper
      fullscreenModeEnabled={true}
      showMap={false}
      child={<FullScreenMapView />}
      breadcrumbSettings={BREADCRUMB_SETTINGS_JSON.CHECKOUT}
    />
  );
}

export default FullScreenMap;
