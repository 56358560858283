// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    GOOGLE_ANALYTICS_HELPER,
    HELPER,
    SUPPORTED_LANG,
    ANALYTICS_HELPER
} from "../../../utils";
import {
    languageAction,
} from "../../../store/actions";

const languageSelected = (locale) => {
    let lang = SUPPORTED_LANG.find(language => {
        return language.locale_switch === locale
    })

    return lang.language_icon
}
const LocaleUpdate = (locale) => {
    let htmlEl = document.querySelector("html");
    // htmlEl.setAttribute("dir", direction);
    htmlEl.setAttribute("lang", locale);

    let lang_direction = HELPER.getLngDirection(locale);
    let bodyEl = document.querySelector("body");
    bodyEl.setAttribute("dir", lang_direction);

    return true;
};

function LanguageInfo() {
    let dispatch = useDispatch();
    const { locale } = useSelector(state => state.Intl);

    const [lang, setLang] = useState("")

    //componentDidMount
    useEffect(() => {
        /*
          SET LANGUAGE ICON
        */

        setLang(languageSelected(locale))
        // returned function will be called on component unmount
        return () => {
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        /*
          UPDATE SELECTED LANGU
        */
        setLang(languageSelected(locale))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    const changeLanguage = () => {
        let lang_selected = SUPPORTED_LANG.find(language => {
            return language.locale_switch === locale
        })
        dispatch(languageAction.updateLanguage(lang_selected.locale));

        LocaleUpdate(locale)
        // LOAD GTM EVENT
        ANALYTICS_HELPER._navigationEventLog(
            GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.NAVIGATION,
            {
                action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.NAVIGATION.LANG_CHANGE.ACTION,
                label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.NAVIGATION.LANG_CHANGE.LABEL,
                properties: {
                    previous: locale,
                    new: lang_selected.locale,
                }
            }
        );
    }

    return (
        <>
            <div className="dropdown d-flex languageIcon">
                <span className="nav-link" onClick={changeLanguage}>
                    {lang}
                </span>
            </div>
        </>
    );
}


export default LanguageInfo;