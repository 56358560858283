// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import * as React from "react";
import {  useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";
import ReactHtmlParser from "html-react-parser";
import ThirdPartyTransactionSlips from "../ThirdPartyTransactionSlips/transactionSlip";

import {
  HELPER,
  CONSTANTS,
  CART_HELPER
} from "../../../utils";
import ALERT_TRIANGLE from '../../../assets/images/logo/alert-triangle.svg'

function OrderReceipt(props) {
  const translate = useTranslate();

  const { shipment_method, order_id, post_order_msg, banner_alert } = useSelector(state => state.cart);
  const { summary } = useSelector(state => state.cart.cart_details);
  const {
    additional_charges,
    placement_type,
    currency_code,
    shipment_charges
  } = summary

  const discountAmount = summary.discount_amount || 0
  const subTotal = summary.sub_total_amount || 0
  const total_amount = summary.total_amount || 0
  const merchant_service_charges = summary.merchant_service_charges || 0

  const {
    // showPaidStamp,
    transaction_data,
    showTransactionSlip,
  } = props;

  // const showStamp = showPaidStamp && <div className="paidStamp"></div>;
  const isInvoice = CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE === placement_type;
  const isPaymentGateway = CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY === placement_type;
  const isUniversal = CONSTANTS?.ORDER_PLACEMENT_TYPE.UNIVERSAL === placement_type;
  const _order_id = HELPER.checkLocalStorage("order_id", order_id)
  const isGiveWp = CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP === placement_type;

  return (
    <div className="receiptMainWrap">

    {banner_alert &&
					<div className={`banner-alert`}>
						<div className={`alertCustom`}>
							<div className={`alertAction`}>
								<span>
									<img src={ALERT_TRIANGLE} alt="alert-triangle"/>
								</span>
							</div>
							<div className="alertMsg">{ReactHtmlParser(banner_alert)}</div>
						</div>
					</div>
			}

      {!!post_order_msg &&
        <section className='post-order-msg'>
          {ReactHtmlParser(post_order_msg)}
        </section>
			}

      {showTransactionSlip ? (
          <ThirdPartyTransactionSlips
            transaction_data={transaction_data}
            cart_summary={summary}
          />
      ) : (
        ""
      )}

      <div className="receiptSection">
        <div className="receiptInfo receiptFirstBox">
          {_order_id ? 
            <div className="receiptSub bigReceiptHeadingBox">
                <span className="left">{translate("ORDER.LABEL.ORDER_NO")}:</span>
                <span className={`right ${_order_id?.length > 23 ? "right-order-num" : ""}`}>
                    {_order_id}
                </span>
              </div>
            : ""
            }

          {/* SUB-TOTAL */}
          {CART_HELPER.RECEIPT_CONTAINER("sub_total_text", subTotal, false, true)}
          {/* SUB-TOTAL */}

          {/* SHIPMENT CHARGES */}
          {(!HELPER.isEmpty(shipment_charges) && !(isPaymentGateway && isInvoice && isGiveWp) || (shipment_method?.id && shipment_method?.id !== 0 && !isUniversal))
          && (isUniversal ? (shipment_charges) : (shipment_method?.cost))
            ? CART_HELPER.RECEIPT_CONTAINER(
              "SHIPPING.BREADCRUMB.HEADING",
              isUniversal ? shipment_charges : shipment_method?.cost,
              false
            )
            : null}
          {/* SHIPMENT CHARGES */}

          {/* ADDITIONAL CHARGES */}
          {!HELPER.isEmpty(additional_charges) ? CART_HELPER.ADD_CHARGES_BREAKDOWN() : "" }
          {/* ADDITIONAL CHARGES */}
        </div>

        {/* DISCOUNT CHARGES */}
        {CART_HELPER.DISCOUNT_RECEIPT_SECTION("discount_text", discountAmount, false)}
        {/* DISCOUNT CHARGES */}

        {/* MERCHANT SERVICE CHARGES */}
        {merchant_service_charges ? (
          <div className="receiptInfo receiptFirstBox receiptServiceCharge">
            {CART_HELPER.RECEIPT_CONTAINER(
              "service_charges_text",
              merchant_service_charges,
              false
            )}
          </div>
        ) : (
          ""
        )}
        {/* MERCHANT SERVICE CHARGES */}

        <div className="receiptTotal" key={"merchant-service-charges"}>
          <span className="total">{translate("total_text")}: </span>
          <span className="totalPrice">
            {HELPER.showPrice(currency_code, total_amount)}
          </span>
        </div>
      </div>
    </div>
  );
}
export default OrderReceipt;
