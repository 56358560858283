// @flow
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import {
  HELPER,
  CONSTANTS
} from "../../../../utils";
import {
  paymentAction,
} from "../../../../store/actions";

const EPPaymentFormFooter = ({ payment, setAccount }) => {
  const translate = useTranslate();
  const accountOptions = payment?.options;
  const INSTRUCTIONS = payment?.instructions === CONSTANTS.PAYMENT_METHOD.INSTRUCTIONS.EASYPAISA ? CONSTANTS.PAYMENT_METHOD.MOBILE_ACCOUNT.SUGGESTION_LIST.EASYPAISA : CONSTANTS.PAYMENT_METHOD.MOBILE_ACCOUNT.SUGGESTION_LIST.JAZZCASH;

  return (
    <>
      {
        !HELPER.isEmpty(payment?.options) ? <div className="easyPaisa" key={`EPPaymentFormFooter-${payment?.token_id}`} >
          <div className="easyPaisaSuggestionList">
            {translate(INSTRUCTIONS)}
          </div>
          <div className="numberList">
            {accountOptions.map((option, key) => {
              return (<>
                <span key={`option-${key}`} onClick={() => setAccount(option?.account_number)}>{option?.account_number}</span>
                <br />
              </>
              );
            })}
          </div>
        </div> : ""
      }

    </>
  )
}

function EasyPaisaMAList({ payment }) {
  const translate = useTranslate();
  let dispatch = useDispatch();
  const { selected_payment_method } = useSelector(state => state.payment);

  const setAccount = (number) => {
    if (number.substr(0, 1) === "0") {
      number.substr(1);
    }
    if (selected_payment_method?.index === payment?.index) {
      dispatch(paymentAction?.UPDATE_EP_PHONE_NUMBER(number));
    }
  };

  const INSTRUCTIONS = payment?.instructions === CONSTANTS.PAYMENT_METHOD.INSTRUCTIONS.EASYPAISA ? CONSTANTS.PAYMENT_METHOD.MOBILE_ACCOUNT.INSTRUCTIONS.EASYPAISA : CONSTANTS.PAYMENT_METHOD.MOBILE_ACCOUNT.INSTRUCTIONS.JAZZCASH;

  return (
    <>
      {
        HELPER.isEmpty(payment?.token_id) ?
          <>
            <div className="browSetting">
              {translate(INSTRUCTIONS.TEXT_1)}
              <ul>
                <li>{translate(INSTRUCTIONS.TEXT_2)}</li>
                <li>{translate(INSTRUCTIONS.TEXT_3)}</li>
                <li>
                  {translate(INSTRUCTIONS.TEXT_4)}
                  <ul>
                    <li>{translate(INSTRUCTIONS.TEXT_4_1)}</li>
                    <li>{translate(INSTRUCTIONS.TEXT_4_2)}</li>
                  </ul>
                </li>
              </ul>
            </div>
            {
              <EPPaymentFormFooter payment={payment} setAccount={setAccount} key={`browSetting-${payment?.token_id}`}/>
            }
          </>
          : ""
      }

    </>
  );
}



export default EasyPaisaMAList;
