import React from "react";

import {
    WebMenuWrapper,
    MobileMenuWrapper,
} from "../../../../containers";

import {
    CART_HELPER,
    CONSTANTS,
} from "../../../../utils";

function CartMenu() {
    return <>
        {(CART_HELPER.CART_DEVICE_TYPE() === CONSTANTS.DEVICE_TYPE.MOBILE) ? <MobileMenuWrapper /> : ""}
        {(CART_HELPER.CART_DEVICE_TYPE() === CONSTANTS.DEVICE_TYPE.WEB) ? <WebMenuWrapper /> : ""}
    </>
}


export default CartMenu
