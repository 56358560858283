import React from "react";
import { useSelector } from "react-redux";

import Zoom from "@material-ui/core/Zoom";

import { HELPER } from "../../utils";

const useNativeLoaderHandler = () => {
  const { showPageLoader } = useSelector(state => state.loading);

    if(!(HELPER.sendEventToAndroid("enableLoader")) && !(HELPER.sendEventToiOS("enableLoader")) && showPageLoader) {
      return(<Zoom in={true}>
          <div className="loading">
            <div className="spinner"></div>
          </div>
        </Zoom>);
    }
    return <></>;
};

  export default useNativeLoaderHandler;