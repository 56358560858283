// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import {
  qistPayAction,
  cartAction,
  alertActions
} from "../../store/actions";
import {
  HELPER,
  LOCAL_STORAGE_SERVICE,
  history
} from "../../utils";


function QistPayCCForm() {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const { showBtnLoader, showBackBtnLoader } = useSelector(state => state.loading);
  const { payment_processing_details } = useSelector(state => state.payment);

  const [iframeURL, setIframeURL] = useState("");

  //componentDidMount
  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      var iframes = document.querySelectorAll('iframe');
      for (var i = 0; i < iframes.length; i++) {
        iframes[i].parentNode.removeChild(iframes[i]);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (HELPER.isEmpty(iframeURL)) {
      setIframeURL(payment_processing_details?.iframe_url);
      // props.hideLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_processing_details?.iframe_url]);

  const setListener = () => {
    window.addEventListener('message', function (e) {
      // extract the data from the message event
      const { data } = e;
      let response = null;
      try {
        response = JSON.parse(data);
        if (!showBtnLoader && response.status === 200) {
          //QisstPay response will be received here. 
          var requestData = {
            customer_cnic: LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value"),
            qp_ref_no: response?.result?.order_id,
            qp_invoice_id: response?.result?.invoice_id,
          };
          dispatch(cartAction.place_order(requestData, history));
        } else {
          dispatch(alertActions.error(response?.message));
        }
      } catch (e) {
        return;
      }
    });
  }

  const hideLoader = () => {
    dispatch(qistPayAction.hideLoader());
    return true;
  };

  const moveBackwards = () => {
    history.goBack();
  };

  return (
    <>
      {!HELPER.isEmpty(iframeURL) ?
        <>
          <div>
            <iframe
              id="inner"
              className="qisstPayIframe"
              src={iframeURL}
              onLoad={hideLoader}
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="Qist pay iframe loading...."
            >
            </iframe>
          </div>
          {setListener()}
          <button
            type="button"
            id="back-btn"
            className="btn btn-outline-primary btn-block niftBackBtn"
            onClick={moveBackwards}
            disabled={showBackBtnLoader ? true : false}
          >
            {translate("back_btn")}
          </button>
        </> : ""}
    </>
  );
}

export default QistPayCCForm;
