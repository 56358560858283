import React from "react";
import ReturntoMerchant from "../../components/ReturntoMerchant";
import {
  CART_CONSTANTS,
  ACTION_CONSTANTS,
  FORM_CONSTANTS,
  ERROR_CONSTANTS
} from "../actionTypes";
import { apiService } from "../middlewares/api_service";
import {
  alertActions,
  errorAction,
  errorPageAction,
  LOG_ORDER_EXPIRED,
  formAction,
  verificationAction,
} from "../actions";
import {
  CONSTANTS,
  ROUTE_CONSTANTS,
  HELPER,
  LOCAL_STORAGE_SERVICE,
  history,
  ANALYTICS_HELPER
} from "../../utils";
import { store } from "..";
import { ADJUST_ANALYTICS_HELPER } from "../../utils/analytics";

export const cartAction = {
  TOGGLE_CART_ICON,
  DEVICE_FINGERPRINT_FOUND,
  DEVICE_FINGERPRINT_ERROR_FOUND,
  update_cart_detail,
  checkout_detail,
  place_order,
  get_payment_info,
  verify_customer_after_checkout,
  return_to_merchant_button,
  update_voucher_code,
  submit_voucher_code,
  TOGGLE_CART_REVIEW_POPUP,
};

function TOGGLE_CART_REVIEW_POPUP() {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: CART_CONSTANTS.CART_REVIEW_OPEN_REQUEST };
  }
}

function TOGGLE_CART_ICON() {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: CART_CONSTANTS.CART_MENU_OPEN_REQUEST };
  }
}


function DEVICE_FINGERPRINT_ERROR_FOUND() {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: CART_CONSTANTS.DEVICE_FINGERPRINT_ERROR };
  }
}

function DEVICE_FINGERPRINT_FOUND(data) {
  return (dispatch) => {
    dispatch(request(data));
  };
  function request(response) {
    return { type: CART_CONSTANTS.DEVICE_FINGERPRINT_FOUND, response };
  }
}

function update_cart_detail() {
  return (dispatch, getState) => {
    const { bypass_expiry_enabled } = getState().cart?.payment_method || {}
    dispatch(request());
    apiService
      .updateCartdetail(bypass_expiry_enabled)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const data = response?.data?.body;
          dispatch(success(data));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;
        ANALYTICS_HELPER._paymentFailure(error_message?.message)
        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            dispatch(failure(error_message?.message));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: CART_CONSTANTS.UPDATE_CART_DETAILS_REQUEST };
  }
  function success(data) {
    return {
      type: CART_CONSTANTS.UPDATE_CART_DETAILS_SUCCESS,
      response: data,
    };
  }
  function failure() {
    return { type: CART_CONSTANTS.UPDATE_CART_DETAILS_FAILURE };
  }
}

function checkout_detail() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getCartdetail()
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const data = response?.data?.body;
          dispatch(success(data));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            dispatch(failure(error_message?.message));
            dispatch(alertActions.error(error_message?.message));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: CART_CONSTANTS.GET_CART_DETAILS_REQUEST };
  }
  function success(data) {
    ANALYTICS_HELPER._cartSuccess(data)
    return {
      type: CART_CONSTANTS.GET_CART_DETAILS_SUCCESS,
      cart_data: data,
    };
  }
  function failure() {
    return { type: CART_CONSTANTS.GET_CART_DETAILS_FAILURE };
  }
}

function place_order(requestData) { 
  return async (dispatch, getState) => {
    
    requestData.timestamp= new Date()
    const {payment_method, cart_details}= getState().cart
    const { analytic_currency_code, total_amount, discount_amount } = cart_details?.summary
    const { bypass_expiry_enabled } = payment_method

    dispatch(formAction.SHOW_LOADER());
    dispatch(request());
    apiService
      .placeOrder(requestData, bypass_expiry_enabled)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseBody = response?.data?.body;
          
          ADJUST_ANALYTICS_HELPER.adjustOrderSyncing({
            order_id: responseBody?.order_id,
            analytic_currency_code,
            total_amount,
            discount_amount
          });


          dispatch(success(responseBody));
          const payment_redirection = responseBody.payment_redirection;
          if (HELPER.stringToBoolean(payment_redirection)) {
            // dispatch(formAction.SHOW_PLEASE_WAIT_LOADER());
            HELPER.redirectToPath(responseBody.payment_link, false);
          } else {
            // Post checkout scenario
            /*
            Case 01: If verification_call == yes &&  ask_for_verification == yes
              then, calling you screen and then success screen. when customer answered the call and verify the order, we will mark customer as verified.
            Case 02: If verification_call == yes &&  ask_for_verification == no
              otp screen and after verification, success screen?
            Case 03: If verification_call == no &&  ask_for_verification == yes
              calling you fro 5 sec and then success screen
            Case 04: If verification_call == no &&  ask_for_verification == no
              success page || redirect_to_merchant
          */
            const verification_call = responseBody?.verification_call;
            const ask_for_verification = responseBody?.ask_for_verification;
            const redirect = responseBody?.redirect;
            if (
              /*
           Case 01: If verification_call == yes &&  ask_for_verification == yes && redirect === yes (Show merchant success page)
             then, show calling you screen and as ask_for_verification == yes show verification pop up as well.
             when customer answered the call and verify the order, we will mark customer as verified.
           */
              verification_call === 1 &&
              ask_for_verification === 1 &&
              redirect === 1
            ) {
              history.push({
                pathname: ROUTE_CONSTANTS.ORDER_SUCCESS,
              });
            } else if (
              /*
             Case 02: If verification_call == no &&  ask_for_verification == yes && redirect === yes (Show merchant success page)
               then, show ask for verification pop up on cart page.
           */
              verification_call === 0 &&
              ask_for_verification === 1 &&
              redirect === 1
            ) {
              //Inorder to disable back button lets consider this otp screen as first screen
              LOCAL_STORAGE_SERVICE._setFirstScreen();
              dispatch(post_checkout_verification());
            } else if (
              /*
             Case 02: If verification_call == yes &&  ask_for_verification == no && redirect === yes (Show merchant success page)
               then, show calling you screen.
           */
              verification_call === 1 &&
              ask_for_verification === 0 &&
              redirect === 1
            ) {
              history.push({
                pathname: ROUTE_CONSTANTS.ORDER_SUCCESS,
              });
            } else if (redirect === 0) {
              /*
             Case 02: If redirect === no (Show bsecure success page)
               then, show calling you screen.
           */
              history.push({
                pathname: ROUTE_CONSTANTS.ORDER_SUCCESS,
              });
            } else {
              const redirect_url = responseBody?.redirect_url;
              const store_url = responseBody?.store_url;
              setTimeout(() => {
                HELPER.redirectToPathAndClearLocalStorage(redirect_url, store_url);
              }, 2000);
            }
          }
          // DELETE CNIC
          LOCAL_STORAGE_SERVICE._deleteFromLocalStorage("cnic_value");
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;
        ANALYTICS_HELPER._paymentFailure(error_message?.message)
        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            dispatch(alertActions.error(error_message?.message));
            dispatch(failure(errorBody));
          }
        }
    });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: CART_CONSTANTS.PLACE_ORDER_REQUEST };
  }
  function success(response) {
    return {
      type: CART_CONSTANTS.PLACE_ORDER_SUCCESS,
      response,
    };
  }
  function failure(error_body) {
    //on call_review_details clear voucher field and call checkout_detail
    if(error_body?.call_review_details){
      store.dispatch(cartAction.update_voucher_code(""));
      store.dispatch(cartAction.checkout_detail());
    }
    return {
      type: CART_CONSTANTS.PLACE_ORDER_FAILURE,
      error: error_body,
    };
  }
  function post_checkout_verification() {
    return {
      type: CART_CONSTANTS.POST_CHECKOUT_VERIFICATION_CHECK,
    };
  }
}

function get_payment_info(requestData, bypass_enabled) {
  return (dispatch, getState) => {
    const { bypass_expiry_enabled } = getState().cart?.payment_method || {}

    dispatch(request());
    apiService
      .getPaymentInfo(requestData, bypass_enabled || bypass_expiry_enabled)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseBody = response?.data?.body;
          dispatch(success(responseBody));
          // Post checkout scenario
          /*
            Case 01: If verification_call == yes &&  ask_for_verification == yes
              then, calling you screen and then success screen. when customer answered the call and verify the order, we will mark customer as verified.
            Case 02: If verification_call == yes &&  ask_for_verification == no
              otp screen and after verification, success screen?
            Case 03: If verification_call == no &&  ask_for_verification == yes
              calling you fro 5 sec and then success screen
            Case 04: If verification_call == no &&  ask_for_verification == no
              success page || redirect_to_merchant
          */
          const verification_call = responseBody?.verification_call;
          const ask_for_verification = responseBody?.ask_for_verification;
          const redirect = responseBody?.redirect;
          if (
            /*
           Case 01: If verification_call == yes &&  ask_for_verification == yes && redirect === yes (Show merchant success page)
             then, show calling you screen and as ask_for_verification == yes show verification pop up as well.
             when customer answered the call and verify the order, we will mark customer as verified.
           */
            verification_call === 1 &&
            ask_for_verification === 1 &&
            redirect === 1
          ) {
            history.push({
              pathname: ROUTE_CONSTANTS.ORDER_SUCCESS,
            });
          } else if (
            /*
             Case 02: If verification_call == no &&  ask_for_verification == yes && redirect === yes (Show merchant success page)
               then, show ask for verification pop up on cart page.
           */
            verification_call === 0 &&
            ask_for_verification === 1 &&
            redirect === 1
          ) {
            dispatch(post_checkout_verification());
          } else if (
            /*
             Case 02: If verification_call == yes &&  ask_for_verification == no && redirect === yes (Show merchant success page)
               then, show calling you screen.
           */
            verification_call === 1 &&
            ask_for_verification === 0 &&
            redirect === 1
          ) {
            history.push({
              pathname: ROUTE_CONSTANTS.ORDER_SUCCESS,
            });
          } else if (redirect === 0) {
            /*
             Case 02: If redirect === no (Show bsecure success page)
               then, show calling you screen.
           */
            history.push({
              pathname: ROUTE_CONSTANTS.ORDER_SUCCESS,
            });
          } else {
            const redirect_url = responseBody?.redirect_url;
            const store_url = responseBody?.store_url;
            setTimeout(() => {
              HELPER.redirectToPathAndClearLocalStorage(redirect_url, store_url);
            }, 2000);
          }
          // DELETE CNIC
          LOCAL_STORAGE_SERVICE._deleteFromLocalStorage("cnic_value");
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const errorBody = error_response?.data?.body;
        const error_message = errorPageAction(error_response);

        if (error_response === undefined) {
          history.push({
            pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
            state: error_message,
          });
        } else {
          if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
            error_message && dispatch(errorPage(error_message));
          } else {
            if (errorBody?.isExpired === 1) {
              dispatch(LOG_ORDER_EXPIRED(errorBody));
            } else {
              dispatch(errorScreen(error_message));
            }
          }
        }
        dispatch(failure(error_message?.body));
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: CART_CONSTANTS.VALIDATE_PAYMENT_INFO_REQUEST };
  }
  function success(response) {
    return {
      type: CART_CONSTANTS.VALIDATE_PAYMENT_INFO_SUCCESS,
      response,
    };
  }
  function failure(error_body) {
    return {
      type: CART_CONSTANTS.VALIDATE_PAYMENT_INFO_FAILURE,
      error: error_body,
    };
  }
  function errorScreen(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function post_checkout_verification() {
    return {
      type: CART_CONSTANTS.POST_CHECKOUT_VERIFICATION_CHECK,
    };
  }
}

function verify_customer_after_checkout(modal) {
  LOCAL_STORAGE_SERVICE._isFirstScreen();
  return (dispatch, getState) => {
    const { cart_success, verification_type } = getState().cart;
  
    const onClose = () => {
      dispatch(close());
    };
    const onSuccess = () => {
      dispatch(close());
      if(verification_type === CONSTANTS.VERIFICATION_TYPE.PHONE){
        history.push({
          pathname: ROUTE_CONSTANTS.PHONE_SCREEN,
        });
      }else if(verification_type === CONSTANTS.VERIFICATION_TYPE.OTP){
        dispatch(verificationAction.OTP_SEND({}, history));
        history.push({
          pathname: ROUTE_CONSTANTS.OTP_SCREEN,
        });
      }
    };

    const onCancel = () => {
      dispatch(close());
      // if (isAndroid && window.Android) {
      //   window.Android.returnToMerchantButton(responseBody.redirect_url);
      // }
      if (HELPER.sendEventToiOS()) {
        window.webkit.messageHandlers.returnToMerchantListener.postMessage(cart_success.redirect_url);
      }
      HELPER.redirectToPathAndClearLocalStorage(cart_success.redirect_url,cart_success?.store_url);
    };

    dispatch(open(modal, onClose, onSuccess, onCancel));
  };
  function close() {
    return { type: ACTION_CONSTANTS.MODAL_CLOSE };
  }
  function open(modal, onClose, onSuccess, onCancel) {
    return {
      type: ACTION_CONSTANTS.MODAL_OPEN,
      modal,
      onClose,
      onSuccess,
      onCancel,
    };
  }
}

function return_to_merchant_button(
  redirect_url,
  store_url,
  disableBackdropClick = true
) {
  return (dispatch) => {
    const onClose = () => {
      dispatch(close());
    };
    const onSuccess = () => {
      if (HELPER.sendEventToiOS()) {
        window.webkit.messageHandlers.returnToMerchantListener.postMessage(redirect_url);
      }
      // if (isAndroid && window.Android) {
      //   window.Android.returnToMerchantButton(redirect_url);
      // }
      // if (window.webkit && isIOS) {
      //   window.webkit.messageHandlers.returnToMerchantListener.postMessage(
      //     redirect_url
      //   );
      // }
      HELPER.redirectToPathAndClearLocalStorage(redirect_url, store_url);
    };
    const onCancel = () => {
      dispatch(close());
    };
    const modal = {
      title: "",
      content: "",
      cancelBtn: "",
      successBtn: <ReturntoMerchant />,
      disableBackdropClick,
      cancelBtnId: "",
      successBtnId: "return-to-merchant",
    };
    dispatch(open(modal, onClose, onSuccess, onCancel));
  };

  function close() {
    return { type: ACTION_CONSTANTS.MODAL_CLOSE };
  }
  function open(modal, onClose, onSuccess, onCancel) {
    return {
      type: ACTION_CONSTANTS.MODAL_OPEN,
      modal,
      onClose,
      onSuccess,
      onCancel,
    };
  }
}

function update_voucher_code(code,voucherify_voucher_id=null) {
  return (dispatch) => {
    dispatch(request(code,voucherify_voucher_id));
  };

  function request(response) {
    return { type: FORM_CONSTANTS.UPDATE_VOUCHER_CODE, response, voucherify_voucher_id };
  }
}

function submit_voucher_code(requestData) {
  return (dispatch, getState) => {
    const { is_customer_app } = getState().configuration?.config;
    const { vouchers, voucher } = getState().cart;
    let _endpoint= apiService.applyVoucher
    let _voucherifyFound=vouchers?.find(e => (e?.code === requestData?.voucher_code))
    let _isTaptapVoucher= is_customer_app === 1 && _voucherifyFound
    if(_isTaptapVoucher){
      requestData={
        voucher_id: voucher?.voucherify_voucher_id || _voucherifyFound?.id
      }
      _endpoint= apiService.applyVoucherifyVoucher
    }

    dispatch(request());
    _endpoint(requestData)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const data = response?.data?.body;
          const success_message = response?.data?.message;
          if(is_customer_app === 1){
            dispatch(alertActions.success(success_message));
            window.scrollTo(0,0)
            setTimeout(() => {
              dispatch(alertActions.clear());
            }, 4000);
          }
          dispatch(success(data, success_message, is_customer_app));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            if(_isTaptapVoucher){
              dispatch(cartAction.update_voucher_code(""));
            }
            dispatch(failure(error_message.message));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: CART_CONSTANTS.VOUCHER_REQUEST };
  }
  function success(response, success_message, is_customer_app) {
    return {
      type: CART_CONSTANTS.VOUCHER_SUCCESS,
      response,
      success_message,
      is_customer_app
    };
  }
  function failure(error_message) {
    return { type: CART_CONSTANTS.VOUCHER_FAILURE, error_message };
  }
}
