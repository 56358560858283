// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { OrderStatusUpdate } from "../../../containers";
import { BREADCRUMB_SETTINGS_JSON, LOCAL_STORAGE_SERVICE, HELPER } from "../../../utils";
import { cartAction, logoutAction } from "../../../store/actions";

import "animate.css";

function OrderFailure() {
  let dispatch = useDispatch();
  const translate = useTranslate();
  const { is_customer_app } = useSelector(state => state.configuration.config);
  const { failure_redirect_url, store_redirect_url } = useSelector(state => state.cart.payment_failure_response);
  //componentDidMount

  useEffect(() => {
      HELPER.sendMerchantBeforeUnloadListener(failure_redirect_url,store_redirect_url);
      dispatch(cartAction.return_to_merchant_button(failure_redirect_url, store_redirect_url));
      if(!HELPER.isEmpty(failure_redirect_url)) { // is_customer_app 
        setTimeout(() => {
          dispatch(logoutAction.CLEAR_REDUX_STORE())
          HELPER.redirectToPathAndClearLocalStorage(failure_redirect_url, store_redirect_url);
        },is_customer_app ? process.env.REACT_APP_RETURN_TO_MERCHANT_TIMEOUT : process.env.REACT_APP_REDIRECT_TO_MERCHANT_TIMEOUT);
      }
      setTimeout(() => {
        dispatch(logoutAction.CLEAR_REDUX_STORE());
        LOCAL_STORAGE_SERVICE._clearLocalStorage();
      }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!HELPER.isEmpty(failure_redirect_url)) {
      HELPER.sendMerchantBeforeUnloadListener(failure_redirect_url,store_redirect_url);
      dispatch(cartAction.return_to_merchant_button(failure_redirect_url, store_redirect_url));

      // if(is_customer_app){
        setTimeout(() => {
          dispatch(logoutAction.CLEAR_REDUX_STORE())
          HELPER.redirectToPathAndClearLocalStorage(failure_redirect_url, store_redirect_url);
        }, is_customer_app ? process.env.REACT_APP_RETURN_TO_MERCHANT_TIMEOUT : process.env.REACT_APP_REDIRECT_TO_MERCHANT_TIMEOUT);
      // }

      setTimeout(() => {
        dispatch(logoutAction.CLEAR_REDUX_STORE());
        LOCAL_STORAGE_SERVICE._clearLocalStorage();
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failure_redirect_url, store_redirect_url]);
  
  return (
    <OrderStatusUpdate
      pageHeading={translate("out_of_tries_heading")}
      screenSubHeading={translate("out_of_failure_text")}
      child=""
      showLangIcon={true}
      breadcrumbOptionParams={BREADCRUMB_SETTINGS_JSON.OUT_OF_RETRIES}
    />
  );
}

export default OrderFailure;
