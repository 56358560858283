import { PAYMENT_CONSTANT } from "../../actionTypes";
import { apiService } from "../../middlewares/api_service";
import {
  LOG_ORDER_EXPIRED,
  errorAction,
  alertActions
} from "../index";
import {
  CONSTANTS,
  ROUTE_CONSTANTS,
  HELPER,
  LOCAL_STORAGE_SERVICE,
  history
} from "../../../utils";

export const generalAction = {
  SAVE_PAYMENT_INSTRUMENT,
  PAYMENT_PROCESSOR_DETAIL
};


function SAVE_PAYMENT_INSTRUMENT(requestData) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .savePaymentInstrument(requestData?.requestData)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const data = response?.data?.body;
          dispatch(success(data));
          //REDIRECT FROM PAYMENT SCREEN
          LOCAL_STORAGE_SERVICE._nextScreen();
          history.push({
            pathname: ROUTE_CONSTANTS.CART,
          });
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        dispatch(failure(error_message.message));

        if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        }
      });
  };

  function request() {
    return { type: PAYMENT_CONSTANT.GENERAL.SAVE_PAYMENT_INSTRUMENT_REQUEST };
  }
  function success(data) {
    return {
      type: PAYMENT_CONSTANT.GENERAL.SAVE_PAYMENT_INSTRUMENT_SUCCESS,
      response: data,
    };
  }
  function failure() {
    return { type: PAYMENT_CONSTANT.GENERAL.SAVE_PAYMENT_INSTRUMENT_FAILURE };
  }
}

function PAYMENT_PROCESSOR_DETAIL() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getQisstPayPaymentInstrument()
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const data = response?.data?.body;
          dispatch(success(data));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        dispatch(failure(error_message.message));
        dispatch(alertActions.error(error_message?.message));

        if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        } else {
          history.push({
            pathname: ROUTE_CONSTANTS.CART,
          });
        }
      });
  };

  function request() {
    return { type: PAYMENT_CONSTANT.GENERAL.PAYMENT_PROCESSOR_DETAIL_REQUEST };
  }
  function success(data) {
    return {
      type: PAYMENT_CONSTANT.GENERAL.PAYMENT_PROCESSOR_DETAIL_SUCCESS,
      response: data,
    };
  }
  function failure() {
    return { type: PAYMENT_CONSTANT.GENERAL.PAYMENT_PROCESSOR_DETAIL_FAILURE };
  }
}
