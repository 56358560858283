import { META_CONSTANTS } from "../../actionTypes";
import { apiService } from "../../middlewares/api_service";
import {
  LOG_ORDER_EXPIRED,
  errorAction,
  alertActions
} from "../../actions";
import {
  CONSTANTS,
  HELPER
} from "../../../utils";

export const metaAction = {
  META_DATA,
  COUNTRY_DROPDOWN_LIST,
  get_default_dropdown,
  COUNTRY_DROPDOWN,
  format_country_dropdown,
};

function COUNTRY_DROPDOWN_LIST() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getCountryDropdownList()
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const dataList = response?.data?.body;
          dispatch(success(dataList));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        dispatch(failure(error_message?.message));

        if (error_response === undefined) {
          dispatch(alertActions.error(error_message?.message));
        } else if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        }
      });
  };

  function request() {
    return { type: META_CONSTANTS.COUNTRY_LIST_REQUEST };
  }
  function success(data) {
    return { type: META_CONSTANTS.COUNTRY_LIST_SUCCESS, response: data };
  }
  function failure() {
    return { type: META_CONSTANTS.COUNTRY_LIST_FAILURE };
  }
}

function META_DATA() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getMetaData()
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const countries = response?.data?.body;
          const data = HELPER.parseMetaData(countries);
          dispatch(success(data));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        dispatch(failure(error_message?.message));

        if (error_response === undefined) {
          dispatch(alertActions.error(error_message?.message));
        } else if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        }
      });
  };

  function request() {
    return { type: META_CONSTANTS.META_REQUEST };
  }
  function success(data) {
    return { type: META_CONSTANTS.META_SUCCESS, metaData: data };
  }
  function failure() {
    return { type: META_CONSTANTS.META_FAILURE };
  }
}

function get_default_dropdown() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getMetaData()
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const countries = response?.data?.body;
          const data = HELPER.parseDefaultMetaData(countries);
          dispatch(success(data));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        dispatch(failure(error_message?.message));

        if (error_response === undefined) {
          dispatch(alertActions.error(error_message?.message));
        } else if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        }
      });
  };

  function request() {
    return { type: META_CONSTANTS.DEFAULT_META_REQUEST };
  }
  function success(data) {
    return { type: META_CONSTANTS.DEFAULT_META_SUCCESS, metaData: data };
  }
  function failure() {
    return { type: META_CONSTANTS.DEFAULT_META_FAILURE };
  }
}

function COUNTRY_DROPDOWN(responseData) {
  return (dispatch) => {
    dispatch(meta_request());
    dispatch(request());
    apiService
      .getMetaData()
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const countries = response?.data?.body;
          const responseBody = HELPER.parseDefaultMetaData(
            countries,
            responseData
          );
          dispatch(success(responseBody));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        dispatch(meta_failure(error_message?.message));
        dispatch(failure(error_message?.message));

        if (error_response === undefined) {
          dispatch(alertActions.error(error_message?.message));
        } else if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        }
      });
  };

  function request() {
    return { type: META_CONSTANTS.DEFAULT_META_REQUEST };
  }
  function meta_request() {
    return { type: META_CONSTANTS.META_REQUEST };
  }
  function success(data) {
    return { type: META_CONSTANTS.DEFAULT_META_SUCCESS, metaData: data };
  }
  function failure() {
    return { type: META_CONSTANTS.DEFAULT_META_FAILURE };
  }
  function meta_failure() {
    return { type: META_CONSTANTS.META_FAILURE };
  }
}




function format_country_dropdown(country_dropdown_record) {
  return (dispatch) => {
    dispatch(meta_success(country_dropdown_record));
  };

  function meta_success(data) {
    return { type: META_CONSTANTS.DEFAULT_META_SUCCESS, metaData: data };
  }
}