
import { store } from "../../store";
import { formAction } from "../../store/actions/formAction";
import { 
  ANALYTICS_HELPER,
  GOOGLE_ANALYTICS_HELPER,
  HELPER,
  CONSTANTS,
  ROUTE_CONSTANTS
} from "../../utils";


function ORDER_INITIATE(response) {
  const {
    address_count,
    delivery_address_id,
    shipment_method_id,
    payment_method_id,
    screen,
    user_journey
  } = response.app_setup;
  const { placement_type } = response.cart?.summary;

  ANALYTICS_HELPER._generalEventLog(
    GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.USER_JOURNEY,
    {
      action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.USER_JOURNEY,
      label: user_journey === CONSTANTS.USER_JOURNEY.NEW_USER ? CONSTANTS.USER_JOURNEY.NEW_USER : CONSTANTS.USER_JOURNEY.RETURNING_USER,
      properties: {
        user_journey: user_journey === CONSTANTS.USER_JOURNEY.NEW_USER ? CONSTANTS.USER_JOURNEY.NEW_USER : CONSTANTS.USER_JOURNEY.RETURNING_USER
      }
    }
  );

  const universalOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.UNIVERSAL;
  const appOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.APP || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.MANUAL || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.COD_VERIFICATION;
  const paymentGatewayOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE
  || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP;

  ANALYTICS_HELPER._generalEventLog(
    GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.PLACEMENT_TYPE,
    {
      action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.PLACEMENT_TYPE,
      label: placement_type,
      properties: {
        placement_type
      }
    }
  );
  if (screen === CONSTANTS.SCREEN_REDIRECT.PHONE) {
    if (appOrder) {
      store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.LOGIN));
    }
  } else if (screen === CONSTANTS.SCREEN_REDIRECT.OTP) {
    if (appOrder) {
      store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.OTP));
    } else if (paymentGatewayOrder) {
      store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.RETURNING_USER_JOURNEY.OTP));
    }
  } else if (screen === CONSTANTS.SCREEN_REDIRECT.CHECKOUT) {
    if (universalOrder) {
      if (payment_method_id > 0) {
        if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.NEW_USER_JOURNEY.PAYMENT));
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.NEW_USER_JOURNEY.REVIEW));
        } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.RETURNING_USER_JOURNEY.PAYMENT));
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.RETURNING_USER_JOURNEY.REVIEW));
        }
      } else {
        if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.NEW_USER_JOURNEY.PAYMENT));
        } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.RETURNING_USER_JOURNEY.PAYMENT));
        }
      }
    }
    if (paymentGatewayOrder) {
      if (payment_method_id > 0) {
        if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.NEW_USER_JOURNEY.PAYMENT));
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.NEW_USER_JOURNEY.REVIEW));
        } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.RETURNING_USER_JOURNEY.PAYMENT));
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.RETURNING_USER_JOURNEY.REVIEW));
        }
      } else {
        if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.NEW_USER_JOURNEY.PAYMENT));
        } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.RETURNING_USER_JOURNEY.PAYMENT));
        }
      }
    }
    if (appOrder) {
      if (delivery_address_id === null) {
        if (address_count === 0) {
          if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
            store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.ADD_ADDRESS));
          } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
            store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.ADD_ADDRESS));
          }
        } else if (address_count === 1) {
          if (delivery_address_id === null) {
            if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.ADD_ADDRESS));
            } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.ADD_ADDRESS));
            }
          } else if (shipment_method_id !== null && shipment_method_id >= 0) {
            if (payment_method_id > 0) {
              if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.REVIEW));
              } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.REVIEW));
              }
            } else {
              if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
              } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
              }
            }
          } else {
            if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
            } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
            }
          }
        } else if (address_count > 1) {
          if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
            store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.ADD_ADDRESS));
          } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
            store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.ADD_ADDRESS));
          }
        }
      } else if (delivery_address_id !== null) {
        if (address_count === 0) {
          if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
            store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.ADD_ADDRESS));
          } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
            store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.ADD_ADDRESS));
          }
        } else if (address_count === 1) {
          if (shipment_method_id !== null && shipment_method_id >= 0) {
            if (payment_method_id > 0) {
              if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.REVIEW));
              } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.REVIEW));
              }
            } else {
              if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
              } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
              }
            }
          } else {
            if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
            } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
            }
          }
        } else if (address_count > 1) {
          if (
            delivery_address_id !== null &&
            shipment_method_id !== null &&
            shipment_method_id >= 0
          ) {
            if (payment_method_id > 0) {
              if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.REVIEW));
              } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.REVIEW));
              }
            } else {
              if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
              } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
              }
            }
          } else {
            if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
            } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
            }
          }
        }
      }
    }
  }

  return true;
}


function OTP_VERIFIED(response) {
  const {
    address_count,
    delivery_address_id,
    shipment_method_id,
    payment_method_id,
    screen,
    user_journey
  } = response.app_setup;
  const { placement_type } = response;

  ANALYTICS_HELPER._generalEventLog(
    GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.USER_JOURNEY,
    {
      action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.USER_JOURNEY,
      label: user_journey === CONSTANTS.USER_JOURNEY.NEW_USER ? CONSTANTS.USER_JOURNEY.NEW_USER : CONSTANTS.USER_JOURNEY.RETURNING_USER,
      properties: {
        user_journey: user_journey === CONSTANTS.USER_JOURNEY.NEW_USER ? CONSTANTS.USER_JOURNEY.NEW_USER : CONSTANTS.USER_JOURNEY.RETURNING_USER
      }
    }
  );

  const universalOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.UNIVERSAL;
  const appOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.APP || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.MANUAL || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.COD_VERIFICATION;
  const paymentGatewayOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE
  || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP;

  if (screen === CONSTANTS.SCREEN_REDIRECT.CHECKOUT) {
    if (universalOrder) {
      if (payment_method_id > 0) {
        if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.NEW_USER_JOURNEY.PAYMENT));
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.NEW_USER_JOURNEY.REVIEW));
        } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.RETURNING_USER_JOURNEY.PAYMENT));
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.RETURNING_USER_JOURNEY.REVIEW));
        }
      } else {
        if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.NEW_USER_JOURNEY.PAYMENT));
        } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.RETURNING_USER_JOURNEY.PAYMENT));
        }
      }
    }
    if (paymentGatewayOrder) {
      if (payment_method_id > 0) {
        if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.NEW_USER_JOURNEY.PAYMENT));
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.NEW_USER_JOURNEY.REVIEW));
        } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.RETURNING_USER_JOURNEY.PAYMENT));
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.RETURNING_USER_JOURNEY.REVIEW));
        }
      } else {
        if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.NEW_USER_JOURNEY.PAYMENT));
        } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.RETURNING_USER_JOURNEY.PAYMENT));
        }
      }
    }
    if (appOrder) {
      if (delivery_address_id === null) {
        if (address_count === 0) {
          if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
            store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.ADD_ADDRESS));
          } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
            store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.ADD_ADDRESS));
          }
        } else if (address_count === 1) {
          if (delivery_address_id === null) {
            if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.ADD_ADDRESS));
            } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.ADD_ADDRESS));
            }
          } else if (shipment_method_id !== null && shipment_method_id >= 0) {
            if (payment_method_id > 0) {
              if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.REVIEW));
              } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.REVIEW));
              }
            } else {
              if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
              } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
              }
            }
          } else {
            if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
            } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
            }
          }
        } else if (address_count > 1) {
          if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
            store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.ADD_ADDRESS));
          } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
            store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.ADD_ADDRESS));
          }
        }
      } else if (delivery_address_id !== null) {
        if (address_count === 0) {
          if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
            store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.ADD_ADDRESS));
          } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
            store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.ADD_ADDRESS));
          }
        } else if (address_count === 1) {
          if (shipment_method_id !== null && shipment_method_id >= 0) {
            if (payment_method_id > 0) {
              if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.REVIEW));
              } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.REVIEW));
              }
            } else {
              if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
              } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
              }
            }
          } else {
            if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
            } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
            }
          }
        } else if (address_count > 1) {
          if (
            delivery_address_id !== null &&
            shipment_method_id !== null &&
            shipment_method_id >= 0
          ) {
            if (payment_method_id > 0) {
              if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.REVIEW));
              } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.REVIEW));
              }
            } else {
              if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
              } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
                store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
              }
            }
          } else {
            if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
            } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
              store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
            }
          }
        }
      }
    }
  }

  return true;
}

function POST_ADDRESS_ANALYTICS_CHECKOUT_STEPS() {
  const state = store.getState();
  const { user_journey } = state.configuration.config
  const { shipment_method_id, payment_method_id, placement_type } = state.redirect

  const universalOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.UNIVERSAL;
  const appOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.APP || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.MANUAL || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.COD_VERIFICATION;
  const paymentGatewayOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE
  || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP;

  if(universalOrder){
    return false
  }

  if(paymentGatewayOrder) {
    return false;
  }

  if(appOrder){
    if (HELPER.isEmpty(shipment_method_id)) {
      if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
        store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.SHIPMENT));
      } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
        store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.SHIPMENT));
      }
    } else {
      if (HELPER.isEmpty(payment_method_id)) {
        if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
        } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
        }
      } else {
        if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.REVIEW));
        } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.REVIEW));
        }
      }
    }
  }
}

function POST_SHIPMENT_ANALYTICS_CHECKOUT_STEPS() {
  const state = store.getState();
  const { user_journey } = state.configuration.config
  const { payment_method_id, placement_type } = state.redirect
  
  const universalOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.UNIVERSAL;
  const appOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.APP || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.MANUAL || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.COD_VERIFICATION;
  const paymentGatewayOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE
  || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP;

  if(universalOrder){
    if(user_journey === CONSTANTS.USER_JOURNEY.NEW_USER){
      if (payment_method_id > 0) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.NEW_USER_JOURNEY.PAYMENT));
        } else {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.NEW_USER_JOURNEY.REVIEW));
        }
    }else if(user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
      if (payment_method_id > 0) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.RETURNING_USER_JOURNEY.PAYMENT));
        } else {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.RETURNING_USER_JOURNEY.REVIEW));
        }
    }
  }

  if(paymentGatewayOrder){
    if(user_journey === CONSTANTS.USER_JOURNEY.NEW_USER){
      if (payment_method_id > 0) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.NEW_USER_JOURNEY.PAYMENT));
        } else {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.NEW_USER_JOURNEY.REVIEW));
        }
    }else if(user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
      if (payment_method_id > 0) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.RETURNING_USER_JOURNEY.PAYMENT));
        } else {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.RETURNING_USER_JOURNEY.REVIEW));
        }
    }
  }

  if(appOrder) {
    if(user_journey === CONSTANTS.USER_JOURNEY.NEW_USER){
      if (payment_method_id > 0) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.PAYMENT));
        } else {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.REVIEW));
        }
    }else if(user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
      if (payment_method_id > 0) {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.PAYMENT));
        } else {
          store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.REVIEW));
        }
    }
  }
}


function POST_PAYMENT_ANALYTICS_CHECKOUT_STEPS() {
  const state = store.getState();
  const { user_journey } = state.configuration.config
  const { route } = state.redirect
  const { placement_type } = state.cart.cart_details.summary;

  const universalOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.UNIVERSAL;
  const appOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.APP || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.MANUAL || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.COD_VERIFICATION;
  const paymentGatewayOrder = placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.PAYMENT_GATEWAY || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.INVOICE
  || placement_type === CONSTANTS?.ORDER_PLACEMENT_TYPE.GIVE_WP;

  if(universalOrder){
    if ([ROUTE_CONSTANTS.ADDRESS_LIST, ROUTE_CONSTANTS.UPDATE_ADDRESS, ROUTE_CONSTANTS.OTP_SCREEN, ROUTE_CONSTANTS.CHECKOUT, ROUTE_CONSTANTS.CART].includes(route?.from)) {
      if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
        store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.NEW_USER_JOURNEY.REVIEW));
      } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
        store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.RETURNING_USER_JOURNEY.REVIEW));
      }
    } else {
      if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
        store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.NEW_USER_JOURNEY.REVIEW));
      } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
        store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.UNIVERSAL_ORDER.RETURNING_USER_JOURNEY.REVIEW));
      }
    }
  }

  if(paymentGatewayOrder){
    if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
      store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.NEW_USER_JOURNEY.REVIEW));
    } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
      store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.PAYMENT_GATEWAY.RETURNING_USER_JOURNEY.REVIEW));
    }
  }

  if(appOrder){
    if ([ROUTE_CONSTANTS.ADDRESS_LIST, ROUTE_CONSTANTS.UPDATE_ADDRESS, ROUTE_CONSTANTS.OTP_SCREEN, ROUTE_CONSTANTS.CHECKOUT, ROUTE_CONSTANTS.CART].includes(route?.from)) {
      if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
        store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.REVIEW));
      } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
        store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.REVIEW));
      }
    } else {
      if (user_journey === CONSTANTS.USER_JOURNEY.NEW_USER) {
        store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.NEW_USER_JOURNEY.REVIEW));
      } else if (user_journey === CONSTANTS.USER_JOURNEY.RETURNING_USER) {
        store.dispatch(formAction.CHECKOUT_STEPS_TRIGGER(CONSTANTS.CHECKOUT_STEP.APP.RETURNING_USER_JOURNEY.REVIEW));
      }
    }
  }
}

const CHECKOUT_STEPS_HELPER = {
  ORDER_INITIATE,
  OTP_VERIFIED,
  POST_ADDRESS_ANALYTICS_CHECKOUT_STEPS,
  POST_SHIPMENT_ANALYTICS_CHECKOUT_STEPS,
  POST_PAYMENT_ANALYTICS_CHECKOUT_STEPS
};

export default CHECKOUT_STEPS_HELPER;