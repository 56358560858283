// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";
import Alert from "../../../components/Alert";

import AddAddressForm from "../AddAddressForm";
import {
  HELPER,
  history,
  ANALYTICS_HELPER,
  GOOGLE_ANALYTICS_HELPER,
} from "../../../utils";
import {
  metaAction,
  addressAction,
  alertActions,
  formAction,
  mapActions,
} from "../../../store/actions";
import { useAddressOnNotMandatory } from '../../../hooks'
import { MAP_CONSTANTS } from "../../../store/actionTypes";
import useNameEmail from "../../../hooks/useNameEmail";

let timeoutTime;
let defaultDropdownState = {
  value: null,
  label: null,
};
let defaultErrorState = {
  name: "",
  email: "",
  country_id: "",
  province_id: "",
  city_id: "",
  area_id: "",
  address: ""
};

function AddressForm(props) {
  let dispatch = useDispatch();
  const translate = useTranslate();
  const { address, setAddress } = useAddressOnNotMandatory()

  const { customer } = useSelector(state => state.configuration);
  const { is_pin_location_mandatory, is_international_shipment } = useSelector(state => state.configuration.config);
  const { showPageLoader } = useSelector(state => state.loading);
  const { address_count } = useSelector(state => state.redirect);
  const { metaData, defaultCountry } = useSelector(state => state.meta);
  const { setCoordinatesLat, setCoordinatesLng, searchPlace, clearAddress } = useSelector(state => state.map);
  const {
    selectedAddress,
    selectedDropdown,
    selectedProfile,
    locationDetails,
  } = useSelector(state => state.address);
  const customer_name = HELPER.titleCase(customer.name)
  const customer_email = customer.email
 
  const [address_id, setAddress_id] = useState(null);
  const [country_id, setCountry_id] = useState(defaultDropdownState);
  const [province_id, setProvince_id] = useState(defaultDropdownState);
  const [city_id, setCity_id] = useState(defaultDropdownState);
  const [area_id, setArea_id] = useState(defaultDropdownState);

  const [province_name, setprovince_name] = useState('')
	const [city_name, setcity_name] = useState('')
	const [area_name, setarea_name] = useState('')

  // const [address, setAddress] = useState("");
  const [errors, setErrors] = useState(defaultErrorState);
  const [countryData, setCountryData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [areasData, setAreasData] = useState([]);
  const [meta, setMetaData] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [is_default, setIs_default] = useState(0);
  const [disableName, setDisableName] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [zip_code, setzip_code] = useState("");

  let isCountryDisabled= locationDetails?.country && locationDetails?.country_id !== -1
	let isProvinceDisabled= locationDetails?.state && locationDetails?.state_id !== -1
	let isCityDisabled= locationDetails?.city && locationDetails?.city_id !== -1

  const checkErrorCondition = () => {
    const errorName = !HELPER.isEmpty(name) ? false : true;
    const errorEmail = !HELPER.isEmpty(email) ? false : true;
    const errorCountry = !HELPER.isEmpty(country_id) ? false : true;
    const errorProvince = !HELPER.isEmpty(province_id) ? false : true;
    const errorCity = !HELPER.isEmpty(city_id) ? false : true;
    const errorAddress = !HELPER.isEmpty(address) ? false : true;
    const errorArea = !HELPER.isEmpty(area_id) ? false : true;

    const errorProvinceName =
			province_id?.value === -1 && HELPER.isEmpty(province_name) ? true : false
		const errorCityName = city_id?.value === -1 && HELPER.isEmpty(city_name) ? true : false
		const errorAreaName = area_id?.value === -1 && HELPER.isEmpty(area_name) ? true : false

    const checkErrorCondition =
      errorName ||
        errorEmail ||
        errorCountry ||
        errorProvince ||
        errorCity ||
        errorAddress ||
        errorArea ||
        errorProvinceName || errorCityName || errorAreaName
        || (is_international_shipment && (typeof country_id === 'object' ? !country_id?.value : !HELPER.isEmpty(country_id)))
        ? true
        : false;
    return checkErrorCondition;
  };

  const state = {
    address_id,
    country_id,
    province_id,
    city_id,
    area_id,
    address,
    errors,
    countryData,
    citiesData,
    provinceData,
    areasData,
    latitude,
    longitude,
    meta,
    is_default,
    address_count,
    is_pin_location_mandatory,
    setCoordinatesLat,
    showAllFields: locationDetails?.error_msg,

    province_name,
    setprovince_name,
    city_name,
    setcity_name,
    area_name,
    setarea_name,

    isCountryDisabled,
    isProvinceDisabled,
    isCityDisabled
  };
  // const errorName = HELPER.isEmpty(errors.name) ? false : true;
  // const errorEmail = HELPER.isEmpty(errors.email) ? false : true;

  const {name, email, setName, setEmail, DisplayFields} = useNameEmail({disableEmail, disableName, errors})

  useEffect(() => {
    if ((HELPER.isEmpty(searchPlace) && !is_international_shipment) ||
     (HELPER.isEmpty(searchPlace) && is_international_shipment && setCoordinatesLat )) {
      dispatch(mapActions.GET_LOCATION_PIN(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleAddressChange = (e) => {

    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.ADDRESS.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.ADDRESS.LABEL,
        properties: {},
      },
    );
    dispatch({type: MAP_CONSTANTS.SEARCHED_ADDRESS , value: e.target.value});
    setAddress(e.target.value);
  };

  const handleDefaultChange = () => {
    setIs_default(!is_default);
  };

  const handleCountryChange = (e, options) => {
    if(country_id?.value === options?.value) return
    // Clear all selected country_id, province_id, city_id, area_id and respective dropdown values
    setCountry_id(defaultDropdownState);
    setProvince_id(defaultDropdownState);
    setCity_id(defaultDropdownState);
    setArea_id(defaultDropdownState);

    setProvinceData([]);
    setCitiesData([]);
    setAreasData([]);

    let selected_id = options?.value;

    if (!HELPER.isEmpty(selected_id)) {
      // set country id
      let selected_country_id = {
        value: selected_id,
        label: options?.label,
      };
      // handleAddressUpdateClear({ name: "country", options })
      setCountry_id(selected_country_id);

      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.COUNTRY.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.COUNTRY.LABEL,
          properties: {
            selected: {
              country: selected_country_id,
            }
          }
        }
      );
      // send selected_country_id.value parameter to parse provinces from HELPER
      const provinces = HELPER.findProvinceByCountryId(
        countryData,
        selected_id
      );
      // save parsed provinces data in provinces dropdown
      setProvinceData(provinces);

    }
  };

  const handleProvinceChange = (e, options) => {
    if(province_id?.value === options?.value) return
    // Clear all selected country_id, province_id, city_id, area_id and respective dropdown values
    setProvince_id(defaultDropdownState);
    setCity_id(defaultDropdownState);
    setArea_id(defaultDropdownState);

    setCitiesData([]);
    setAreasData([]);

    let selected_id = options?.value;

    if (!HELPER.isEmpty(selected_id)) {
      // set province id
      let selected_province_id = {
        value: selected_id,
        label: options?.label,
      };
      // handleAddressUpdateClear({ name: "state", options })
      setProvince_id(selected_province_id);
      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.PROVINCE.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.PROVINCE.LABEL,
          properties: {
            selected: {
              province: selected_province_id,
            }
          }
        }
      );
      // send selected_province_id.value parameter to parse cities from HELPER
      const cities = HELPER.findCityByProvinceId(provinceData, selected_id);
      // save parsed cities data in cities dropdown
      setCitiesData(cities);
    }
  };

  const handleCityChange = (e, options) => {
    if(city_id?.value === options?.value) return
    // Clear all selected country_id, province_id, city_id, area_id and respective dropdown values
    setCity_id(defaultDropdownState);
    setArea_id(defaultDropdownState);

    setAreasData([]);

    let selected_id = options?.value;

    if (!HELPER.isEmpty(selected_id)) {
      // set city id
      let selected_city_id = {
        value: selected_id,
        label: options?.label,
      };
      // handleAddressUpdateClear({ name: "city", options })
      setCity_id(selected_city_id);
      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.CITY.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.CITY.LABEL,
          properties: {
            selected: {
              city: selected_city_id,
            }
          }
        }
      );

      // send selected_city_id.value parameter to parse areas from HELPER
      const areas = HELPER.findAreaByCityId(citiesData, selected_id);
      // save parsed areas data in areas dropdown
      setAreasData(areas);
    }
  };

  const handleAreaChange = (e, options) => {
    if(area_id?.value === options?.value) return
    // Clear all selected area_id and respective dropdown values
    setArea_id(defaultDropdownState);

    let selected_id = options?.value;

    if (!HELPER.isEmpty(selected_id)) {
      // set area id
      let selected_area_id = {
        value: selected_id,
        label: options?.label,
      };
      // handleAddressUpdateClear({ name: "area", options })
      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.AREA.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.INPUT.AREA.LABEL,
          properties: {
            selected: {
              area: selected_area_id,
            }
          }
        }
      );
      // save parsed areas data in areas dropdown
      setArea_id(selected_area_id);
    }
  };

  const handleValidation = () => {
    // same as above, but feel free to move this into a class method now.
    let validation_errors = {
      name: "",
      email: "",
      country_id: "",
      province_id: "",
      city_id: "",
      area_id: "",
      address: "",
      latitude: "",
      longitude: ""
    };
    let validation_field_name = "";
    if (!name) {
      validation_errors.name = translate("VALIDATIONS.IS_REQUIRED");
      validation_field_name = "name";
    } else if (name.length < 3) {
      validation_errors.name = translate("VALIDATIONS.NAME_MIN");
      validation_field_name = "name";
    } else if (name.length > 50) {
      validation_errors.name = translate("name_field_max_validation");
      validation_field_name = "name";
    } else if (!email) {
      validation_errors.email = translate("VALIDATIONS.IS_REQUIRED");
      validation_field_name = "email";
    } else if (
      !HELPER.isEmpty(email) &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    ) {
      validation_errors.email = translate("invalid_email_address");
      validation_field_name = "email";
    } else if (email.length > 50) {
      validation_errors.email = translate("email_field_max_validation");
      validation_field_name = "email";
    }
    else if (is_pin_location_mandatory === 1) {
      if (HELPER.isEmpty(latitude)) {
        validation_errors.latitude = translate("mandatory_pin_placeholder");
        dispatch(alertActions.error(translate("mandatory_pin_placeholder")))
        validation_field_name = "email";
      } else if (HELPER.isEmpty(longitude)) {
        validation_errors.longitude = translate("mandatory_pin_placeholder");
        dispatch(alertActions.error(translate("mandatory_pin_placeholder")))
        validation_field_name = "email";
      } else if (!address) {
        validation_errors.address = translate("VALIDATIONS.IS_REQUIRED");
        validation_field_name = "address";
      } else if (address.length < 5) {
        validation_errors.address = translate("address_field_min_validation");
        validation_field_name = "address";
      }
      //new 
      if (HELPER.isEmpty(country_id.value)) {
        validation_errors.country_id = translate("VALIDATIONS.IS_REQUIRED");
        validation_field_name = "country_id";
      } else if (HELPER.isEmpty(province_id.value) || (province_id?.value === -1 && !province_name)) {
        validation_errors.province_id = translate("VALIDATIONS.IS_REQUIRED");
        validation_field_name = "province_id";
      } else if (HELPER.isEmpty(city_id.value) || (city_id?.value === -1 && !city_name)) {
        validation_errors.city_id = translate("VALIDATIONS.IS_REQUIRED");
        validation_field_name = "city_id";
      } 
      else if (locationDetails?.error_msg) {
				if (HELPER.isEmpty(area_id.value) || (area_id?.value === -1 && !area_name)) {
          validation_errors.area_id = translate("VALIDATIONS.IS_REQUIRED");
          validation_field_name = "area_id";
        }
			}
    }
    else {
      if (HELPER.isEmpty(country_id.value)) {
        validation_errors.country_id = translate("VALIDATIONS.IS_REQUIRED");
        validation_field_name = "country_id";
      } else if (HELPER.isEmpty(province_id.value) || (province_id?.value === -1 && !province_name)) {
        validation_errors.province_id = translate("VALIDATIONS.IS_REQUIRED");
        validation_field_name = "province_id";
      } else if (HELPER.isEmpty(city_id.value) || (city_id?.value === -1 && !city_name)) {
        validation_errors.city_id = translate("VALIDATIONS.IS_REQUIRED");
        validation_field_name = "city_id";
      } else if (!address) {
        validation_errors.address = translate("VALIDATIONS.IS_REQUIRED");
        validation_field_name = "address";
      } else if (address.length < 5) {
        validation_errors.address = translate("address_field_min_validation");
        validation_field_name = "address";
      } else if (HELPER.isEmpty(area_id.value)|| (area_id?.value === -1 && !area_name)) {
        validation_errors.area_id = translate("VALIDATIONS.IS_REQUIRED");
        validation_field_name = "area_id";
      }
    }
    if (validation_field_name !== "") {
      HELPER.scrollTo(validation_field_name, -200)
      // scroller.scrollTo(validation_field_name, { offset: -200, smooth: true });
    }
    setErrors(validation_errors);
    return validation_errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationCheck = handleValidation();
    if (
      validationCheck.name === "" &&
      validationCheck.email === "" &&
      validationCheck.country_id === "" &&
      validationCheck.province_id === "" &&
      validationCheck.city_id === "" &&
      validationCheck.area_id === "" &&
      validationCheck.address === "" &&
      validationCheck.latitude === "" &&
      validationCheck.longitude === "" &&
      !showPageLoader
    ) {
      if (HELPER.isEmpty(address_id)) {
        const addressObject = {
          address_id: address_id,
          name,
          email,
          country_id: country_id.value,
          province_id: province_id.value,
          city_id: city_id.value,
          area_id: area_id?.value,
          address,
          latitude,
          longitude,
          is_default: is_default === true || is_default === 1 ? 1 : 0,
          zip_code,

          province_name,
          area_name,
          city_name,

        };
        const dropdown = {
          countryData,
          provinceData,
          citiesData,
          areasData,
        };
        dispatch(addressAction.add_new_customer_address(addressObject, dropdown, history));
      } else {
        const addressObject = {
          address_id: address_id,
          country_id: country_id.value,
          province_id: province_id.value,
          city_id: city_id.value,
          area_id: area_id?.value,
          address,
          latitude,
          longitude,
          is_default: is_default === true || is_default === 1 ? 1 : 0,
          zip_code,

          province_name,
          area_name,
          city_name,
        };
        dispatch(addressAction.update_customer_address(
          addressObject,
          history,
          true
        ));
        dispatch(alertActions.clear());
      }
      if (typeof timeoutTime !== "undefined") {
        clearTimeout(timeoutTime);
      }
    } else {
      timeoutTime = setTimeout(() => {
        setErrors(defaultErrorState);
      }, 5000);
    }
    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.SUBMIT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.SUBMIT.LABEL,
        properties: {}
      }
    );
  };

  //componentDidMount
  useEffect(() => {
    ANALYTICS_HELPER._checkoutSteps(3, GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS);
    // FIREBASE_ANALYTICS_HELPER.checkoutSteps(FIREBASE_ANALYTICS_HELPER.CHECKOUT.ADDRESS.TIER);

    // REDIRECTION_RULE.PRE_ADDRESS_REDIRECTION_RULE();
    // Clear alerts
      dispatch(alertActions.clear());
    if (parseInt(address_count) === 0) {
      setIs_default(1);
    }
    // Check if reducer has metaData values and assign it to meta state if not, then call meta data api asap
    if (HELPER.isEmpty(metaData)) {
      dispatch(metaAction.META_DATA());
    } else {
      // Since meta state has values of country data thus assign metaData reducer state to countrydata as well
      setMetaData(metaData);
      setCountryData(metaData);
    }
    // selectedAddress reducer state is used to save old form values
    if (!HELPER.isEmpty(selectedAddress)) {
      setAddress_id(selectedAddress.id);
      setName(selectedAddress.name);
      setEmail(selectedAddress.email);
      setCountry_id(selectedAddress.country_id);
      setProvince_id(selectedAddress.province_id);
      setCity_id(selectedAddress.city_id);
      setArea_id(selectedAddress.area_id);
      setAddress(selectedAddress.address);
      setCountryData(selectedDropdown?.countryData);
      setProvinceData(selectedDropdown?.provinceData);
      setCitiesData(selectedDropdown?.citiesData);
      setAreasData(selectedDropdown?.areasData);
      setIs_default(Boolean(Number(selectedAddress.is_default)));
    }
    // check if customer_name is already present in reducer state or local storage then copy it from there and disable the state
    // Same as, for email field
    if (
      !HELPER.isEmpty(customer_name) &&
      !HELPER.isEmpty(customer_name) &&
      customer_name !== "Guest" &&
      customer_name !== "guest"
    ) {
      dispatch(formAction.UPDATE_CUSTOMER_NAME(customer_name));
      setDisableName(true);
    }
    if (!HELPER.isEmpty(customer_email)) {
      setEmail(customer_email);
      setDisableEmail(true);
    }
    // returned function will be called on component unmount
    return () => {
      dispatch(alertActions.clear());
      clearTimeout(timeoutTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // check if customer_name is already present in reducer state or local storage then copy it from there and disable the state
    // Same as, for email field
    if (
      customer_name !== "guest" &&
      customer_name !== "Guest" &&
      !HELPER.isEmpty(customer_name)
    ) {
      setName(customer_name);
      setDisableName(true);
    }
    if (!HELPER.isEmpty(customer_email)) {
      setEmail(customer_email);
      setDisableEmail(true);
    }
    if (defaultCountry && !HELPER.isEmpty(metaData)) {
      const provinces = HELPER.findProvinceByCountryId(
        metaData,
        defaultCountry?.value
      );
      defaultCountry?.value && setCountry_id(defaultCountry);
      setProvinceData(provinces);
    }
    if (!HELPER.isEmpty(metaData)) {
      setMetaData(metaData);
      setCountryData(metaData);
    }
    // selectedAddress reducer state is used to save old form values
    if (!HELPER.isEmpty(selectedAddress)) {
      setAddress_id(selectedAddress.id);
      if (selectedProfile.name !== "guest" && selectedProfile.name !== "Guest") {
        setName(selectedProfile.name);
        setDisableName(true);
      }
      setEmail(selectedProfile.email);
      setDisableEmail(true);
      setCountry_id({
        value: selectedAddress.country_id,
        label: selectedAddress.country_name,
      });
      setProvince_id({
        value: selectedAddress.province_id,
        label: selectedAddress.province_name,
      });
      setCity_id({
        value: selectedAddress.city_id,
        label: selectedAddress.city_name,
      });
      setArea_id({
        value: selectedAddress.area_id,
        label: selectedAddress.area_name,
      });

      setprovince_name(selectedAddress?.province_name || "")
      setcity_name(selectedAddress?.city_name || "")
      setarea_name(selectedAddress?.area_name || "")

      setAddress(selectedAddress.address);
      setCountryData(selectedDropdown?.countryData);
      setProvinceData(selectedDropdown?.provinceData);
      setCitiesData(selectedDropdown?.citiesData);
      setAreasData(selectedDropdown?.areasData);
      setIs_default(Boolean(Number(selectedAddress.is_default)));
    }
    if (setCoordinatesLat || setCoordinatesLng) {
      setLatitude(setCoordinatesLat);
      setLongitude(setCoordinatesLng);
    }
    if (!HELPER.isEmpty(locationDetails)) {
      var provinces,
        cities,
        areas = [];
      const loc_details_country_id = locationDetails.country_id;
      const loc_details_state_id = locationDetails.state_id;
      const loc_details_city_id = locationDetails.city_id;
      const loc_details_area_id = locationDetails.area_id;
      // Get province, city and area values from metaData;
      provinces = HELPER.findProvinceByCountryId(
        metaData,
        loc_details_country_id
      );
      if (!HELPER.isEmpty(loc_details_state_id)) {
        cities = HELPER.findCityByProvinceId(provinces, loc_details_state_id);
        if (!HELPER.isEmpty(loc_details_city_id)) {
          areas = HELPER.findAreaByCityId(cities, loc_details_city_id);
        }
      }
      setCountry_id({
        value: loc_details_country_id,
        label: locationDetails.country,
      });
      setProvinceData(provinces);
      setProvince_id({
        value: loc_details_state_id,
        label: locationDetails.state,
      });
      setCitiesData(cities || []);
      setCity_id({
        value: loc_details_city_id,
        label: locationDetails.city,
      });
      setAreasData(areas);
      setArea_id({
        value: loc_details_area_id,
        label: locationDetails.area,
      });

      setprovince_name(locationDetails?.state || "")
      setcity_name(locationDetails?.city || "")
      setarea_name(locationDetails?.area || "")

      setAddress(locationDetails.address);
    }
    if (HELPER.isEmpty(setCoordinatesLat) && clearAddress) {
      setLatitude("");
      setLongitude("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customer_name,
    customer_email,
    metaData,
    selectedAddress,
    setCoordinatesLat,
    setCoordinatesLng,
    locationDetails,
  ]);

  const checkError = checkErrorCondition();

  return (
    <div className="signInView">
      <h1>{translate("ADDRESS.TITLE")}</h1>
      <p className="signviewsmalltext">
        {/* Enter your <span>shipping address</span> below */}
        {translate("ADDRESS.ADD.PLACEHOLDER")}
      </p>
      <Alert />
      <form className="myForm addressForm">
      <div className="addressForm-fields">
       
        {DisplayFields()}
        {/* Add New Address Form */}
        <AddAddressForm
          state={state}
          handleCountryChange={handleCountryChange}
          handleProvinceChange={handleProvinceChange}
          handleCityChange={handleCityChange}
          handleAreaChange={handleAreaChange}
          handleChange={handleAddressChange}
          handleDefaultChange={handleDefaultChange}
          handleZipcode={(e)=>setzip_code(e.target.value)}
        />
         </div>
        <div className="address-singleBtn singleBtn">
          <button
            type="button"
            className={"btn btn-primary btn-block "}
            id="address-submit"
            onClick={handleSubmit}
            disabled={checkError ? true : false}
          >
            {translate("next_btn")}
          </button>
        </div>
      </form>
    </div>
  );
}

function mapStateToProps(state) {
}

export default AddressForm;
