// @flow

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { FormControlLabel, Checkbox } from "@material-ui/core";
import { parsePhoneNumber, isValidNumber } from "libphonenumber-js";
import { Accordion, Card } from "react-bootstrap";
import Modal from "../../../components/Modal";
import {
  CONSTANTS,
  GOOGLE_ANALYTICS_HELPER,
  ANALYTICS_HELPER,
  HELPER,
  LOCAL_STORAGE_SERVICE
} from "../../../utils";
import {
  customerAction,
  paymentAction,
} from "../../../store/actions";
import FormCard from "./card";
import PAYMENT_PROTECTION from './PaymentProtection'

const error = {
  easypaisaMA: null,
  bankAccount: null,
  bankWallet: null,
  qisstPay: null,
  general: null,
};

const FORM_TYPE = CONSTANTS.PAYMENT_METHOD.FORM_TYPE;

const validatePhone = (country, phone) => {
  let code = country.dialCode
  if (HELPER.isEmpty(phone)) {
    return false
  } else if (phone.length <= 4) {
    return false
  } else if (phone.length > 4 && phone.substr(0, code.length) === country.dialCode && !HELPER.isEmpty(phone.slice(code.length))) {
    return parsePhoneNumber("+" + phone);
  } else if (phone.substr(0, code.length) === country.dialCode && HELPER.isEmpty(phone.slice(0, code.length))) {
    return false;
  } else if (phone.length > 4) {
    return parsePhoneNumber("+" + country.dialCode + phone);
  }
};
/*
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});
*/

const PaymentMethods = React.forwardRef(({ reference, submitBtnClicked }, ref) => {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const {
    email,
    contact_number,
    selected_country
  } = useSelector(state => state.form);
  const { pay_limit_reached } = useSelector(state => state.cart.payment_failure_response);
  const { showBtnLoader } = useSelector(state => state.loading);
  const { footer_note } = useSelector(state => state.payment?.selected_payment_method);
  const { customer_cnic_validation } = useSelector(state => state.cart?.cnic_requirement);
  const {
    payment_methods,
    selected_payment_method
  } = useSelector(state => state.payment);
  const selectedPaymentMethod = selected_payment_method
  
  const [default_card, setCardAsDefault] = useState(selected_payment_method?.is_default === 1 ? true : false);
  const [errors, setErrors] = useState(error);

  useEffect(() => {
    /*
    *  GET PAYMENT METHODS
    */
   if(!pay_limit_reached){
     dispatch(paymentAction.get_payment_method());
   }

   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reference) {
      handleSubmit()
      submitBtnClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference]);


  useEffect(() => {
    setCardAsDefault(selected_payment_method?.is_default === 1 ? true : false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod.id]);

  useEffect(() => {
    setErrors(error);
    setCardAsDefault(selected_payment_method?.is_default === 1 ? true : false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod?.account_no]);

  const handleValidation = () => {
    const easyPaisaMobileAccountUsecase = CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.EASY_PAISA_ACCOUNT.includes(selectedPaymentMethod?.form_type);
    const bankAccountUsecase = CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.BANK_ACCOUNT_NUMBER.includes(selectedPaymentMethod?.form_type);
    const apgWalletAccountUsecase = selectedPaymentMethod?.form_type === FORM_TYPE.APG.WALLET;
    const bankCodeUsecase = CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.BANK_ACCOUNT_CODE.includes(selectedPaymentMethod?.form_type);
    const cnic = LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value");
    // same as above, but feel free to move this into a class method now.
    let errors = {
      easypaisaMA: null,
      bankAccount: null,
      bankWallet: null,
      qisstPay: null,
      general: null,
    };
    let focusErrorClass = "";
    setErrors(errors)

    //on index -1
    if(selectedPaymentMethod?.index === -1){
      errors.general=translate("PAYMENT.VALIDATION.SELECT_PAYMENT");
      dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.general));
      setErrors(errors)
      return errors;
    }

    //cnic required then test on every case
    if (selectedPaymentMethod?.is_cnic_required) {
      if(HELPER.isEmpty(cnic)){
        focusErrorClass = "cnicField"
        errors.bankAccount = translate("PAYMENT.VALIDATION.CNIC_REQUIRED");
        dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.bankAccount));
      }else if (!HELPER.isEmpty(cnic) &&  cnic.length < customer_cnic_validation) {
        errors.bankAccount = translate("PAYMENT.VALIDATION.CNIC_FIELD", {
          x: customer_cnic_validation,
        });
        focusErrorClass = "cnicField"
        dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.bankAccount));
      }
    } 
    
    if (easyPaisaMobileAccountUsecase && HELPER.isEmpty(selectedPaymentMethod?.account_no)) {
      focusErrorClass = "easypaisaMA"
      errors.easypaisaMA = translate("PAYMENT.VALIDATION.EP_REQUIRED");
      dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.easypaisaMA));
    } else if (easyPaisaMobileAccountUsecase && !HELPER.isEmpty(selectedPaymentMethod?.account_no)) {
      if (selectedPaymentMethod?.account_no.length !== 11 || selectedPaymentMethod?.account_no[1] !== "3") {
        focusErrorClass = "easypaisaMA"
        errors.easypaisaMA = translate("PAYMENT.VALIDATION.INVALID_PHONE");
        dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.easypaisaMA));
      }
    } else if (apgWalletAccountUsecase && HELPER.isEmpty(selectedPaymentMethod?.account_no)) {
      focusErrorClass = "apgWalletAccount"
      errors.bankWallet = translate("PAYMENT.VALIDATION.WALLET_REQUIRED");
      dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.bankWallet));
    } else if (bankAccountUsecase && HELPER.isEmpty(selectedPaymentMethod?.account_no)) {
      focusErrorClass = "apgBankAccount"
      errors.bankAccount = translate("PAYMENT.VALIDATION.BANK_ACCOUNT_REQUIRED");
      dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.bankAccount));
      if (bankCodeUsecase && HELPER.isEmpty(selectedPaymentMethod?.bank_code)) {
        focusErrorClass = "apgBankAccount"
        errors.bankAccount = translate("PAYMENT.VALIDATION.BANK_ACCOUNT_REQUIRED");
        dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.bankAccount));
      } 
    } else if (selectedPaymentMethod?.is_cnic_required) {
      if(HELPER.isEmpty(cnic)){
        focusErrorClass = "cnicField"
        errors.bankAccount = translate("PAYMENT.VALIDATION.CNIC_REQUIRED");
        dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.bankAccount));
      }else if (!HELPER.isEmpty(cnic) &&  cnic.length < customer_cnic_validation) {
        errors.bankAccount = translate("PAYMENT.VALIDATION.CNIC_FIELD", {
          x: customer_cnic_validation,
        });
        focusErrorClass = "cnicField"
        dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.bankAccount));
      }
    }  else if (selectedPaymentMethod?.is_email_required && (selectedPaymentMethod?.is_email_required ? HELPER.isEmpty(email) : false)) {
      focusErrorClass = "email_address"
      errors.qisstPay = translate("PAYMENT.VALIDATION.EMAIL_REQUIRED");
      dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.qisstPay));
    } else if (selectedPaymentMethod?.is_phone_required) {
      focusErrorClass = "email_address"
      if (HELPER.isEmpty(contact_number)) {
        errors.qisstPay = translate("PAYMENT.VALIDATION.PHONE_REQUIRED");
      }
      else if (!HELPER.isEmpty(contact_number)) {
        const phone = validatePhone(selected_country, contact_number);
        if (!contact_number) {
          errors.qisstPay = translate("VALIDATIONS.IS_REQUIRED");
        } if (HELPER.isEmpty(contact_number)) {
          errors.qisstPay = translate("VALIDATIONS.IS_REQUIRED");
        } else if (HELPER.isEmpty(phone?.countryCallingCode)) {
          errors.qisstPay = translate("invalid_phone_number");
        } else if (!HELPER.isEmpty(contact_number) && isValidNumber(phone.number) === false) {
          errors.qisstPay = translate("invalid_phone_number");
        }
      }
      dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.qisstPay));
    }
    else if (HELPER.isEmpty(selectedPaymentMethod?.id)) {
      focusErrorClass = "signviewsmalltext"
      errors.general = translate("PAYMENT.VALIDATION.IS_REQUIRED");
      dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.general));
    }
    else if (CONSTANTS.PAYMENT_METHOD.FORM_TYPE.PAYFAST.BANK_ACCOUNT_NUMBER === selectedPaymentMethod?.form_type
      && !selectedPaymentMethod?.bank_code) {
      focusErrorClass = "apgBankAccount"
      errors.bankAccount = translate("PAYMENT.VALIDATION.SELECT_BANK");
      dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(errors.bankAccount));
    }
    if (!HELPER.isEmpty(focusErrorClass)) {
      HELPER.scrollTo(focusErrorClass, -200)
      // scroller.scrollTo(focusErrorClass, { offset: -200, smooth: true });
      setTimeout(() => {
        dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD(null));
      }, 10000);
    }
    setErrors(errors)
    return errors;
  };

  const handleSubmit = () => {
    // e.preventDefault();
    let validationCheck = handleValidation();
    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.PAYMENT,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.SUBMIT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.SUBMIT.LABEL,
        properties: {}
      }
    );
    if (HELPER.isEmpty(validationCheck.general)
      && HELPER.isEmpty(validationCheck.easypaisaMA)
      && HELPER.isEmpty(validationCheck.bankAccount)
      && HELPER.isEmpty(validationCheck.bankWallet)
      && HELPER.isEmpty(validationCheck.qisstPay)
      && !showBtnLoader) {
      const easyPaisaMobileAccountUsecase = CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.EASY_PAISA_ACCOUNT.includes(selectedPaymentMethod?.form_type)
      const bankAccountUsecase = CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.BANK_ACCOUNT_NUMBER.includes(selectedPaymentMethod?.form_type);
      const bankCodeUsecase = CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.BANK_ACCOUNT_CODE.includes(selectedPaymentMethod?.form_type);
      const apgWalletAccountUsecase = selectedPaymentMethod?.form_type === FORM_TYPE.APG.WALLET;
      const data = {
        payment_method_id: parseInt(selectedPaymentMethod?.id),
        token_id: parseInt(selectedPaymentMethod?.token_id),
        is_default: selectedPaymentMethod?.is_default,
        mobile_account_number: easyPaisaMobileAccountUsecase ? selectedPaymentMethod?.account_no : null,
        wallet_number: apgWalletAccountUsecase ? selectedPaymentMethod?.account_no : null,
        bank_account_number: bankAccountUsecase ? selectedPaymentMethod?.account_no : null,
        cnic: selectedPaymentMethod?.is_cnic_required ? LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value") : null,
        country_code: selectedPaymentMethod?.is_phone_required ? selected_country?.dialCode : "",
        phone_number: selectedPaymentMethod?.is_phone_required ? (contact_number.substr(0, 1) === "0" ? contact_number.substr(1) : contact_number) : "",
        email: selectedPaymentMethod?.is_email_required ? email : "",
        bank_code: bankCodeUsecase ? selectedPaymentMethod?.bank_code : ""
      };
      if (selectedPaymentMethod?.is_phone_required) {
        dispatch(customerAction.phone_number_changed());
      }
      dispatch(paymentAction.post_payment_method(data));
    }
  };
  /*
  const addNewPaymentMethod = (payment_method) => {
      selectedPaymentIndex(add_new_is_checked_identifier);
  };
  */

  //componentWillRecieveProp
  useEffect(() => {
    if (!HELPER.isEmpty(selectedPaymentMethod) && payment_methods?.length > 0) {

     // on changing payment method from cockpit update cc_gateway
      if(selectedPaymentMethod?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.API_BASED){
          let _cc_gateway= payment_methods.find(e => e?.id === selectedPaymentMethod?.id)?.cc_gateway
          if(_cc_gateway && _cc_gateway !== selectedPaymentMethod?.cc_gateway){
            dispatch(paymentAction.update_selected_payment_index({...selected_payment_method, cc_gateway: _cc_gateway}))
          }
       }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod]);

  const deletePaymentMethod = (payment) => {
    const modal = {
      title: translate("delete_confirmation_text"),
      content: "",
      cancelBtn: translate("cancel_btn"),
      successBtn: translate("yes_btn"),
      cancelBtnId: "cancel",
      successBtnId: "delete-payment-method",
      disableBackdropClick: true,
    };
    const onSuccess = () => {
      const requestData = {
        instrument_id: payment.token_id,
        payment_method_id: payment.id,
      };
      dispatch(paymentAction.delete_saved_card(requestData));
    };
    dispatch(paymentAction.delete_payment_popup(modal, onSuccess));
  };

  const handleCheckboxChange = () => {
    let is_default = default_card ? 1 : 0;
    dispatch(paymentAction?.PAYMENT_METHOD_SAVE_FOR_NEXT_TIME(!is_default))
    setCardAsDefault(!is_default);
  };

  const [ExpandedItem ,setExpandedItem]=useState(null)
  const [defaultActive ,setdefaultActive]=useState(0)

  const setSelectedPaymentIndex=async (obj, _isCreditCard=false)=>{
    !_isCreditCard && setdefaultActive(`form-${obj?.index}`)
    dispatch(paymentAction.update_selected_payment_index(obj))
    // _isCreditCard && setcallhandleSubmit(true)
  }

  const AccordionPayments = () =>{
   return payment_methods.map((payment_method) => {
      const is_checked_identifier = "form-" + payment_method?.index;
      let _expanded= (selected_payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.EASY_PAISA_ACCOUNT) ?
        selected_payment_method?.instructions === payment_method?.instructions
         : selected_payment_method?.form_type === payment_method?.form_type
      let _isCreditCard=payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.API_BASED
      
      
      const _FormCard=(payment_method, checked_identifier, add_new=false)=>(
        <div
          key={"accordian-" +  payment_method?.index}
          id={"payment-method-" + payment_method?.id}
        >
          <FormCard
            key={"FormCard-" + payment_method?.index}
            index={payment_method?.index}
            is_checked_identifier={checked_identifier}
            payment_method={payment_method}
            deletePaymentMethod={deletePaymentMethod}
            errors={errors}
            add_new={add_new}
              />
        </div>
      )

      const changeParentAccordion = ({ payment_method }) =>{
        if(payment_method?.form_type !== selected_payment_method?.form_type){
          dispatch(paymentAction.update_selected_payment_index({
            form_type: payment_method?.form_type,
            index: -1,
            instructions:payment_method?.instructions,
            id:payment_method?.id
          }))
        }
      }
      
      return (<>
          {payment_method?.instruments?.list?.length === 0 ?
            <>
              {_FormCard(payment_method, is_checked_identifier)}
            </>

          :
          <>
           <Card className={`accordion-card ${ExpandedItem !== "form-" + payment_method?.index && _expanded ? "activeShow" : ""}`}>
            <Accordion.Toggle  as={Card.Header}
              eventKey={"form-" + payment_method?.index}
              component={"div"}
              className={`accordion-container-parent card-header
              ${ExpandedItem === "form-" + payment_method?.index ? "no-background" : ""} 
              ${payment_method?.payment_protection_enabled ? "protection-enabled" : ""}`}
              onClick={()=> changeParentAccordion({ payment_method })}
             >
                 <div className="media paymentMethodHeader">
                    <div className="paymentMethodHeaderBody">
                      <div className={`image ${!HELPER.isEmpty(payment_method.icon) ? "codImage" : ""}`}>
                        <img src={payment_method.icon} alt={payment_method?.index} />
                      </div>
                      <div className="information">
                        <h5 className={`mt-0 overflowText`}>
                          {payment_method?.name}
                          </h5>
                      </div>
                      <div className="icon trashIcon"></div>
                    </div>
                  </div>
                  {parseInt(payment_method?.payment_protection_enabled) === 1 ? <PAYMENT_PROTECTION /> : ""}
            </Accordion.Toggle>
            </Card>
            <Accordion.Collapse  eventKey={"form-" + payment_method?.index}>
              <Accordion activeKey={defaultActive}   onSelect={(e) => setdefaultActive(e)}>
                {payment_method.instruments?.list.map((payment_method_list) => {
                    const _is_checked_identifier = "form-" + payment_method_list?.index;
                    return <>
                      {_FormCard(payment_method_list, _is_checked_identifier)}
                    </>
                  })}

              {selected_payment_method?.index === payment_method.index ?
                <>
                 {_FormCard(payment_method?.instruments?.add_new, is_checked_identifier, true)}
                </>
                :
              <Card key={"accordian-" + payment_method?.index} onClick={()=>  setSelectedPaymentIndex(payment_method?.instruments?.add_new, _isCreditCard)}>
                <div className="add-new-card" >
                  <div></div>
                  <div >{translate(_isCreditCard ? "PAYMENT_METHODS.ADD_CARD" : "PAYMENT_METHODS.ADD_NEW")}</div>
                  <div className="d-flex"> <i className="icomoon-icon-add"/></div>
                </div>
              </Card>
              }
              </Accordion>
            </Accordion.Collapse> 
          </>
       }
        </>)
  })
  }

  return (
    <>
      <div className={`customRadio  ${HELPER.isEmpty(footer_note) ? "" : "mb-5"}`} ref={ref}>
        <Accordion defaultActiveKey={0} className="paymentMethod"
          onSelect={(e) => setExpandedItem(e)}>
          {payment_methods?.length > 0 && AccordionPayments()}

          <div>
            <Card className="defaultCard">
            {selected_payment_method?.can_default ? <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.MuiSvgIcon-root': {
                          fontSize: 70,
                          borderRadius: 50
                      }
                  }}
                  color="primary"
                  name="saveCard"
                  className="saveDafaults"
                  value={Number(default_card)}
                  onChange={handleCheckboxChange}
                  checked={Number(default_card) === 1 ? true : false}
                />
              }
              label={translate("default_placeholder")}
            /> : ""}
            </Card>
          </div>
        </Accordion>
      </div>
      {<Modal />}
    </>
  );
});

export default PaymentMethods;
