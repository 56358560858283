// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import {
  CONSTANTS,
  HELPER
} from "../../../../utils";

function EasyPaisaReceipt({ transaction_data, saveScreenshotTitle }) {
  const translate = useTranslate();
  const { locale } = useSelector(state => state.Intl);
  const { cart_details, order_id } = useSelector(state => state.cart);
  const { merchant_name } = useSelector(state => state.configuration.config);
  const { currency_code, total_amount } = cart_details.summary;

  const transaction_id = transaction_data?.payment_token ?? "";
  const payment_gateway_name = transaction_data?.payment_gateway_name ?? "";
  const payment_time = transaction_data?.payment_time ?? "";

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    saveScreenshotTitle(
      merchant_name + " bSecure " + order_id + "-" + transaction_id
    );
    // returned function will be called on component unmount
    return () => {
      document.body.className = "";
    };
  });

  const showText = () => {
    const RECEIPT_TEXT = {
      time: payment_time,
      payment_gateway_name,
    };
    return HELPER.transactionReciptText(
      translate("transation_reciept_text", RECEIPT_TEXT),
      payment_gateway_name,
      payment_time
    );
  };

  return (
    <>
      <div className="transactionSlipDetails">
        <div className="amountPay">
          <h4>{translate("transation_reciept_heading")}</h4>
          <h2>
            {HELPER.showPrice(currency_code, total_amount ?? 0)}
          </h2>
        </div>
        <div className="remainingTime">
          <h4>{translate("transation_reciept_sub_heading")}</h4>
          <h5>{payment_time}</h5>
        </div>
        <div className="transaction-detail">
          <h4>{translate("transation_reciept_id")}</h4>
          <h5>{transaction_id}</h5>
        </div>
        <p>
          {showText()}{" "}
          {locale === CONSTANTS.IS_URDU ? (
            ""
          ) : (
            <strong>{translate("transation_reciept_id")}.</strong>
          )}
        </p>
      </div>
    </>
  );
}

export default EasyPaisaReceipt;
