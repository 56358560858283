import * as React from "react";
import {
  ADDRESS_CONSTANTS,
  ACTION_CONSTANTS,
  ERROR_CONSTANTS
} from "../actionTypes";
import { isIOS } from "react-device-detect";
import { apiService } from "../middlewares/api_service";
import {
  LOCAL_STORAGE_SERVICE,
  HELPER,
  REDIRECTION_RULE,
  CONSTANTS,
  ROUTE_CONSTANTS,
} from "../../utils";
import {
  alertActions,
  errorAction,
  SUCCESS_ALERT_ACTION,
  metaAction,
  LOG_ORDER_EXPIRED,
} from "./index";
import IosLocationUpdate from "../../components/IosLocationUpdate";
import { store } from "../../store";
import { mapActions } from "./mapActions";

export const addressAction = {
  add_new_customer_address,
  add_customer_address,
  get_all_addresses,
  update_customer_address,
  update_order_address,
  get_location_detail,
  delete_selected_address,
  delete_address_popup,
  set_location_details,
  empty_location_details,
  disable_location_permission_popup,
  set_selected_address_index
};

function set_selected_address_index(index) {
  return (dispatch) => {
    dispatch(request(index));
  };

  function request(selected_index) {
    return { type: ADDRESS_CONSTANTS.SET_SELECTED_ADDRESS_INDEX, selected_index };
  }
}


function get_all_addresses() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getAddresses()
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseData = response?.data?.body;
          // show error msg if received error and address count not equal to zero
          if (!HELPER.isEmpty(responseData?.error) && !!responseData?.address_count) {
            dispatch(alertActions.error(responseData?.error));
          }
          dispatch(success(responseData));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            dispatch(failure(error_message?.message));
            dispatch(alertActions.error(error_message?.message, true, CONSTANTS.ERROR_TYPE.ALERT, false));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: ADDRESS_CONSTANTS.GET_CUSTOMER_ADDRESS_LIST_REQUEST };
  }
  function success(response) {
    return {
      type: ADDRESS_CONSTANTS.GET_CUSTOMER_ADDRESS_LIST_SUCCESS,
      response,
    };
  }
  function failure() {
    return { type: ADDRESS_CONSTANTS.GET_CUSTOMER_ADDRESS_LIST_FAILURE };
  }
}

function add_new_customer_address(data, dropdown, history, redirect = true) {
  const state = store.getState();
  return (dispatch) => {
    dispatch(request());
    apiService
      .addAddress(data)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          // Response
          let responseBody = response?.data?.body;
          dispatch(success(responseBody, dropdown, data));
          //Redirection cases for address screens
          if (redirect) {
            REDIRECTION_RULE.POST_ADDRESS_REDIRECTION_RULE(state.redirect);
          } else {
            history.push({
              pathname: ROUTE_CONSTANTS.ADDRESS_LIST,
            });
          }
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            dispatch(failure(error_message?.message));
            dispatch(alertActions.error(error_message?.message));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: ADDRESS_CONSTANTS.ADD_NEW_ADDRESS_REQUEST };
  }
  function success(responseBody, dropdown, data) {
    return {
      type: ADDRESS_CONSTANTS.ADD_NEW_ADDRESS_SUCCESS,
      address: responseBody,
      dropdown: dropdown,
      profile: data,
    };
  }
  function failure() {
    return { type: ADDRESS_CONSTANTS.ADD_NEW_ADDRESS_FAILURE };
  }
}

function add_customer_address(data) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .addAddress(data)
      .then((response) => {
        // check response status
        const responseStatus = response?.data?.status;
        // Check if respone sttaus is success
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseBody = response?.data?.body;
          dispatch(success(responseBody));
          const successMessage = SUCCESS_ALERT_ACTION(response);
          dispatch(alertActions.success(successMessage));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            dispatch(failure(error_message?.message));
            dispatch(alertActions.error(error_message?.message));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: ADDRESS_CONSTANTS.ADD_CUSTOMER_ADDRESS_REQUEST };
  }
  function success(data) {
    return {
      type: ADDRESS_CONSTANTS.ADD_CUSTOMER_ADDRESS_SUCCESS,
      address: data,
    };
  }
  function failure() {
    return { type: ADDRESS_CONSTANTS.ADD_CUSTOMER_ADDRESS_FAILURE };
  }
}

function update_customer_address(
  data,
  history,
  redirect = false,
) {
  const state = store.getState();
  return (dispatch) => {
    dispatch(request());
    apiService
      .updateAddress(data)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          // Add screen
          LOCAL_STORAGE_SERVICE._nextScreen();
          const responseBody = response?.data?.body;
          dispatch(success(responseBody));
          // Redirection Usecases
          if (!redirect) {
            history.push({
              pathname: ROUTE_CONSTANTS.ADDRESS_LIST,
            });
            const successMessage = SUCCESS_ALERT_ACTION(response);
            dispatch(alertActions.success(successMessage));
          } else {
            REDIRECTION_RULE.POST_ADDRESS_REDIRECTION_RULE(state.redirect);
          }
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            dispatch(failure(error_message?.message));
            dispatch(alertActions.error(error_message?.message));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: ADDRESS_CONSTANTS.UPDATE_CUSTOMER_ADDRESS_REQUEST };
  }
  function success(data) {
    return { type: ADDRESS_CONSTANTS.UPDATE_CUSTOMER_ADDRESS_SUCCESS, data };
  }
  function failure() {
    return { type: ADDRESS_CONSTANTS.UPDATE_CUSTOMER_ADDRESS_FAILURE };
  }
}

function update_order_address(data) {
  const state = store.getState();
  return (dispatch) => {
    dispatch(request());
    apiService
      .updateOrderAddress(data)
      .then((response) => {
        const responseStatus = response?.data?.status;
        const responseData = response?.data?.body;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          // If customer is re-visited and already verified
          dispatch(success(responseData));
          REDIRECTION_RULE.POST_ADDRESS_REDIRECTION_RULE({...state.redirect, ...responseData?.app_setup});
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            dispatch(failure(error_message?.message));
            dispatch(alertActions.error(error_message?.message));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: ADDRESS_CONSTANTS.UPDATE_ORDER_ADDRESS_REQUEST };
  }
  function success(response) {
    return { type: ADDRESS_CONSTANTS.UPDATE_ORDER_ADDRESS_SUCCESS, response };
  }
  function failure() {
    return { type: ADDRESS_CONSTANTS.UPDATE_ORDER_ADDRESS_FAILURE };
  }
}

function get_location_detail(requestData, prevCoordinatesData, isUpdateAddress = false) {
  return (dispatch, getState) => {
    const state = getState();
    const { searchAddress } = state.map;
    dispatch(alertActions.clear());
    dispatch(request());
    apiService
      .getLocationDetails(requestData)
      .then(async(response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          let responseData = response?.data?.body;
          // var call_meta_deta = false;
          if (responseData.sync_metadata === 1) {
            // call_meta_deta = true;
            dispatch(metaAction.META_DATA());
          }
          if(!isUpdateAddress){
            await dispatch(mapActions.set_position(requestData?.latitude, requestData?.longitude))
          }else{
            // await dispatch(mapActions.set_position(requestData?.latitude, requestData?.longitude))
            await dispatch(mapActions.update_position(requestData?.latitude, requestData?.longitude));
          }
          if(!responseData.address){
            responseData = {...responseData}
          }
          dispatch(success(responseData));
          if(responseData?.error_msg){
            const {area_id, city_id, state_id}= responseData || {}
            if(area_id !== -1 && city_id !== -1 && state_id !== -1){
              dispatch(alertActions.error(responseData?.error_msg));
            }
          }
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            dispatch(failure({...prevCoordinatesData, address: searchAddress}));
            // prevCoordinatesData.latitude && window.location.reload();
            dispatch(alertActions.error(error_message?.message));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: ADDRESS_CONSTANTS.GET_LOCATION_DETAILS_REQUEST };
  }
  function success(data) {
    return {
      type: ADDRESS_CONSTANTS.GET_LOCATION_DETAILS_SUCCESS,
      data,
    };
  }
  function failure(data) {
    return { type: ADDRESS_CONSTANTS.GET_LOCATION_DETAILS_FAILURE, data };
  }
}

function delete_selected_address(requestData) {
  return (dispatch) => {
    dispatch(request(requestData));
    apiService
      .deleteAddress(requestData)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseData = response?.data?.body;
          dispatch(success(responseData));
          const successMessage = SUCCESS_ALERT_ACTION(response);
          dispatch(alertActions.success(successMessage));
          // Close modal
          dispatch(close());
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            dispatch(failure(error_message?.message));
            dispatch(alertActions.error(error_message?.message));
          }
        }
        // Close modal
        dispatch(close());
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: ADDRESS_CONSTANTS.DELETE_CUSTOMER_ADDRESS_REQUEST, deleted_index: requestData?.address_id };
  }
  function success(responseData) {
    return {
      type: ADDRESS_CONSTANTS.DELETE_CUSTOMER_ADDRESS_SUCCESS,
      address: responseData,
    };
  }
  function failure() {
    return { type: ADDRESS_CONSTANTS.DELETE_CUSTOMER_ADDRESS_FAILURE };
  }
  function close() {
    return { type: ACTION_CONSTANTS.MODAL_CLOSE };
  }
}

function delete_address_popup(modal, onSuccess) {
  return (dispatch) => {
    const onClose = () => {
      dispatch(close());
    };
    const onCancel = () => {
      dispatch(close());
    };
    dispatch(open(modal, onClose, onSuccess, onCancel));
  };

  function close() {
    return { type: ACTION_CONSTANTS.MODAL_CLOSE };
  }
  function open(modal, onClose, onSuccess, onCancel) {
    return {
      type: ACTION_CONSTANTS.MODAL_OPEN,
      modal,
      onClose,
      onSuccess,
      onCancel,
    };
  }
}

function set_location_details(data) {
  return (dispatch) => {
    dispatch(request(data));
  };

  function request(response) {
    return { type: ADDRESS_CONSTANTS.SET_LOCATION_DETAILS, response };
  }
}

function empty_location_details(clearError=true) {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: ADDRESS_CONSTANTS.EMPTY_LOCATION_DETAILS , clearError};
  }
}

function disable_location_permission_popup(title) {
  return (dispatch) => {
    const onClose = () => {
      dispatch(close());
    };
    const onSuccess = () => {
      dispatch(close());
    };
    const onCancel = () => {
      dispatch(close());
    };
    let modal;
    if (isIOS) {
      modal = {
        title,
        content: <IosLocationUpdate />,
        cancelBtn: "",
        successBtn: "",
        disableBackdropClick: false,
      };
    } else {
      modal = {
        title,
        content: "",
        cancelBtn: "",
        successBtn: "",
        disableBackdropClick: false,
        cancelBtnId: "",
        successBtnId: "",
      };
    }
    dispatch(open(modal, onClose, onSuccess, onCancel));
  };

  function close() {
    return { type: ACTION_CONSTANTS.MODAL_CLOSE };
  }
  function open(modal, onClose, onSuccess, onCancel) {
    return {
      type: ACTION_CONSTANTS.MODAL_OPEN,
      modal,
      onClose,
      onSuccess,
      onCancel,
    };
  }
}
