/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import ReturntoMerchant from "../../../../components/ReturntoMerchant";

import {
    HELPER
} from "../../../../utils";
import {
    logoutAction
} from "../../../../store/actions";

function ExpiredOrderDialog() {
    let dispatch = useDispatch();
    const { store_url } = useSelector(state => state.configuration.config);

    const translate = useTranslate();

    const redirectToStore = () => {
        dispatch(logoutAction.CLEAR_REDUX_STORE())
        HELPER.redirectToPathAndClearLocalStorage(store_url, store_url);
    };

    return (
        <>
            <div className="dialogPopupInv expiredDialog">
                <DialogContent className="expiredDialogContent">
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        component={"div"}
                    >
                        <div className="expired">
                            <div className="iconImage">
                                <i className={"iconW new-icons-icon-expired"}></i>
                                {/* <img src={IMAGE_SRC.ICONS.EXPIRED} alt="map-search-icon" /> */}
                            </div>
                            <div className="message">
                                <h3>{translate("DIALOG.MODAL.ORDER_EXPIRED.HEADING")}</h3>
                                {/* <p>{translate("DIALOG.MODAL.ORDER_EXPIRED.PLACEHOLDER")}</p> */}
                                {
                                    !HELPER.isEmpty(store_url) ? <div className="returnBtn" onClick={redirectToStore}>
                                        <button className="blueSubmitBtn"><ReturntoMerchant /></button>
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </div>
        </>
    );
}

export default ExpiredOrderDialog;
