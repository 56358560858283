import { META_CONSTANTS, CONFIG_CONSTANTS } from "../actionTypes";

const initState = {
  metaData: [],
  defaultCountry: {
    value: null,
    label: null,
  },

  defaultPhoneCode: [],
  phoneCodeDropdownList: [],
  onlyCountriesDropdownList: [],
};

const metaDataReducer = (state = initState, action) => {
  switch (action.type) {
    case CONFIG_CONSTANTS.INITIATE_ORDER_REQUEST:
    case CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_REQUEST:
      return initState;
    case META_CONSTANTS.META_REQUEST:
      return state;

    case META_CONSTANTS.META_SUCCESS:
      return {
        ...state,
        metaData: action.metaData.countryObject,
        defaultCountry: action.metaData.default_country,
      };
    case META_CONSTANTS.META_FAILURE:
      return state;
    case META_CONSTANTS.COUNTRY_LIST_REQUEST:
      return state;
    case META_CONSTANTS.COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        defaultPhoneCode: action.response.default,
        phoneCodeDropdownList: action.response.dropdown,
        onlyCountriesDropdownList: action.response.countriesList,
      };
    case META_CONSTANTS.COUNTRY_LIST_FAILURE:
      return state;
    default:
      return state;
  }
};
export default metaDataReducer;
