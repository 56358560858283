import {
    HELPER,
} from '../../utils';


const ADJUST_EVENT_TRACKERS = {
    'NET_ORDER': process.env.REACT_APP_ADJUST_EVENT_TRACKER_NET_ORDER,
    'GROSS_ORDER': process.env.REACT_APP_ADJUST_EVENT_TRACKER_GROSS_ORDER,
    'DISCOUNT_AMOUNT': process.env.REACT_APP_ADJUST_EVENT_TRACKER_DISCOUNT_AMOUNT,
    'NMV': process.env.REACT_APP_ADJUST_EVENT_TRACKER_NMV,
    'GMV': process.env.REACT_APP_ADJUST_EVENT_TRACKER_GMV,
}

const segmentEnabled = parseInt(process.env.REACT_APP_SEGMENT_ENABLED) === 1

const adjustOrderSyncing = (adjustSyncResp) => {
    try {
        const { order_id, analytic_currency_code, total_amount, discount_amount } = adjustSyncResp
        if (segmentEnabled) {
            if (total_amount > 0) {
                const revenueObj = {
                    amount: total_amount,
                    currency_code: analytic_currency_code,
                    event_token: ADJUST_EVENT_TRACKERS.NET_ORDER
                };
                calculateGrossOrderRevenue(revenueObj, order_id);
            }
            if (discount_amount > 0) {
                const discountRevenueObj = {
                    amount: discount_amount,
                    currency_code: analytic_currency_code,
                    event_token: ADJUST_EVENT_TRACKERS.DISCOUNT_AMOUNT
                };
                calculateGrossOrderDiscount(discountRevenueObj, order_id);
            }
        }
    } catch (e) {
        return;
    }
}

const calculateGrossOrderDiscount = (revenueObj, order_id) => {
    if (HELPER.sendEventToAndroid("adjustCalculateRevenue")) {
        window.Android.adjustCalculateRevenue(revenueObj?.event_token, order_id, revenueObj?.currency_code, revenueObj?.amount);
    }
    if (HELPER.sendEventToiOS("adjustCalculateRevenue")) {
        window.webkit.messageHandlers.adjustCalculateRevenue.postMessage(
            JSON.stringify({ revenueObj: revenueObj, transactionId: order_id })
        );
    }
}

const calculateGrossOrderRevenue = (revenueObj, order_id) => {
    if (HELPER.sendEventToAndroid("adjustCalculateRevenue")) {
        window.Android.adjustCalculateRevenue(revenueObj?.event_token, order_id, revenueObj?.currency_code, revenueObj?.amount);
    }
    if (HELPER.sendEventToiOS("adjustCalculateRevenue")) {
        window.webkit.messageHandlers.adjustCalculateRevenue.postMessage(
            JSON.stringify({ revenueObj: revenueObj, transactionId: order_id })
        );
    }
}


const ADJUST_ANALYTICS_HELPER = {
    adjustOrderSyncing
};

export default ADJUST_ANALYTICS_HELPER;