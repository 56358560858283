// @flow

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";
import {
  alertActions,
  cartAction,
  shippingAction,
} from "../../../store/actions";
import {
  GOOGLE_ANALYTICS_HELPER,
  HELPER,
  REDIRECTION_RULE,
  ANALYTICS_HELPER,
  LOCAL_STORAGE_SERVICE,
  ROUTE_CONSTANTS,
  history,
  CONSTANTS
} from "../../../utils";
import Alert from "../../../components/Alert";
import LoadingButton from "../../../components/LoadingButton";
import BackButton from "../../../components/BackButton";

import ShippingMethods from "../Methods";

function ShippingForm() {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const { selectedIndex, shipmentMethods, error, editShipment } = useSelector(state => state.shipment);
  const { shipmentBtnLoader } = useSelector(state => state.loading);
  const { placement_type } = useSelector(state => state.cart.cart_details.summary);
  const isUniversal = CONSTANTS?.ORDER_PLACEMENT_TYPE.UNIVERSAL === placement_type;


  const backBtn = LOCAL_STORAGE_SERVICE._isFirstScreen();

  //componentDidMount
  useEffect(() => {
    /*
     *  UPDATE CART ITEMS DROPDOWN
     */
    dispatch(cartAction.update_cart_detail());
    /*
     *  UPDATE CHECKOUT STEP - ANALYTICS
    */
    // FIREBASE_ANALYTICS_HELPER.checkoutSteps(FIREBASE_ANALYTICS_HELPER.CHECKOUT.SHIPMENT.TIER);
    ANALYTICS_HELPER._checkoutSteps(4, GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.SHIPMENT);
    /*
     *  REDIRECTION RULES FOR SHIPMENT SCREEN
    */
    REDIRECTION_RULE.PRE_SHIPMENT_REDIRECTION_RULE();
    /*
     *  cleart any alert message if exists
    */
    dispatch(alertActions.clear());
    /*
     *  get all shipment methods
    */
    if (isUniversal && !(HELPER.isEmpty(editShipment?.storeId) || HELPER.isEmpty(editShipment?.merchantId))) {
      dispatch(shippingAction.get_shipping_method({
        store_id: editShipment?.storeId,
        merchant_id: editShipment?.merchantId
      }))
    }else if(!isUniversal){
      dispatch(shippingAction.get_shipping_method())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //componentDidMount
  useEffect(() => {
    if (HELPER.isEmpty(shipmentMethods) && !HELPER.isEmpty(error)) {
      dispatch(alertActions.info(<div className="alertHeading">{error}</div>))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentMethods]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.SHIPMENT,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.SHIPMENT.SUBMIT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.SHIPMENT.SUBMIT.LABEL,
        properties: {
          shipment_method_id: selectedIndex.id
        }
      }
    );

    if (selectedIndex.id === 0 || selectedIndex.id === "0") {
      REDIRECTION_RULE.POST_SHIPMENT_REDIRECTION_RULE();
    } else if (!HELPER.isEmpty(selectedIndex.id) && selectedIndex.id !== 0) {
      const data = {
        shipment_method_id: selectedIndex.id,
        store_id: editShipment?.storeId,
        merchant_id: editShipment?.merchantId
      };
      dispatch(shippingAction.post_shipping_method(data));
    } else {
      dispatch(alertActions.error("Select shipping method first"));
    }
  };

  return (
    <>

      <h1>{translate("SHIPPING.LIST.TITLE")}</h1>
      <p className="signviewsmalltext">{translate("SHIPPING.LIST.PLACEHOLDER")}</p>
      <Alert />
      <form className="myForm">
        <ShippingMethods />
        <div className="optionBtn">
          {!backBtn && !HELPER.isEmpty(selectedIndex) ? (
            <BackButton btnTitle={translate("back_btn")} />
          ) :
            // if no shipmentMethods available or bykea case(shipment_enabled 0) then show back btn which redirect to adress lists
            (HELPER.isEmpty(selectedIndex) &&
              (shipmentMethods?.length === 0 || (shipmentMethods?.length === 1 && !shipmentMethods[0]?.shipment_enabled))) &&
            <BackButton btnTitle={translate("back_btn")}
              handleBack={() => history.push({
                pathname: ROUTE_CONSTANTS.ADDRESS_LIST,
              })}
            />
          }
          <button
            type="button"
            onClick={handleSubmit}
            id="shipment-method-submit"
            className="btn btn-primary width150"
            disabled={HELPER.isEmpty(selectedIndex) || shipmentBtnLoader ? true : false || shipmentMethods?.length === 0}
          >
            <LoadingButton
              loadingState={shipmentBtnLoader}
              preloadingText={translate("next_btn")}
              loadingText={translate("next_btn")}
            />
          </button>
        </div>
      </form>
    </>
  );
}

export default ShippingForm;
