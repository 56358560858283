// @flow
//eslint-disable-next-line

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { Accordion, Card } from "react-bootstrap";
import Alert from "../../../components/Alert";
import Modal from "../../../components/Modal";

import {
  addressAction,
  alertActions,
  metaAction,
  mapActions
} from "../../../store/actions";
import {
  ROUTE_CONSTANTS,
  HELPER,
  ADDRESS_HELPER,
  LOCAL_STORAGE_SERVICE,
  ANALYTICS_HELPER,
  GOOGLE_ANALYTICS_HELPER,
  history,
} from "../../../utils";

let timeoutTime;
let defaultErrors = {
  type: null,
  message: null,
};

function AddressComponent() {
  // const {
  //   // addressUpdated,
  // } = props;
  let dispatch = useDispatch();

  const translate = useTranslate();
  const { is_pin_location_mandatory } = useSelector(state => state.configuration.config);
  const { address_count, delivery_address_id } = useSelector(state => state.redirect);
  const { showPageLoader } = useSelector(state => state.loading);
  const { metaData } = useSelector(state => state.meta);
  const {
    addressBook,
    selectedAddress,
    // addressUpdated,
    deleted_address_index,
    selected_address_index
  } = useSelector(state => state.address);

  const backBtn = LOCAL_STORAGE_SERVICE._isFirstScreen();
  const [addressCount, setAddressCount] = useState(address_count);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedDefault, setSelectedDefault] = useState(null);
  const [errors, setErrors] = useState(defaultErrors);

  const disableBackBtn = parseInt(addressCount) === 0 ||
    selected_address_index === deleted_address_index ||
    HELPER.isEmpty(selected_address_index) ||
    showPageLoader;

  //componentDidMount
  useEffect(() => {

    // FIREBASE_ANALYTICS_HELPER.checkoutSteps(FIREBASE_ANALYTICS_HELPER.CHECKOUT.ADDRESS.TIER);
    ANALYTICS_HELPER._checkoutSteps(3, GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS);
    // REDIRECTION_RULE.PRE_ADDRESS_REDIRECTION_RULE(history);
    dispatch(alertActions.clear())
    // if (HELPER.isEmpty(addressBook) || addressUpdated) {
    dispatch(addressAction.get_all_addresses())
    // }
    if (!HELPER.isEmpty(delivery_address_id)) {
      setSelectedIndex(delivery_address_id);
      dispatch(addressAction.set_selected_address_index(delivery_address_id))
    }
    // returned function will be called on component unmount
    return () => {
      clearTimeout(timeoutTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAddressCount(address_count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address_count]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationCheck = handleValidation();
    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.SUBMIT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.SUBMIT.LABEL,
        properties: {}
      }
    );
    if (validationCheck.selectedIndex === "" && validationCheck.latitude === "" && validationCheck.longitude === "") {
      const data = {
        customer_address_id:
          selectedIndex === -1 ? selectedAddress.id : selectedIndex,
      };
      dispatch(addressAction.update_order_address(data))
    }
  };

  const handleValidation = () => {
    let record = "";
    if (selectedIndex !== -1) {
      let index = addressBook.findIndex(x => x.address_id === selectedIndex);
      record = addressBook[index]
    }

    // same as above, but feel free to move this into a class method now.
    let errors = {
      selectedIndex: "",
      latitude: "",
      longitude: ""
    };
    if (!selectedIndex) {
      /*
        SCROLL SCREEN TO TOP ON EACH RENDER
      */
      errors.selectedIndex = translate("address_selection_required");
      dispatch(alertActions.error(errors.selectedIndex))
    } else if (selectedIndex === -1 && HELPER.isEmpty(selectedAddress)) {
      /*
        SCROLL SCREEN TO TOP ON EACH RENDER
      */
      errors.selectedIndex = translate("address_save_required");
      dispatch(alertActions.error(errors.selectedIndex))
    } else if (HELPER.isEmpty(record?.lat) && !!is_pin_location_mandatory) {
      /*
        SCROLL SCREEN TO TOP ON EACH RENDER
      */
      errors.latitude = translate("mandatory_pin_placeholder");
      dispatch(alertActions.error(errors.latitude))
    } else if (HELPER.isEmpty(record?.long) && !!is_pin_location_mandatory) {
      /*
        SCROLL SCREEN TO TOP ON EACH RENDER
      */
      errors.longitude = translate("mandatory_pin_placeholder");
      dispatch(alertActions.error(errors.longitude))
    }
    setErrors(errors);
    timeoutTime = setTimeout(() => {
      setErrors(defaultErrors);
    }, 5000);
    return errors;
  };

  const addClassToCard = (element) => {
    if (element?.shipment_available === 0) return
    setSelectedIndex(element.address_id);

    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.SELECTION.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.SELECTION.LABEL,
        properties: {

        }
      }
    );
    dispatch(addressAction.set_selected_address_index(element.address_id))
    setSelectedDefault(null);
  };

  const onEdit = (address) => {
    //on edit ask for current permission on use current location
    !is_pin_location_mandatory && dispatch(mapActions.GET_LOCATION_PIN(true))
    //
    dispatch(addressAction.empty_location_details())
    dispatch(mapActions.INITIATE_MAP())
    //Add flag identifier for address recieved for edit purpose
    address.edit_address = 1;
    if (HELPER.isEmpty(metaData)) {
      dispatch(metaAction.COUNTRY_DROPDOWN(address))
    }
    else if (!HELPER.isEmpty(metaData)) {
      let countryDropdown = {
        countries: metaData
      }
      const responseBody = ADDRESS_HELPER.formatExistingAddressDropdown(
        countryDropdown,
        address
      );
      dispatch(metaAction.format_country_dropdown(responseBody))
    }
    if (!HELPER.isEmpty(address?.lat) && !HELPER.isEmpty(address?.long)) {
      dispatch(mapActions.update_position(parseFloat(address?.lat), parseFloat(address?.long)))
    }
    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.EDIT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.EDIT.LABEL,
        properties: {}
      }
    );
    dispatch(mapActions.SET_FULL_SCREEN_MODE(!!is_pin_location_mandatory))
    //removed timeout check
    history.push({
      pathname: ROUTE_CONSTANTS.UPDATE_ADDRESS,
      state: { address },
    });
  };

  const deleteAddress = (address) => {
    if(Object.keys(addressBook).length > 1){
      const modal = {
        title: translate("delete_confirmation_text"),
        content: "",
        cancelBtn: translate("cancel_btn"),
        successBtn: translate("yes_btn"),
        cancelBtnId: "cancel",
        successBtnId: "delete-address",
        disableBackdropClick: true,
      };
      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.DELETE.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.DELETE.LABEL,
          properties: {}
        }
      );
      const onSuccess = () => {
        if (selectedIndex === address.address_id) {
          setSelectedIndex(null);
          dispatch(addressAction.set_selected_address_index(address.address_id))
        }
        const requestData = {
          address_id: address.address_id,
        };
        dispatch(addressAction.delete_selected_address(requestData))
      };
      dispatch(addressAction.delete_address_popup(modal, onSuccess))
    }
  };

  const moveBackwards = () => {
    dispatch(addressAction.empty_location_details())
    history.push({
      pathname: ROUTE_CONSTANTS.CART,
    });
  };

  const onAddAddress = () => {
    if (HELPER.isEmpty(metaData)) {
      dispatch(metaAction.META_DATA())
    }

    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.ADD.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.ADDRESS.ADD.LABEL,
        properties: {}
      }
    );
    //Empty location details reducer
    dispatch(addressAction.empty_location_details())
    dispatch(mapActions.SET_FULL_SCREEN_MODE(!!is_pin_location_mandatory))
    history.push({
      pathname: ROUTE_CONSTANTS.UPDATE_ADDRESS,
    });
  };

  return (
    <>
      <Modal />
      <h1>{translate("ADDRESS.EDIT.TITLE")}</h1>
      <p className="signviewsmalltext">
        {translate("ADDRESS.EDIT.PLACEHOLDER2")}
      </p>
      <Alert />
      <div className="customRadio newCustomRadio shipmentRadio">
        <Accordion defaultActiveKey={selectedDefault}>
            {/* ADD NEW ADDRESS */}
             <button
            type="button"
            onClick={() => onAddAddress(-1)}
            className={`btn btn-outline-primary btn-block address-btn`}>
              <span>+ </span>
              {translate("ADDRESS.ADD_NEW")}
            </button>

          {/* EDIT ADDRESS */}
          {addressBook.map((address) => (
            <Card
              key={address?.address_id}
              className={
                parseInt(selectedIndex) === address?.address_id
                  ? `${address?.shipment_available !== 0 ? "activeShow" : "outline-danger"}`
                  : `${address?.shipment_available === 0 ? "outline-danger" : ""} activeButton`
              }
              as={"div"}
            >
              <div
                className={`${address?.shipment_available === 0 ? "card-header-disabled" : ""} card-header card-header-left`}
                onClick={() => addClassToCard(address)}
              >
                <div className="media editMedia">
                  <div
                    className={`icon trashIcon ${Object.keys(addressBook).length <= 1 ? "disabled" : ""}`}
                    onClick={() => deleteAddress(address)}
                  >
                    <div className="icomoon-icon-trash"></div>
                  </div>
                  <div className="media-body address-body">
                    <h5 className="mt-0 overflowText address-detail">{address.address}</h5>
                    <p>{HELPER.addressFormatting(address)}</p>
                    <span>{address?.created_by}</span>
                  </div>
                </div>
              </div>

              {address?.shipment_available === 0 &&
                <button
                  disabled
                  type="button"
                  className="btn btn-primary btn-block zero-padding btn-error shipmentNotAvailable"
                >
                  {translate("ADDRESS.DELIVERY.MODE_NOT_AVAILABLE")}
                </button>
              }
              {address?.shipment_available !== 0 &&
                <Accordion.Toggle className="card-text" as={"div"} eventKey={address?.address_id}>
                  <button
                    type="button"
                    className="btn btn-primary btn-block zero-padding editAdd"
                    onClick={() => onEdit(address)}
                  >
                    {translate("ADDRESS.EDIT.TITLE")}
                  </button>
                </Accordion.Toggle>
              }
            </Card>
          ))}
        </Accordion>
      </div>
      <div className="pb-9"></div>


        <div className={`bottomFixedBar paymentBottomFixed`}>
          <div className="container">
            <div className="row">
              <div className={"paymentBottomOptionBtn "}>
                {!backBtn && (
                    <button
                    type="button"
                    className={"btn btn-outline-primary width150"}
                    onClick={moveBackwards}
                    disabled={disableBackBtn ? true : false}
                  >
                    {translate("back_btn")}
                  </button>
                )}
                <button
                    type="button"
                    className={
                      `btn btn-primary width150 ${!backBtn ? "btn-twice" : ""} ` +
                      (errors.message !== null ||
                        showPageLoader
                        ? "disabled"
                        : "")
                    }
                    onClick={handleSubmit}
                    id="address-submit"
                    disabled={disableBackBtn ? true : false}>
                        {translate("next_btn")}
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default AddressComponent;
