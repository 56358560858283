import {
  CUSTOMER_CONSTANTS,
  ERROR_CONSTANTS
} from "../actionTypes";
import { apiService } from "../middlewares/api_service";
import {
  alertActions,
  errorAction,
  LOG_ORDER_EXPIRED
} from "../../store/actions";
import {
  CONSTANTS,
  HELPER,
} from "../../utils";

export const customerAction = {
  get_profile,
  phone_number_changed,
  update_profile,
};

function phone_number_changed() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: CUSTOMER_CONSTANTS.PHONE_NUMBER_CHANGED };
  }
}

function get_profile() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getCustomerProfile()
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (!HELPER.isEmpty(responseStatus) && responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS) {
          const data = response?.data?.body;
          var phone = data?.phone_number;
          // Parse country code and phone number
          var countryCode = phone.substring(0, 2);
          var networkCode = phone.substring(2, 5);
          var phoneNumber = phone.slice(5);
          var showPhone = phoneNumber.slice(-4);
          var hideNumbers = "";
          for (var i = phoneNumber.length - 4; i > 0; i--) {
            hideNumbers += "*";
          }

          var responseData = {
            country_code: countryCode,
            phone: hideNumbers + showPhone,
            network_code: networkCode,
            name: data?.name,
            email: data?.email,
          };
          dispatch(success(responseData));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response === undefined) {
          dispatch(alertActions.error(error_message?.message));
        } else if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        } else {
          dispatch(failure(error_message?.message));
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: CUSTOMER_CONSTANTS.PROFILE_REQUEST };
  }
  function success(data) {
    return {
      type: CUSTOMER_CONSTANTS.PROFILE_SUCCESS,
      data,
    };
  }
  function failure() {
    return { type: CUSTOMER_CONSTANTS.PROFILE_FAILURE };
  }
}


function update_profile(obj) {
  return async (dispatch) => {
    let _resStatus=""
    dispatch(request());
    await apiService
      .updateCustomerProfile(obj)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (!HELPER.isEmpty(responseStatus) && responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS) {
          var responseData = {
            name: obj.customer_name,
            email: obj.customer_email,
          };
          dispatch(success(responseData));
          _resStatus=responseStatus
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;
        if (error_response === undefined) {
          dispatch(alertActions.error(error_message?.message));
        } else if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        } else {
          dispatch(alertActions.error(error_message?.message));
          dispatch(failure(error_message?.message));
        }
        _resStatus=error?.response?.status
      });
      return _resStatus
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: CUSTOMER_CONSTANTS.UPDATE_PROFILE_REQUEST };
  }
  function success(data) {
    return {
      type: CUSTOMER_CONSTANTS.UPDATE_PROFILE_SUCCESS,
      data,
    };
  }
  function failure() {
    return { type: CUSTOMER_CONSTANTS.UPDATE_PROFILE_FAILURE };
  }
}