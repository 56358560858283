import { ROUTE_CONSTANTS } from "./utils";

import Home from './components/Home'
import Error from './components/Errors'
import Processing from './components/Processing'
import AddNewAddress from './containers/Address/AddNewAddress'
import Cart from './containers/Checkout/Cart'
import EditAddress from './containers/Address/EditAddress'
import EditAddressForm from './containers/Address/EditAddressForm'
import CreditCard from './containers/CreditCard'
import OneTapLogin from './containers/OneTapLogin'
import OTP from './containers/OTP'
import Payment from './containers/Payment'
import VerifyPhone from './containers/VerifyPhone'
import OrderSuccess from './containers/Order/Success'
import OrderFailure from './containers/Order/Failure'
import OutOfRetries from './containers/Order/OutOfRetries'
import Shipping from './containers/Shipping/Form'
import ConfigurationWrapper from './containers/Configuration'
import CheckoutOtp from './containers/Checkout/CheckoutOtp'
import FullScreenMap from './containers/GoogleMap/FullScreen'
import Status from './containers/Status'
import Abandoned from './containers/Abandoned'

const routes = [
  {
    path: ROUTE_CONSTANTS.BASE,
    exact: true,
    // component: Home,
    component: Home,
    protected: false,
    default: false,
    view: "Base",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.INVOICE_CHECKOUT,
    exact: true,
    component: ConfigurationWrapper,
    protected: false,
    default: false,
    view: "Launch",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.PAYMENT_CHECKOUT,
    exact: true,
    component: ConfigurationWrapper,
    protected: false,
    default: false,
    view: "Launch",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.CHECKOUT,
    exact: true,
    component: ConfigurationWrapper,
    protected: false,
    default: false,
    view: "Launch",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.UNIVERSAL_CHECKOUT,
    exact: true,
    component: ConfigurationWrapper,
    protected: false,
    default: false,
    view: "Launch",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.PAYMENT_INFO,
    exact: true,
    component: Processing,
    protected: true,
    default: false,
    view: "Payment Info",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.PROCESSING,
    exact: true,
    component: Processing,
    protected: true,
    default: false,
    view: "Processing",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.BUY_NOW,
    exact: true,
    component: ConfigurationWrapper,
    protected: false,
    default: false,
    view: "Launch Market Order",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.PAYMENT_PROCESSING,
    exact: true,
    component: Processing,
    protected: true,
    default: false,
    view: "EP Processing",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.ONE_TAP_LOGIN,
    exact: true,
    component: OneTapLogin,
    protected: false,
    default: false,
    view: "One Tap Login",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.ONE_TAP_LOGIN_WITH_REF,
    exact: false,
    component: OneTapLogin,
    protected: false,
    default: false,
    view: "One Tap Login",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.PHONE_SCREEN,
    exact: true,
    component: VerifyPhone,
    protected: true,
    default: false,
    view: "Login",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.OTP_SCREEN,
    exact: true,
    component: OTP,
    protected: true,
    default: false,
    view: "OTP",
    allowBack: true
  },
  {
    path: ROUTE_CONSTANTS.RETRY_OTP,
    exact: true,
    component: OTP,
    protected: true,
    default: false,
    view: "OTP",
    allowBack: true
  },
  // {
  //   path: ROUTE_CONSTANTS.CHANGE_NETWORK,
  //   exact: true,
  //   component: NetworkChange,
  //   protected: false,
  //   default: false,
  // view: "Index Screen",
  // },
  {
    path: ROUTE_CONSTANTS.MAP_FULL_SCREEN,
    exact: true,
    component: FullScreenMap,
    protected: true,
    default: false,
    view: "Map Full Screen",
    allowBack: true
  },
  {
    path: ROUTE_CONSTANTS.ADDRESS_LIST,
    exact: true,
    component: EditAddress,
    protected: true,
    default: false,
    view: "Address List",
    allowBack: true
  },
  {
    path: ROUTE_CONSTANTS.UPDATE_ADDRESS,
    exact: true,
    component: EditAddressForm,
    protected: true,
    default: false,
    view: "Edit Address",
    allowBack: true
  },
  {
    path: ROUTE_CONSTANTS.ADD_NEW_ADDRESS,
    exact: true,
    component: AddNewAddress,
    protected: true,
    default: false,
    view: "Add New Address",
    allowBack: true
  },
  {
    path: ROUTE_CONSTANTS.PAYMENT,
    exact: true,
    component: Payment,
    protected: true,
    default: false,
    view: "Payment",
    allowBack: true,
    hideCustomerBack: true,
  },
  {
    path: ROUTE_CONSTANTS.SHIPPING,
    exact: true,
    component: Shipping,
    protected: true,
    default: false,
    view: "Shipping",
    allowBack: true
  },
  {
    path: ROUTE_CONSTANTS.CART,
    exact: true,
    component: Cart,
    protected: true,
    default: false,
    view: "Review",
    allowBack: true
  },
  {
    path: ROUTE_CONSTANTS.BANK_OTP,
    exact: true,
    component: CheckoutOtp,
    protected: true,
    default: false,
    view: "Bank OTP",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.CREDIT_CARD,
    exact: true,
    component: CreditCard,
    protected: true,
    default: false,
    view: "Credit Card",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.ORDER_SUCCESS,
    exact: true,
    component: OrderSuccess,
    protected: true,
    default: false,
    view: "Success",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.ORDER_FAILURE,
    exact: true,
    component: OrderFailure,
    protected: true,
    default: false,
    view: "Failure",
    allowBack: true
  },
  {
    path: ROUTE_CONSTANTS.OUT_OF_RETRIES,
    exact: true,
    component: OutOfRetries,
    protected: true,
    default: false,
    view: "Out of tries",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
    exact: true,
    component: Error,
    protected: false,
    default: false,
    view: "Error",
    allowBack: true
  },
  {
    path: ROUTE_CONSTANTS.STATUS,
    exact: true,
    component: Status,
    protected: false,
    default: false,
    view: "Status",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.ABANDONED,
    exact: true,
    component: Abandoned,
    protected: false,
    default: false,
    view: "Abandoned",
    allowBack: false
  },
  {
    path: ROUTE_CONSTANTS.ANY,
    exact: false,
    component: Error,
    protected: false,
    default: false,
    view: "*",
    allowBack: false
  },
];
export default routes;
