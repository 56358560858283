import { PAYMENT_CONSTANT } from "../../actionTypes";
import { apiService } from "../../middlewares/api_service";
import {
  errorAction,
  LOG_ORDER_EXPIRED
} from "../index";
import { CONSTANTS, HELPER } from "../../../utils";

export const qistPayAction = {
  getIframe,
  hideLoader,
  showLoader
};


function getIframe() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getQisstPayPaymentInstrument()
      .then((response) => {
        const responseStatus = response?.data?.status;
        const data = response?.data?.body;

        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          dispatch(success(data));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        }

        dispatch(failure(error_message.message));
      });
  };

  function request() {
    return { type: PAYMENT_CONSTANT.QIST_PAY.GET_QIST_PAY_DETAILS_REQUEST };
  }
  function success(data) {
    return {
      type: PAYMENT_CONSTANT.QIST_PAY.GET_QIST_PAY_DETAILS_SUCCESS,
      response: data,
    };
  }
  function failure() {
    return { type: PAYMENT_CONSTANT.QIST_PAY.GET_QIST_PAY_DETAILS_FAILURE };
  }
}


function showLoader() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: PAYMENT_CONSTANT.QIST_PAY.SHOW_LOADER,
    };
  }
}


function hideLoader() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: PAYMENT_CONSTANT.QIST_PAY.HIDE_LOADER,
    };
  }
}