// @flow

import * as React from "react";
import CheckoutWrapper from "../../../components/CheckoutWrapper";
import { BREADCRUMB_SETTINGS_JSON } from "../../../utils";
import ShippingForm from "./form";

function Shipping() {
  return (
    <CheckoutWrapper
      fullscreenModeEnabled={false}
      breadcrumbSettings={BREADCRUMB_SETTINGS_JSON.CHECKOUT}
      child={<ShippingForm />}
    />
  );
}

export default Shipping;
