// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

// import {
//   Alert,
//   BackButton,
//   CheckoutWrapper,
//   LoadingButton
// } from "../../components";
import Alert from "../../components/Alert";
import LoadingButton from "../../components/LoadingButton";
import BackButton from "../../components/BackButton";
import CheckoutWrapper from "../../components/CheckoutWrapper";

import { PaymentMethods } from "../../containers";

import {
  BREADCRUMB_SETTINGS_JSON,
  HELPER,
  ANALYTICS_HELPER,
  LOCAL_STORAGE_SERVICE,
  GOOGLE_ANALYTICS_HELPER,
  REDIRECTION_RULE,
  // FIREBASE_ANALYTICS_HELPER
} from "../../utils";
import {
  alertActions,
  cartAction,
} from "../../store/actions";

let timeoutTime;


function Payment() {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const { showBtnLoader } = useSelector(state => state.loading);
  const { selected_payment_method } = useSelector(state => state.payment);
  // const { placement_type } = useSelector(state => state.cart.cart_details.summary);

  const backBtn = LOCAL_STORAGE_SERVICE._isFirstScreen();
  const [accessPaymentMethod, updateAccessToPaymentMethod] = useState("")

  const { footer_note } = selected_payment_method
  //componentDidMount
  useEffect(() => {
    dispatch(alertActions.clear())
    /*
    *  UPDATE CHECKOUT STEP - ANALYTICS
    */
    // FIREBASE_ANALYTICS_HELPER.checkoutSteps(FIREBASE_ANALYTICS_HELPER.CHECKOUT.PAYMENT.TIER);
    ANALYTICS_HELPER._checkoutSteps(5, GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.PAYMENT);
    /*
     *  UPDATE CART ITEMS DROPDOWN
     */
    dispatch(cartAction.update_cart_detail())
    /*
     *  REDIRECTION RULES FOR PAYMENT SCREEN
    */
    REDIRECTION_RULE.PRE_PAYMENT_REDIRECTION_RULE();
    // returned function will be called on component unmount
    return () => {
      clearTimeout(timeoutTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.PAYMENT,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.SUBMIT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.PAYMENT.SUBMIT.LABEL,
        properties: {
          selected: {
            id: selected_payment_method?.id,
            name: selected_payment_method?.name
          }
        }
      }
    );
    updateAccessToPaymentMethod(true)
  };

  const submitBtnClicked = () => {
    updateAccessToPaymentMethod(!accessPaymentMethod)
  };

  return (
    <CheckoutWrapper
      fullscreenModeEnabled={false}
      breadcrumbSettings={BREADCRUMB_SETTINGS_JSON.CHECKOUT}
      child={
        <>
          <h1>{translate("PAYMENT.LIST.TITLE")}</h1>
          <p className="signviewsmalltext">{translate("PAYMENT.LIST.PLACEHOLDER")}</p>
          <Alert />
          <PaymentMethods reference={accessPaymentMethod} submitBtnClicked={submitBtnClicked} />
          <div className={`bottomFixedBar paymentBottomFixed ${HELPER.isEmpty(footer_note) ? "" : "footerReviewFixed"}`}>
            <div className="container">
              <div className="row">
                {!HELPER.isEmpty(footer_note) ? <div className="footerReview">
                  <span className="mainfooterReviewText">
                    <span className={"iconW icomoon-info-outline subFooterReviewIcon"}></span>
                    <span className="subFooterReviewText">{footer_note}</span>
                  </span>
                </div> : ""}
                <div className={`paymentBottomOptionBtn ${backBtn ? " col-12" : ""}`}>
                  {!backBtn && <BackButton btnTitle={translate("back_btn")} />}
                  <button
                    type="button"
                    onClick={handleSubmit}
                    id="payment-method-submit"
                    className={`btn btn-primary width150 ${backBtn ? " btn-block " : "btn-twice"} ${showBtnLoader ? "notClickedBtn" : "clickedBtn"} `}
                    disabled={HELPER.isEmpty(selected_payment_method?.id) || showBtnLoader ? true : false}
                  >
                    <LoadingButton
                      loadingState={showBtnLoader}
                      showBlueLoader={false}
                      preloadingText={translate("next_btn")}
                      loadingText={translate("next_btn")}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    />
  );
}

export default Payment;
