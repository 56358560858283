import { NETWORK_CONSTANTS, CONFIG_CONSTANTS } from "../actionTypes";

const initState = {
  networks: [],
  networkList: [],
  is_submitted: null,
  is_selected: null,
  phone_number: null,
};

const networkReducer = (state = initState, action) => {
  switch (action.type) {
    case CONFIG_CONSTANTS.INITIATE_ORDER_REQUEST:
    case CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_REQUEST:
      return initState;
    case NETWORK_CONSTANTS.NETWORK_LIST_REQUEST:
      return {
        ...state,
      };
    case NETWORK_CONSTANTS.NETWORK_LIST_SUCCESS:
      return {
        ...state,
        networks: action.networks,
        networkList: action.networkList?.networks,
      };
    case NETWORK_CONSTANTS.NETWORK_LIST_FAILURE:
      return {
        ...state,
      };

    case NETWORK_CONSTANTS.NETWORK_CHANGE_REQUEST:
      return {
        ...state,
        is_selected: action.network_id,
      };
    case NETWORK_CONSTANTS.NETWORK_UPDATE_REQUEST:
      return {
        ...state,
        is_selected: action.network_id,
      };
    case NETWORK_CONSTANTS.NETWORK_PHONE_REQUEST:
      return {
        ...state,
        phone_number: action.phone_number,
        is_selected: {
          value: action?.network_id,
          label: action?.network_name,
        },
      };
    default:
      return state;
  }
};
export default networkReducer;
