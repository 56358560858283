import {
  NETWORK_CONSTANTS,
  ERROR_CONSTANTS
} from "../actionTypes";
import { apiService } from "../middlewares/api_service";
import {
  alertActions,
  errorAction,
  LOG_ORDER_EXPIRED,
} from "./index";
import {
  CONSTANTS,
  HELPER,
  history,
  LOCAL_STORAGE_SERVICE
} from "../../utils";

export const networkAction = {
  change_network,
  get_all_networks,
  update_network_value,
  update_network_phone,
};

function get_all_networks(countryId) {
  let requestData = {
    country_id: countryId,
  };
  return (dispatch) => {
    dispatch(request());
    apiService
      .getAllNetworks(requestData)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (!HELPER.isEmpty(responseStatus) && responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS) {
          const networks = response?.data?.body;
          const list = HELPER.createNetworkList(networks);
          dispatch(success(networks, list));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        if (error_response === undefined) {
          dispatch(alertActions.error(error_message?.message));
        } else if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          dispatch(failure(error_message?.message));

          const errorBody = error_response?.data?.body;
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: NETWORK_CONSTANTS.NETWORK_LIST_REQUEST };
  }
  function success(networks, list) {
    return {
      type: NETWORK_CONSTANTS.NETWORK_LIST_SUCCESS,
      networks: networks,
      networkList: list,
    };
  }
  function failure() {
    return { type: NETWORK_CONSTANTS.NETWORK_LIST_FAILURE };
  }
}

function change_network(network_id, networkList) {
  return (dispatch, getState) => {
    const state = getState();
    const { route } = state.redirect;
    dispatch(request(network_id));
    const network_name = HELPER.getNetworkNameFromId(networkList, network_id);
    LOCAL_STORAGE_SERVICE._updateInLocalStorage("network_name", network_name);
    history.push({
      pathname: route.from,
      state: {
        network_id,
      },
    });
  };

  function request() {
    return { type: NETWORK_CONSTANTS.NETWORK_CHANGE_REQUEST, network_id };
  }
}

function update_network_value(networkList, network_name) {
  return (dispatch) => {
    const network_id = HELPER.getNetworkIdFromName(networkList, network_name);
    // LOCAL_STORAGE_SERVICE._updateInLocalStorage("network_name", network_name);
    // LOCAL_STORAGE_SERVICE._updateInLocalStorage("networkId", network_id);
    dispatch(request(network_id, network_name));
  };

  function request(network_id, network_name) {
    return {
      type: NETWORK_CONSTANTS.NETWORK_UPDATE_REQUEST,
      network_id,
      network_name,
    };
  }
}

function update_network_phone(phone_number, network_id, network_name) {
  return (dispatch) => {
    dispatch(request(phone_number, network_id, network_name));
    // LOCAL_STORAGE_SERVICE._updateInLocalStorage("phone", phone_number);
  };

  function request(phone_number, network_id) {
    return {
      type: NETWORK_CONSTANTS.NETWORK_PHONE_REQUEST,
      phone_number,
      network_id,
      network_name,
    };
  }
}
