import React, { useState, useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastProvider } from 'react-toast-notifications';
import { IntlProvider, IntlActions } from "react-redux-multilingual";
import { StylesProvider, ThemeProvider, jssPreset } from "@material-ui/styles";
import { create } from "jss";
import TagManager from "react-gtm-module";
import { createTheme } from '@material-ui/core/styles'
import rtl from "jss-rtl";

import "react-phone-input-2/lib/style.css";
import routes from "./route";
import {DefaultRoute} from "./components/DefaultRoute";
import {PrivateRoute} from "./components/PrivateRoute";
import PageLoader from "./components/PageLoader";
import ErrorBoundary from "./components/ErrorBoundary";

import {
  CONSTANTS,
  LANG_TRANS,
  HELPER,
  LOCAL_STORAGE_SERVICE,
  history,
  ANALYTICS_HELPER,
  // FIREBASE_ANALYTICS_HELPER
} from "./utils";
import "tabler-react/dist/Tabler.css";
import "./assets/sass/app.scss";
import "./App.css";
import { useMobileAlerts } from '../src/hooks'
import { useRollbarPerson } from "@rollbar/react";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const ltrTheme = createTheme({ direction: "ltr" });
const rtlTheme = createTheme({ direction: "rtl" });
let tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayerName: 'bSecureCheckout'
}

const LocaleUpdate = (locale) => {
  let htmlEl = document.querySelector("html");
  // htmlEl.setAttribute("dir", direction);
  htmlEl.setAttribute("lang", locale);

  let lang_direction = HELPER.getLngDirection(locale);
  let bodyEl = document.querySelector("body");
  bodyEl.setAttribute("dir", lang_direction);

  return true;
};

function App() {
  useMobileAlerts()
  let dispatch = useDispatch();
  const { locale } = useSelector(state => state.Intl);
  const { checkoutStep, checkoutStepName } = useSelector(state => state.analytics);
  const { config, customer, order_ref } = useSelector(state => state.configuration);
  const { gtm_id, segment_id, ga_id } = config
  const { user_id } = useSelector(state => state.configuration.customer);

  const defaultLocale = HELPER.getDefaultLocale(locale);
  LOCAL_STORAGE_SERVICE._saveToLocalStorage("locale", defaultLocale);

  const [langLocale, setLangLocale] = useState(defaultLocale);

  useRollbarPerson({id: order_ref ,username: customer?.name, email: customer?.email});

  useEffect(() => {
    if (HELPER.isNotEmpty(checkoutStep)) {
      // FIREBASE_ANALYTICS_HELPER.checkoutSteps(checkoutStep,checkoutStepName);
      ANALYTICS_HELPER._checkoutSteps(checkoutStep,checkoutStepName);
    }
  }, [checkoutStep])
  
  useEffect(() => {
    if(HELPER.isNotEmpty(ga_id)){
      ANALYTICS_HELPER._initializeGAAnalytics(ga_id)
    }
  }, [ga_id]);

  useEffect(() => {
    if(HELPER.isNotEmpty(segment_id) && HELPER.isNotEmpty(user_id)){
      ANALYTICS_HELPER._initializeSegmentAnalytics(segment_id, user_id)
    }
  }, [segment_id, user_id]);
  
  useEffect(() => {
    dispatch(IntlActions.setLocale(langLocale));
    setTimeout(() => {
      HELPER.isEmpty(gtm_id) && TagManager.initialize(tagManagerArgs);
      if (!HELPER.isEmpty(gtm_id) && tagManagerArgs.gtmId !== gtm_id) {
        tagManagerArgs.gtmId = gtm_id;
        TagManager.initialize(tagManagerArgs);
      }
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!HELPER.isEmpty(locale)) {
      LocaleUpdate(locale);
      const storageLocale = LOCAL_STORAGE_SERVICE._getFromLocalStorage("locale");
      if (locale !== storageLocale) {
        setLangLocale(storageLocale);
        LOCAL_STORAGE_SERVICE._updateInLocalStorage("locale", storageLocale);
      } else {
        setLangLocale(locale);
      }
    }

    setTimeout(() => {
      if (!HELPER.isEmpty(gtm_id) && tagManagerArgs.gtmId !== gtm_id) {
        tagManagerArgs.gtmId = gtm_id;
        TagManager.initialize(tagManagerArgs);
      }
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <>
      <PageLoader />
      <ToastProvider>
        <StylesProvider jss={jss}>
          <ThemeProvider
            theme={locale === CONSTANTS.IS_URDU ? rtlTheme : ltrTheme}
          >
            <IntlProvider
              translations={LANG_TRANS}
              locale={langLocale}
              defaultLocale="en"
            >
                <Router history={history}>
                  <ErrorBoundary>
                    <Switch>
                      {routes.map((route, index) => {
                        return  route.protected ? (
                          <PrivateRoute key={index} {...route} />
                        ) : route.default ? (
                          <DefaultRoute key={index} {...route} />
                        ) : (
                          <Route key={index} {...route} />
                        );
                      })}
                    </Switch>
                  </ErrorBoundary>
                </Router>
            </IntlProvider>
          </ThemeProvider>
        </StylesProvider>
      </ToastProvider>
    </>
  );
}

export default App;

