import React, { Component } from "react";
import { ANALYTICS_HELPER } from "../../utils";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        ANALYTICS_HELPER._appException(error?.message, error)
        console.error("ErrorBoundary: ", error, info);
    }

    render() {
        // if (this.state.hasError) {
        //     return <h1>Something went wrong.</h1>;
        // }
        return this.props.children;
    }
}

export default React.memo(ErrorBoundary);
