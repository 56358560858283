// @flow
import React from "react";
import { IMAGE_SRC } from "../../utils";

function CreditCardScan(props) {
  const { showCCScan } = props;
  return showCCScan ? (
    <div className="ccScreen">
      <img src={IMAGE_SRC.CCScreen} alt="credit card scan icon" />
      <div className="ccScreenbtn">
        <span className="btn btn-primary btn-tiny">
          <span className="icon-icon-camera">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </span>
          Scan
        </span>
      </div>
    </div>
  ) : (
    ""
  );
}

export default CreditCardScan;
