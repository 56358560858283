// @flow
import React from "react";

import { Card } from "react-bootstrap";

import CnicField from "../../../../components/FormElements/Cnic";
import EmailField from "../../../../components/FormElements/Email";
import PhoneField from "../../../../components/FormElements/Phone";

function EditPaymentForm(props) {
  const { showChild, child, subChild, payment } = props;

  const askForEmail = parseInt(payment?.is_email_required) === 1 ? true : false;
  const askForPhone = parseInt(payment?.is_phone_required) === 1 ? true : false;
  const askForCnic = parseInt(payment?.is_cnic_required) === 1 ? true : false;

  return (
    <>
      {showChild ? <Card.Body className="paymentActions">
        <div className="checkBoxCard mb-0">
          {
            askForEmail ? <EmailField /> : ""
          }
          {
            askForPhone ? <PhoneField /> : ""
          }
          {child}
          {
            askForCnic ? <CnicField hideText={true} /> : ""
          }
        </div>
        {subChild}
      </Card.Body>
        : ""
      }
    </>
  );
}

export default EditPaymentForm;
