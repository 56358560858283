import React, { Fragment } from "react";
import {
  List,
  ListItem,
  Divider,
} from "@material-ui/core";

export default function DropdownMethods({ 
  listItems, handleSelect, startIcon,
  setvisible, visible, value, selectValue=""}) {

  const ListItemLinkComponent = (e) => {
    let _selectValue=selectValue ? e[selectValue] : e
    return (
      <div>
        <ListItem
          className={`list-item ${value === _selectValue ? "selected-value" : ""}`}
          onClick={() => {handleSelect(e)}}
        >
            {!!startIcon && <i className={`${startIcon}`}></i>}
            <span>{_selectValue}</span>
        </ListItem>
        <Divider />
      </div>
    );
  };

  return (
    <div className="dropdown-methods">
      <div className={`outlineSelect`}
          onClick={() => setvisible(!visible)}>
            <div className='list-item'>
              {!!startIcon && <i className={`${startIcon}`}></i>}
              <span>{value}</span>
            </div>
            <i className={`icomoon-navigate-bottom ${visible ? "rotate-180" : ""}`} />
          </div>
          {visible && (
            <div className="methods-listing-container mt-2">
              <List
                sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              >
                {listItems?.map((e, index) => (
                  <Fragment key={selectValue ? e[selectValue] : e?.id || index}>{ListItemLinkComponent(e)}</Fragment>
                ))}
              </List>
          </div>
          )}
    </div>
   
  );
}
