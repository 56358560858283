// @flow

import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { Button } from "@material-ui/core";

import { dialogAction } from "../../../store/actions";
import {
  ANALYTICS_HELPER,
  CONSTANTS,
  HELPER,
} from "../../../utils";

function TermsAndConditions() {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const { locale } = useSelector(state => state.Intl);
  const {
    cart_details,
    cnic_requirement
  } = useSelector(state => state.cart);
  const { voucher_applicable } = cart_details.summary

  const handleClickOpen = () => {
    ANALYTICS_HELPER._termPopup()
    dispatch(dialogAction.openDialog(CONSTANTS.DIALOG.CATEGORY.MODAL, CONSTANTS.DIALOG.TYPE.MODAL.TERMS));
  };

  const CopyRightsIcon = <div className="copyRightsIcon"> <i className="icomoon-powered-icon"></i> </div>

  const formatTermsAndCondition = () => {
    let terms_and_condition_component;
    const terms_text = translate("CART.PLACEHOLDER.TERMS.PREPEND_PLACEHOLDER");
    const terms_component = (
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {translate("CART.PLACEHOLDER.TERMS.TITLE")}
      </Button>
    );
    if (locale === CONSTANTS.IS_URDU) {
      terms_and_condition_component = (
        <div className={`termCondition ${parseInt(cnic_requirement.cnic_required) === 1 || HELPER?.stringToBoolean(voucher_applicable) ? "termsAdditionalHeader" : ""}`}>
          { CopyRightsIcon }
          <div className="terms">
            {terms_text} {terms_component}{" "}
            {translate("CART.PLACEHOLDER.TERMS.APPEND_PLACEHOLDER")}
          </div>
        </div>
      );
    } else {
      terms_and_condition_component = (
        <div className={`termCondition ${parseInt(cnic_requirement.cnic_required) === 1 || HELPER?.stringToBoolean(voucher_applicable) ? "termsAdditionalHeader" : ""}`}>
          { CopyRightsIcon }
          <div className="terms">{terms_text} {terms_component}</div>
        </div>
      );
    }
    return <> {terms_and_condition_component}</>;
  };
  return (
    <>
      {formatTermsAndCondition()}
    </>
  );
}

export default TermsAndConditions;
