// @flow
//eslint-disable-next-line

import * as React from "react";
import { useSelector } from "react-redux";

function CustomerNumber() {
  const { configuration } = useSelector(state => state);
  const { country_code, network_code, phone_number } = configuration.customer;
  return (
    <>
      +{country_code}({network_code}){phone_number}
    </>
  );
}


export default CustomerNumber;
