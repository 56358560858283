// @flow
//eslint-disable-next-line
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import OtpInput from "react-otp-input";
import ReactHtmlParser from "html-react-parser";
import AppWrapper from "../../components/App";
import LoadingButton from "../../components/LoadingButton";
import Alert from "../../components/Alert";
import MetaTags from "../../components/MetaTags";
import SelectPhoneDialog from "../../components/CustomizeDialog/SelectPhoneDialog";

import { verificationAction, alertActions } from "../../store/actions";
import {
  ANALYTICS_HELPER,
  GOOGLE_ANALYTICS_HELPER,
  LOCAL_STORAGE_SERVICE,
  ROUTE_CONSTANTS,
  BREADCRUMB_SETTINGS_JSON,
  REDIRECTION_RULE,
  history,
  CONSTANTS,
  HELPER,
} from "../../utils";

// import "react-code-input/styles/style.scss";

function OTP() {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const {
    sendOTP,
    retryOtp,
    customer,
    isPhoneDialog,
  } = useSelector(state => state.configuration);
  const {
    // country_code,
    // network_code,
    // phone_number,
    has_multiple_phone_numbers,
  } = customer;
  const { route, otp_msg } = useSelector(state => state.redirect);
  const { verification_type } = useSelector(state => state.cart);
  
  // const get_network_name = "";
  // const formatted_phone_number = "+" + country_code + "(" + network_code + ")" + phone_number;
  const showBackBtn = LOCAL_STORAGE_SERVICE._isFirstScreen();
  // const showBackBtn = initial_screen !== CONSTANTS.SCREEN_REDIRECT.OTP || (initial_screen !== CONSTANTS.SCREEN_REDIRECT.OTP && backBtn)
  const digitLimit = 6;
  // Check if network id is recieved via route parameters from select-network screen
  // let networkId = location.state?.network_id;
  // State assign
  const [verify_otp, setVerify_otp] = useState("");
  // const [network_id, setNetwork_id] = useState(null);

  //componentDidMount
  useEffect(() => {
    // FIREBASE_ANALYTICS_HELPER.checkoutSteps(FIREBASE_ANALYTICS_HELPER.CHECKOUT.OTP.TIER);
    ANALYTICS_HELPER._checkoutSteps(2, GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.OTP);
    REDIRECTION_RULE.PRE_OTP_REDIRECTION_RULE()
    // Clear alerts
    dispatch(alertActions.clear())
    // if (!HELPER.isEmpty(networkId)) {
    //   // this.props.resendOTP(network_id, history, location);
    //   setNetwork_id(networkId);
    //   setVerify_otp("");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (verify_otp.length === 1) {
      // LOAD GTM EVENT
      ANALYTICS_HELPER._generalEventLog(
        GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.OTP,
        {
          action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.OTP.INPUT.ACTION,
          label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.OTP.INPUT.LABEL,
          properties: {}
        }
      );
    }
    if (verify_otp.length === digitLimit) {
      submitOTP(window.event)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify_otp]);

  useEffect(() => {
    if (retryOtp) {
      setVerify_otp("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryOtp]);


  const moveBackwards = () => {
    if (route.from === ROUTE_CONSTANTS.CART) {
      history.goBack();
    } else {
      history.push({
        pathname: ROUTE_CONSTANTS.PHONE_SCREEN,
      });
    }
  };

  const submitOTP = (e) => {
    e.preventDefault();
    e.target.blur();
    // LOAD GTM EVENT
    ANALYTICS_HELPER._generalEventLog(
      GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.OTP,
      {
        action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.OTP.SUBMIT.ACTION,
        label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.OTP.SUBMIT.LABEL,
        properties: {}
      }
    );
    if (verify_otp.length === digitLimit) {
      dispatch(verificationAction.OTP_VERIFY(verify_otp));
    } else {
      dispatch(alertActions.error(translate("otp_validation_message")));
    }
  };

  // const get_network_name = HELPER.checkLocalStorage(
  //   "network_name": ""
  //   !HELPER.isEmpty(network_name) ? network_name : "Unidentified network"
  // );

  return (
    <>
      <MetaTags />
      <AppWrapper
        showMerchantLogo={true}
        showAccountDropdown={false}
        showLangIcon={true}
        allowProfileAPI={false}
        showFooter={false}
        breadcrumb={
          {
            type: BREADCRUMB_SETTINGS_JSON.OTP.TYPE,
            options: BREADCRUMB_SETTINGS_JSON.OTP,
            show: BREADCRUMB_SETTINGS_JSON.OTP.SHOW
          }}
      >
        <div className="screen midWrap bg-white">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="signInView midView bg-white">
                  <h1>{translate("OTP.TITLE")}</h1>
                  {!isPhoneDialog ?
                  <Alert /> : ""}
                  <form className="myForm" onSubmit={submitOTP} id="otp-form">
                    <p className="Otptext">
                      {ReactHtmlParser(HELPER.parseMessage(otp_msg))}
                     {/* {ReactHtmlParser(
                        HELPER.otpTEXT_FORMATting(
                          translate("OTP.SUB_TITLE", {
                            network_name: get_network_name,
                            phone_number: formatted_phone_number,
                          }),
                          get_network_name,
                          formatted_phone_number
                        )
                      )}
                       <span className="num">
                        <CustomerNumber />
                      </span> 
                      {translate("OTP.CLICK_HERE")}
                      {/* . <NotOnNetwork /> */}
                    </p>

                    <div className="OtpNum">
                      <div className="OTPPin">
                        <div
                          id="otp-pin-input"
                          className="OTPPinNo otpScreen"
                          data-pin
                        >
                          <OtpInput
                            value={verify_otp}
                            shouldAutoFocus={true}
                            onChange={(e) => { setVerify_otp(e); }}
                            numInputs={digitLimit}
                            isInputNum={true}
                            name="verify_otp"
                            autoComplete="one-time-code"
                          />
                        </div>
                      </div>
                    </div>
                    
                    {!!has_multiple_phone_numbers &&
                    <div className="another-num" onClick={()=>  dispatch(verificationAction.MULTI_PHONE_DIALOG(true))}>{translate("OTP.USE_ANOTHER")}</div>
                    }

                    <div className="optionBtn">
                      {!showBackBtn && (verification_type !== CONSTANTS.VERIFICATION_TYPE.OTP) && (
                        <button
                          type="button"
                          id="back-btn"
                          className="btn btn-outline-primary width150"
                          onClick={moveBackwards}
                        >
                          {translate("back_btn")}
                        </button>
                      )}
                      <button
                        type="submit"
                        id="otp-submit"
                        className="btn btn-primary width150"
                        disabled={
                          verify_otp.length !== digitLimit || sendOTP ? true : false
                        }
                      >
                        <LoadingButton
                          loadingState={sendOTP}
                          preloadingText={translate("next_btn")}
                          loadingText={translate("next_btn")}
                        />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
      {isPhoneDialog &&
        <SelectPhoneDialog open={isPhoneDialog} />
      }
    </>
  );
}

export default OTP;
