import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { Accordion, Card } from "react-bootstrap";

import {
  HELPER,
  CONSTANTS,
} from "../../../utils";
import { paymentAction } from "../../../store/actions";
import PAYMENT_PROTECTION from './PaymentProtection'
import { APGBankAccount, DirectBankTransferPaymentForm, EasyPaisaMAList, EasyPaisaMAPaymentForm, EditCreditCardPaymentForm, EditPaymentForm } from "../Gateways";

const FORM_TYPE = CONSTANTS.PAYMENT_METHOD.FORM_TYPE;

const FormCard = ({ payment_method, errors, deletePaymentMethod, add_new }) => {
  const translate = useTranslate();
  let dispatch = useDispatch();

  const { selected_payment_method } = useSelector(state => state.payment);

  const setSelectedPaymentIndex = () => {
    if (payment_method?.index !== selected_payment_method.index) {
      dispatch(paymentAction.update_selected_payment_index(payment_method));
    }
  };

  const showPaymentName = () => {
    const { payment_protection_enabled, token_id } = payment_method
    return (
      <>
        <div className="media paymentMethodHeader">
          <div className="paymentMethodHeaderBody">
            <div className={`image ${!HELPER.isEmpty(payment_method.icon) ? "codImage" : ""}`}>
              {HELPER.isNotEmpty(payment_method.icon) ?
                <img src={payment_method.icon} alt={"credit card"} />
                : ""}
            </div>
            <div className="information">
              <h5 className={`mt-0 overflowText ${payment_method?.form_type === FORM_TYPE.APG.EASY_PAISA_ACCOUNT && token_id !== null ? "removeOverflow" : ""}`}>
                {add_new && HELPER.titleCase(translate("PAYMENT_METHODS.ADD_NEW"))} {HELPER.titleCase(payment_method.name)}
              </h5>
              {!HELPER.isEmpty(token_id) ? <h6 className="overflowText">{payment_method.account_no}</h6> : ""}
            </div>
            {!HELPER.isEmpty(token_id) ?
              <div
                className="icon trashIcon"
                onClick={() => deletePaymentMethod(payment_method)}
              >
                <span className="icomoon-icon-trash"></span>
              </div>
              :
              <div className="icon trashIcon"></div>
            }
          </div>
        </div>
        {parseInt(payment_protection_enabled) === 1 ? <PAYMENT_PROTECTION /> : ""}
      </>
    );
  };

  const showPaymentForm = () => {
    const form_type = payment_method.form_type;
    const token_id = payment_method.token_id;
    let childComponent = <></>;
    let subChildComponent = <></>;
    let showChildComponent = payment_method?.is_expandable === CONSTANTS.YES;
    let data;
    if ( 
      (form_type === FORM_TYPE.CREDIT_CARD.API_BASED ||
        form_type === FORM_TYPE.CREDIT_CARD.GENERAL) &&
      !HELPER.isEmpty(token_id)
    ) {
      childComponent = <EditCreditCardPaymentForm payment={payment_method} />
    } else if (CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.EASY_PAISA_ACCOUNT.includes(form_type)) {
      childComponent = <EasyPaisaMAPaymentForm
        payment={payment_method}
        errors={errors}
      />
      subChildComponent = <EasyPaisaMAList payment={payment_method} />
    } else if (CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.DBT.includes(form_type)) {
      childComponent = <DirectBankTransferPaymentForm payment={payment_method} />
    } else if (CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.WALLET.includes(form_type)) {
      data = {
        name: translate("wallet_account_number"),
        label: translate("wallet_account_number"),
        placeholder: translate("placeholder_wallet_account_number"),
        className: "bankWallet",
        id: payment_method.id,
        is_default: payment_method.is_default,
      };
      childComponent = <APGBankAccount
        data={data}
        payment={payment_method}
      />
    } else if (CONSTANTS.PAYMENT_METHOD.PAYMENT_FORM_TYPE.BANK_ACCOUNT_NUMBER.includes(form_type)) {
      data = {
        name: translate("bank_account_number"),
        label: translate("bank_account_number"),
        placeholder: translate("placeholder_bank_account_number"),
        className: "bankAccount",
        id: payment_method.id,
        is_default: payment_method.is_default,
      };
      childComponent = <APGBankAccount
        data={data}
        payment={payment_method}
      />
    }


    return <EditPaymentForm
      payment={payment_method}
      child={childComponent}
      subChild={subChildComponent}
      showChild={showChildComponent}
    />;
  };

  return (
    <>
      <Card
        className={`${selected_payment_method?.index === payment_method?.index ? "activeShow" : ""} ${payment_method.form_type !== FORM_TYPE.DEFAULT ? "card-header-left" : ""}`}
        onClick={setSelectedPaymentIndex}
        eventKey={"form-" + payment_method?.index}
      >
        {/* CHECK IF FORM TYPE IS ADDRESS THAT IS COD THEN DON'T SHOW IS EDIT BTN AS IT HAS NO FORM OR SAVED VALUE */}
        <Accordion.Toggle
          as={Card.Header}
          eventKey={"form-" + payment_method?.index}
          component={"div"}
        >
          {showPaymentName()}
        </Accordion.Toggle>
        {/* CHECK IF FORM TYPE IS ADDRESS THAT IS COD THEN DON'T SHOW ANY FORM AS IT HAS NO FORM OR SAVED VALUE */}
        {/* WE WILL HAVE DIFFERENT FORMS FOR EACH PAYMENT TYPE AS EVERY PAYMENT METHOD WILL HAVE DIFFERENT LOGIC */}
        <Accordion.Collapse
          eventKey={"form-" + payment_method?.index}
        >
          {showPaymentForm()}
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export default FormCard;
