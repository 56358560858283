import { HELPER, } from "../../utils";
import {
  PAYMENT_CONSTANT,
  CART_CONSTANTS,
  LANG_CONSTANTS,
  CONFIG_CONSTANTS
} from "../actionTypes";

const initState = {
  // Payment Page
  payment_methods: [],
  //default selection
  selected_payment_method: [],

  //Payment verification
  payment_verification_required: false,
  //Credit Card payment verification
  cc_payment_verified: false,
  cc_payment_otp_required: null,
  cc_payment_email_otp_required: null,
  cc_payment_3ds_required: null, //is3ds: null,
  cc_payment_redirect_url: null, //3ds_url: null,

  //NIFT
  loadPluginScript: false,
  loadSessionScript: false,
  nift_details_loading: "",
  nift_reload_start: false,
  nift_msgs: [],
  nift_msg_type: "",
  nift_details: [],
  nift_resp_code: "",
  payment_processing_details: [],
  payment_processing_details_loading: false,
  // Qist Pay
  qist_pay_iframe_url: "",
  nift_script_error_count: 0,
};

let error;

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case CONFIG_CONSTANTS.INITIATE_ORDER_REQUEST:
    case CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_REQUEST:
      return initState;
    case CART_CONSTANTS.GET_CART_DETAILS_REQUEST:
      return {
        ...state,
        nift_details_loading: "",
        payment_processing_details_loading: false,
        nift_script_error_count: 0
      };
    // NIFT
    case PAYMENT_CONSTANT.NIFT.UPDATE_MSGS:
      error = !HELPER.isEmpty(action?.msg)
        ? action?.msg
        : state.nift_msgs;
      return { ...state, nift_msgs: error, nift_msg_type: action?.msg_type };

    case PAYMENT_CONSTANT.NIFT.LOAD_NIFT_PLUGIN_SCRIPT:
      return { ...state, loadPluginScript: action?.state };
    case PAYMENT_CONSTANT.NIFT.LOAD_SESSION_JS:
      return { ...state, loadSessionScript: action?.state, nift_reload_start: true };
    case PAYMENT_CONSTANT.NIFT.RE_LOAD_NIFT_SCRIPT:
      return {
        ...state,
        nift_resp_code: "",
        nift_details_loading: action?.state,
        // loadPluginScript: !state?.loadPluginScript,
        // loadSessionScript: !state?.loadSessionScript,
      };
    case PAYMENT_CONSTANT.NIFT.CLEAR_MSGS:
      return {
        ...state,
        nift_msgs: [],
        nift_msg_type: "",
      };
    case LANG_CONSTANTS.UPDATE_LANGUAGE_LOCALE:
      return {
        ...state,
        nift_details_loading:
          action?.prevLocale !== action?.locale ? "" : false,
      };
    case PAYMENT_CONSTANT.NIFT.GET_NIFT_DETAILS_REQUEST:
      return {
        ...state,
        payment_processing_details_loading: false,
      };
    case PAYMENT_CONSTANT.NIFT.GET_NIFT_DETAILS_SUCCESS:
      return {
        ...state,
        nift_resp_code: "",
        nift_details: action.response,
        payment_processing_details_loading: true,
      };
    case PAYMENT_CONSTANT.NIFT.GET_NIFT_DETAILS_FAILURE:
      return {
        ...state,
        payment_processing_details_loading: false,
      };
    case PAYMENT_CONSTANT.NIFT.UPDATE_NIFT_RESP_REQUEST:
      return { ...state };
    case PAYMENT_CONSTANT.NIFT.UPDATE_NIFT_RESP_SUCCESS:
      return {
        ...state,
        nift_resp_code: action?.code,
        nift_details_loading: "",
      };
    case PAYMENT_CONSTANT.NIFT.UPDATE_NIFT_RESP_FAILURE:
      return {
        ...state,
        nift_resp_code: action?.code,
      };
    case PAYMENT_CONSTANT.NIFT.CLEAR_NIFT_DETAILS:
      return {
        ...state,
        nift_details: [],
      };
    case PAYMENT_CONSTANT.GENERAL.PAYMENT_PROCESSOR_DETAIL_REQUEST:
      return {
        ...state,
        payment_processing_details_loading: true,
      };
    case PAYMENT_CONSTANT.GENERAL.PAYMENT_PROCESSOR_DETAIL_SUCCESS:
      return {
        ...state,
        payment_processing_details: action?.response,
        payment_processing_details_loading: false,
      };
    case PAYMENT_CONSTANT.GENERAL.PAYMENT_PROCESSOR_DETAIL_FAILURE:
      return {
        ...state,
        payment_processing_details_loading: false,
      };
    // State update for API: GET PAYMENT METHOD
    // GET v1/order/payment-method
    case PAYMENT_CONSTANT.GET_MERCHANT_PAYMENT_METHOD_REQUEST:
      return state;
    case PAYMENT_CONSTANT.GET_MERCHANT_PAYMENT_METHOD_SUCCESS:
     var _selected=action?.data?.selected_payment_method;
      return {
        ...state,
        payment_methods: action.data?.methods,
        selected_payment_method: !HELPER.isEmpty(_selected) ? _selected : state.selected_payment_method,
      };
    case PAYMENT_CONSTANT.GET_MERCHANT_PAYMENT_METHOD_FAILURE:
      return state;
    // State update for API: UPDATE PAYMENT METHOD
    // POST v1/order/payment-method
    case PAYMENT_CONSTANT.UPDATE_ORDER_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
      };
    case PAYMENT_CONSTANT.UPDATE_ORDER_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        selected_payment_method: { ...state.selected_payment_method , id: action?.paymentMethod?.payment_method_id },
        cc_payment_verified: false,
        cc_payment_otp_required: 0, //is_otp: ask for otp,
        cc_payment_email_otp_required: 0, //is_otp: ask for otp,
      };
    case PAYMENT_CONSTANT.UPDATE_ORDER_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
      };
    case PAYMENT_CONSTANT.DELETE_CREDIT_CARD_REQUEST:
      return {
        ...state,
        payment_methods: [],
      };
    case PAYMENT_CONSTANT.DELETE_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        payment_methods: action.data?.methods,
        selected_payment_method: !HELPER.isEmpty(action?.data?.selected_payment_method) ? action?.data?.selected_payment_method : state.selected_payment_method,
      };
    case PAYMENT_CONSTANT.DELETE_CREDIT_CARD_FAILURE:
      return state;
    // CREDIT CARD PAYMENT VERIFICATION UPDATE
    // State update for API: Validate PrePayment
    // POST v1/order/validate-payment
    case PAYMENT_CONSTANT.VALIDATE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        payment_methods: initState.payment_methods,
        payment_verification_required: true,
      };
    case PAYMENT_CONSTANT.VALIDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        cc_payment_verified: true,
        cc_redirection: action.response?.redirection,
        cc_payment_3ds_required: action.response?.is_i3ds, //is3ds: null,
        cc_payment_redirect_url: action.response?.redirect_url, //3ds_url: null,
        cc_payment_otp_required: action.response?.is_otp, //is_otp: ask for otp,
        cc_payment_email_otp_required: HELPER.isNotEmpty(action.response?.is_email_otp) ? action.response?.is_email_otp : 0, //is_otp: ask for otp,
      };
    case PAYMENT_CONSTANT.VALIDATE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        payment_verification_required: state.payment_verification_required,
      };
    case PAYMENT_CONSTANT.VALIDATE_PAYMENT_METHOD_BACK:
      return {
        ...state,
        cc_payment_verified: false,
        cc_payment_otp_required: 0, //is_otp: ask for otp,
        cc_payment_email_otp_required: 0, //is_otp: ask for otp,
      };
    case PAYMENT_CONSTANT.UPDATE_SELECTED_PAYMENT_INDEX:
      return {
        ...state,
        selected_payment_method: action?.selected_payment,
      };
    case PAYMENT_CONSTANT.UPDATE_DEFAULT_PAYMENT_ACCOUNT_NO:
      return {
        ...state,
        selected_payment_method: { ...state.selected_payment_method, account_no: action?.account_no },
      };
    case PAYMENT_CONSTANT.GENERAL.EP_MOBILE_NUMBER_REQUEST:
      return {
        ...state,
        selected_payment_method: { ...state.selected_payment_method, account_no: action?.response },
      }
    case PAYMENT_CONSTANT.PAYMENT_METHOD_SAVE_FOR_NEXT_TIME:
      return {
        ...state,
        selected_payment_method: { ...state.selected_payment_method, is_default: action?.is_default },
      };
    //PAYMENT VERIFIED REQUIRED ?
    // State update for internal use
    case PAYMENT_CONSTANT.PAYMENT_VERIFICATION_REQUIRED:
      return {
        ...state,
        payment_verification_required: true,
      };
    case CART_CONSTANTS.PLACE_ORDER_FAILURE:
      return {
        ...state,
        payment_verification_required: true,
        cc_payment_verified: false,
        payment_processing_details: [],
        payment_processing_details_loading: false,
      };

      case CART_CONSTANTS.CLEAR_PAYMENT_VERIFIED:
        return {
          ...state,
          cc_payment_verified: false,
        };
        
    case PAYMENT_CONSTANT.NIFT.NIFT_SCRIPT_ERROR_COUNT:
      return { ...state, nift_script_error_count: state.nift_script_error_count + 1 };

    default:
      return {
        ...state,
      };
  }
};
export default cartReducer;
