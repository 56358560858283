// @flow
//eslint-disable-next-line

import React from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import ReactHtmlParser from "html-react-parser";

import { Accordion } from "react-bootstrap";

import {
    CART_HELPER,
    HELPER,
    CONSTANTS
} from "../../../../utils";


function FORMAT_PRICE(currency_code, product) {
    const { product_total, product_sub_total, campaign_id, campaign_discount_price, campaign_discount_sale_price } = product;
  
    const price = HELPER.isNotEmpty(campaign_id) ? campaign_discount_price : product_sub_total;
    const discountedPrice = HELPER.isNotEmpty(campaign_id) ? campaign_discount_sale_price : product_total
  
    const formattedPrice = HELPER.formatPrice(currency_code, price)
    const discountPrice = HELPER.formatPrice(currency_code, discountedPrice)
  
    if (!HELPER.isEmpty(formattedPrice?.charges) && formattedPrice?.charges !== discountPrice?.charges) {
      return <span><span>{formattedPrice?.currency}</span> {formattedPrice?.charges}</span>;
    }
    return "";
}

function FORMAT_DISCOUNTED_PRICE(currency_code, product) {
    const { product_total, product_sub_total, campaign_id, campaign_discount_price, campaign_discount_sale_price } = product;
  
    const price = HELPER.isNotEmpty(campaign_id) ? campaign_discount_price : product_sub_total;
    const discountedPrice = HELPER.isNotEmpty(campaign_id) ? campaign_discount_sale_price : product_total
  
    const formattedPrice = HELPER.formatPrice(currency_code, price)
    const discountPrice = HELPER.formatPrice(currency_code, discountedPrice)
  
    if (!HELPER.isEmpty(discountPrice?.charges) || formattedPrice?.charges === discountPrice?.charges) {
      return <span><span>{discountPrice?.currency}</span> {discountPrice?.charges}</span>;
    }
    return "";
  }

function MenuWrapper() {
    const translate = useTranslate();
    const { is_customer_app } = useSelector(state => state.configuration.config);
    const { items, count } = useSelector(state => state.cart.cart_details);
    const {
        currency_code,
        discount_amount,
        merchant_service_charges,
        shipment_charges,
        sub_total_amount,
        total_amount,
        additional_charges
    } = useSelector(state => state.cart.cart_details.summary);


    return (
        <div className={`cartSlide ${(CART_HELPER.CART_DEVICE_TYPE() === CONSTANTS.DEVICE_TYPE.MOBILE) ? "webCartSlide" : ""}  `}>
            <div className={`cartSlideHeader ${(CART_HELPER.CART_DEVICE_TYPE() === CONSTANTS.DEVICE_TYPE.MOBILE) ? "d-none" : ""}`}>
                <div className="cartSlideHeaderContainer">
                    <span>Your bag has ({count}) items</span>
                </div>
            </div>
            <div className="insideCartSlideWrap">
                <div className="insideWrapper">
                    {
                        items.map((product, i) => {
                            return (
                                <div className="cartProductsWrap" key={i}>
                                    <div className="cartPrdLeft">
                                        {is_customer_app === 1 &&
                                            <div className="storeInfo d-flex mb-1">
                                                {
                                                    HELPER.isNotEmpty(product?.store?.branding?.favicon) ?
                                                        <div className="store-icon">
                                                            <img src={product?.store?.branding?.favicon} alt="bSecure" />
                                                        </div> : ""
                                                }
                                                <div className="store-name">
                                                    {product?.store?.name}
                                                </div>
                                            </div>
                                        }

                                        <span className="cartProdName">
                                            {HELPER.titleCase(product?.product_name)}
                                        </span>
                                        {ReactHtmlParser(
                                            HELPER.parseMessage(
                                                CART_HELPER.PRODUCT_ATTRIBUTES(product?.product_attributes_json)
                                            ))}
                                        {!HELPER.isEmpty(product?.product_additional_notes) ? <span className="cartAddNotes">{product?.product_additional_notes}</span> : ""}
                                        <div className="cartInfoBottom">
                                            <div className="qty">
                                                {ReactHtmlParser(
                                                     HELPER.parseMessage(
                                                        HELPER.parseQuantity(translate("quantity_keyword"), product.product_qty)
                                                ))}
                                            </div>
                                            <div className="price">
                                                <div className="slideProdPrice suggestPrice strikeText"> {FORMAT_PRICE(currency_code, product)} </div>
                                                <div className="slideProdPrice newPrice"> {FORMAT_DISCOUNTED_PRICE(currency_code, product)} </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        HELPER.isNotEmpty(product.product_image) ?
                                            <div className="cartPrdRight">
                                                <div className="sale-header-off cart-top">
                                                    {HELPER.CAMPAIGN_SALE_NAME(product)}
                                                    <div className="cartProdImgWrap">
                                                        {HELPER.PRODUCT_OFF(product)}
                                                        <img src={product.product_image} alt={`"product-"${product?.product_name}`} />
                                                    </div>
                                                </div>
                                            </div> : ""
                                    }
                                </div>
                            );
                        })
                    }
                </div>
                <div
                    className={"cartMenu nestedAccordion"}
                >
                    <Accordion defaultActiveKey={0}>
                        <Accordion.Toggle eventKey={1} as={"div"} component={"div"}>
                            <div onClick={() => false} className="billingDetailsSlide">
                                <i className="new-icon-billing"></i>
                                <span>{translate("CART.BILLING_INFO")}</span>
                                <i className="icomoon-navigate-bottom"></i>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={1} className="billingDetailsWrap">
                            <div className="receiptSection">
                                {/* SUB-TOTAL */}
                                <div className="subTotalDetails" key="sub-total">
                                    {CART_HELPER.RECEIPT_CONTAINER("CART.SUB_TOTAL", sub_total_amount, false, true)}
                                </div>
                                {/* SUB-TOTAL */}
                                {/* Builder Shipping */}
                                {shipment_charges ?
                                <div className="subTotalDetails" key="shipping">
                                    {CART_HELPER.RECEIPT_CONTAINER("CART.SHIPPING", shipment_charges, false)}
                                </div> : ""}
                                {/* Builder Shipping */}
                                {/* ADDITIONAL CHARGES */}
                                <div className="subTotalDetails" key="shipping2">
                                    {!HELPER.isEmpty(additional_charges) ? CART_HELPER.ADD_CHARGES_BREAKDOWN() : ""}
                                </div>
                                {/* ADDITIONAL CHARGES */}
                                {/* DISCOUNT CHARGES */}
                                <div className="subTotalDetails" key="discount">
                                    {CART_HELPER.DISCOUNT_RECEIPT_SECTION("CART.DISCOUNT", discount_amount, false)}
                                </div>
                                {/* DISCOUNT CHARGES */}
                                {/* MERCHANT SERVICE CHARGES */}
                                <div className="subTotalDetails" key="service-charges">
                                    {merchant_service_charges ?
                                        <div className="receiptDiscounts">
                                            {
                                                CART_HELPER.RECEIPT_CONTAINER(
                                                    "CART.SERVICE_CHARGES",
                                                    merchant_service_charges,
                                                    false)
                                            }
                                        </div> : ""
                                    }
                                    {/* MERCHANT SERVICE CHARGES */}
                                </div>
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                </div>
            </div>
            <div className={`cartSlideTotal`}>
                <div className="cartSlideTotalContainer">
                    <span>{translate("CART.TOTAL")}</span>
                    <span className="cartSlideTotalAmount">
                        <span>
                            {HELPER.formatPrice(currency_code, total_amount).currency}
                        </span>
                        {HELPER.formatPrice(currency_code, total_amount).charges}
                    </span>
                </div>
            </div>
        </div>

    )
}


export default MenuWrapper;

