import { LANG_CONSTANTS } from "../actionTypes";
import { IntlActions } from "react-redux-multilingual";
import { LOCAL_STORAGE_SERVICE } from "../../utils";

export const languageAction = {
  openLanguagePopup,
  closeLanguagePopup,
  updateLanguage,
};
// TERMS & CONDITIONS
function openLanguagePopup() {
  return (dispatch) => {
    dispatch(open());
  };
  function open() {
    return { type: LANG_CONSTANTS.LANGUAGE_POPUP_OPEN };
  }
}

function closeLanguagePopup() {
  return (dispatch) => {
    dispatch(close());
  };

  function close() {
    return { type: LANG_CONSTANTS.LANGUAGE_POPUP_CLOSE };
  }
}

function updateLanguage(lang_locale) {
  return (dispatch) => {
    const prevLocale = LOCAL_STORAGE_SERVICE._getFromLocalStorage("locale");
    LOCAL_STORAGE_SERVICE._updateInLocalStorage("locale", lang_locale);
    dispatch(IntlActions.setLocale(lang_locale));
    dispatch(set_locale(lang_locale, prevLocale));
  };

  function set_locale(lang_locale, prevLocale) {
    return {
      type: LANG_CONSTANTS.UPDATE_LANGUAGE_LOCALE,
      locale: lang_locale,
      prevLocale: prevLocale,
    };
  }
}
