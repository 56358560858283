import {
  PAYMENT_CONSTANT, ACTION_CONSTANTS,
  ERROR_CONSTANTS
} from "../actionTypes";
import { apiService } from "../middlewares/api_service";
import {
  alertActions,
  errorAction,
  SUCCESS_ALERT_ACTION,
  LOG_ORDER_EXPIRED,
} from "./index";
import {
  ANALYTICS_HELPER,
  APP_HELPER,
  CONSTANTS,
  HELPER,
  LOCAL_STORAGE_SERVICE,
  REDIRECTION_RULE,
} from "../../utils";

export const paymentAction = {
  get_payment_method,
  post_payment_method,
  UPDATE_ERROR_ORDER_PAYMENT_METHOD,
  UPDATE_EP_PHONE_NUMBER,
  delete_saved_card,
  delete_payment_popup,
  validate_payment_method,
  payment_method_verification,
  update_selected_payment_index,
  PAYMENT_METHOD_SAVE_FOR_NEXT_TIME,
  update_payment_account_no,
  move_back_from_bank_otp_screen,
};

function get_payment_method() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getPaymentMethods()
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const data = response?.data?.body;
          const payment_methods = APP_HELPER.PAYMENT_METHOD_PAYLOAD_UPDATE(data.methods)
          dispatch(success(data, payment_methods));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;
        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          dispatch(failure(error_message?.message));
          dispatch(alertActions.error(error_message?.message));

          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: PAYMENT_CONSTANT.GET_MERCHANT_PAYMENT_METHOD_REQUEST };
  }
  function success(response, data) {
    return {
      type: PAYMENT_CONSTANT.GET_MERCHANT_PAYMENT_METHOD_SUCCESS,
      response,
      data,
    };
  }
  function failure() {
    return { type: PAYMENT_CONSTANT.GET_MERCHANT_PAYMENT_METHOD_FAILURE };
  }
}

function post_payment_method(requestData) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .setPaymentMethod(requestData)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const data = response?.data?.body;
          dispatch(success(data));
          //REDIRECT FROM PAYMENT SCREEN
          REDIRECTION_RULE.POST_PAYMENT_REDIRECTION_RULE();
          dispatch(alertActions.clear());
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          dispatch(failure(error_message?.message));
          dispatch(alertActions.error(error_message?.message));

          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: PAYMENT_CONSTANT.UPDATE_ORDER_PAYMENT_METHOD_REQUEST };
  }
  function success(data) {
    return {
      type: PAYMENT_CONSTANT.UPDATE_ORDER_PAYMENT_METHOD_SUCCESS,
      paymentMethod: data,
    };
  }
  function failure() {
    return { type: PAYMENT_CONSTANT.UPDATE_ORDER_PAYMENT_METHOD_FAILURE };
  }
}

function validate_payment_method(requestData) {
  requestData={...requestData, 
    requestData:{
      ...requestData?.requestData,
      customer_cnic: LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value")}}
  return async (dispatch, getState) => {
    const { bypass_expiry_enabled } = getState().cart?.payment_method || {}
    dispatch(alertActions.clear());
    dispatch(request());
    apiService
      .validatePaymentMethod(requestData?.requestData, bypass_expiry_enabled)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const data = response?.data?.body;
          dispatch(success(data));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;
        ANALYTICS_HELPER._paymentFailure(error_message?.message)
        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          dispatch(failure(errorBody));
          dispatch(alertActions.clear())
          dispatch(alertActions.error(error_message?.message, true, CONSTANTS.ERROR_TYPE.ALERT, false));

          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: PAYMENT_CONSTANT.VALIDATE_PAYMENT_METHOD_REQUEST };
  }
  function success(data) {
    return {
      type: PAYMENT_CONSTANT.VALIDATE_PAYMENT_METHOD_SUCCESS,
      response: data,
    };
  }
  function failure(error_body) {
    return {
      type: PAYMENT_CONSTANT.VALIDATE_PAYMENT_METHOD_FAILURE,
      error: error_body,
    };
  }
}

function delete_saved_card(requestData) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .deleteCreditCard(requestData)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseData = response?.data?.body;
          const payment_methods = APP_HELPER.PAYMENT_METHOD_PAYLOAD_UPDATE(responseData.methods)
          dispatch(success(requestData, responseData, payment_methods));

          const successMessage = SUCCESS_ALERT_ACTION(response);
          dispatch(alertActions.success(successMessage));
          dispatch(close());
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const errorBody = error_response?.data?.body;
        const error_message = errorAction(error_response);

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          dispatch(failure(error_message?.message));
          dispatch(alertActions.error(error_message?.message));

          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          }
        }
        dispatch(close());
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: PAYMENT_CONSTANT.DELETE_CREDIT_CARD_REQUEST };
  }
  function success(payload, response, data) {
    return {
      type: PAYMENT_CONSTANT.DELETE_CREDIT_CARD_SUCCESS,
      payload,
      response,
      data,
    };
  }
  function failure() {
    return { type: PAYMENT_CONSTANT.DELETE_CREDIT_CARD_FAILURE };
  }
  function close() {
    return { type: ACTION_CONSTANTS.MODAL_CLOSE };
  }
}

function UPDATE_EP_PHONE_NUMBER(response) {
  return (dispatch) => {
    dispatch(request(response));
  };

  function request(response) {
    return { type: PAYMENT_CONSTANT.GENERAL.EP_MOBILE_NUMBER_REQUEST, response };
  }
}

function update_payment_account_no(account_no) {
  return (dispatch) => {
    dispatch(request(account_no));
  };

  function request(account_no) {
    return {
      type: PAYMENT_CONSTANT.UPDATE_DEFAULT_PAYMENT_ACCOUNT_NO,
      account_no,
    };
  }
}

function PAYMENT_METHOD_SAVE_FOR_NEXT_TIME(state) {
  return (dispatch) => {
    dispatch(request(state));
  };

  function request(state) {
    return {
      type: PAYMENT_CONSTANT.PAYMENT_METHOD_SAVE_FOR_NEXT_TIME,
      is_default: state ? 1 : 0,
    };
  }
}

function update_selected_payment_index(payment_method) {
  return (dispatch) => {
    dispatch(request(payment_method));
  };

  function request(payment_method) {
    return {
      type: PAYMENT_CONSTANT.UPDATE_SELECTED_PAYMENT_INDEX,
      selected_payment: payment_method,
    };
  }
}

function move_back_from_bank_otp_screen() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: PAYMENT_CONSTANT.VALIDATE_PAYMENT_METHOD_BACK,
    };
  }
}

//THIS FUNCTION WILL REVEAL THAT PAYMENT METHOD VERIFICATION IS REQUIRED AND ALSO SAVE PAYMENT METHOD TYPE
function payment_method_verification(verificationType) {
  return (dispatch) => {
    dispatch(request(verificationType));
  };

  function request() {
    return {
      type: PAYMENT_CONSTANT.PAYMENT_VERIFICATION_REQUIRED,
      verification_type: verificationType,
    };
  }
}

function UPDATE_ERROR_ORDER_PAYMENT_METHOD(error_message) {
  return (dispatch) => {
    if (!HELPER.isEmpty(error_message)) {
      dispatch(alertActions.error(error_message));
    } else {
      dispatch(clear());
      dispatch(alertActions.clear());
    }
  };
  function clear() {
    return { type: PAYMENT_CONSTANT.CLEAR_ERROR_UPDATE_ORDER_PAYMENT_METHOD };
  }
}

function delete_payment_popup(modal, onSuccess) {
  return (dispatch) => {
    const onClose = () => {
      dispatch(close());
    };
    const onCancel = () => {
      dispatch(close());
    };
    dispatch(open(modal, onClose, onSuccess, onCancel));
  };

  function close() {
    return { type: ACTION_CONSTANTS.MODAL_CLOSE };
  }
  function open(modal, onClose, onSuccess, onCancel) {
    return {
      type: ACTION_CONSTANTS.MODAL_OPEN,
      modal,
      onClose,
      onSuccess,
      onCancel,
    };
  }
}
