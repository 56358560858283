import React from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";


function MandatoryPin() {
  const translate = useTranslate();

  const { is_pin_location_mandatory } = useSelector(state => state.configuration.config);

  return !!is_pin_location_mandatory ??
    <div id="mandatoryPin">
      <div className="pinStripe">
        <p>
          {translate("mandatory_pin_placeholder")}
        </p>
      </div>
    </div>
}


export default MandatoryPin;
