import {
  CONFIG_CONSTANTS,
  FORM_CONSTANTS,
} from "../actionTypes";

const initState = {
  name: "",
  email: "",
  contact_number: null,
  selected_country: [],
  country_list: [],
};

const numberReducer = (state = initState, action) => {
  switch (action.type) {
    case CONFIG_CONSTANTS.INITIATE_ORDER_REQUEST:
    case CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_REQUEST:
      return initState;
    case FORM_CONSTANTS.UPDATE_CUSTOMER_NAME:
      return {
        ...state,
        name: action?.response,
      };
    case FORM_CONSTANTS.UPDATE_CUSTOMER_EMAIL:
      return {
        ...state,
        email: action?.response,
      };
    case FORM_CONSTANTS.UPDATE_PHONE_NUMBER:
      return {
        ...state,
        contact_number: action?.response,
      };
    case FORM_CONSTANTS.UPDATE_COUNTRY:
      return {
        ...state,
        selected_country: action?.response,
      };
    case FORM_CONSTANTS.UPDATE_COUNTRY_LIST:
      return {
        ...state,
        country_list: action?.response
      }
    default:
      return state;
  }
};
export default numberReducer;
