

// @flow
//eslint-disable-next-line

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    GOOGLE_ANALYTICS_HELPER,
    HELPER,
    ANALYTICS_HELPER
} from "../../../../utils";
import {
    cartAction
} from "../../../../store/actions";

const MobileMenuWrapper = () => {
    let dispatch = useDispatch();

    const { items, count } = useSelector(state => state.cart.cart_details);
    const { showCartMenu } = useSelector(state => state.action);

    //componentDidMount
    useEffect(() => {
        // returned function will be called on component unmount
        return () => {
            if (showCartMenu) {
                openCartMenu()
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openCartMenu = () => {
        ANALYTICS_HELPER._navigationEventLog(
            GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.NAVIGATION,
            {
                action: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.NAVIGATION.CART_MENU.ACTION,
                label: GOOGLE_ANALYTICS_HELPER.GTM_EVENT.NAVIGATION.CART_MENU.LABEL,
                properties: {}
            }
        );
        dispatch(cartAction.TOGGLE_CART_ICON())
    }

    return (
        !HELPER.isEmpty(items) ? <div className="cartIcon" onClick={openCartMenu} >
            <span className="nav-link">
                <i className="icomoon-navigate-cart"></i>
                {count >= 0 ? <div className="cartCounter"><span>{count}</span></div> : ""}
            </span>
        </div> : ""
    )
};

export default MobileMenuWrapper;
