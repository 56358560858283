import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";

import { isMobile } from "react-device-detect";
import { GoogleMap as ReactGoogleMap, Marker } from "react-google-maps";
import Modal from "../../../components/Modal";

import MapControl from "./control";
import FullScreenControl from "./fullscreen";
import { mapActions, addressAction } from "../../../store/actions";
import {
  CONSTANTS,
  HELPER,
  IMAGE_SRC,
  ROUTE_CONSTANTS,
  history
} from "../../../utils";
import { locationPermissionDenied } from '../../../hooks'
import { isAndroid, osVersion } from "react-device-detect";
import { store } from "../../../store";

class MapComponent extends Component {
  constructor(props) {
    super(props);
    const { translate } = this.props;
    this.state = {
      lat: 24.8259898,
      lng: 66.9890924,
      defaultLat: null,
      defaultLng: null,
      disable: false,
      isMarkerShown: false,
      bounds: false,
      locationPopupTile: translate("browser_location_enable_text"),
      mapTitle: translate("find_geo_location"),
      editLocationTitle: translate("LOCATION.EDIT_LOCATION"),
    };
    this.handleGetMyLocation = this.handleGetMyLocation.bind(this);
    this.handleMapFullScreenControl = this.handleMapFullScreenControl.bind(
      this
    );
  }

  showMarkerConfig() {
    const { p } = this.props;
    const { defaultLat, defaultLng } = this.state;
    if ((!HELPER.isEmpty(defaultLat) || !HELPER.isEmpty(defaultLng)) && p.isMarkerShown) {
      this.setState({ isMarkerShown: true });
    } else {
      this.setState({ isMarkerShown: false });
    }
  }

  componentWillMount() {
    const {
      p,
      updateCoordinatesLat,
      updateCoordinatesLng,
      setCoordinatesLat,
      setCoordinatesLng,
      enableFullScreenMode,
      getMyLocation,
      is_pin_location_mandatory,
      updateLocBounds,
      updateLocBoundsLat,
      updateLocBoundsLng
    } = this.props;
    const { defaultLat, defaultLng } = this.state;
    this.initAutocomplete();

    if ((!HELPER.isEmpty(defaultLat) || !HELPER.isEmpty(defaultLng)) && p.isMarkerShown) {
      this.setState({ isMarkerShown: true });
    } else {
      this.setState({ isMarkerShown: false });
    }
    // if(location.pathname === ROUTE_CONSTANTS.ADD_NEW_ADDRESS && HELPER.isEmpty(setCoordinatesLat))
    // {
    //   this.handleGetMyLocation();
    // }

    if (getMyLocation) {
      this.handleGetMyLocation();
      store.dispatch(mapActions.GET_LOCATION_PIN(false))
    }

    if (!HELPER.isEmpty(updateCoordinatesLat)) {
      const lat = parseFloat(updateCoordinatesLat);
      const lng = parseFloat(updateCoordinatesLng);
      const bounds = new window.google.maps.LatLngBounds();
      const positionLatLng = new window.google.maps.LatLng(lat, lng);
      bounds.extend(positionLatLng);
      this.setState({
        defaultLat: lat,
        defaultLng: lng,
        isMarkerShown: true,
        bounds,
      });
      if (!HELPER.isEmpty(updateCoordinatesLat) && !HELPER.isEmpty(updateCoordinatesLng)) {
        store.dispatch(mapActions.set_position(updateCoordinatesLat, updateCoordinatesLng))
      }

    }

    if(updateLocBounds){
      if(HELPER.isNotEmpty(updateLocBoundsLat)) {
        const lat = parseFloat(updateLocBoundsLat);
        const lng = parseFloat(updateLocBoundsLng);
        const bounds = new window.google.maps.LatLngBounds();
        const positionLatLng = new window.google.maps.LatLng(lat, lng);
        bounds.extend(positionLatLng);
        this.setState({
          defaultLat: lat,
          defaultLng: lng,
          isMarkerShown: true,
          bounds,
        });
        this.setState({
          lat,
          lng,
          disable: true,
        }, this.showMarkerConfig);
      }
    }

    if (!HELPER.isEmpty(setCoordinatesLat)) {
      const lat = parseFloat(setCoordinatesLat);
      const lng = parseFloat(setCoordinatesLng);
      const bounds = new window.google.maps.LatLngBounds();
      const positionLatLng = new window.google.maps.LatLng(lat, lng);
      bounds.extend(positionLatLng);
      this.setState({
        defaultLat: lat,
        defaultLng: lng,
        isMarkerShown: true,
        bounds,
      });
      //store.dispatch(mapActions.set_position(updateCoordinatesLat, updateCoordinatesLng))
    }
    if (enableFullScreenMode && is_pin_location_mandatory) {
      history.push({
        pathname: ROUTE_CONSTANTS.MAP_FULL_SCREEN,
      });
      store.dispatch(mapActions.SET_FULL_SCREEN_MODE(false))
    }
  }

  componentWillReceiveProps(nextProps) {
    const { translate } = this.props;
    const {
      updateCoordinatesLat,
      updateCoordinatesLng,
      find_geo_location,
      getMyLocation,
      clearAddress,
      updateLocBounds,
      updateLocBoundsLat,
      updateLocBoundsLng
    } = nextProps;
    if (getMyLocation) {
      this.handleGetMyLocation();
      store.dispatch(mapActions.GET_LOCATION_PIN(false))
    }

    if (find_geo_location) {
      this.setState({
        mapTitle: translate("found_geo_location"),
      });
    }

      
    if(this.props.updateLocBounds !== updateLocBounds){
      if(HELPER.isNotEmpty(updateLocBoundsLat)) {
        this.setState({
          defaultLat: null,
          defaultLng: null,
          isMarkerShown: false,
        })
        const lat = parseFloat(updateLocBoundsLat);
        const lng = parseFloat(updateLocBoundsLng);
        const bounds = new window.google.maps.LatLngBounds();
        const positionLatLng = new window.google.maps.LatLng(lat, lng);
        bounds.extend(positionLatLng);
        this.setState({
          defaultLat: lat,
          defaultLng: lng,
          isMarkerShown: true,
          bounds,
        });
        this.setState({
          lat,
          lng,
          disable: true,
        }, this.showMarkerConfig);
      }
    }

    if (this.props.updateCoordinatesLat !== nextProps.updateCoordinatesLat) {
      // this.setState({
      //   lat: updateCoordinatesLat,
      //   lng: updateCoordinatesLng,
      //   disable: true,
      // }, this.showMarkerConfig);
      if (!HELPER.isEmpty(updateCoordinatesLat) && !HELPER.isEmpty(updateCoordinatesLng)) {
        store.dispatch(mapActions.set_position(updateCoordinatesLat, updateCoordinatesLng))
      }
    }
    if (clearAddress) {
      this.setState({
        defaultLat: null,
        defaultLng: null,
        isMarkerShown: false,
      })
    }
  }

  initAutocomplete = () => {
    var element = document.getElementById('map-address');
    if (element) {
      const options = {
        componentRestrictions: { country: this.props.international_countries ?? "pk" },
        fields: ["address_components", "formatted_address", "geometry"],
        // origin: map.getCenter(),
        strictBounds: false,
        types: ["establishment"],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        element,
        options
      );
      autocomplete.addListener("place_changed", async () => {
        // var places = new window.google.maps.places.Autocomplete(element);
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert(
            "No details available for input: '" + place.name + "'"
          );
          return;
        } else {
          var latlng = new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
          let obj =await HELPER.getGeocodeAddress(latlng)

          var coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          }
          if (!(HELPER.isEmpty(coordinates?.lat) && HELPER.isEmpty(coordinates?.lng))) {
            const lat = coordinates?.lat;
            const lng = coordinates?.lng;
            const requestData = {
              latitude: lat,
              longitude: lng,
              formatted_address: place?.formatted_address,
              ...obj,
            };
            const prevCoordinatesData = {
              latitude: this.state.defaultLat,
              longitude: this.state.defaultLng,
            };
            store.dispatch(addressAction.get_location_detail(requestData, prevCoordinatesData, true))
          }
        }
      });
    }
  };

  onMarkerDragEnd =async (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    var latlng = new window.google.maps.LatLng(lat ,lng);
    let obj =await HELPER.getGeocodeAddress(latlng)
    if (!(HELPER.isEmpty(lat) && HELPER.isEmpty(lng))) {
      const requestData = {
        latitude: lat,
        longitude: lng,
        ...obj
      };
      const prevCoordinatesData = {
        latitude: this.state.defaultLat,
        longitude: this.state.defaultLng,
      };
      store.dispatch(addressAction.get_location_detail(requestData, prevCoordinatesData))
    }
  };

  handleGetMyLocation = () => {
    const thisref = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          thisref.props.locationPermission !== CONSTANTS.PERMISSIONS.GRANTED && store.dispatch(mapActions.UPDATE_PERMISSION_LOCATION(CONSTANTS.PERMISSIONS.GRANTED));
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          var latlng = new window.google.maps.LatLng(lat ,lng);
          let obj =await HELPER.getGeocodeAddress(latlng)
          if (!HELPER.isEmpty(lat) && !HELPER.isEmpty(lng) && !HELPER.isEmpty(thisref.map)) {
            // const bounds = new window.google.maps.LatLngBounds();
            // const positionLatLng = new window.google.maps.LatLng(lat, lng);
            // bounds.extend(positionLatLng);
            // thisref.map?.panTo({ lat, lng });
            // thisref.setState({
            //   defaultLat: lat,
            //   defaultLng: lng,
            //   isMarkerShown: true,
            //   bounds,
            // });
            const requestData = {
              latitude: lat,
              longitude: lng,
              ...obj
            };
            const prevCoordinatesData = {
              latitude: thisref.state.defaultLat,
              longitude: thisref.state.defaultLng,
            };
            store.dispatch(addressAction.get_location_detail(requestData, prevCoordinatesData, false))
          }
          if (!thisref.props.is_pin_location_mandatory && thisref.props.enableFullScreenMode
            && thisref.props.currentPositionByBtn) {
            store.dispatch(mapActions.SET_FULL_SCREEN_MODE(false))
            history.push(ROUTE_CONSTANTS.MAP_FULL_SCREEN)
          }
        },
        function (error) {
          if (error.code === 1) {
            //if pin location 0 and denied permission go back
            // thisref.props.locationPermission !== CONSTANTS.PERMISSIONS.DENIED && thisref.props.PermissionLocation(CONSTANTS.PERMISSIONS.DENIED);
            thisref.props.locationPermission !== CONSTANTS.PERMISSIONS.DENIED && store.dispatch(mapActions.UPDATE_PERMISSION_LOCATION(CONSTANTS.PERMISSIONS.GRANTED));
            if (!HELPER.isEmpty(thisref.map) && !thisref.props.is_pin_location_mandatory && window.location.pathname === ROUTE_CONSTANTS.MAP_FULL_SCREEN) {
              history.goBack()
            }
            else if (thisref.props.is_pin_location_mandatory && window.location?.pathname !== ROUTE_CONSTANTS.MAP_FULL_SCREEN) {
              if (isAndroid && osVersion === "12" && HELPER.sendEventToAndroid("locationPermissionDenied")) {
                locationPermissionDenied(true)
              }
              else {
                store.dispatch(addressAction.disable_location_permission_popup(thisref.state.locationPopupTile))
                // thisref.props.disableLocationPermissionPopup(thisref.state.locationPopupTile);
              }
            }
          }
          console.error("Error Code = " + error.code + " - " + error.message);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 10000 }
      );
    }
  };

  handleMapFullScreenControl = () => {
    const { location } = this.props;
    if (location.pathname === ROUTE_CONSTANTS.MAP_FULL_SCREEN) {
      history.goBack();
    } else {
      history.push({
        pathname: ROUTE_CONSTANTS.MAP_FULL_SCREEN,
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.defaultLat !== nextState.defaultLat ||
      this.state.mapTitle !== nextState.mapTitle
    ) {
      return true;
    } else {
      // force our map to never update after the initial load. If there is a case where you do want it to update, put that logic here
      return false;
    }
  }

  handleAddressChange = (e) => {
    this.setState({ address: e.target.value })
  };


  render() {
    const {
      lat,
      lng,
      isMarkerShown,
      defaultLat,
      defaultLng,
      bounds,
      mapTitle,
    } = this.state;
    const { locale, location } = this.props;
    let en_mapTypeControlOptions = location.pathname === ROUTE_CONSTANTS.MAP_FULL_SCREEN ? window.google.maps.ControlPosition.TOP_RIGHT : window.google.maps.ControlPosition.TOP_LEFT
    let ur_mapTypeControlOptions = location.pathname === ROUTE_CONSTANTS.MAP_FULL_SCREEN ? window.google.maps.ControlPosition.TOP_LEFT : window.google.maps.ControlPosition.TOP_RIGHT
    return (
      <div>
        <ReactGoogleMap
          ref={(map) => {
            this.map = map;
            if (map && bounds && isMarkerShown) {
              map.fitBounds(bounds);
            }
          }}
          defaultZoom={10}
          disableDefaultUI={true}
          defaultCenter={{ lat, lng }}
          options={{
            draggable: true, // make map draggable
            panControl: false,
            mapTypeControl: isMobile ? false : true,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            rotateControl: false,
            scrollwheel: false,
            zoomControl: true,
            fullscreenControl: false,
            maxZoom: 18,
            mapTypeControlOptions: {
              position: locale === CONSTANTS.IS_URDU ? ur_mapTypeControlOptions : en_mapTypeControlOptions,
            },
            panControlOptions: {
              position: window.google.maps.ControlPosition.TOP_RIGHT,
            },
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            fullscreenControlOptions: {
              position: window.google.maps.ControlPosition.TOP_RIGHT,
              style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            },
          }}
        >
          <>
            <FullScreenControl
              position={locale === CONSTANTS.IS_URDU ? window.google.maps.ControlPosition.TOP_LEFT : window.google.maps.ControlPosition.RIGHT_TOP}
              parentClass="mapTopLayer"
            >
              <div id="fullScreenToggleBtn">
                <div
                  className="fullscreen-btn"
                  onClick={this.handleMapFullScreenControl}
                  id="map-fullscreen"
                  dir="ltr"
                >
                  <button
                    title="Toggle fullscreen view"
                    aria-label="Toggle fullscreen view"
                    type="button"
                    className={"fullscreen-control"}
                  >
                    <img
                      src={IMAGE_SRC.ICONS.MAP_FULL_SCREEN_CONTROL}
                      alt="Toggle fullscreen view"
                    />
                  </button>
                </div>
              </div>
            </FullScreenControl>
            <MapControl
              position={window.google.maps.ControlPosition.RIGHT_BOTTOM}
              parentClass="ribbionParent"
            >
              <div className="mapRibbion"
                onClick={this.handleGetMyLocation}
              >
                <p>{mapTitle} </p>
              </div>
              <div
                className="mappinbtn"
                onClick={this.handleGetMyLocation}
                id="get-my-location"
                dir="ltr"
              >
                <button type="button">
                  <img src={IMAGE_SRC.ICONS.BLACK_MAP_PIN} alt="map location" />
                </button>
              </div>
            </MapControl>
          </>
          {isMarkerShown && (
            <Marker
              position={{
                lat: parseFloat(defaultLat),
                lng: parseFloat(defaultLng),
              }}
              draggable={true}
              onDragEnd={(e) => this.onMarkerDragEnd(e)}
            />
          )}
        </ReactGoogleMap>
        {
          [ROUTE_CONSTANTS.ADD_NEW_ADDRESS, ROUTE_CONSTANTS.UPDATE_ADDRESS].includes(window.location.pathname) &&
          <button type="button" className="btn btn-outline-primary btn-block mapLocationBtn"
            onClick={this.handleMapFullScreenControl}>
            <i className="icomoon-map-link"></i>
            <span>{this.state.editLocationTitle}</span>
          </button>
        }
        <Modal />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    updateCoordinatesLat,
    updateCoordinatesLng,
    setCoordinatesLng,
    setCoordinatesLat,
    enableFullScreenMode,
    getMyLocation,
    clearAddress,
    locationPermission,
    currentPositionByBtn,
    updateLocBounds,
    updateLocBoundsLat,
    updateLocBoundsLng
  } = state.map;
  const { is_pin_location_mandatory, international_countries } = state.configuration.config;
  const { find_geo_location } = state.address;
  const { locale } = state.Intl;
  return {
    updateCoordinatesLat,
    updateCoordinatesLng,
    setCoordinatesLng,
    setCoordinatesLat,
    find_geo_location,
    locale,
    enableFullScreenMode,
    getMyLocation,
    clearAddress,
    is_pin_location_mandatory,
    international_countries,
    locationPermission,
    currentPositionByBtn,
    updateLocBounds,
    updateLocBoundsLat,
    updateLocBoundsLng
  };
}
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslate(MapComponent)));
