// @flow
//eslint-disable-next-line

import * as React from "react";
import { useSelector } from "react-redux";

import "animate.css";

import {
  CART_HELPER,
  CONSTANTS
} from "../../../../utils";
import GeneralBreadcrumb from "../../../../components/App/AppBreadcrumb/Breadcrumb/Types/General";
import OrderStatusBreadcrumb from "../../../../components/App/AppBreadcrumb/Breadcrumb/Types/OrderStatus";
import OTPBreadcrumb from "../../../../components/App/AppBreadcrumb/Breadcrumb/Types/Otp";

function Breadcrumb(props) {
  const { options, label } = props;

  const { configuration, action } = useSelector(state => state);
  const { disableClick } = configuration;
  const { showCartMenu } = action

  const {
    CLASS,
  } = options;

  return (
    <div style={{background: options?.banner_theme}} className={`topStatus animate__animated animate__fadeInDown ${CLASS} ${disableClick ? "noEvents" : ""} ${showCartMenu && (CART_HELPER.CART_DEVICE_TYPE() === CONSTANTS.DEVICE_TYPE.MOBILE) ? "d-none" : ""}`}>
      {label === CONSTANTS.BREADCRUMB_TYPE.GENERAL ? <GeneralBreadcrumb options={options} /> : ""}
      {label === CONSTANTS.BREADCRUMB_TYPE.ORDER_STATUS ? <OrderStatusBreadcrumb options={options} /> : ""}
      {label === CONSTANTS.BREADCRUMB_TYPE.OTP ? <OTPBreadcrumb /> : ""}
    </div>
  );
}

export default Breadcrumb;