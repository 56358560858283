// @flow
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { verificationAction } from "../../store/actions";
import {
  HELPER,
  history,
  ROUTE_CONSTANTS
} from "../../utils";

function OneTapLoginForm() {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const { order_ref } = useSelector(state => state.configuration);
  const { phone_number, name } = useSelector(state => state.configuration.customer);


  const user = HELPER.capitalizeFirstLetter(name.substring(0, 22));

  const continueAsUser = () => {
    // Add identifier parameter to checkout route so that orderInitiate API is called with customer_identifier parameter
    history.push({
      pathname: ROUTE_CONSTANTS.CHECKOUT,
      search: "?ref=" + order_ref,
      query: "?ref=" + order_ref,
      state: {
        identifier: "one-tap-login",
      },
    });
  }

  const notAsUser = () => {
    // redirecting on checkout to show loading stage and on backend call veify token api
    dispatch(verificationAction.REMOVE_CUSTOMER_IDENTIFIER())
    history.push({
      pathname: ROUTE_CONSTANTS.CHECKOUT,
      search: "?ref=" + order_ref,
      query: "?ref=" + order_ref,
      state: {
        identifier: "one-tap-login",
      },
    });
  }

  return (
    <>
      <h1 className="oneTapHeading">{HELPER.capitalizeFirstLetter(name)}</h1>
      <p className="ContNum">
        {phone_number}
      </p>
      <div className="continueBtn newContinueBtnWrap">
        <button
          type="button"
          id="continue-as-user"
          className="btn btn-primary btn-block"
          onClick={continueAsUser}
        >
          {HELPER.oneTapTEXT_FORMAT(
            translate("continue_as_user", {
              customer_name: user,
            }),
            user,
            "AS_USER"
          )}
        </button>
        <button
          type="button"
          id="not-as-user"
          className="btn btn-outline-primary btn-block"
          onClick={notAsUser}
        >
          {HELPER.oneTapTEXT_FORMAT(
            translate("not_as_user", { customer_name: user }),
            user,
            "NOT_AS_USER"
          )}
        </button>
      </div>
    </>
  );
}


export default OneTapLoginForm;
