// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import "animate.css";
import Alert from "../../../components/Alert";
import AppWrapper from "../../../components/App";
import LoadingButton from "../../../components/LoadingButton";

import { PaymentMethods } from "../../../containers";

import {
  logoutAction,
  paymentAction
} from "../../../store/actions";
import {
  BREADCRUMB_SETTINGS_JSON,
  HELPER,
  ROUTE_CONSTANTS,
  GOOGLE_ANALYTICS_HELPER,
  history,
  // FIREBASE_ANALYTICS_HELPER,
  ANALYTICS_HELPER
} from "../../../utils";

let timeoutTime;


const OrderFailure = () => {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const { showBtnLoader } = useSelector(state => state.loading);
  const { footer_note } = useSelector(state => state.payment?.selected_payment_method);
  const { payment_failure_response } = useSelector(state => state.cart);

  const [accessPaymentMethod, updateAccessToPaymentMethod] = useState("")


  const handleSubmit = () => {
    updateAccessToPaymentMethod(true)
  };

  const submitBtnClicked = () => {
    updateAccessToPaymentMethod(!accessPaymentMethod)
  };
  function moveToCart() {
    const { failure_redirect_url, store_url } = payment_failure_response;
    if(HELPER.isEmpty(failure_redirect_url)){
      dispatch(paymentAction.UPDATE_ERROR_ORDER_PAYMENT_METHOD());
      history.push({
        pathname: ROUTE_CONSTANTS.CART,
      });
    }else{
      dispatch(logoutAction.CLEAR_REDUX_STORE());
      HELPER.redirectToPathAndClearLocalStorage(failure_redirect_url, store_url);
    }
  }

  //componentDidMount
  useEffect(() => {
    // FIREBASE_ANALYTICS_HELPER.checkoutSteps(FIREBASE_ANALYTICS_HELPER.CHECKOUT.FAILURE.TIER);
    ANALYTICS_HELPER._checkoutSteps(7, GOOGLE_ANALYTICS_HELPER.GTM_EVENT_CATEGORY.FAILURE);
    // returned function will be called on component unmount
    return () => {
      clearTimeout(timeoutTime);
      if (window.opener !== null) {
        window.onbeforeunload = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      payment_failure,
      pay_limit_reached,
    } = payment_failure_response;
    //CHECK IF PAYMENT IS FAILED THEN SHOW PAYMENT FAILURE PAGE AND RE-RUN PAYMENT FLOW
    if (payment_failure === 1) {
      history.push({
        pathname: ROUTE_CONSTANTS.ORDER_FAILURE,
      });
    }
    //CHECK IF PAYMENT LIMIT IS EXCEEDED THEN SHOW OUT OF RETRIES AND REDIRECT TO STORE
    if (pay_limit_reached) {
      history.push({
        pathname: ROUTE_CONSTANTS.OUT_OF_RETRIES,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_failure_response]);

  return (
    <AppWrapper
      showMerchantLogo={true}
      showAccountDropdown={true}
      showLangIcon={true}
      allowProfileAPI={true}
      showFooter={false}
      breadcrumb={{
        type: BREADCRUMB_SETTINGS_JSON.FAILED_ORDER.TYPE,
        options: BREADCRUMB_SETTINGS_JSON.FAILED_ORDER,
        show: BREADCRUMB_SETTINGS_JSON.FAILED_ORDER.SHOW
      }}
    >
      <div className="screen midWrap bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="signInView midView bg-white">
                <h1>{translate("failure_heading")}</h1>
                <p className="signviewsmalltext">{translate("failure_text")}</p>
                <Alert />
                <PaymentMethods reference={accessPaymentMethod} submitBtnClicked={submitBtnClicked} />

                {/* Cart Screen Fixed Blue Bar Section Starts Here */}
                <div className={`bottomFixedBar paymentBottomFixed ${HELPER.isEmpty(footer_note) ? "" : "footerReviewFixed"}`}>
                  <div className="container">
                    <div className="row">
                      {/* <div className={"optionBtn"}> */}
                      {!HELPER.isEmpty(footer_note) ? <div className="footerReview">
                        <span className="mainfooterReviewText">
                          <span className={"iconW icomoon-info-outline subFooterReviewIcon"}></span>
                          <span className="subFooterReviewText">{footer_note}</span>
                        </span>
                      </div> : ""}
                      <div className={"paymentBottomOptionBtn "}>
                        <button
                          type="button"
                          className="btn btn-outline-primary width150"
                          onClick={() => moveToCart()}
                        >
                          {translate("cancel_btn")}
                        </button>
                        <button
                          type="button"
                          id="payment-method-submit"
                          onClick={handleSubmit}
                          className={"btn btn-primary width150 btn-twice"}
                          disabled={showBtnLoader ? true : false}
                        >
                          <LoadingButton
                            loadingState={showBtnLoader}
                            showBlueLoader={false}
                            preloadingText={translate("retry_btn")}
                            loadingText={translate("retry_btn")}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Cart Screen Fixed Blue Bar Section Ends Here */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppWrapper>
  );
}


export default OrderFailure;
