// @flow
//eslint-disable-next-line

import React from "react";
import CheckoutWrapper from "../../../components/CheckoutWrapper";

import { BREADCRUMB_SETTINGS_JSON } from "../../../utils";
import EditAddressForm from "./form";

function EditAddress() {
  // This functional component specifies to show map component and contain add new address form
  return (
    <CheckoutWrapper
      fullscreenModeEnabled={false}
      showMap={true}
      child={<EditAddressForm />}
      breadcrumbSettings={BREADCRUMB_SETTINGS_JSON.CHECKOUT}
    />
  );
}

export default EditAddress;
