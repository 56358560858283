// @flow
import React, { useEffect } from "react";
import { useTranslate } from "react-redux-multilingual/lib/context";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../components/Alert";
import CheckoutWrapper from "../../components/CheckoutWrapper";

import { alertActions, niftAction, generalAction } from "../../store/actions";
import GeneralCCForm from "./Forms/General";
import NIFTCCForm from "./Forms/NIFT";
import QistPayCCForm from "../QistPay";
import {
  BREADCRUMB_SETTINGS_JSON,
  CONSTANTS,
  HELPER,
  ROUTE_CONSTANTS,
  history,
} from "../../utils";


let timeoutTime;
function CreditCard() {
  let dispatch = useDispatch();
  const translate = useTranslate();

  const { nift_details, nift_msgs, nift_msg_type, payment_processing_details, payment_processing_details_loading } = useSelector(state => state.payment);
  const { payment_failure_response, pay_limit_reached, payment_method } = useSelector(state => state.cart);
  // const { selected_payment_method } = useSelector(state => state.payment);
  // const { route } = useSelector(state => state.redirect);

  const NIFT = payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.NIFT;
  const QISSTPAY = payment_method?.form_type === CONSTANTS.PAYMENT_METHOD.FORM_TYPE.CREDIT_CARD.QISSTPAY;

  //componentDidMount
  useEffect(() => {
    dispatch(alertActions.clear())
    if (NIFT) {
      dispatch(niftAction.CLEAR_ERRORS())
      if (HELPER.isEmpty(nift_details) && !payment_processing_details_loading) {
        dispatch(niftAction.LOAD_TRANSACTION_PARAMS())
      }
    }
    if (QISSTPAY && HELPER.isEmpty(payment_processing_details?.iframe_url) && !payment_processing_details_loading) {
      // props.showQisstPayLoader()
      dispatch(generalAction.PAYMENT_PROCESSOR_DETAIL())
    }
    // returned function will be called on component unmount
    return () => {
      if (NIFT) {
        dispatch(niftAction.CLEAR_CREDENTIALS())
      }
      if (window.opener !== null) {
        // window.onunload = null;
        window.onbeforeunload = null;
      }
      clearTimeout(timeoutTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { payment_failure } = payment_failure_response;
    //CHECK IF PAYMENT IS FAILED THEN SHOW PAYMENT FAILURE PAGE AND RE-RUN PAYMENT FLOW
    if (payment_failure === 1) {
      history.push({
        pathname: ROUTE_CONSTANTS.ORDER_FAILURE,
      });
    }
    //CHECK IF PAYMENT LIMIT IS EXCEEDED THEN SHOW OUT OF RETRIES AND REDIRECT TO STORE
    if (pay_limit_reached) {
      history.push({
        pathname: ROUTE_CONSTANTS.OUT_OF_RETRIES,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_failure_response]);

  useEffect(() => {
    if (NIFT) {
      if (!HELPER.isEmpty(nift_msgs)) {
        nift_msg_type === "success"
          ? dispatch(alertActions.successAlerts(nift_msgs))
          : dispatch(alertActions.error(nift_msgs));
        timeoutTime = setTimeout(() => {
          dispatch(niftAction.CLEAR_ERRORS())
        }, 5000);
        timeoutTime = setTimeout(() => {
          dispatch(alertActions.clear())
        }, 10000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nift_msgs, nift_msg_type]);

  const selectHeading = () => {
    if (QISSTPAY)
      return translate("qp_details_heading");
    else
      return translate("card_details_heading");
  };

  const selectPlaceholder = () => {
    if (QISSTPAY)
      return translate("qp_details_placeholder");
    else
      return translate("card_details_placeholder");
  };

  const formSelection = () => {
    if (NIFT)
      return <NIFTCCForm />;
    else if (QISSTPAY)
      return <QistPayCCForm />;
    else
      return <GeneralCCForm/>;
  };

  const selectChildComponent = () => {
    return (
      <>
        <div className="signInView bg-white">
          {/* PAGE CONTENT HERE STARTS */}
          <h1>{selectHeading()}</h1>
          <p className="signviewsmalltext">
            {selectPlaceholder()}
          </p>
          <Alert />
          {formSelection()}
          {/* PAGE CONTENT HERE END */}
        </div>
      </>
    );
  };
  return (
    <CheckoutWrapper
      fullscreenModeEnabled={false}
      child={selectChildComponent()}
      breadcrumbSettings={BREADCRUMB_SETTINGS_JSON.CHECKOUT}
      showLangIcon={!NIFT}
    // showCCScan={true}
    />
  );
}


export default CreditCard;
