import { ACTION_CONSTANTS, CART_CONSTANTS, CONFIG_CONSTANTS, ERROR_CONSTANTS } from "../../actionTypes";
import { IntlActions } from "react-redux-multilingual";
import { apiService } from "../../middlewares/api_service";
import {
  LOCAL_STORAGE_SERVICE,
  HELPER,
  CONSTANTS,
  ROUTE_CONSTANTS,
  ANALYTICS_HELPER,
  history,
  REDIRECTION_RULE,
} from "../../../utils";
import {
  alertActions,
  errorAction,
  errorPageAction,
  SUCCESS_ALERT_ACTION,
  LOG_ORDER_EXPIRED,
} from "../index";

export const verificationAction = {
  IDENTIFY_CUSTOMER_TOKEN,
  REMOVE_CUSTOMER_IDENTIFIER,
  INITIATE_ORDER,
  OTP_VERIFY,
  OTP_SEND,
  OTP_RESEND,
  SET_OTP_TIMER_STATE,
  MULTI_PHONE_DIALOG,
  CANCEL_ORDER,
  INITIATE_ORDER_ABANDONED,
};

function IDENTIFY_CUSTOMER_TOKEN(reference_token) {
  LOCAL_STORAGE_SERVICE._deleteFromSessionStorage("cnic_value")
  // Parse request data
  const requestData = JSON.stringify({
    order_ref: reference_token,
  });
  return (dispatch) => {
    dispatch(request(reference_token));
    apiService
      .identifyCustomer(requestData)
      .then(async (response) => {
        LOCAL_STORAGE_SERVICE._removeAccessToken();
        // check response status
        const responseStatus = response?.data?.status;
        const responseBody = response?.data?.body;
        // Check if [ user_identified = 1/0, user_name=x, phone_number=x, new_access_token=x]
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS &&
          responseBody?.user_identified === 1
        ) {
          // get customer information from current_access_token
          // return user name, phone_number (92312*****9), new_access_token in response if customer is_verified=1
          dispatch(IntlActions.setLocale(responseBody?.config?.default_lang));
          // show Continue As screen
          LOCAL_STORAGE_SERVICE._nextScreen(); //Increase screen count for back btn display condition
          dispatch(verifiedSuccess(responseBody));
          history.push({
            pathname: ROUTE_CONSTANTS.ONE_TAP_LOGIN,
          });
        } else {
          // if( user_identified ==0), normal flow -- call intialize
          dispatch(unverifiedSuccess(responseBody));
          history.push({
            pathname: ROUTE_CONSTANTS.CHECKOUT,
            search: "?ref=" + reference_token,
            query: "?ref=" + reference_token,
            state: {
              identifier: "one-tap-login",
            },
          });
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorPageAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response === undefined) {
          dispatch(failure(error_message?.message));
          history.push({
            pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
            state: error_message,
          });
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            if (error_message?.type === ERROR_CONSTANTS.MESSAGE) {
              dispatch(failure(error_message?.message));
              dispatch(alertActions.error(error_message?.message));
            }
            else if (error_message?.type === ERROR_CONSTANTS.ERROR) {
              dispatch(errorScreen(error_message));
              history.push({
                pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
              });
            }
            else if(error_response?.status !== CONSTANTS.HTTP_RESPONSE.SERVER_ERROR){
              dispatch(failure());
              history.push({
                pathname: ROUTE_CONSTANTS.CHECKOUT,
                search: "?ref=" + reference_token,
                query: "?ref=" + reference_token,
                state: {
                  identifier: "one-tap-login",
                },
              });
            }
          }
        }
      });
  };

  function request(reference_token) {
    return { type: CONFIG_CONSTANTS.IDENTIFY_CUSTOMER_VALIDATION_REQUEST, reference_token };
  }
  function verifiedSuccess(data) {
    return {
      type: CONFIG_CONSTANTS.IDENTIFY_CUSTOMER_VALIDATION_SUCCESSFULL,
      data,
    };
  }
  function unverifiedSuccess(data) {
    return {
      type: CONFIG_CONSTANTS.IDENTIFY_CUSTOMER_VALIDATION_UNSUCCESSFULL,
      data,
    };
  }
  function failure() {
    return { type: CONFIG_CONSTANTS.IDENTIFY_CUSTOMER_VALIDATION_FAILURE };
  }
  function errorScreen(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
}

function REMOVE_CUSTOMER_IDENTIFIER() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: CONFIG_CONSTANTS.CUSTOMER_IDENTIFIER_DELETE };
  }
}

function INITIATE_ORDER(reference_token, identifier) {
  LOCAL_STORAGE_SERVICE._clearLocalStorage();
  LOCAL_STORAGE_SERVICE._deleteFromSessionStorage("cnic_value")
  // delete access token before order initiate;
  LOCAL_STORAGE_SERVICE._removeAccessToken();
  // Parse request data
  var requestData;
  if (HELPER.isEmpty(identifier)) {
    requestData = JSON.stringify({
      order_ref: reference_token,
      screen_params: {
        color_depth: window.screen.colorDepth,
        inner_width: window.innerWidth,
        inner_height: window.innerHeight,
      }
    });
  } else {
    requestData = JSON.stringify({
      order_ref: reference_token,
      customer_identifier: identifier,
      screen_params: {
        color_depth: window.screen.colorDepth,
        inner_width: window.innerWidth,
        inner_height: window.innerHeight,
      }
    });
  }
  return (dispatch) => {
    dispatch(request());
    apiService
      .orderInitiate(requestData)
      .then((response) => {
        const responseStatus = response?.data?.status;
        /* Check if access token exists and verify it via another api
         * else check if respone sttaus is success login a customer account
         */
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseBody = response?.data?.body;
          //Parse phone numbers
          // var phone = responseBody?.customer?.phone_number;

          // let customer_info = [];
          // if (!HELPER.isEmpty(phone)) {
          //   // Parse country code and phone number
          //   var countryCode = phone.substring(0, 2);
          //   var networkCode = phone.substring(2, 5);
          //   var phoneNumber = phone.slice(5);
          //   var showPhone = phoneNumber.slice(-4);
          //   var hideNumbers = "";
          //   for (var i = phoneNumber.length - 4; i > 0; i--) {
          //     hideNumbers += "*";
          //   }
          //   customer_info.country_code = countryCode;
          //   customer_info.phone_network_code = networkCode;
          //   customer_info.phone = hideNumbers + showPhone;
          // }
            setTimeout(() => {
              ANALYTICS_HELPER._initOrderLogging(responseBody);
            }, 2000);
          dispatch(IntlActions.setLocale(responseBody?.config?.default_lang));
          LOCAL_STORAGE_SERVICE._saveToLocalStorage("locale", responseBody?.config?.default_lang);
          dispatch(success(responseBody, reference_token));
          REDIRECTION_RULE.POST_PHONE_REDIRECTION_RULE(responseBody)
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorPageAction(error_response);

        if (error_response === undefined) {
          dispatch(errorScreen(error_message));
          history.push({
            pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
          });
        } else {
          if (error_message.type === ERROR_CONSTANTS.MESSAGE) {
            dispatch(failure(error_message?.message));
            dispatch(alertActions.error(error_message?.message));
          } else if(error_response?.status !== CONSTANTS.HTTP_RESPONSE.SERVER_ERROR){
            dispatch(errorScreen(error_message));
            history.push({
              pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
            });
          }
        }
      });
  };

  function request() {
    return { type: CONFIG_CONSTANTS.INITIATE_ORDER_REQUEST };
  }
  function success(response, reference_token) {
    return {
      type: CONFIG_CONSTANTS.INITIATE_ORDER_SUCCESS,
      response,
      // customer_info,
      reference_token,
    };
  }
  function failure() {
    return { type: CONFIG_CONSTANTS.INITIATE_ORDER_FAILURE };
  }
  function errorScreen(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
}

function OTP_VERIFY(otp) {
  const requestData = JSON.stringify({
    otp,
  });
  return (dispatch, getState) => {
    const { route } = getState().redirect;
    const { ask_for_verification } = getState().cart;

    const redirectFromSuccess = [ROUTE_CONSTANTS.ORDER_SUCCESS,ROUTE_CONSTANTS.OUT_OF_RETRIES].includes(route.from);
    dispatch(request());
    apiService
      .verifyOtp(requestData)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseBody = response?.data?.body;
          dispatch(success(responseBody));

          if(ask_for_verification){
            dispatch({type: CART_CONSTANTS.POST_CHECKOUT_VERIFICATION_COMPLETED});
            history.push({
              pathname: ROUTE_CONSTANTS.ORDER_SUCCESS,
            });
          }
          
          if(HELPER.isNotEmpty(responseBody?.app_setup)){
            REDIRECTION_RULE.POST_OTP_REDIRECTION_RULE(responseBody);
          } else if (redirectFromSuccess){
            const { cart_success } = getState().cart;
            const redirect_url = cart_success?.redirect_url;
            const store_url = cart_success?.store_url;
            HELPER.redirectToPathAndClearLocalStorage(redirect_url, store_url, false);
          }
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        dispatch(failure(error_message?.message));
        dispatch(alertActions.error(error_message?.message));

        if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        } else if (!HELPER.isEmpty(errorBody?.app_url)) {
          setTimeout(() => {
            HELPER.redirectToPathAndClearLocalStorage(errorBody?.app_url);
          }, 2500);
        }
      });
  };

  function request() {
    return { type: CONFIG_CONSTANTS.OTP_VERIFY_REQUEST };
  }
  function success(data) {
    return {
      type: CONFIG_CONSTANTS.OTP_VERIFY_SUCCESS,
      data,
    };
  }
  function failure() {
    return { type: CONFIG_CONSTANTS.OTP_VERIFY_FAILURE };
  }
}

function SET_OTP_TIMER_STATE(state) {
  return (dispatch) => {
    dispatch(request(state));
  }
  function request(state) {
    return { type: CONFIG_CONSTANTS.DISABLE_OTP_TIMER, state };
  }
}

function OTP_RESEND(network_id) {
  const requestData = JSON.stringify({
    network_id,
  });
  return (dispatch) => {
    dispatch(request());
    apiService
      .resendOtp(requestData)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const successMessage = SUCCESS_ALERT_ACTION(response);
          dispatch(alertActions.success(successMessage));
          dispatch(success());
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        dispatch(failure(error_message?.message));
        dispatch(alertActions.error(error_message?.message));

        if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        } else if (!HELPER.isEmpty(errorBody?.app_url)) {
          setTimeout(() => {
            HELPER.redirectToPathAndClearLocalStorage(errorBody?.app_url);
          }, 2500);
        }
      });
  };

  function request() {
    return { type: CONFIG_CONSTANTS.OTP_RETRY_REQUEST };
  }
  function success() {
    return { type: CONFIG_CONSTANTS.OTP_RETRY_SUCCESS };
  }
  function failure() {
    return { type: CONFIG_CONSTANTS.OTP_RETRY_FAILURE };
  }
}

function OTP_SEND(data) {
  return (dispatch, getState) => {
    const { verification_type } = getState().cart;
    const { initial_screen } = getState().redirect;
    let _api= verification_type ? apiService.sendOtpAfterCheckout(data) : apiService.sendOtp(data)

    dispatch(request(data));
    _api.then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseBody = response?.data?.body;
          dispatch(success(responseBody));
          if(initial_screen !== CONSTANTS.SCREEN_REDIRECT.OTP){
            LOCAL_STORAGE_SERVICE._nextScreen();
          }

          history.push({
            pathname: ROUTE_CONSTANTS.OTP_SCREEN,
          });
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        dispatch(failure(error_message?.message));
        dispatch(alertActions.error(error_message?.message));

        if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        } else if (!HELPER.isEmpty(errorBody?.app_url)) {
          setTimeout(() => {
            HELPER.redirectToPathAndClearLocalStorage(errorBody?.app_url);
          }, 2500);
        }

      });
  };
  function request(data) {
    return { type: CONFIG_CONSTANTS.PHONE_VERIFICATION_REQUEST, data };
  }
  function success(response) {
    return {
      type: CONFIG_CONSTANTS.PHONE_VERIFICATION_SUCCESS,
      response,
    };
  }
  function failure() {
    return { type: CONFIG_CONSTANTS.PHONE_VERIFICATION_FAILURE };
  }
}

function MULTI_PHONE_DIALOG(bool) {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: CONFIG_CONSTANTS.MULTI_PHONE_DIALOG, bool};
  }
}

function CANCEL_ORDER(body) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .orderCancel(body)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseBody = response?.data?.body;
          dispatch(success(responseBody));
          if(responseBody?.cancel_url){
						window.location.href = responseBody.cancel_url
					}
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status === CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {
          dispatch(errorPage(error_message));
        } else {
          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          } else {
            dispatch(failure(error_message?.message));
            dispatch(alertActions.error(error_message?.message));
          }
        }
      });
  };

  function errorPage(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
  function request() {
    return { type: CONFIG_CONSTANTS.CANCEL_ORDER.REQUEST };
  }
  function success() {
    return { type: CONFIG_CONSTANTS.CANCEL_ORDER.SUCCESS };
  }
  function failure() {
    return { type: CONFIG_CONSTANTS.CANCEL_ORDER.FAILURE };
  }
}

function INITIATE_ORDER_ABANDONED(reference_token, identifier) {
  LOCAL_STORAGE_SERVICE._clearLocalStorage();
  LOCAL_STORAGE_SERVICE._removeAccessToken();
  var requestData;
  if (HELPER.isEmpty(identifier)) {
    requestData = JSON.stringify({
      order_ref: reference_token,
      screen_params: {
        color_depth: window.screen.colorDepth,
        inner_width: window.innerWidth,
        inner_height: window.innerHeight,
      }
    });
  } else {
    requestData = JSON.stringify({
      order_ref: reference_token,
      customer_identifier: identifier,
      screen_params: {
        color_depth: window.screen.colorDepth,
        inner_width: window.innerWidth,
        inner_height: window.innerHeight,
      }
    });
  }
  return (dispatch) => {
    dispatch(request());
    dispatch({type: ACTION_CONSTANTS.SHOW_PAGE_LOADER})
    apiService
      .orderInitiateAbandoned(requestData)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseBody = response?.data?.body;
          dispatch(IntlActions.setLocale(responseBody?.config?.default_lang));
          LOCAL_STORAGE_SERVICE._saveToLocalStorage("locale", responseBody?.config?.default_lang);
          dispatch(success(responseBody, reference_token));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorPageAction(error_response);

        if (error_response === undefined) {
          dispatch(errorScreen(error_message));
          history.push({
            pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
          });
        } else {
          if (error_message.type === ERROR_CONSTANTS.MESSAGE) {
            dispatch(failure(error_message?.message));
            dispatch(alertActions.error(error_message?.message));
            history.push({
              pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
            });
          } else {
            dispatch(errorScreen(error_message));
            history.push({
              pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
            });
          }
        }
      });
  };

  function request() {
    return { type: CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_REQUEST };
  }
  function success(response, reference_token) {
    return {
      type: CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_SUCCESS,
      response,
      reference_token,
    };
  }
  function failure() {
    return { type: CONFIG_CONSTANTS.INITIATE_ORDER_ABANDONED_FAILURE };
  }
  function errorScreen(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
}
