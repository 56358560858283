// @flow
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { niftAction, alertActions } from "../../../../store/actions";
import {
  HELPER,
  CONSTANTS,
  history,
  LOCAL_STORAGE_SERVICE
} from "../../../../utils";

function NIFTForm() {
  let dispatch = useDispatch();
  const { payment_method } = useSelector(state => state.cart);

  const niftTransactionParameters = useSelector(state => state.payment.nift_details.PGWHPCTransactionParameters);

  //componentDidMount
  useEffect(() => {
    try {
      initNIFTTransaction();
    } catch (error) {
      window.onerror("error found in NIFT FORM Initialization", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initNIFTTransaction = () => {
    try {
      setTransactionParameters();
    } catch (error) {
      window.onerror("error found in setTransactionParameters", error);
    }
  };

  const setTransactionParameters = () => {
    /*eslint-disable */
    PGWHPCTransactionParameters.__00amt__ = niftTransactionParameters.__00amt__;
    PGWHPCTransactionParameters.__01brn__ = niftTransactionParameters.__01brn__;
    PGWHPCTransactionParameters.__02des__ = niftTransactionParameters.__02des__;
    PGWHPCTransactionParameters.__03lan__ = niftTransactionParameters.__03lan__;
    PGWHPCTransactionParameters.__04mid__ = niftTransactionParameters.__04mid__;
    PGWHPCTransactionParameters.__05pwd__ = niftTransactionParameters.__05pwd__;
    PGWHPCTransactionParameters.__06url__ = niftTransactionParameters.__06url__;
    PGWHPCTransactionParameters.__07smid__ = niftTransactionParameters.__07smid__;
    PGWHPCTransactionParameters.__08seh__ = niftTransactionParameters.__08seh__;
    PGWHPCTransactionParameters.__09cur__ = niftTransactionParameters.__09cur__;
    PGWHPCTransactionParameters.__10tdt__ = niftTransactionParameters.__10tdt__;
    PGWHPCTransactionParameters.__11edt__ = niftTransactionParameters.__11edt__;
    PGWHPCTransactionParameters.__12trn__ = niftTransactionParameters.__12trn__;
    PGWHPCTransactionParameters.__13ver__ = niftTransactionParameters.__13ver__;
    //PGWHPCTransactionParameters.__14fbu__ = "";

    /*eslint-enable */
    try {
      initializeEventListener();
    } catch (error) {
      window.onerror("error found in initializeEventListener", error);
    }
  };

  const initializeEventListener = () => {
    try {
      document.head.insertAdjacentHTML(
        "beforeend",
        `<style id="antiClickjack" type="text/css">
        </style>`
      );
      /*eslint-disable */
      if(CONSTANTS.PAYMENT_METHOD.INPUT_TYPE.NIFT_CC_FORM === payment_method?.input_type){
        PGWHPCCard.initialize("PGWHPCCARDContainer2");
      }else{
        PGWHPCAcct.initialize("PGWHPCACCTContainer2");
      }
     
      /*eslint-enable */
      responseListener();
    } catch (error) {
      window.onerror("error found in initialize", error);
    }
  };

  const setErrors = (errorMsg) => {
    if (!HELPER.isEmpty(errorMsg)) {
      if (!HELPER.isEmpty(errorMsg?.error)) {
        dispatch(niftAction.SHOW_ERRORS("danger", errorMsg?.error));
      }
      else {
        dispatch(niftAction.SHOW_ERRORS("danger", errorMsg));
      }
    }
  };

  const updateNIFTErrorResponse = (msg, nift_response_type) => {
    processTransactionResponse(msg, nift_response_type);
    if(!HELPER.isEmpty(msg?.pp_ResponseMessage)) {
      setErrors(msg?.pp_ResponseMessage);
    } else if(typeof msg === 'string' || msg instanceof String) {
      setErrors(msg);
    }else { 
      // console.log("updateNIFTErrorResponse: ", msg)
    }
    /*eslint-disable */
    enableProcessButton();
    /*eslint-enable */
  };

  const processTransactionResponse = (msg, nift_response_type) => {
    /*eslint-disable */
    const response = {
      nift_response_type,
      nift_response_code: msg?.pp_ResponseCode,
      nift_response: msg,
      nift_request: { PGWHPCTransactionParameters },
      retreivalReferenceNo: msg?.pp_RetreivalReferenceNo,
      txnDateTime: msg?.pp_TxnDateTime,
      txnRefNo: msg?.pp_TxnRefNo,
      customer_cnic: LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value")
    };
    /*eslint-enable */
    dispatch(niftAction.FORM_SUBMIT(response, history));
  };

  const responseListener = () => {
    /*eslint-disable */
    //Success Code Mappings
    PGWHPCSuccessMap.onValidSecurityCode = function (msg) {
      // setSuccessMessages(msg);
    };
    PGWHPCSuccessMap.onValidMasterCard = function (msg) {
      // setSuccessMessages(msg);
    };
    PGWHPCSuccessMap.onValidSessionResponse = function (msg) {
      // setSuccessMessages(msg);
    };
    PGWHPCSuccessMap.onPurchaseProcessedViaCard = function (msg) {
      processTransactionResponse(msg, CONSTANTS.NIFT_RESPONSE_HANDLER.API);
    };
    PGWHPCSuccessMap.onPurchaseProcessedViaAccount = function (msg) {
      processTransactionResponse(msg, CONSTANTS.NIFT_RESPONSE_HANDLER.API);
    };

    // 7. Error Code Mappings
    //Error Code Mappings
    PGWHPCErrorMap.onInvalidTransactionDetails = function (msg) {
      setErrors(msg);
    };
    PGWHPCErrorMap.onInvalidContainerArea = function (msg) {
      setErrors(msg);
    };
    PGWHPCErrorMap.onInvalidCardNumber = function (msg) {
      setErrors(msg);
    };
    PGWHPCErrorMap.onInvalidExpiryYear = function (msg) {
      setErrors(msg);
    };
    PGWHPCErrorMap.onInvalidExpiryMonth = function (msg) {
      setErrors(msg);
    };
    PGWHPCErrorMap.onInvalidSecurityCode = function (msg) {
      setErrors(msg);
    };
    PGWHPCErrorMap.onInvalidCNIC = function (msg) {
      setErrors(msg)
    };
    PGWHPCErrorMap.onInvalidContact = function (msg) {
      updateNIFTErrorResponse(msg, CONSTANTS.NIFT_RESPONSE_HANDLER.API);
    };
    PGWHPCErrorMap.onInvalidBank = function (msg) {
      setErrors(msg)
    };
    PGWHPCErrorMap.onInvalidAccount = function (msg) {
      updateNIFTErrorResponse(msg, CONSTANTS.NIFT_RESPONSE_HANDLER.API);
    };
    PGWHPCErrorMap.onInvalidOTP = function (msg) {
      updateNIFTErrorResponse(msg, CONSTANTS.NIFT_RESPONSE_HANDLER.API);
    };
    PGWHPCErrorMap.onRequestTimedOut = function (msg) {
      updateNIFTErrorResponse(msg, CONSTANTS.NIFT_RESPONSE_HANDLER.API);
    };
    PGWHPCErrorMap.onSystemError = function (msg) {
      updateNIFTErrorResponse(msg, CONSTANTS.NIFT_RESPONSE_HANDLER.API);
    };
    PGWHPCErrorMap.onGenericError = function (msg) {
      updateNIFTErrorResponse(msg, CONSTANTS.NIFT_RESPONSE_HANDLER.API);
    };
    //New handlers
    PGWHPCErrorMap.onExpiredOTP = function (msg) {}
    PGWHPCSuccessMap.onSuccessProcessAccountDetails = function (msg) {}
    PGWHPCSuccessMap.onSuccessPaymentValidation = function (msg) {}
    /*eslint-enable */
    // this.enableProcessButton();
  };

  return <></>;
}

export default withRouter(NIFTForm);
