import React from "react";
import { IMAGE_SRC } from "../../utils";

function LoadingButton(props) {
  const {
    loadingState,
    preloadingText,
    loadingText,
    showBlueLoader = false,
  } = props;

  const Spinner = () => {
    return (
      <img
        src={
          showBlueLoader
            ? IMAGE_SRC.BSECURE_BLUE_SPINNER
            : IMAGE_SRC.BSECURE_SPINNER
        }
        className="zoom2 pr-2"
        height="20"
        alt="spinner-white"
      />
    );
  };

  return loadingState ? (
    <>
      {" "}
      <Spinner showBlueLoader={showBlueLoader} /> <span>{loadingText}</span>{" "}
    </>
  ) : (
    <span>{preloadingText}</span>
  );
}

export default LoadingButton;
