import { PAYMENT_CONSTANT } from "../../actionTypes";
import { apiService } from "../../middlewares/api_service";
import {
  LOG_ORDER_EXPIRED,
  alertActions,
  cartAction,
  errorAction
} from "../index";
import { CONSTANTS, ROUTE_CONSTANTS, HELPER, LOCAL_STORAGE_SERVICE } from "../../../utils";

export const niftAction = {
  SHOW_ERRORS,
  CLEAR_ERRORS,
  LOAD_TRANSACTION_PARAMS,
  CLEAR_CREDENTIALS,
  FORM_SUBMIT,
  LOAD_SESSION_JS,
  LOAD_PLUGIN_JS,
  RELOAD_NIFT_SCRIPT,
};

function RELOAD_NIFT_SCRIPT(val) {
  return (dispatch) => {
    dispatch(request(val));
  };

  function request() {
    return {
      type: PAYMENT_CONSTANT.NIFT.RE_LOAD_NIFT_SCRIPT,
      state: val,
    };
  }
}

function LOAD_PLUGIN_JS(state) {
  return (dispatch) => {
    dispatch(request(state));
  };

  function request(state) {
    return {
      type: PAYMENT_CONSTANT.NIFT.LOAD_NIFT_PLUGIN_SCRIPT,
      state,
    };
  }
}

function LOAD_SESSION_JS(state) {
  return (dispatch) => {
    dispatch(request(state));
  };

  function request() {
    return {
      type: PAYMENT_CONSTANT.NIFT.LOAD_SESSION_JS,
      state,
    };
  }
}

function CLEAR_ERRORS() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: PAYMENT_CONSTANT.NIFT.CLEAR_MSGS,
    };
  }
}

function SHOW_ERRORS(type, msg) {
  return (dispatch) => {
    dispatch(request(type, msg));
  };

  function request(msg_type, msg) {
    return {
      type: PAYMENT_CONSTANT.NIFT.UPDATE_MSGS,
      msg_type,
      msg,
    };
  }
}

function LOAD_TRANSACTION_PARAMS(data) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getNIFTDetails(data)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const data = response?.data?.body;
          dispatch(success(data));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const errorBody = error_response?.data?.body;
        dispatch(failure(error_message.message));
        if(error_message.status === 422){
          dispatch(alertActions.error(error_message?.message));
        }

        if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        }
      });
  };

  function request() {
    return { type: PAYMENT_CONSTANT.NIFT.GET_NIFT_DETAILS_REQUEST };
  }
  function success(data) {
    return {
      type: PAYMENT_CONSTANT.NIFT.GET_NIFT_DETAILS_SUCCESS,
      response: data,
    };
  }
  function failure() {
    return { type: PAYMENT_CONSTANT.NIFT.GET_NIFT_DETAILS_FAILURE };
  }
}

function CLEAR_CREDENTIALS() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: PAYMENT_CONSTANT.NIFT.CLEAR_NIFT_DETAILS,
    };
  }
}

function FORM_SUBMIT(data, history) {
  let isErrorReporting = data?.error_reporting
  return async (dispatch,getState) => {
    const { bypass_expiry_enabled } = getState().cart?.payment_method || {}
    dispatch(request());
    if(isErrorReporting) dispatch({type: PAYMENT_CONSTANT.NIFT.NIFT_SCRIPT_ERROR_COUNT})

    apiService
      .validatePaymentMethod(data, bypass_expiry_enabled)
      .then((response) => {
        const responseStatus = response?.data?.status;
        
        if(isErrorReporting) return

        var requestData = {
          customer_cnic: LOCAL_STORAGE_SERVICE._getFromLocalStorage("cnic_value"),
        };

        cartAction.place_order(requestData, history);

        dispatch(success(responseStatus));
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        const responseStatus = error_response?.status;
        const errorBody = error_response?.data?.body;

        dispatch(failure(responseStatus, errorBody));
        dispatch(alertActions.error(error_message?.message));

        if (errorBody?.isExpired === 1) {
          dispatch(LOG_ORDER_EXPIRED(errorBody));
        }
        else if (errorBody?.payment_failure === 1) {
          //CHECK IF PAYMENT IS FAILED THEN SHOW PAYMENT FAILURE PAGE AND RE-RUN PAYMENT FLOW
          history.push({
            pathname: ROUTE_CONSTANTS.ORDER_FAILURE,
          });
        }
        else if (errorBody?.limit_reached) {
          //CHECK IF PAYMENT LIMIT IS EXCEEDED THEN SHOW OUT OF RETRIES AND REDIRECT TO STORE
          history.push({
            pathname: ROUTE_CONSTANTS.OUT_OF_RETRIES,
          });
        }
      });
  };

  function request() {
    return { type: PAYMENT_CONSTANT.NIFT.UPDATE_NIFT_RESP_REQUEST };
  }
  function success(status) {
    return {
      type: PAYMENT_CONSTANT.NIFT.UPDATE_NIFT_RESP_SUCCESS,
      code: status,
    };
  }
  function failure(responseStatus, errorBody) {
    return {
      type: PAYMENT_CONSTANT.NIFT.UPDATE_NIFT_RESP_FAILURE,
      code: responseStatus,
      error: errorBody,
    };
  }
}
