import React from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { Accordion, Card } from "react-bootstrap";
import {
  IMAGE_SRC,
  CONSTANTS
} from "../../../utils";

function EasyPaisaProcessing() {
  const translate = useTranslate();
  const { input_type } = useSelector(state => state.cart.payment_method);

  const instructions = input_type === CONSTANTS.PAYMENT_METHOD.INSTRUCTIONS.JAZZCASH ? "PAYMENT_METHODS.PROCESSING.JAZZCASH" : "PAYMENT_METHODS.PROCESSING.EASYPAISA"
  const interNetworkIcon = input_type === CONSTANTS.PAYMENT_METHOD.INSTRUCTIONS.JAZZCASH ? IMAGE_SRC.PAYMENT_METHODS.JAZZ_CASH : IMAGE_SRC.PAYMENT_METHODS.TELENOR;
  const intraNetworkIcon = input_type === CONSTANTS.PAYMENT_METHOD.INSTRUCTIONS.JAZZCASH ? IMAGE_SRC.PAYMENT_METHODS.MOBILINK : IMAGE_SRC.PAYMENT_METHODS.EASY_PAISA;

  return (
    <>
      <div className="processingRadio processingCardRadio">
        <div className="accordion">
          <Card key={1}>
            <Accordion.Toggle
              as={Card.Header}
              className="ep-card-header-left "
              eventKey={1}
              component={"div"}
            >
              <div className="media ep-card">
                <div className="ep-card-body">
                  <div className="ep-card-detail">
                    <div className={"image"}>
                      <img
                        src={interNetworkIcon}
                        alt={"credit card"}
                        key={Date.now()}
                      />
                    </div>
                    <div className="information">
                      <h5 className="mt-0 overflowText">
                        {translate(`${instructions}.INTER_NETWORK`)}
                      </h5>{" "}
                      <span>{translate(`${instructions}.INTER_NETWORK_INSTRUCTIONS`)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Toggle>
          </Card>
          <Card key={2}>
            <Accordion.Toggle
              as={Card.Header}
              className="ep-card-header-left "
              eventKey={2}
              component={"div"}
            >
              <div className="media ep-card">
                <div className="ep-card-body">
                  <div className="ep-card-detail">
                    <div className={"image"}>
                      <img
                        src={intraNetworkIcon}
                        alt={"credit card"}
                        key={Date.now()}
                      />
                    </div>
                    <div className="information">
                      <h5 className="mt-0 overflowText">
                        {translate(`${instructions}.INTRA_NETWORK`)}
                      </h5>{" "}
                      <span>{translate(`${instructions}.INTRA_NETWORK_INSTRUCTIONS`)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Toggle>
          </Card>
        </div>
      </div>
    </>
  );
}

export default EasyPaisaProcessing;
