import { Divider } from "@material-ui/core";
import React from "react";
import { useTranslate } from "react-redux-multilingual/lib/context";
import AppWrapper from "../../components/App";
import { BREADCRUMB_SETTINGS_JSON } from "../../utils";

function Status() {
  const t = useTranslate();

  return (
    <>
      <AppWrapper
        showPoweredBy={true}
        showAccountDropdown={false}
        showLangIcon={false}
        showMerchantLogo={false}
        allowProfileAPI={false}
        showFooter={false}
        breadcrumb={{
          type: BREADCRUMB_SETTINGS_JSON.DEFAULT.TYPE,
          options: BREADCRUMB_SETTINGS_JSON.DEFAULT,
          show: BREADCRUMB_SETTINGS_JSON.DEFAULT.SHOW
        }}
        homePageHeader={true}
      >
        <div className="statusContainer">
            <div className="inProgress">
              <h2>{t("STATUS.IN_PROGRESS")}</h2>
            </div>
            <Divider className="inProgressDivider" />
            <div className="inProgressText">
              {t("STATUS.IN_PROGRESS_TEXT")}
            </div>
        </div>
      </AppWrapper>
    </>
  );
}

export default Status;
