import React, { useEffect, useState } from "react";
import { HELPER, IMAGE_SRC, history, LOCAL_STORAGE_SERVICE } from "../../../utils";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import routes from "../../../route";

let image;
function MerchantLogo(props) {
    const [showBackIcon, setshowBackIcon] = useState(false)
    const [customerBack, setcustomerBack] = useState(true)
    const { imgClasses } = props;
    const { fav_icon_url, desktop_logo_url, is_customer_app, store_url } = useSelector(state => state.configuration.config);
    //Check added to check if favicon image is provided by api; If it is then only update favicon.
    if (!HELPER.isEmpty(fav_icon_url)) {
        HELPER.setFavicon(fav_icon_url);
    }
    //Check added to check if desktop_logo_url image is provided by api; If it is then only update desktop_logo_url else show default one.
    if (!HELPER.isEmpty(desktop_logo_url) && desktop_logo_url !== undefined) {
        image = desktop_logo_url;
    } else {
        image = IMAGE_SRC.BSECURE_SECURE_LOGO;
    }

    useEffect(() => {
        let path = routes.find(route => {
            return route.path === location?.pathname;
        });
        setcustomerBack(path.hideCustomerBack)
        setshowBackIcon(!!path?.allowBack)
    }, [])

    useEffect(() => {
        if (!HELPER.isEmpty(fav_icon_url)) {
            HELPER.setFavicon(fav_icon_url);
        }
        //Check added to check if desktop_logo_url image is provided by api; If it is then only update desktop_logo_url else show default one.
        if (!HELPER.isEmpty(desktop_logo_url) && desktop_logo_url !== undefined) {
            image = desktop_logo_url;
        } else {
            image = IMAGE_SRC.BSECURE_SECURE_LOGO;
        }
    }, [desktop_logo_url, fav_icon_url])

    const moveBackwards = () => {
        if (LOCAL_STORAGE_SERVICE._isFirstScreen()) {
            HELPER.redirectToPathAndClearLocalStorage(store_url, store_url);
        }
        else {
            history.goBack();
        }
    }

    return <div className="headerLogo">
        {showBackIcon && isMobile && is_customer_app === 1
            && !customerBack ? <div className="back_icon" onClick={moveBackwards}>
            <i className="icomoon-arrow-back1"></i>
        </div> : ""
        }
        {
            HELPER.isNotEmpty(image) ?
                <div className={`logo_One ${showBackIcon && isMobile && is_customer_app === 1
                    && !customerBack ? "backIcon" : ""}`}>
                    <img src={image} className={imgClasses} alt="bSecure" width="94" height="47" />
                </div> : ""
        }
    </div>
}


export default MerchantLogo
