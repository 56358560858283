// @flow

import * as React from "react";
import AppWrapper from "../../components/App";
import MetaTags from "../../components/MetaTags";

function SSOWrapper(props) {
  const {
    child,
    breadcrumbOptions,
    showPoweredBy,
    showAccountDropdown,
    showMerchantLogo,
    allowProfileAPI,
    showLangIcon,
    showFooter,
  } = props;

  return (
    <>
      <MetaTags />
      <AppWrapper
        showPoweredBy={showPoweredBy}
        showAccountDropdown={showAccountDropdown}
        showLangIcon={showLangIcon}
        showMerchantLogo={showMerchantLogo}
        showFooter={showFooter}
        allowProfileAPI={allowProfileAPI}
        breadcrumb={breadcrumbOptions}
      >
        <div className={`phone-screen screen midWrap bg-white`}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="signInView midView bg-white">{child}</div>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    </>
  );
}

export default SSOWrapper;
