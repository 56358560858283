// @flow
//eslint-disable-next-line
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual/lib/context";

import { dialogAction } from "../../../store/actions";
import {
    IMAGE_SRC,
    CONSTANTS
} from "../../../utils";

function AppFooter() {
    let dispatch = useDispatch();
    const translate = useTranslate();

    const openTermsPopup = () => {
        dispatch(dialogAction.openDialog(CONSTANTS.DIALOG.CATEGORY.MODAL, CONSTANTS.DIALOG.TYPE.MODAL.TERMS));
    };


    const openPrivacyPopup = () => {
        dispatch(dialogAction.openDialog(CONSTANTS.DIALOG.CATEGORY.MODAL, CONSTANTS.DIALOG.TYPE.MODAL.PRIVACY));
    };

    return (
        <>
            <section className="footerWrap">
                <div className="container">
                    <div className="footer-main">
                        <img src={IMAGE_SRC.FOOTER_LOGO} alt="" onClick={() => window.open(process.env.REACT_APP_BSECURE_WEBSITE_URL, '_blank')} />
                        <ul>
                            <li><span onClick={openTermsPopup}>{translate("FOOTER.TERMS")}</span></li>
                            <li><span onClick={openPrivacyPopup}>{translate("FOOTER.PRIVACY")}</span></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AppFooter;
