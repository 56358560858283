import { CATALOG_ORDERS, ERROR_CONSTANTS } from "../../actionTypes";
import { apiService } from "../../middlewares/api_service";
import {
  errorPageAction,
  LOG_ORDER_EXPIRED
} from "../../actions";
import {
  CONSTANTS,
  ROUTE_CONSTANTS,
  HELPER
} from "../../../utils";

export const catalogAction = {
  initiate_product_catalogue,
};

function initiate_product_catalogue(product_reference, history) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .verifyCatalogOrder(product_reference)
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseBody = response?.data?.body;
          const order_reference = responseBody?.order_reference;
          history.push({
            pathname: ROUTE_CONSTANTS.CHECKOUT,
            search: "?ref=" + order_reference,
            query: "?ref=" + order_reference,
          });
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorPageAction(error_response);
        const errorBody = error_response?.data?.body;

        if (error_response?.status !== CONSTANTS.HTTP_RESPONSE.SERVER_ERROR) {

          if (errorBody?.isExpired === 1) {
            dispatch(LOG_ORDER_EXPIRED(errorBody));
          }
  
          dispatch(failure(error_message?.message));
          dispatch(errorScreen(error_message));
  
          history.push({
            pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
          });
        }
        
      });
  };

  function request() {
    return { type: CATALOG_ORDERS.INITIATE_CATALOG_ORDER_REQUEST };
  }
  function failure() {
    return { type: CATALOG_ORDERS.INITIATE_CATALOG_ORDER_FAILURE };
  }
  function errorScreen(error) {
    return { type: ERROR_CONSTANTS.ERROR, error };
  }
}
